export default [
  {
    p: "زۀ طالبعلم يم.",
    f: "zu taalibílum yim.",
    e: "I am a student.",
    a: "zu-taalibilum-yum",
  },
  {
    p: "تاسو طالبعلمان يئ؟",
    f: "taaso taalibilmáan yey?",
    e: "Are you students?",
    a: "taaso-taalibiliumaan-yey",
  },
  {
    p: "هغه شپُونکے دے.",
    f: "haghá shpoonkáy day.",
    e: "He is a shepherd.",
    a: "hagha-shpoonkay-day",
  },
  {
    p: "هغوی شپُونکی دی.",
    f: "haghwee shpoonkée dee.",
    e: "They are shepherds.",
    a: "haghwee-shpoonkee",
  },
  {
    p: "هغه کمیاب سړے دے.",
    f: "hágha kamyáab saRáy day.",
    e: "He is a successful man.",
    a: "hagha-kamyaab-sarray-day",
  },
  {
    hider: "show plural",
    p: "هغوى کامياب سړى دی.",
    f: "haghwée kamyaab saRée dee.",
    e: "They are successful men.",
    a: "haghwee-kamyaab-sarree-dee",
  },
  {
    p: "هغه مشهُور ډاکټر دے.",
    f: "hagha mushahóor DaakTúr day.",
    e: "He is a famous doctor.",
    a: "hagha-mash-dakt",
  },
  {
    hider: "show plural",
    p: "هغوى مشهُور ډاکټران دى.",
    f: "haghwee mushahóor DaakTuráan dee.",
    e: "They are famous doctors.",
    a: "haghwee-mash-dakt",
  },
  {
    p: "تۀ يو کلک مُسلمان يې؟",
    f: "tu yo kluk mUsalmáan ye?",
    e: "Are you a strong Muslim?",
    a: "tu-yo-kluk-muslm",
  },
  {
    hider: "show plural",
    p: "تاسو کلک مسلمانان يئ؟",
    f: "taaso kluk mUsalmaanáan yey?",
    e: "Are you strong Muslims?",
    a: "taaso-kluk-mslmn",
  },
  {
    p: "دے يو مالدار ملک دے.",
    f: "day yo maaldáar malík day.",
    e: "He is a rich tribal leader.",
    a: "day-yo-maldaar-mlk",
  },
  {
    hider: "show plural",
    p: "هغوی مالداره ملکان دی.",
    f: "haghwee maaldáara malikaan dee.",
    e: "They are rich tribal leaders.",
    a: "haghwee-maldaara-malikaan",
  },
  {
    p: "هغه مُلا غټ دے.",
    f: "hágha mUláa ghaT day.",
    e: "That mullah is fat.",
    a: "hagha-muulaa-ghatt",
  },
  {
    p: "هغه مُليان غټ دى.",
    f: "hágha mUlayáan ghaT dee.",
    e: "Those mullahs are fat.",
    a: "hagha-muulayan-ghatt",
  },
  {
    p: "هغه سست عيسائى دے.",
    f: "hágha sust eesaaée day.",
    e: "He is a lazy Christian.",
    a: "hagha-sust-eesaaee",
  },
  {
    p: "هغوى سست عيسايان دى.",
    f: "haghwée sust eesaayáan dee.",
    e: "They are lazy Christians.",
    a: "haghwee-sust-eesaayaan",
  },
  {
    p: "دے کُوڼ پښتُون دے.",
    f: "day kooN puxtóon day.",
    e: "He is a deaf Pukhtun.",
    a: "day-koonn-puxtoon",
  },
  {
    p: "هغوى کانړۀ پښتانۀ دى.",
    f: "haghwée kaaNú puxtaanú dee.",
    e: "They are deaf Pukhtuns.",
    a: "haghwee-kaannu-puxtaanu",
  },
  {
    p: "دا درُوند مېلمه دے.",
    f: "day droond melmá day.",
    e: "He is an important guest.",
    a: "daa-droond-melma",
  },
  {
    p: "هغوى درانۀ مېلمانۀ دى.",
    f: "haghwée draanú melmaanú dee.",
    e: "They are important guests.",
    a: "haghwee-draanu-melmaanu",
  },
  {
    p: "زما ترۀ ډېر خفه دے.",
    f: "zmáa tru Der khufa day.",
    e: "My uncle is very upset.",
    a: "zmaa-tru-dder-khufa",
  },
  {
    p: "زما ترُونه ډېر خفه دى.",
    f: "zmáa tróona Der khufa dee.",
    e: "My uncles are very upset.",
    a: "zmaa-troona-dder-khufa",
  },
  {
    p: "هغه ښۀ پلار دے.",
    f: "hagha xu plaar day.",
    e: "He is a good father.",
    a: "hagha-xu-plaar",
  },
  {
    p: "هغوى ښۀ پلاران دى.",
    f: "haghwée xu plaaráan dee.",
    e: "They are good fathers.",
    a: "haghwee-xu-plaaraan",
  },
  {
    p: "ستا ورور ډېر تکړه دے.",
    f: "staa wroor Der tukRá day.",
    e: "Your brother is very strong.",
    a: "staa-wror-dder-takrra",
  },
  {
    p: "ستا ورُوڼه ډېر تکړه دى.",
    f: "staa wróoNa Der tukRá dee.",
    e: "Your brothers are very strong.",
    a: "staa-wroonna-dder-takrra",
  },
  {
    p: "زما مور ستړې ده.",
    f: "zumaa mor stúRe da.",
    e: "My mother is tired.",
    a: "zmaa-mor-sturre-da",
  },
  {
    p: "زمُونږ میندې ستړې دي.",
    f: "zmoonG myande stúRe dee.",
    e: "Our mothers are tired.",
    a: "zmoongg-myande-sturre",
  },
];
