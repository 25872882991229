
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.36,"time":21600,"words":72}
/* @jsxRuntime classic */
/* @jsx mdx */
import memorizingMoments from "./memorizing-moments";
import Examples from "../components/Examples";

export const numbers = ["One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen", "Twenty", "Twenty-One", "Twenty-Two", "Twenty-Three", "Twenty-Four", "Twenty-Five", "Twenty-Six", "Twenty-Seven"];

const layoutProps = {
  numbers
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Below are all the memorising moments from the lessons in order:`}</p>
    <div>
    {memorizingMoments.slice(1).map((chap, i) => <>
            <h5>Lesson {numbers[i]}</h5>
            <Examples memorizingMoment mdxType="Examples">
                {chap}
            </Examples>
        </>)}
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {
  "title": "Memorising Moments"
}

