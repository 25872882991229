
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"44 min read","minutes":43.105,"time":2586300,"words":8621}
/* @jsxRuntime classic */
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import memorizingMoments from "./memorizing-moments";
import donkeyMan from "../assets/images/donkey-man.png";
import potter from "../assets/images/potter.png";
import washingDishes from "../assets/images/washing-dishes.png";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-fourteen-directions"
    }}>{`Dialogue fourteen: Directions`}</h2>
    <h4 {...{
      "id": "to-the-post-office"
    }}>{`To the Post Office`}</h4>
    <Dialogue audio="dialogue-14" mdxType="Dialogue">
  {[{
        s: "L",
        p: "بخښنه غواړم ډاکخانه چرته ده؟",
        f: "bakhxana ghwáaRum, Daakkhaaná chărta da?",
        e: "Excuse me, where is the Post Office?",
        a: "14d1"
      }, {
        s: "H",
        p: "تاسو پياده ځئ؟",
        f: "taaso pyaadá dzey?",
        e: "Are you going on foot?",
        a: "14d2"
      }, {
        s: "L",
        p: "او، کۀ لرې نۀ وى",
        f: "aw, ku lure nu wee.",
        e: "Yes, if it's not far.",
        a: "14d3"
      }, {
        s: "H",
        p: "مخامخ لاړ شه، نو بيا په چوک کښې ګڅ لاس ته تاؤ شه بيا هلته پوښتنه وکه.",
        f: "mukhaamúkh laaR sha, no byaa pu chok ke guts laas ta taaw sha byaa halta poxtúna oo-ka.",
        e: "Go straight and then turn left at the intersection, then ask there.",
        a: "14d4"
      }, {
        s: "L",
        p: "څومره لرې ده؟",
        f: "tsóomra lúre da?",
        e: "How far is it?",
        a: "14d5"
      }, {
        s: "H",
        p: "ډېره لرې خو نۀ ده، نزدې ده.",
        f: "Déra lúre kho nu da, nizde da.",
        e: "It's not very far, it's close.",
        a: "14d6"
      }]}
    </Dialogue>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`👆 Today, as you go out, make a point of asking several people for directions to places you already know and use this dialogue. You can practice giving directions by looking for confirmation of what you already know about the location you are heading for. For example:`}</p>
    <Examples memorizingMoment mdxType="Examples">
  {[{
        p: "زۀ ډاکخانې ته ځم. اول ښى طرف ته تاؤ شم – دا ټيک ده؟",
        f: "zu Daakhaané ta dzum. awwál xee taráf ta taaw shum – daa Teek da?",
        e: "I'm going to the post office. First I turn right – is that correct?",
        a: "14e1"
      }]}
    </Examples>
    <h4 {...{
      "id": "directions-to-your-own-house"
    }}>{`Directions to your own house`}</h4>
    <img src={donkeyMan} style={{
      float: "right"
    }} />
    <p>{`👆 Ask someone who knows your house to give directions to it. Then write them here in phonetics or script and learn them. Invite a friend over.`}</p>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation drill`}</h2>
    <ol>
      <li parentName="ol">{`Illustrating `}<Word w={{
          p: "ښک",
          f: "xk",
          e: "",
          a: "xk-sound"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "دا ښکر دے.",
        f: "daa xkur day.",
        e: "This is a horn.",
        a: "14p1"
      }, {
        p: "دے ښکار کوى.",
        f: "day xhaar kawee.",
        e: "He is hunting.",
        a: "14p2"
      }, {
        p: "دے خپله ماشُومه ښکلوى.",
        f: "day khpula maashóoma xkUlawee.",
        e: "He is kissing his daughter.",
        a: "14p3"
      }, {
        p: "تۀ ښُکلے ښکارې.",
        f: "tu xkUlay xkaare.",
        e: "You are looking handsome.",
        a: "14p4"
      }]}
    </Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Contrasting `}<Word w={{
          p: "ڼ",
          f: "N",
          e: "",
          a: "nn-sound"
        }} mdxType="Word" /> and <Word w={{
          p: "ن",
          f: "n",
          e: "",
          a: "n-sound"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "مڼې خوښوم.",
        f: "maNé khwaxawum.",
        e: "I like apples.",
        a: "14p5"
      }, {
        p: "رڼا ښکارى.",
        f: "raNáa xkáaree.",
        e: "The light is coming.",
        a: "14p6"
      }, {
        p: "دا کڼه ده کۀ نه؟",
        f: "daa kaNá da ku na?",
        e: "Is she deaf or not?",
        a: "14p7"
      }, {
        p: "کارخانه بنده ده.",
        f: "kaarkhaaná bánda da.",
        e: "The factory is closed.",
        a: "14p8"
      }]}
    </Examples>
    <h2 {...{
      "id": "imperatives"
    }}>{`Imperatives`}</h2>
    <ol>
      <li parentName="ol">{`The imperative relates to commands, orders, directions, requests and prohibitions. Imperatives are formed by dropping the person endings from the `}<em parentName="li">{`present`}</em>{` or `}<em parentName="li">{`subjunctive`}</em>{` forms of verbs and adding the appropriate imperative ending (see the charts below for examples). For example the base subjunctive form of `}<Word w={{
          p: "پوهېدل",
          f: "pohedúl",
          e: "",
          a: "pohedul-f"
        }} mdxType="Word" /> meaning 'to know, to understand', is <Word w={{
          p: "پوه شـ",
          f: "poh sh-",
          e: "",
          a: ""
        }} mdxType="Word" />{`, and so the imperative forms become:`}</li>
    </ol>
    <p>{`{" "}`}</p>
    <Table bordered unset wide verticalContent titleRow={["Singular", "Plural or Polite Sing."]} mdxType="Table">
  {[[{
        p: "پوه شه!",
        f: "poh sha!",
        e: "Understand!",
        a: "poh-sha"
      }, {
        p: "پوه شئ!",
        f: "poh shey!",
        e: "Understand!",
        a: "poh-shey"
      }]]}
    </Table>
As you can see, you add a <Word w={{
      p: "ـه",
      f: "-a",
      e: "",
      a: "a-sound"
    }} mdxType="Word" /> ending
to make an imperative directed at a single person and <Word w={{
      p: "ـئ",
      f: "-ey",
      e: "",
      a: "ey-sound"
    }} mdxType="Word" /> for an imperative directed at multiple people. You can also use <Word w={{
      p: "ـئ",
      f: "-ey",
      e: "",
      a: "ey-sound"
    }} mdxType="Word" /> if you're being polite talking to one person. For example:
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "تاسو هلته وګرځئ!",
        f: "táaso hálta óogardzey!",
        e: "You walk there!",
        a: "14e2"
      }]}
    </Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Using the `}<em parentName="li">{`subjunctive`}</em>{` form of a verb for an imperative indicates that you are commanding someone to do something as a one-time event (often right away). Using the regular present form of the verb means that you are commanding someone to do something in general, or repeated over some time. Simple (non-compound) verbs are made into their subjunctive forms by adding `}<Word w={{
          p: "و",
          f: "oo-",
          e: "",
          a: "oo-sound"
        }} mdxType="Word" />{` to the beginning. Note the following examples:`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "دا هلک وهه!",
        f: "daa halúk wahá!",
        e: "Hit this boy! (Generally, whenever)",
        a: "14e3"
      }, {
        p: "دا هلک ووهه!",
        f: "daa halúk óo-waha!",
        e: "Hit this boy! (one-time / now)",
        a: "14e4"
      }, {
        p: "هره ورځ يوه ګولۍ خوره!",
        f: "hărawrudz yuwa golúy khorá!",
        e: "Eat one pill every day! (repeated)",
        a: "14e5"
      }, {
        p: "دا ګولۍ نن وخوره!",
        f: "daa golúy nun óo-khora!",
        e: "Eat this pill today! (one-time)",
        a: "14e6"
      }]}
    </Examples>
    <p><em parentName="p">{`note: Some grammars might describe this as the difference between the “perfective” and “imperfective” aspects. (Adding `}<Word w={{
          p: "و",
          f: "-oo",
          e: "",
          a: "oo-sound"
        }} mdxType="Word" />{` puts it in the "`}<a parentName="em" {...{
          "href": "https://en.wikipedia.org/wiki/Perfective_aspect"
        }}>{`perfective aspect`}</a>{`").`}</em></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`When making imperative commands using compound verbs you need to be careful and see if whether a compound verb is a “doing” (dynamic) compound or a “making” (stative) compound.`}</li>
    </ol>
    <p>{`a. “Doing” (dynamic) compounds often use the verb `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to do",
        a: "kawul"
      }} mdxType="Word" />, and the object of the sentence is <em>inside of the compound</em>. When making the subjunctive / one-time forms of the verb, you <em>add a <Word w={{
          p: "و",
          f: "oo",
          e: "",
          a: "oo-sound"
        }} mdxType="Word" /></em>{` on the front.`}</p>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "مینه کوه!",
        f: "meena kawa!",
        e: "Show love! (generally)",
        a: "14e7"
      }, {
        p: "مینه وکه!",
        f: "meena oo-ka!",
        e: "Show love! (one-time, now)",
        a: "14e8"
      }]}
    </Examples>
    <p>{`b. “Making” (stative) compounds use the verb `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to make",
        a: "kawul"
      }} mdxType="Word" />, to apply the other word in the compound to an object <em>outside of the compound</em>. When you make the subjunctive / one-time form of these compound you <em>do <strong>not</strong> add a <Word w={{
          p: "و",
          f: "oo",
          e: "",
          a: "oo-sound"
        }} mdxType="Word" />{` on front`}</em>{`!`}</p>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "اوبهٔ ورکوه!",
        f: "oobú wăr-kawá!",
        e: "Water it! (continuously, generally)",
        a: "14e9"
      }, {
        p: "اوبهٔ ورکه!",
        f: "oobú wăr-ka!",
        e: "Water it! (one-time, now)",
        a: "14e10"
      }]}
    </Examples>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`The negative form is shown by the prefix `}<Word w={{
          p: "مهٔ",
          f: "mu",
          e: "",
          a: "mu-f"
        }} mdxType="Word" />{` meaning “don’t” as in the following:`}</li>
    </ol>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "دا مهٔ کوه!",
        f: "daa mú-kawa!",
        e: "Don't do that",
        a: "14e11"
      }]}
    </Examples>
    <p>{`In the negative, there is no distinction between the one-time or continuous forms of the commands. There’s just one form that covers both.`}</p>
    <h2 {...{
      "id": "a-summary-of-the-imperative-forms-of-verbs"
    }}>{`A summary of the imperative forms of verbs`}</h2>
    <ul>
      <li parentName="ul">{`The top of each row ending in `}<Word w={{
          p: "ـه",
          f: "-a",
          e: "",
          a: "a-sound"
        }} mdxType="Word" /> is for commands directed to the singular <Word w={{
          p: "ته",
          f: "tu",
          e: "",
          a: "tu"
        }} mdxType="Word" /></li>
      <li parentName="ul">{`the bottom row ending in `}<Word w={{
          p: "ـئ",
          f: "-ey",
          e: "",
          a: "ey-sound"
        }} mdxType="Word" /> is for commands directed to the plural or respectful <Word w={{
          p: "تاسو",
          f: "táaso",
          e: "",
          a: "taaso"
        }} mdxType="Word" />{`.`}</li>
    </ul>
    <Table bordered wide unset verticalContent titleRow={["Infinitive", [<>One-Time</>, <span style={{
      fontWeight: "normal",
      fontSize: "smaller"
    }}>
        (from subjunctive)
      </span>], [<>Continuous</>, <span style={{
      fontWeight: "normal",
      fontSize: "smaller"
    }}>
        (from present)
      </span>], "Negative"]} mdxType="Table">
  {[[{
        p: "اچول",
        f: "aachawul",
        e: "to put in",
        a: "achawul",
        rowSpan: 2
      }, {
        p: "واچوه",
        f: "wáachawa",
        e: "",
        a: "waachawa"
      }, {
        p: "اچوه",
        f: "aachawá",
        e: "",
        a: ""
      }, {
        p: "مهٔ اچوه",
        f: "mú-aachawa",
        e: "",
        a: "mu-aachawa"
      }], [{
        p: "واچوئ",
        f: "wáachawey",
        e: "",
        a: "waachawey"
      }, {
        p: "اچوئ",
        f: "aachawéy",
        e: "",
        a: ""
      }, {
        p: "مهٔ اچوئ",
        f: "mú-aachawey",
        e: "",
        a: "mu-aachawey"
      }], [{
        p: "اخستل",
        f: "akhistúl",
        e: "to buy/take",
        a: "akhistul-m",
        rowSpan: 2
      }, {
        p: "واخله",
        f: "wáakhla",
        e: "",
        a: "waakhla"
      }, {
        p: "اخله",
        f: "aakhlá",
        e: "",
        a: ""
      }, {
        p: "مهٔ اخله",
        f: "mú-aakhla",
        e: "",
        a: "mu-aakhla"
      }], [{
        p: "واخلئ",
        f: "wáakhley",
        e: "",
        a: "waakhley"
      }, {
        p: "اخلئ",
        f: "aakhléy",
        e: "",
        a: ""
      }, {
        p: "مهٔ اخلئ",
        f: "mú-aakhley",
        e: "",
        a: "mu-aakhley"
      }], [{
        p: "اغوستل",
        f: "aaghostúl",
        e: "to wear",
        a: "aghostul-m",
        rowSpan: 2
      }, {
        p: "واغونده",
        f: "wáaghonda",
        e: "",
        a: "waaghonda"
      }, {
        p: "اغونده",
        f: "áaghonda",
        e: "",
        a: "aaghonda"
      }, {
        p: "مهٔ اغونده",
        f: "mú-aaghonda",
        e: "",
        a: "mu-aaghonda"
      }], [{
        p: "واغوندئ",
        f: "wáaghondey",
        e: "",
        a: "waaghondey"
      }, {
        p: "اغوندئ",
        f: "áaghondey",
        e: "",
        a: ""
      }, {
        p: "مهٔ اغوندئ",
        f: "mú-aaghondey",
        e: "",
        a: "mu-aaghondey"
      }], [{
        p: "اورېدل",
        f: "aawredúl",
        e: "to hear",
        a: "aawredul-m",
        rowSpan: 2
      }, {
        p: "واوره",
        f: "wáawra",
        e: "",
        a: "waawra"
      }, {
        p: "اوره",
        f: "áawra",
        e: "",
        a: ""
      }, {
        p: "مهٔ اوره",
        f: "mú-aawra",
        e: "",
        a: "mu-aawra"
      }], [{
        p: "واورئ",
        f: "wáawrey",
        e: "",
        a: "waawrey"
      }, {
        p: "اورئ",
        f: "áawrey",
        e: "",
        a: ""
      }, {
        p: "مهٔ اورئ",
        f: "mú-aawrey",
        e: "",
        a: "mu-aawrey"
      }], [{
        p: "اوسېدل",
        f: "osedul",
        e: "to live / stay",
        a: "osedul-m",
        rowSpan: 2
      }, [{
        p: "اوسه",
        f: "ósa",
        a: "osa-perf"
      }, {
        p: "اسېږه",
        f: "óseGa",
        e: "",
        a: ""
      }], [{
        p: "اوسه",
        f: "osá",
        e: "",
        a: ""
      }, {
        p: "اسېږه",
        f: "oséGa",
        e: "",
        a: "osegga-imp"
      }], [{
        p: "مهٔ اوسه",
        f: "mú-osa",
        e: "",
        a: "mu-osa"
      }, {
        p: "مه اسېږه",
        f: "mú-oseGa",
        e: "",
        a: "mu-osegga"
      }]], [[{
        p: "اوسئ",
        f: "ósey",
        a: "osey-perf"
      }, {
        p: "اوسېږئ",
        f: "óseGey",
        a: ""
      }], [{
        p: "اوسئ",
        f: "oséy",
        a: ""
      }, {
        p: "اسېږئ",
        f: "oséGey",
        e: "",
        a: "oseggey-imp"
      }], [{
        p: "مهٔ اوسئ",
        f: "mú-osey",
        e: "",
        a: "mu-osey"
      }, {
        p: "مه اسېږئ",
        f: "mú-oseGey",
        e: "",
        a: "mu-oseggey"
      }]], [{
        p: "پخښل",
        f: "bakhxúl",
        e: "to forgive",
        a: "bakhxul-m",
        rowSpan: 2
      }, {
        p: "ابخښه",
        f: "óobakhxa",
        e: "",
        a: "oobakhxa"
      }, {
        p: "بخښه",
        f: "bakhxá",
        e: "",
        a: ""
      }, {
        p: "مهٔ بخښه",
        f: "mú-bakhxa",
        e: "",
        a: "mu-bakhxa"
      }], [{
        p: "ابخښئ",
        f: "óobakhxey",
        e: "",
        a: "oobakhxey"
      }, {
        p: "بخښئ",
        f: "bakhxéy",
        e: "",
        a: ""
      }, {
        p: "مهٔ بخښئ",
        f: "mú-bakhxey",
        e: "",
        a: "mu-bakhxey"
      }], [{
        p: "بوتلل",
        f: "botlúl",
        e: "to take (animate)",
        a: "botlul-m",
        rowSpan: 2
      }, {
        p: "بوځه",
        f: "bódza",
        e: "",
        a: "bodza-perf"
      }, {
        p: "بوځه",
        f: "bodzá",
        e: "",
        a: ""
      }, {
        p: "مهٔ بوځه",
        f: "mú-bodza",
        e: "",
        a: "mu-bodza"
      }], [{
        p: "بوځئ",
        f: "bódzey",
        e: "",
        a: "bodzey-perf"
      }, {
        p: "بوځئ",
        f: "bodzéy",
        e: "",
        a: ""
      }, {
        p: "مهٔ بوځئ",
        f: "mú-bodzey",
        e: "",
        a: "mu-bodzey"
      }], [{
        p: "پاڅېدل",
        f: "paatsedul",
        e: "to get up",
        a: "paatsedul-m",
        rowSpan: 2
      }, [{
        p: "پاڅه",
        f: "páatsa",
        e: "",
        a: "paatsa-perf"
      }, {
        p: "پاڅېږه",
        f: "páatseGa",
        e: "",
        a: ""
      }], [{
        p: "پاڅه",
        f: "paatsá",
        e: "",
        a: ""
      }, {
        p: "پاڅېږه",
        f: "paatséGa",
        e: "",
        a: ""
      }], [{
        p: "مه پاڅه",
        f: "mú-paatsa",
        e: "",
        a: "mu-paatsa"
      }, {
        p: "مهٔ پاڅېږه",
        f: "mú-paatseGa",
        e: "",
        a: ""
      }]], [[{
        p: "پاڅئ",
        f: "páatsey",
        e: "",
        a: "paatsey-perf"
      }, {
        p: "پاڅېږئ",
        f: "páatseGey",
        e: "",
        a: ""
      }], [{
        p: "پاڅئ",
        f: "paatséy",
        e: "",
        a: ""
      }, {
        p: "پاڅېږئ",
        f: "paatséGey",
        e: "",
        a: ""
      }], [{
        p: "مه پاڅئ",
        f: "mú-paatsey",
        e: "",
        a: "mu-paatsey"
      }, {
        p: "مهٔ پاڅېږئ",
        f: "mú-paatseGey",
        e: "",
        a: ""
      }]], [{
        p: "پوهېدل",
        f: "pohedúl",
        e: "to know (understand)",
        a: "pohedul-m",
        rowSpan: 2
      }, {
        p: "پوه شه",
        f: "póh sha",
        e: "",
        a: "poh-sha"
      }, {
        p: "پوهېږه",
        f: "pohéGa",
        e: "",
        a: ""
      }, {
        p: "مهٔ پوهېږه",
        f: "mú-poheGa",
        e: "",
        a: "mu-pohegga"
      }], [{
        p: "پوه شئ",
        f: "póh shey",
        e: "",
        a: "poh-shey"
      }, {
        p: "پوهېږئ",
        f: "pohéGey",
        e: "",
        a: ""
      }, {
        p: "مهٔ پوهېږئ",
        f: "mú-poheGey",
        e: "",
        a: "mu-poheggey"
      }], [{
        p: "پېژندل",
        f: "pejzandúl",
        e: "to know (recognize)",
        a: "pejzandul-m",
        rowSpan: 2
      }, {
        p: "وپېژنه",
        f: "óopejzana",
        e: "",
        a: "oopejzana"
      }, {
        p: "پېژنه",
        f: "péjzana",
        e: "",
        a: ""
      }, {
        p: "مهٔ پېژنه",
        f: "mú-pejzana",
        e: "",
        a: "mu-pejzana"
      }], [{
        p: "وپېژنئ",
        f: "óopejzaney",
        e: "",
        a: "oopejzaney"
      }, {
        p: "پېژنئ",
        f: "péjzaney",
        e: "",
        a: ""
      }, {
        p: "مهٔ پېژنئ",
        f: "mú-pejzaney",
        e: "",
        a: "mu-pejzaney"
      }], [{
        p: "تلل",
        f: "tlul",
        e: "to go",
        a: "tlul-m",
        rowSpan: 2
      }, {
        p: "لاړ شه",
        f: "laaR sha",
        e: "",
        a: "laarr-sha"
      }, {
        p: "ځه",
        f: "dza",
        e: "",
        a: "dza"
      }, {
        p: "مهٔ ځه",
        f: "mú-dza",
        e: "",
        a: "mu-dza"
      }], [{
        p: "لاړ شئ",
        f: "laaR shey",
        e: "",
        a: "laarr-shey"
      }, {
        p: "ځئ",
        f: "dzey",
        e: "",
        a: "dzey"
      }, {
        p: "مهٔ ځئ",
        f: "mú-dzey",
        e: "",
        a: "mu-dzey"
      }], [{
        p: "تلل",
        f: "talúl",
        e: "to weigh",
        a: "talul-m",
        rowSpan: 2
      }, {
        p: "وتله",
        f: "óotala",
        e: "",
        a: "ootala"
      }, {
        p: "تله",
        f: "tala",
        e: "",
        a: ""
      }, {
        p: "مهٔ تله",
        f: "mú-tala",
        e: "",
        a: "mu-tala"
      }], [{
        p: "وتلئ",
        f: "óotaley",
        e: "",
        a: "ootaley"
      }, {
        p: "تلئ",
        f: "taley",
        e: "",
        a: ""
      }, {
        p: "مهٔ تلئ",
        f: "mú-taley",
        e: "",
        a: "mu-taley"
      }], [{
        p: "څښل / څکل",
        f: "skul",
        e: "to drink",
        a: "skul-m",
        rowSpan: 2
      }, {
        p: "وڅښه",
        f: "óoska",
        e: "",
        a: "ooska"
      }, {
        p: "څښه",
        f: "ska",
        e: "",
        a: ""
      }, {
        p: "مهٔ څښه",
        f: "mú-ska",
        e: "",
        a: "mu-ska"
      }], [{
        p: "وڅښئ",
        f: "óoskey",
        e: "",
        a: "ooskey"
      }, {
        p: "څښئ",
        f: "skey",
        e: "",
        a: ""
      }, {
        p: "مهٔ څښئ",
        f: "mú-skey",
        e: "",
        a: "mu-skey"
      }], [{
        p: "ځملاستل",
        f: "tsumlaastúl",
        e: "to lie (down)",
        a: "tsumlaastul-m",
        rowSpan: 2
      }, {
        p: "څمله",
        f: "tsúmla",
        e: "",
        a: "tsumla-perf"
      }, {
        p: "څمله",
        f: "tsumlá",
        e: "",
        a: ""
      }, {
        p: "مهٔ څمله",
        f: "mú-tsumla",
        e: "",
        a: "mu-tsumla"
      }], [{
        p: "څملئ",
        f: "tsúmley",
        e: "",
        a: "tsumley-perf"
      }, {
        p: "څملئ",
        f: "tsumléy",
        e: "",
        a: ""
      }, {
        p: "مهٔ څملئ",
        f: "mú-tsumley",
        e: "",
        a: "mu-tsumley"
      }], [{
        p: "خبرې کول",
        f: "khabure kawul",
        e: "to talk",
        a: "khabure-kawul-m",
        rowSpan: 2
      }, {
        p: "خبرې وکړه",
        f: "khabure óo-kRa",
        e: "",
        a: ""
      }, {
        p: "خبرې کوه",
        f: "khabure kawá",
        e: "",
        a: "khabure-kawa"
      }, {
        p: "خبرې مهٔ کوه",
        f: "khabure mú-kawa",
        e: "",
        a: "khabure-mu-kawa"
      }], [{
        p: "خبرې وکړئ",
        f: "khabure óo-kRey",
        e: "",
        a: ""
      }, {
        p: "خبرې کوئ",
        f: "khabure kawéy",
        e: "",
        a: "khabure-kawey"
      }, {
        p: "خبرې مهٔ کوئ",
        f: "khabure mú-kawey",
        e: "",
        a: "khabure-mu-kawey"
      }], [{
        p: "ختل",
        f: "khatúl",
        e: "to climb",
        a: "khatul-m",
        rowSpan: 2
      }, {
        p: "وخېژه",
        f: "óokhejza",
        e: "",
        a: "ookhejza"
      }, {
        p: "خېژه",
        f: "khéjza",
        e: "",
        a: ""
      }, {
        p: "مهٔ خېژه",
        f: "mú-khejza",
        e: "",
        a: "mu-khejza"
      }], [{
        p: "وخېژئ",
        f: "óokhejzey",
        e: "",
        a: "ookhejzey"
      }, {
        p: "خېژئ",
        f: "khéjzey",
        e: "",
        a: ""
      }, {
        p: "مهٔ خېژئ",
        f: "mú-khejzey",
        e: "",
        a: "mu-khejzey"
      }], [{
        p: "خوړل",
        f: "khoRúl",
        e: "to eat",
        a: "khorrul-m",
        rowSpan: 2
      }, {
        p: "وخوړه",
        f: "óokhora",
        e: "",
        a: "ookhora"
      }, {
        p: "خوړه",
        f: "khorá",
        e: "",
        a: ""
      }, {
        p: "مهٔ خوړه",
        f: "mú-khora",
        e: "",
        a: "mu-khora"
      }], [{
        p: "وخوړئ",
        f: "óokhorey",
        e: "",
        a: "ookhorey"
      }, {
        p: "خوړئ",
        f: "khoréy",
        e: "",
        a: ""
      }, {
        p: "مهٔ خوړئ",
        f: "mú-khorey",
        e: "",
        a: "mu-khorey"
      }], [{
        p: "راتلل",
        f: "raatlúl",
        e: "to come",
        a: "raatlul-m",
        rowSpan: 2
      }, {
        p: "راشه",
        f: "ráasha",
        e: "",
        a: ""
      }, {
        p: "راځه",
        f: "raadzá",
        e: "",
        a: "raadza"
      }, {
        p: "مهٔ راځه",
        f: "mú-raadza",
        e: "",
        a: "mu-raadza"
      }], [{
        p: "راشئ",
        f: "ráashey",
        e: "",
        a: ""
      }, {
        p: "راځئ",
        f: "raadzéy",
        e: "",
        a: "raadzey"
      }, {
        p: "مهٔ راځئ",
        f: "mú-raadzey",
        e: "",
        a: "mu-raadzey"
      }], [{
        p: "راوړل",
        f: "raawRúl",
        e: "to bring (inanimate)",
        a: "raawrrul-m",
        rowSpan: 2
      }, {
        p: "راوړه",
        f: "ráawRa",
        e: "",
        a: "raawrra-perf"
      }, {
        p: "راوړه",
        f: "raawRá",
        e: "",
        a: ""
      }, {
        p: "مهٔ راوړه",
        f: "mú-raawRa",
        e: "",
        a: "mu-raawrra"
      }], [{
        p: "راوړئ",
        f: "ráawRey",
        e: "",
        a: "raawrrey-perf"
      }, {
        p: "راوړئ",
        f: "raawRéy",
        e: "",
        a: ""
      }, {
        p: "مهٔ راوړئ",
        f: "mú-raawRey",
        e: "",
        a: "mu-raawrrey"
      }], [{
        p: "راوستل",
        f: "raawustúl",
        e: "to bring (animate)",
        a: "raawustul-m",
        rowSpan: 2
      }, {
        p: "راوله",
        f: "ráawula",
        e: "",
        a: "raawula-perf"
      }, {
        p: "راوله",
        f: "raawulá",
        e: "",
        a: ""
      }, {
        p: "مهٔ راوله",
        f: "mú-raawula",
        e: "",
        a: "mu-raawula"
      }], [{
        p: "راولئ",
        f: "ráawuley",
        e: "",
        a: "raawuley-perf"
      }, {
        p: "راولئ",
        f: "raawuléy",
        e: "",
        a: ""
      }, {
        p: "مهٔ راولئ",
        f: "mú-raawuley",
        e: "",
        a: "mu-raawuley"
      }], [{
        p: "رسېدل",
        f: "rasedúl",
        e: "to arrive",
        a: "rasedul-m",
        rowSpan: 2
      }, [{
        p: "ورسه",
        f: "óorasa",
        e: "",
        a: "oorasa"
      }, {
        p: "ورسېږه",
        f: "óoraseGa",
        e: "",
        a: ""
      }], [{
        p: "ورسه",
        f: "wăr-rasá",
        e: "",
        a: ""
      }, {
        p: "ورسېږه",
        f: "wăr-raséGa",
        e: "",
        a: ""
      }], [{
        p: "مهٔ ورسه",
        f: "mú-wăr-rasa",
        e: "",
        a: "mu-oorasa"
      }, {
        p: "مهٔ ورسېږه",
        f: "mú-wăr-raséGa",
        e: "",
        a: ""
      }]], [[{
        p: "ورسئ",
        f: "óorasey",
        e: "",
        a: "oorasey"
      }, {
        p: "ورسېږئ",
        f: "óoraseGey",
        e: "",
        a: ""
      }], [{
        p: "ورسئ",
        f: "wăr-raséy",
        e: "",
        a: ""
      }, {
        p: "ورسېږئ",
        f: "wăr-raséGey",
        e: "",
        a: ""
      }], [{
        p: "مهٔ ورسئ",
        f: "mú-wăr-rasey",
        e: "",
        a: "mu-oorasey"
      }, {
        p: "مهٔ ورسېږئ",
        f: "mú-wăr-raséGey",
        e: "",
        a: ""
      }]], [{
        p: "کتل",
        f: "katúl",
        e: "to look at",
        a: "katul-m",
        rowSpan: 2
      }, {
        p: "وګوره",
        f: "óogora",
        e: "",
        a: "oogora"
      }, {
        p: "ګوره",
        f: "góra",
        e: "",
        a: ""
      }, {
        p: "مهٔ ګوره",
        f: "mú-gora",
        e: "",
        a: "mu-gora"
      }], [{
        p: "وګورئ",
        f: "óogorey",
        e: "",
        a: "oogorey"
      }, {
        p: "ګورئ",
        f: "górey",
        e: "",
        a: ""
      }, {
        p: "مهٔ ګورئ",
        f: "mú-gorey",
        e: "",
        a: "mu-gorey"
      }], [{
        p: "کرل",
        f: "karúl",
        e: "to sow",
        a: "karul-m",
        rowSpan: 2
      }, {
        p: "وکره",
        f: "óokara",
        e: "",
        a: "ookara"
      }, {
        p: "کره",
        f: "kará",
        e: "",
        a: ""
      }, {
        p: "مهٔ کره",
        f: "mú-kara",
        e: "",
        a: "mu-kara"
      }], [{
        p: "وکرئ",
        f: "óokarey",
        e: "",
        a: "ookarey"
      }, {
        p: "کرئ",
        f: "karéy",
        e: "",
        a: ""
      }, {
        p: "مهٔ کرئ",
        f: "mú-karey",
        e: "",
        a: "mu-karey"
      }], [{
        p: "کول",
        f: "kawúl",
        e: "to do",
        a: "kawul-m",
        rowSpan: 2
      }, {
        p: "وکړه",
        f: "ookRa / ooka",
        e: "",
        a: "ooka"
      }, {
        p: "کوه",
        f: "kawá",
        e: "",
        a: ""
      }, {
        p: "مهٔ کوه",
        f: "mú-kawa",
        e: "",
        a: "mu-kawa"
      }], [{
        p: "وکړئ",
        f: "ookRey / ookey",
        e: "",
        a: "ookey"
      }, {
        p: "کوئ",
        f: "kawéy",
        e: "",
        a: ""
      }, {
        p: "مهٔ کوئ",
        f: "mú-kawey",
        e: "",
        a: "mu-kawey"
      }], [{
        p: "کول",
        f: "kawúl",
        e: "to make",
        a: "kawul-m",
        rowSpan: 2
      }, {
        p: "کړه",
        f: "kRa / ka",
        e: "",
        a: ""
      }, {
        p: "کوه",
        f: "kawá",
        e: "",
        a: ""
      }, {
        p: "مهٔ کوه",
        f: "mú-kawa",
        e: "",
        a: "mu-kawa"
      }], [{
        p: "کړئ",
        f: "kRa / key",
        e: "",
        a: ""
      }, {
        p: "کوئ",
        f: "kawéy",
        e: "",
        a: ""
      }, {
        p: "مهٔ کوئ",
        f: "mú-kawey",
        e: "",
        a: "mu-kawey"
      }], [{
        p: "کېدل",
        f: "kedúl",
        e: "to happen",
        a: "kedul-m",
        rowSpan: 2
      }, {
        p: "وشه",
        f: "óosha",
        e: "",
        a: ""
      }, {
        p: "کېږه",
        f: "kéGa",
        e: "",
        a: ""
      }, {
        p: "مهٔ کېږه",
        f: "mú-kéGa",
        e: "",
        a: ""
      }], [{
        p: "وشئ",
        f: "óoshey",
        e: "",
        a: ""
      }, {
        p: "کېږئ",
        f: "kéGey",
        e: "",
        a: ""
      }, {
        p: "مهٔ کېږئ",
        f: "mú-kéGey",
        e: "",
        a: ""
      }], [{
        p: "کېدل",
        f: "kedúl",
        e: "to become",
        a: "kedul-m",
        rowSpan: 2
      }, {
        p: "شه",
        f: "sha",
        e: "",
        a: "sha"
      }, {
        p: "کېږه",
        f: "kéGa",
        e: "",
        a: ""
      }, {
        p: "مهٔ شه",
        f: "mú-sha",
        e: "",
        a: "mu-sha"
      }], [{
        p: "شئ",
        f: "shey",
        e: "",
        a: "shey"
      }, {
        p: "کېږئ",
        f: "kéGey",
        e: "",
        a: ""
      }, {
        p: "مهٔ کېږئ",
        f: "mú-shey",
        e: "",
        a: "mu-shey"
      }], [{
        p: "کېښودل",
        f: "kexodúl",
        e: "to put",
        a: "kexodul-m",
        rowSpan: 2
      }, {
        p: "کېږده",
        f: "kéGda",
        e: "",
        a: "keggda-perf"
      }, {
        p: "کېږده",
        f: "keGdá",
        e: "",
        a: ""
      }, {
        p: "مهٔ کېږده",
        f: "mú-keGda",
        e: "",
        a: "mu-keggda"
      }], [{
        p: "کېږدئ",
        f: "kéGdey",
        e: "",
        a: "keggdey-perf"
      }, {
        p: "کېږدئ",
        f: "keGdéy",
        e: "",
        a: ""
      }, {
        p: "مهٔ کېږدئ",
        f: "mú-keGdey",
        e: "",
        a: "mu-keggdey"
      }], [{
        p: "کښېناستل",
        f: "kxenaastúl",
        e: "to sit",
        a: "kenaastul",
        rowSpan: 2
      }, {
        p: "کښېنه",
        f: "kéna",
        e: "",
        a: "kena-perf"
      }, {
        p: "کښېنه",
        f: "kená",
        e: "",
        a: ""
      }, {
        p: "مهٔ کښېنه",
        f: "mú-kena",
        e: "",
        a: "mu-kena"
      }], [{
        p: "کښېنئ",
        f: "kéney",
        e: "",
        a: "keney-perf"
      }, {
        p: "کښېنئ",
        f: "kenéy",
        e: "",
        a: ""
      }, {
        p: "مهٔ کښېنئ",
        f: "mú-keney",
        e: "",
        a: "mu-keney"
      }], [{
        p: "ګرځېدل",
        f: "gurdzedul",
        e: "to walk / go around",
        a: "gurdzedul-m",
        rowSpan: 2
      }, [{
        p: "وګرځه",
        f: "óogurdza",
        e: "",
        a: "oogurdza"
      }, {
        p: "وګرځېږه",
        f: "óogurdzeGa",
        e: "",
        a: ""
      }], [{
        p: "ګرځه",
        f: "gúrdza",
        e: "",
        a: ""
      }, {
        p: "ګرځېږه",
        f: "gurdzéGa",
        e: "",
        a: ""
      }], [{
        p: "مهٔ ګرځه",
        f: "mú-gurdza",
        e: "",
        a: "mu-gurdza"
      }, {
        p: "مهٔ ګرځېږه",
        f: "mú-gurdzeGa",
        e: "",
        a: ""
      }]], [[{
        p: "وګرځئ",
        f: "óogurdzey",
        e: "",
        a: "oogurdzey"
      }, {
        p: "وګرځېږئ",
        f: "óogurdzeGey",
        e: "",
        a: ""
      }], [{
        p: "ګرځئ",
        f: "gúrdzey",
        e: "",
        a: ""
      }, {
        p: "ګرځېږئ",
        f: "gurdzéGey",
        e: "",
        a: ""
      }], [{
        p: "مهٔ ګرځئ",
        f: "mú-gurdzey",
        e: "",
        a: "mu-gurdzey"
      }, {
        p: "مهٔ ګرځېږئ",
        f: "mú-gurdzeGey",
        e: "",
        a: ""
      }]], [{
        p: "ګنډل",
        f: "ganDúl",
        e: "to sew",
        a: "ganddul-m",
        rowSpan: 2
      }, {
        p: "وګنډه",
        f: "óoganDa",
        e: "",
        a: "oogandda"
      }, {
        p: "ګنډه",
        f: "ganDá",
        e: "",
        a: ""
      }, {
        p: "مهٔ ګنډه",
        f: "mú-ganDa",
        e: "",
        a: "mu-gandda"
      }], [{
        p: "وګنډئ",
        f: "óoganDey",
        e: "",
        a: "ooganddey"
      }, {
        p: "ګنډئ",
        f: "ganDéy",
        e: "",
        a: ""
      }, {
        p: "مهٔ ګنډئ",
        f: "mú-ganDey",
        e: "",
        a: "mu-ganddey"
      }], [{
        p: "لګول",
        f: "lugawúl",
        e: "to make touch / turn on",
        a: "lugawul-m",
        rowSpan: 2
      }, {
        p: "ولګوه",
        f: "óolugawa",
        e: "",
        a: "oolugawa"
      }, {
        p: "لګوه",
        f: "lugawá",
        e: "",
        a: ""
      }, {
        p: "مهٔ لګوه",
        f: "mú-lugawa",
        e: "",
        a: "mu-lugawa"
      }], [{
        p: "ولګوئ",
        f: "óolugawey",
        e: "",
        a: "oolugawey"
      }, {
        p: "لګوئ",
        f: "lugawéy",
        e: "",
        a: ""
      }, {
        p: "مهٔ لګوئ",
        f: "mú-lugawey",
        e: "",
        a: "mu-lugawey"
      }], [{
        p: "لوستل",
        f: "lostul / lwustul",
        e: "to read",
        a: "lostul-m",
        rowSpan: 2
      }, {
        p: "ولوله",
        f: "óolwula",
        e: "",
        a: "oolwula"
      }, {
        p: "لوله",
        f: "lwúla",
        e: "",
        a: ""
      }, {
        p: "مهٔ لوله",
        f: "mú-lwula",
        e: "",
        a: "mu-lwula"
      }], [{
        p: "ولولئ",
        f: "óolwuley",
        e: "",
        a: "oolwuley"
      }, {
        p: "لولئ",
        f: "lwúley",
        e: "",
        a: ""
      }, {
        p: "مهٔ لولئ",
        f: "mú-lwuley",
        e: "",
        a: "mu-lwuley"
      }], [{
        p: "لیدل",
        f: "leedúl",
        e: "to see",
        a: "leedul-m",
        rowSpan: 2
      }, {
        p: "ووینه",
        f: "óoweena",
        e: "",
        a: "ooweena"
      }, {
        p: "وینه",
        f: "wéena",
        e: "",
        a: ""
      }, {
        p: "مهٔ وینه",
        f: "mú-weena",
        e: "",
        a: "mu-weena"
      }], [{
        p: "ووینئ",
        f: "óoweeney",
        e: "",
        a: "ooweeney"
      }, {
        p: "وینئ",
        f: "wéeney",
        e: "",
        a: ""
      }, {
        p: "مهٔ وینئ",
        f: "mú-weeney",
        e: "",
        a: "mu-weeney"
      }], [{
        p: "لېږل",
        f: "leGúl",
        e: "to send",
        a: "leggul-m",
        rowSpan: 2
      }, {
        p: "ولېږه",
        f: "óoleGa",
        e: "",
        a: "oolegga"
      }, {
        p: "لېږه",
        f: "leGá",
        e: "",
        a: ""
      }, {
        p: "مهٔ لېږه",
        f: "mú-leGa",
        e: "",
        a: "mu-legga"
      }], [{
        p: "ولېږئ",
        f: "óoleGey",
        e: "",
        a: "ooleggey"
      }, {
        p: "لېږئ",
        f: "leGéy",
        e: "",
        a: ""
      }, {
        p: "مهٔ لېږئ",
        f: "mú-leGey",
        e: "",
        a: "mu-leggey"
      }], [{
        p: "لیکل",
        f: "leekúl",
        e: "to write",
        a: "leekul-m",
        rowSpan: 2
      }, {
        p: "ولیکه",
        f: "óoleeka",
        e: "",
        a: "ooleeka"
      }, {
        p: "لیکه",
        f: "leeká",
        e: "",
        a: ""
      }, {
        p: "مهٔ لیکه",
        f: "mú-leeka",
        e: "",
        a: "mu-leeka"
      }], [{
        p: "ولیکئ",
        f: "óoleekey",
        e: "",
        a: "ooleekey"
      }, {
        p: "لیکئ",
        f: "leekéy",
        e: "",
        a: ""
      }, {
        p: "مهٔ لیکئ",
        f: "mú-leekey",
        e: "",
        a: "mu-leekey"
      }], [{
        p: "ننوتل",
        f: "nunawatúl",
        e: "to enter",
        a: "nunawatul-m",
        rowSpan: 2
      }, {
        p: "ننوځه",
        f: "núnaoodza",
        e: "",
        a: "nunaoodza-perf"
      }, {
        p: "ننوځه",
        f: "nunaoodza",
        e: "",
        a: ""
      }, {
        p: "مهٔ ننوځه",
        f: "mú-nunaoodza",
        e: "",
        a: "mu-nunaoodza"
      }], [{
        p: "ننوځئ",
        f: "nunaóodzey",
        e: "",
        a: "nunaoodzey-perf"
      }, {
        p: "ننوځئ",
        f: "nunaoodzey",
        e: "",
        a: ""
      }, {
        p: "مهٔ ننوځئ",
        f: "mú-nunaoodzey",
        e: "",
        a: "mu-nunaoodzey"
      }], [{
        p: "نیول",
        f: "neewúl",
        e: "to catch",
        a: "neewul-m",
        rowSpan: 2
      }, {
        p: "ونیسه",
        f: "óoneesa",
        e: "",
        a: "ooneesa"
      }, {
        p: "نیسه",
        f: "néesa",
        e: "",
        a: ""
      }, {
        p: "مهٔ نیسه",
        f: "mú-neesa",
        e: "",
        a: "mu-neesa"
      }], [{
        p: "ونیسئ",
        f: "óoneesey",
        e: "",
        a: "ooneesey"
      }, {
        p: "نیسئ",
        f: "néesey",
        e: "",
        a: ""
      }, {
        p: "مهٔ نیسئ",
        f: "mú-neesey",
        e: "",
        a: "mu-neesey"
      }], [{
        p: "وتل",
        f: "watúl",
        e: "to exit",
        a: "watul-m",
        rowSpan: 2
      }, {
        p: "وځه",
        f: "óodza",
        e: "",
        a: "oodza-perf"
      }, {
        p: "وځه",
        f: "oodzá",
        e: "",
        a: ""
      }, {
        p: "مهٔ وځه",
        f: "mú-oodza",
        e: "",
        a: "mu-oodza"
      }], [{
        p: "وځئ",
        f: "óodzey",
        e: "",
        a: "oodzey-perf"
      }, {
        p: "وځئ",
        f: "oodzéy",
        e: "",
        a: ""
      }, {
        p: "مهٔ وځئ",
        f: "mú-oodzey",
        e: "",
        a: "mu-oodzey"
      }], [{
        p: "ودرېدل",
        f: "oodrédul",
        e: "to stop",
        a: "oodredul-m",
        rowSpan: 2
      }, {
        p: "ودرېږه",
        f: "‍óodreGa",
        e: "",
        a: "oodregga-perf"
      }, {
        p: "ودرېږه",
        f: "oodréGa",
        e: "",
        a: ""
      }, {
        p: "مهٔ ودرېږه",
        f: "mú-oodreGa",
        e: "",
        a: "mu-oodregga"
      }], [{
        p: "ودرېږئ",
        f: "‍óodreGey",
        e: "",
        a: "oodreggey-perf"
      }, {
        p: "ودرېږئ",
        f: "oodréGey",
        e: "",
        a: ""
      }, {
        p: "مهٔ ودرېږئ",
        f: "mú-oodreGey",
        e: "",
        a: "mu-oodreggey"
      }], [{
        p: "وړل",
        f: "oRúl",
        e: "to take (inanimate)",
        a: "orrul-m",
        rowSpan: 2
      }, {
        p: "یوسه",
        f: "yósa",
        e: "",
        a: "yosa"
      }, {
        p: "وړه",
        f: "oRá",
        e: "",
        a: ""
      }, {
        p: "مهٔ وړه",
        f: "mú-oRa",
        e: "",
        a: "mu-orra"
      }], [{
        p: "یوسئ",
        f: "yósey",
        e: "",
        a: "yosey"
      }, {
        p: "وړئ",
        f: "oRéy",
        e: "",
        a: ""
      }, {
        p: "مهٔ وړئ",
        f: "mú-oRey",
        e: "",
        a: "mu-orrey"
      }], [{
        p: "وهل",
        f: "wahúl",
        e: "to hit",
        a: "wahul-m",
        rowSpan: 2
      }, {
        p: "ووهه",
        f: "óowaha",
        e: "",
        a: "oowaha"
      }, {
        p: "وهه",
        f: "wahá",
        e: "",
        a: ""
      }, {
        p: "مهٔ وهه",
        f: "mú-waha",
        e: "",
        a: "mu-waha"
      }], [{
        p: "ووهئ",
        f: "óowahey",
        e: "",
        a: "oowahey"
      }, {
        p: "وهئ",
        f: "wahéy",
        e: "",
        a: ""
      }, {
        p: "مهٔ وهئ",
        f: "mú-wahey",
        e: "",
        a: "mu-wahey"
      }], [{
        p: "ویستل",
        f: "weestúl",
        e: "to extract",
        a: "weestul-m",
        rowSpan: 2
      }, {
        p: "وباسه",
        f: "óobaasa",
        e: "",
        a: "oobaasa-perf"
      }, {
        p: "وباسه",
        f: "oobáasa",
        e: "",
        a: ""
      }, {
        p: "مهٔ وباسه",
        f: "mú-oobaasa",
        e: "",
        a: "mu-oobaasa"
      }], [{
        p: "وباسئ",
        f: "óobaasey",
        e: "",
        a: "oobaasey-perf"
      }, {
        p: "وباسئ",
        f: "oobáasey",
        e: "",
        a: ""
      }, {
        p: "مهٔ وباسئ",
        f: "mú-oobaasey",
        e: "",
        a: "mu-oobaasey"
      }], [{
        p: "وئیل / ویل",
        f: "wayul / waayúl",
        e: "to say",
        a: "wayul-m",
        rowSpan: 2
      }, {
        p: "ووایه",
        f: "óowaaya",
        e: "",
        a: "oowaaya"
      }, {
        p: "وایه",
        f: "wáaya",
        e: "",
        a: ""
      }, {
        p: "مهٔ وایه",
        f: "mú-waaya",
        e: "",
        a: "mu-waaya"
      }], [{
        p: "ووایئ",
        f: "óowaayey",
        e: "",
        a: "oowaayey"
      }, {
        p: "وایئ",
        f: "wáayey",
        e: "",
        a: ""
      }, {
        p: "مهٔ وایئ",
        f: "mú-waayey",
        e: "",
        a: "mu-waayey"
      }], [{
        p: "وینځل",
        f: "weendzúl",
        e: "to wash",
        a: "weendzul-m",
        rowSpan: 2
      }, {
        p: "ووینځه",
        f: "óoweendza",
        e: "",
        a: "ooweendza"
      }, {
        p: "وینځه",
        f: "wéendza",
        e: "",
        a: ""
      }, {
        p: "مهٔ وینځه",
        f: "mú-weendza",
        e: "",
        a: "mu-weendza"
      }], [{
        p: "ووینځئ",
        f: "óoweendzey",
        e: "",
        a: "ooweendzey"
      }, {
        p: "وینځئ",
        f: "wéendzey",
        e: "",
        a: ""
      }, {
        p: "مهٔ وینځئ",
        f: "mú-weendzey",
        e: "",
        a: "mu-weendzey"
      }]]}
    </Table>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The “one-time” (perfective) forms of the imperatives are based on the `}<em parentName="p">{`subjunctive form`}</em>{` of the verb. (Introduced in Lesson 16). For regular verbs, the subjunctive form is made by adding `}<Word w={{
            p: "و",
            f: "oo",
            e: "",
            a: "oo-sound"
          }} mdxType="Word" />{` to the beginning. Some words, like the following, take totally irregular forms in the subjunctive:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <Word w={{
              p: "تلل",
              f: "tlul",
              e: "",
              a: "tlul-m"
            }} mdxType="Word" />
          </li>
          <li parentName="ul">
            <Word w={{
              p: "راتلل",
              f: "raatlul",
              e: "",
              a: "raatlul-m"
            }} mdxType="Word" />
          </li>
          <li parentName="ul">
            <Word w={{
              p: "کول",
              f: "kawul",
              e: "to do",
              a: "kawul-m"
            }} mdxType="Word" />
          </li>
          <li parentName="ul">
            <Word w={{
              p: "کول",
              f: "kawul",
              e: "to make",
              a: "kawul-m"
            }} mdxType="Word" />
          </li>
          <li parentName="ul">
            <Word w={{
              p: "کېدل",
              f: "kedul",
              e: "to happen",
              a: "kedul-m"
            }} mdxType="Word" />
          </li>
          <li parentName="ul">
            <Word w={{
              p: "کېدل",
              f: "kedul",
              e: "to become",
              a: "kedul-m"
            }} mdxType="Word" />
          </li>
          <li parentName="ul">
            <Word w={{
              p: "وړل",
              f: "oRúl",
              e: "to take (inanim.)",
              a: "orrul-m"
            }} mdxType="Word" />
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`It’s impórtant to put the émphasis on the right sýllable!!`}</strong>{` You’ll notice that all the one-time commands have the emphasis on the first syllable. With some words you can’t add an `}<Word w={{
            p: "و",
            f: "oo",
            e: "",
            a: "oo-sound"
          }} mdxType="Word" />{` to the front of the word, but there is still a difference in where you put the emphasis! For instance:`}</p>
      </li>
    </ol>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "څمله",
        f: "tsúmla",
        e: "lie down (once)",
        a: ""
      }, {
        p: "څمله",
        f: "tsumlá",
        e: "lie down (over and over, in general)",
        a: ""
      }, {
        p: "دلته ودرېږه",
        f: "dălta óodreGa",
        e: "stop here now!",
        a: ""
      }, {
        p: "دلته ودرېږه",
        f: "dălta oodréGa",
        e: "stop here always!",
        a: ""
      }]}
    </Examples>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">
        <p parentName="li">{`The imperative mood is always directed at “you” in the second person singular `}<Word w={{
            p: "ته",
            f: "tu",
            e: "",
            a: "tu"
          }} mdxType="Word" /> or the second person plural <Word w={{
            p: "تاسو",
            f: "táaso",
            e: "",
            a: "taaso"
          }} mdxType="Word" />{`, but these pronouns are generally omitted unless there is some ambiguity or the speaker wants to add emphasis to the command.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the imperative mood, the second person singular subject marker for verbs is `}<Word w={{
            p: "ـه",
            f: "-a",
            e: "",
            a: "a-sound"
          }} mdxType="Word" />. This differs from its present tense subject marker <Word w={{
            p: "ـې",
            f: "-e",
            a: "e-sound"
          }} mdxType="Word" />{`. For example,`}</p>
      </li>
    </ol>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "تهٔ هلته وګرځه!",
        f: "tu hálta óogurdza!",
        e: "You turn there!",
        a: "14e12"
      }, {
        p: "تهٔ هلته ګرځې؟",
        f: "tu hálta gúrdze?",
        e: "Are you walking there?",
        a: "14e13"
      }]}
    </Examples>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`The plural subject marker for both the imperative mood and the present tense is `}<Word w={{
          p: "ـئ",
          f: "-ey",
          e: "",
          a: "ey-sound"
        }} mdxType="Word" />{`.`}</li>
    </ol>
    <h2 {...{
      "id": "intransitive-verbs-in-the-imperative-mood"
    }}>{`Intransitive verbs in the imperative mood`}</h2>
    <ol>
      <li parentName="ol">{`Regular intransitive verbs:`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "تۀ د کريم سره مۀ ګرځه!",
        f: "tu du karéem sara mú-gurdza!",
        e: "Don't you walk with Karim! (s.)",
        a: "14e14"
      }, {
        p: "د شپې يواځې مۀ ګرځئ!",
        f: "du shpe yawaadze mú-gurdzey!",
        e: "Don't walk alone at night! (pl.)",
        a: "14e15"
      }, {
        p: "د شپې يواځې مۀ ګرځه!",
        f: "du shpe yawaadze mú-gurdza!",
        e: "Don't walk alone at night! (s.)",
        a: "14e16"
      }, {
        p: "دلته مۀ ودرېږه!",
        f: "dălta mú-oodreGa!",
        e: "Don't stand here! (s.)",
        a: "14e17"
      }, {
        p: "په کلاس کښې خبرې مۀ کوه!",
        f: "pu klaas ke khabure mú-kawa!",
        e: "Don't talk in class! (s.)",
        a: "14e18"
      }]}
    </Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Compound intransitive verbs:`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "نن شپه دلته پاتې شه!",
        f: "nun shpa dălta paate sha!",
        e: "Stay here tonight! (s.)",
        a: "14e19"
      }, {
        p: "ماشُومانو، اُودۀ شئ!",
        f: "maashoomáano, oodú shey!",
        e: "Children, go to sleep! (pl.)",
        a: "14e19b"
      }, {
        p: "خورې، مۀ غُصه کېږه!",
        f: "khore, mú-ghUsa kéGa!",
        e: "Sister, don't be angry! (s.)",
        a: "14e20"
      }, {
        p: "وروره، مۀ خفه کېږه!",
        f: "wrora, mú-khufa kéGa!",
        e: "Brother, don't be upset! (s.)",
        a: "14e21"
      }, {
        p: "کاکا، مۀ خفه کېږه!",
        f: "kaakaa, mú-khufa kéGa!",
        e: "Uncle, don't be sad! (s.)",
        a: "14e22"
      }]}
    </Examples>
    <h4 {...{
      "id": "notes-1"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The last two sentences could be made more respectful by making the imperatives plural with `}<Word w={{
            p: "ـئ",
            f: "-ey",
            e: "",
            a: "ey-sound"
          }} mdxType="Word" />{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Suffixes added for calling someone are:`}</p>
      </li>
    </ol>
    <ul>
      <li parentName="ul">
        <Word w={{
          p: "ـه",
          f: "-a",
          e: "",
          a: "a-sound"
        }} mdxType="Word" /> for masuline nouns: <Word w={{
          p: "وروره",
          f: "wror-a",
          e: "",
          a: "wrora"
        }} mdxType="Word" />
      </li>
      <li parentName="ul">
        <Word w={{
          p: "ـې",
          f: "-e",
          e: "",
          a: "e-sound"
        }} mdxType="Word" /> for feminine nouns: <Word w={{
          p: "خورې",
          f: "khor-e",
          e: "",
          a: "khore"
        }} mdxType="Word" />
      </li>
      <li parentName="ul">
        <Word w={{
          p: "ـو",
          f: "-o",
          e: "",
          a: "o-sound"
        }} mdxType="Word" /> for plural nouns: <Word w={{
          p: "ماشومانو",
          f: "maashoomáano",
          e: "",
          a: "maashoomaano"
        }} mdxType="Word" />
      </li>
    </ul>
    <h2 {...{
      "id": "transitive-verbs-in-the-imperative-mood"
    }}>{`Transitive verbs in the imperative mood`}</h2>
    <ol>
      <li parentName="ol">{`Regular transitive verbs:`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "نن خط وليکه!",
        f: "nun khat óoleeka!",
        e: "Write a letter today!",
        a: "14e24"
      }, {
        p: "په انګرېزۍ کښې مۀ ليکئ!",
        f: "pu angrezúy ke mú-leekey",
        e: "Don't write in English!",
        a: "14e25"
      }, {
        p: "دا قميص نن وګنډه!",
        f: "daa qaméez nun óoganDa!",
        e: "Sew this shirt today!",
        a: "14e26"
      }, {
        p: "بيا دا جامې ووينځئ!",
        f: "byaa daa jaamé óoweendzey!",
        e: "Then wash these clothes!",
        a: "14e27"
      }, {
        p: "اوس اخبار مۀ ګوره!",
        f: "oos akhbaar mú-gora!",
        e: "Don't read the paper now!",
        a: "14e28"
      }]}
    </Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Compound transitive verbs:`}</li>
    </ol>
    <p>{`a. The imperative forms of dynamic, or “doing” compounds (verbs where the object is in the compound)`}</p>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "زر زر کار کوه!",
        f: "zur zur kaar kawá",
        e: "Do your work quickly!",
        a: "14e29"
      }, {
        p: "په سکُول کښې جګړې مۀ کوه!",
        f: "pu skool ke jagúRe mú-kawa!",
        e: "Don't fight in school!",
        a: "14e30"
      }, {
        p: "تېزې خبرې مۀ کوئ!",
        f: "teze khabúre mú-kawey!",
        e: "Don't talk loudly!",
        a: "14e31"
      }, {
        p: "اُوچتې خبرې مۀ کوه!",
        f: "oochute khabure mú-kawa!",
        e: "Don't talk so loudly!",
        a: "14e32"
      }]}
    </Examples>
    <p>{`b. The imperative forms of stative, or “making” compounds (verbs where the object is outside the compound)`}</p>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "دا قميصُونه اِسترى که!",
        f: "daa qameesóona istree ka!",
        e: "Iron these shirts!",
        a: "14e33"
      }, {
        p: "ور بند که!",
        f: "war band ka!",
        e: "Shut the door!",
        a: "14e34"
      }, {
        p: "مِهربانى وکه کړکۍ مهٔ بندوه",
        f: "miribaanee óoka kiRkúy mú bandawa.",
        e: "Please don't shut the window.",
        a: "14e35"
      }, {
        p: "کړکۍ کولاؤ که!",
        f: "kiRkúy kooláaw ka!",
        e: "Open the window!",
        a: "14e36"
      }, {
        p: "ور مۀ کولاؤوه!",
        f: "war mú koolaawawa!",
        e: "Don't open the door!",
        a: "14e37"
      }, {
        p: "نن دا کُرسۍ جوړې که!",
        f: "nun daa kÚrsúy joRe ka!",
        e: "Fix these chairs today!",
        a: "14e38"
      }, {
        p: "کړکۍ دلته مۀ جوړوئ!",
        f: "kiRkúy dălta mú-joRawey!",
        e: "Don't build the window here!",
        a: "14e39"
      }, {
        p: "نن د غرمې ډوډۍ د پاره سبزى پخه که.",
        f: "nun du gharmé DoDúy du paara subzee pakhá ka.",
        e: "Cook vegetables for the mid-day meal today.",
        a: "14e40"
      }, {
        p: "دا غوښه د ماښام ډوډۍ د پاره مۀ پخوه.",
        f: "daa ghwáxa du maaxaam DoDúy du paara mú-pakhawa.",
        e: "Don't cook this meat for the evening meal.",
        a: "14e41"
      }]}
    </Examples>
    <h4 {...{
      "id": "notes-2"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">{`All negative commands are based off of the present form of the verb. Only positive commands differentiate between one-time (perfective aspect) commands, and continuous (imperfective aspect) commands.`}</li>
    </ol>
    <h4 {...{
      "id": "using-word-w-p-کول-f-kawul-e-to-do-a---"
    }}>{`Using `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to do",
        a: ""
      }} mdxType="Word" /></h4>
    <Table unset bordered verticalContent titleRow={["", "Positive", "Negative"]} mdxType="Table">
  {[[{
        e: [<div>One-time</div>, <div style={{
          fontSize: "smaller",
          color: "gray"
        }}>Perfective</div>]
      }, {
        p: "وکړه / وکه",
        f: "óoka / óokRa",
        e: "based on subjunctive form",
        a: ""
      }, {
        p: "مهٔ کوه",
        f: "mú-kawa",
        e: "based on present form",
        rowSpan: 2
      }], [{
        e: [<div>Ongoing</div>, <div style={{
          fontSize: "smaller",
          color: "gray"
        }}>
            Imperfective
          </div>]
      }, {
        p: "کوه",
        f: "kawá",
        e: "based on present form"
      }]]}
    </Table>
    <h4 {...{
      "id": "using-word-w-p-کول-f-kawul-e-to-make-a---"
    }}>{`Using `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to make",
        a: ""
      }} mdxType="Word" /></h4>
    <Table unset bordered verticalContent titleRow={["", "Positive", "Negative"]} mdxType="Table">
  {[[{
        e: [<div>One-time</div>, <div style={{
          fontSize: "smaller",
          color: "gray"
        }}>Perfective</div>]
      }, {
        p: "کړه / که",
        f: "ka / kRa",
        e: "based on subjunctive form",
        a: ""
      }, {
        p: "مهٔ کوه",
        f: "mú-kawa",
        e: "based on present form",
        rowSpan: 2
      }], [{
        e: [<div>Ongoing</div>, <div style={{
          fontSize: "smaller",
          color: "gray"
        }}>
            Imperfective
          </div>]
      }, {
        p: "کوه",
        f: "kawá",
        e: "based on present form"
      }]]}
    </Table>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`As usual with stative “making” compound verbs, adjectives are inflected according to the object’s gender and number. See the example with the regular adjective (make it), and with the irregular adjective (cook it) below:`}</li>
    </ol>
    <h4 {...{
      "id": "make-it"
    }}>{`Make it!`}</h4>
    <Table bordered unset verticalContent titleRow={["", "Masculine", "Feminine"]} mdxType="Table">
  {[[{
        e: "Singular"
      }, {
        p: "جوړ که!",
        f: "joR ka!",
        e: "",
        a: "jorr-ka"
      }, {
        p: "جوړه که!",
        f: "jóRa ka!",
        e: "",
        a: "jorra-ka"
      }], [{
        e: "Plural"
      }, {
        p: "جوړ که!",
        f: "joR ka!",
        e: "",
        a: "jorr-ka"
      }, {
        p: "جوړې که!",
        f: "jóRe ka!",
        e: "",
        a: "jorre-ka"
      }]]}
    </Table>
    <h5 {...{
      "id": "word-w-p-کټ-f-kat-e-bed-m-a-katt-m--"
    }}><Word w={{
        p: "کټ",
        f: "kaT",
        e: "bed (m.)",
        a: "katt-m"
      }} mdxType="Word" /></h5>
    <p>{`Try making these sentences covering up the Pashto.`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "کټ جوړ که!",
        f: "kaT joR ka!",
        e: "Make a bed (m. s.)!",
        a: "14e42"
      }, {
        p: "کټونه جوړ که!",
        f: "kaTóona joR ka!",
        e: "Make beds (m. pl.)!",
        a: "14e43"
      }]}
    </Examples>
    <h5 {...{
      "id": "word-w-p-لینده-f-leendá-e-slinghsot-f-a-leendda-m--"
    }}><Word w={{
        p: "لینده",
        f: "leendá",
        e: "slinghsot (f.)",
        a: "leendda-m"
      }} mdxType="Word" /></h5>
    <Examples mdxType="Examples">
  {[{
        p: "لینده جوړه که!",
        f: "leendá joRa ka!",
        e: "Make a slingshot (f. s.)!",
        a: "14e44"
      }, {
        p: "لیندې جوړې که!",
        f: "leendé joRe ka!",
        e: "Make slingshots (f. pl.)!",
        a: "14e45"
      }]}
    </Examples>
    <h5 {...{
      "id": "word-w-p-چائې-f-chaay-e-tea-f-pl-a-chaay-m--"
    }}><Word w={{
        p: "چائې",
        f: "chaay",
        e: "tea (f. pl.)",
        a: "chaay-m"
      }} mdxType="Word" /></h5>
    <Examples mdxType="Examples">
  {[{
        p: "چائې جوړې که!",
        f: "chaay jóRe ka!",
        e: "Make tea (f. pl.)!",
        a: "14e46"
      }]}
    </Examples>
    <img src={potter} />
    <h4 {...{
      "id": "cook-it"
    }}>{`Cook it!`}</h4>
    <Table bordered unset verticalContent titleRow={["", "Masculine", "Feminine"]} mdxType="Table">
  {[[{
        e: "Singular"
      }, {
        p: "پوخ که!",
        f: "pokh ka!",
        e: "",
        a: "pokh-ka"
      }, {
        p: "پخه که!",
        f: "pakhá ka!",
        e: "",
        a: "pakha-ka"
      }], [{
        e: "Plural"
      }, {
        p: "پاخه که!",
        f: "paakhú ka!",
        e: "",
        a: "paakhu-ka"
      }, {
        p: "پخې که!",
        f: "pakhé ka!",
        e: "",
        a: "pakhe-ka"
      }]]}
    </Table>
    <img src={washingDishes} />
    <p>{`👆 Now try making sentences with some foods of different genders. Have your helper say the food and you tell him or her to cook it!`}</p>
    <p>{`ADD ENGLISH HERE`}</p>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "ټېپر",
        f: "Tepúr",
        e: "turnip - m. s.",
        a: "ttepur-m"
      }, {
        p: "غوښه",
        f: "ghwáxa",
        e: "meat - f. s. (never plural)",
        a: "ghwaxa-m"
      }, {
        p: "مولۍ",
        f: "moolúy",
        e: "radish - f. s.",
        a: "mooluy-m"
      }, {
        p: "ډوډۍ",
        f: "DoDúy",
        e: "bread / food - f. s.",
        a: "ddodduy-m"
      }, {
        p: "سبزی",
        f: "sabzée",
        e: "vegtables - f. s.",
        a: "sabzee-m"
      }, {
        p: "اګۍ",
        f: "agúy",
        e: "egg - f. s.",
        a: "aguy-m"
      }, {
        p: "ورېژې",
        f: "wréjze",
        e: "rice - f. pl.",
        a: "wrejze-m"
      }, {
        p: "چائې",
        f: "chaay / che",
        e: "tea - f. pl.",
        a: "chaay-m2"
      }, {
        p: "پۍ",
        f: "puy",
        e: "milk - f. pl.",
        a: "puy-m"
      }, {
        p: "اوبهٔ",
        f: "oobú",
        e: "water - f. pl.",
        a: "oobu-m"
      }]}
    </Examples>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`While Pukhtuns tend to be more direct in giving commands than English speakers, there are a number of polite ways in which to express orders, requests and directions. The addition of `}<Word w={{
          p: "مهربانی",
          f: "mirabaanee",
          e: "",
          a: "mirabaane-2"
        }} mdxType="Word" />{` to the start of the sentence, as in the request “Please don’t shut the window” is one such way.`}</li>
    </ol>
    <h4 {...{
      "id": "verbs-commonly-used-in-the-imperative-mood"
    }}>{`Verbs commonly used in the imperative mood`}</h4>
    <Examples mdxType="Examples">
  {[{
        p: "بازار ته لاړ شه او چينى واخله!",
        f: "baazáar ta laaR sha aw cheenee waakhla!",
        e: "Go to the bazaar and get sugar!",
        a: "14e47"
      }, {
        p: "بيمار يې، نن سکُول ته مۀ ځه!",
        f: "beemáar ye, nun skool ta mu-dza!",
        e: "You are sick, don't go to school today!",
        a: "14e48"
      }, {
        p: "نن خپل کار ختم که!",
        f: "nun khpul kaar khátum ka!",
        e: "Finish your work today!",
        a: "14e49"
      }, {
        p: "بجلى ولګوه!",
        f: "bijlée óolugawa!",
        e: "Turn on the electricity!",
        a: "14e50"
      }, {
        p: "بجلى بله که!",
        f: "bijlee bála ka!",
        e: "Turn on the electricity!",
        a: "14e51"
      }, {
        p: "پکے بند که!",
        f: "pakáy band ka!",
        e: "Turn off the fan!",
        a: "14e52"
      }, {
        p: "په ګرمو اوبو دا کپړې مۀ وينځه!",
        f: "pu gármo oobó ke daa kapRé mú-weendza!",
        e: "Don't wash these cloths in hot water!",
        a: "14e53"
      }, {
        p: "مېلمانۀ په بېټک کښې کښېنوه!",
        f: "melmaanu pu beTúk ke kenawa!",
        e: <>
          Seat the guests in the <em>betuk!</em>
        </>,
        a: "14e54"
      }, {
        p: "دا کتاب دفتر ته يوسه!",
        f: "daa kitáab dăftúr ta yósa!",
        e: "Take this book to the office!",
        a: "14e55"
      }, {
        p: "مِهربانى وکه زما سلامُونه هغه ته ورسوه!",
        f: "mirabaanee óoka, zmaa salaamoona haghú ta óorasawa!",
        e: "Please give my greetings to him!",
        a: "14e56"
      }, {
        p: "لرې شه!",
        f: "lúre sha!",
        e: "Go away!",
        a: "14e58"
      }, {
        p: "چپ شه!",
        f: "chup sha!",
        e: "Be quiet!",
        a: "14e59"
      }, {
        p: "صبر وکه!",
        f: "sábur óoka",
        e: "Be patienT!",
        a: "14e60"
      }, {
        p: "بچ شه!",
        f: "bach sha!",
        e: "Save yourself!",
        a: "14e61"
      }, {
        p: "شاته شه!",
        f: "shaa ta sha!",
        e: "Get to the back!",
        a: "14e62"
      }, {
        p: "اخوا شه!",
        f: "aakhwaa sha!",
        e: "Go there!",
        a: "14e63"
      }, {
        p: "شور مۀ کوه!",
        f: "shor mú-kawa!",
        e: "Don't make a noise!",
        a: "14e64"
      }, {
        p: "دېخوا شه!",
        f: "dékhwaa sha!",
        e: "Come here!",
        a: "14e65"
      }, {
        p: "چائې اوڅښه!",
        f: "chaay óoska!",
        e: "Drink tea!",
        a: "14e66"
      }, {
        p: "منډې مۀ وهه!",
        f: "munDe mú-waha!",
        e: "Don't run!",
        a: "14e67"
      }, {
        p: "ډوډۍ وخوره!",
        f: "DoDúy óokhora!",
        e: "Eat the food!",
        a: "14e68"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p>{`The `}<Word w={{
        p: "بېټک",
        f: "beTúk",
        e: "",
        a: "bettuk"
      }} mdxType="Word" />{` is the men’s guest room.`}</p>
    <h4 {...{
      "id": "some-polite-expressions"
    }}>{`Some polite expressions`}</h4>
    <Examples mdxType="Examples">
  {[{
        p: "تاسو کښېنئ!",
        f: "táaso kéney!",
        e: "Sit down!",
        a: "14e68b"
      }, {
        p: "خفه نۀ شئ، تاسو لږ اِنتظار وکئ.",
        f: "khufa nu shey, táaso luG intizáar óokey.",
        e: "I hope you won't be upset, please wait a little bit.",
        a: "14e69"
      }, {
        p: "راځئ! زۀ اوس چرته تلم خو يو ساعت د پاره کښېنئ.",
        f: "raadzey! zu oos chărta tlum, kho yo saat dupáara kéney.",
        e: "Come in! I was just going somewhere, but sit down for a little while.",
        a: "14e70"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>{`The last sentence is useful if you were leaving to go somewhere else at the moment a guest arrives.`}</p>
    <h2 {...{
      "id": "comprehension-drill-asking-and-giving-directions"
    }}>{`Comprehension drill: Asking and giving directions`}</h2>
    <p>{`Learn the common landmarks around your area that people use for direction-giving.`}</p>
    <p>{`👆 Try practising the words in the following chart with your teacher. Start by talking about an area you are familiar with, then move to something that is less familiar. Use a picture or a map to help you.`}</p>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "ښى لاس ته",
        f: "xee laas ta",
        e: "to the right",
        a: "14w1"
      }, {
        p: "دا لار ده",
        f: "daa laar da",
        e: "it's this road",
        a: "14w2"
      }, {
        p: "جُماعت",
        f: "jUmáat",
        e: "mosque",
        a: "14w3"
      }, {
        p: "ګڅ لاس ته",
        f: "guts laas ta",
        e: "to the left",
        a: "14w4"
      }, {
        p: "دا سيند دے",
        f: "daa seend day",
        e: "it's this river",
        a: "14w5"
      }, {
        p: "مخامخ ځئ",
        f: "mukhaamukh dzey",
        e: "go straight",
        a: "14w6"
      }, {
        p: "باغ",
        f: "baagh",
        e: "park, orchard, garden",
        a: "14w7"
      }, {
        p: "نېغ",
        f: "negh",
        e: "straight",
        a: "14w8"
      }, {
        p: "سيده",
        f: "séeda",
        e: "straight",
        a: "14w9"
      }, {
        p: "پلازه",
        f: "pláaza",
        e: "shopping center, plaza",
        a: "14w10"
      }, {
        p: "سل قدمه لاړ شه",
        f: "sil qadúma laaR sha",
        e: "go 100 paces",
        a: "14w11"
      }, {
        p: "تنګه کوڅه ده",
        f: "tánga kootsá da",
        e: "it's a narrow street",
        a: "14w12"
      }, {
        p: "تاؤ شه",
        f: "taaw sha",
        e: "",
        a: "14w13"
      }, {
        p: "پلن سړک",
        f: "plun saRúk",
        e: "",
        a: "14w14"
      }, {
        p: "ښکته",
        f: "xkúta",
        e: "",
        a: "14w15"
      }, {
        p: "بره",
        f: "bára",
        e: "",
        a: "14w16"
      }, {
        p: "ستن",
        f: "stun",
        e: "pole (electri-city, telephone, etc.)",
        a: "14w17"
      }, {
        p: "پُل",
        f: "pUl",
        e: "bridge",
        a: "14w18"
      }]}
    </Examples>
    <h2 {...{
      "id": "memorising-moment"
    }}>{`Memorising moment`}</h2>
    <h4 {...{
      "id": "matalóona"
    }}>{`matalóona`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[14]}</Examples>
    <h4 {...{
      "id": "note-3"
    }}>{`NOTE:`}</h4>
    <p>{`Taru Jabba is a small town on the outskirts of Peshawar that was known for its bandits and robbers. There are many regional versions of this proverb. One of the most common is: `}<Word w={{
        p: "هله به خبر شې چې تالو ژبې له ورشې.",
        f: "",
        e: "You'll know when your tongue sticks to your palate",
        a: ""
      }} mdxType="Word" />{`. Discuss with local friends which variant they use and what they understand by it.`}</p>
    <h4 {...{
      "id": "note-4"
    }}>{`NOTE:`}</h4>
    <p>{`The particle `}<Word w={{
        p: "دې",
        f: "de",
        e: "",
        a: "de-part"
      }} mdxType="Word" />{` can be used in prayers to mean 'may’. For example,`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "خُدائ دې وبخښه!",
        f: "khUdáay de óobakhxa!",
        e: "May God forgive you!",
        a: "14e71"
      }]}
    </Examples>
    <p>{`Be careful though, because `}<Word w={{
        p: "دې",
        f: "de",
        e: "",
        a: "de-part"
      }} mdxType="Word" />{` can have many other meanings!`}</p>
    <h2 {...{
      "id": "exercises-for-lesson-fourteen"
    }}>{`Exercises for Lesson Fourteen`}</h2>
    <ol>
      <li parentName="ol">{`Complete these sentences with the correct imperative verb form:`}</li>
    </ol>
    <Quiz mdxType="Quiz">
  {[{
        pref: {
          p: "تلل",
          f: "tlul"
        },
        beg: {
          p: "تهٔ بازار ته",
          f: "tu baazáar ta"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "ځه",
          f: "dza"
        }, {
          p: "لاړ شه",
          f: "laaR sha"
        }, {
          p: "لاړه شه",
          f: "laaRa sha"
        }]
      }, {
        pref: {
          p: "ګرځېدل",
          f: "gurdzedúl"
        },
        beg: {
          p: "په بازار کښې مهٔ",
          f: "pu baazáar ke mú"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "ګرځه",
          f: "gúrdza"
        }]
      }, {
        pref: {
          p: "پخول",
          f: "pakhawúl"
        },
        beg: {
          p: "ورېژې",
          f: "wréjze"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "پخې که",
          f: "pakhé ka"
        }, {
          p: "پخې کړه",
          f: "pakhé kRa"
        }, {
          p: "پخوه",
          f: "pakhawa"
        }, {
          p: "پخې کئ",
          f: "pakhé key"
        }, {
          p: "پخې کړئ",
          f: "pakhé key"
        }, {
          p: "پخوئ",
          f: "pakhawey"
        }]
      }, {
        pref: {
          p: "وړل",
          f: "oRúl"
        },
        beg: {
          p: "کُرسۍ دننه",
          f: "kUrsúy dunúna"
        },
        end: {
          p: "!",
          f: "!"
        },
        answer: [{
          p: "یوسه",
          f: "yósa"
        }, {
          p: "وړه",
          f: "oRá"
        }, {
          p: "یوسئ",
          f: "yósey"
        }, {
          p: "وړئ",
          f: "oRéy"
        }]
      }, {
        pref: {
          p: "وینځل",
          f: "weendzúl"
        },
        beg: {
          p: "لوښی",
          f: "lóoxee"
        },
        end: {
          p: "!",
          f: "!"
        },
        answer: [{
          p: "ووینځه",
          f: "óoweendza"
        }, {
          p: "وینځه",
          f: "weendza"
        }, {
          p: "ووینځئ",
          f: "óoweendzey"
        }, {
          p: "وینځئ",
          f: "weendzey"
        }]
      }]}
    </Quiz>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Make a list of sequential commands to perform several common tasks you need done. Write them down, learn them and then try them out.`}</li>
    </ol>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-fourteen-directions",
    level: 2,
    title: "Dialogue fourteen: Directions",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation drill",
    children: [
        
      ]
  },
{
    id: "imperatives",
    level: 2,
    title: "Imperatives",
    children: [
        
      ]
  },
{
    id: "a-summary-of-the-imperative-forms-of-verbs",
    level: 2,
    title: "A summary of the imperative forms of verbs",
    children: [
        
      ]
  },
{
    id: "intransitive-verbs-in-the-imperative-mood",
    level: 2,
    title: "Intransitive verbs in the imperative mood",
    children: [
        
      ]
  },
{
    id: "transitive-verbs-in-the-imperative-mood",
    level: 2,
    title: "Transitive verbs in the imperative mood",
    children: [
        
      ]
  },
{
    id: "comprehension-drill-asking-and-giving-directions",
    level: 2,
    title: "Comprehension drill: Asking and giving directions",
    children: [
        
      ]
  },
{
    id: "memorising-moment",
    level: 2,
    title: "Memorising moment",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-fourteen",
    level: 2,
    title: "Exercises for Lesson Fourteen",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Fourteen"
}

