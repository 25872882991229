export function handleAudioPlay(
  example: IExample | IDialogueLine,
  dKeyDown: boolean
) {
  if (!example.a) {
    return () => null;
  }
  return (e: React.MouseEvent<HTMLDivElement>) => {
    if (dKeyDown) {
      handleDownload(example);
    } else {
      playAudio(example.a);
    }
  };
}

function getAudioPath(a: string) {
  return `/audio/${a}.mp3`;
}

function playAudio(a: string | undefined) {
  if (!a) return;
  let audio = new Audio(getAudioPath(a));
  audio.addEventListener("ended", () => {
    audio.remove();
    audio.srcObject = null;
  });
  audio.play().catch((e) => {
    alert("Error playing audio - Connect to the internet and try again");
    console.error(e);
  });
}

function handleDownload(ps: IExample | IDialogueLine) {
  if (!ps.a) return;
  const p = Array.isArray(ps.p) ? ps.p.join(" - ") : ps.p || "recording";
  const documentName = `${p.replace(".", "")}.mp3`;

  fetch(getAudioPath(ps.a))
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      const href = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.download = documentName;
      a.href = href;
      a.click();
      a.href = "";
    })
    .catch((err) => console.error(err));
}
