
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"28 min read","minutes":27.62,"time":1657200,"words":5524}
/* @jsxRuntime classic */
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import memorizingMoments from "./memorizing-moments";
import park from "../assets/images/park.png";
import boyOnLedge from "../assets/images/boy-on-ledge.png";
import tree from "../assets/images/tree.png";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-seventeen-ladies-visit-to-the-tailor"
    }}>{`Dialogue seventeen: Ladies’ visit to the tailor`}</h2>
    <Dialogue audio="dialogue-17a" mdxType="Dialogue">
  {[{
        s: "L",
        p: ["دا کپړه مې راؤړې ده.", "زۀ غواړم چې د دې نه يو اعلیٰ قميص جوړ کړم.", "خو غاړه به ورله داسې کوې."],
        f: ["daa kapRá me raawuRe da.", "zu ghwaaRum che du de no yo aalaa qamées joR kRum.", "kho ghaaRa ba wăr-la daase kawe."],
        e: ["I have brought this cloth.", "I wish to make a lovely kamis from it.", "But make the neck like this."],
        a: "17d1"
      }, {
        s: "H",
        p: ["تاسو بې غمه شئ، ډېره خوارى به ورسره وکړم.", "دېخوا راشه چې ناپ دې واخلم."],
        f: ["táasa be ghuma shey, Déra khwaaree ba wăr-sara óokRum.", "dekhwaa ráasha che naap de wáakhlum."],
        e: ["Don't worry, I will work very hard on it.", "Come here so I can take your measurements."],
        a: "17d2"
      }, {
        s: "L",
        p: "ډېره مِهربانى. کله پورې به جوړې شى؟",
        f: "Dera mirabaanee. kala pore ba jóRe shee?",
        e: "Thank you very much, when will it be ready?",
        a: "17d3"
      }, {
        s: "H",
        p: "بس، لس ورځې پس ورپسې راشئ.",
        f: "bas, luswrudze pas ba wăr-pase ráashey.",
        e: "OK, come after ten days.",
        a: "17d4"
      }, {
        s: "L",
        p: "نه، شپږ ورځې پس به ورپسې راشم.",
        f: "na, shpuGwrudze pas ba wăr-pase ráashum.",
        e: "No, I will come back for it after six days.",
        a: "17d5"
      }, {
        s: "H",
        p: "دا خو به ډېره ګرانه شى، ما سره خو د مخکښې نه کار ډېر دے.",
        f: "daa kho ba Déra gráana shee, maa sara kho du mákhke na kaar Der day.",
        e: "That will be very hard, I have a lot of work to do before it.",
        a: "17d6"
      }, {
        s: "L",
        p: "خیر دے، ارجنټ کپړو کښې يې راله جوړې کړه!",
        f: "khay day, arjúnT kapRó ke ye raa-la jóRe kRa!",
        e: "That's OK, make it as a priority!",
        a: "17d7"
      }, {
        s: "H",
        p: "ښه ده چې تاسو دومره اِصرار کوئ، نو بيا ورپسې په دېرشم تاريخ مازيګر ټایم راشئ.",
        e: "OK, if you insist so much, come back on the 30th in the afternoon.",
        f: "xa da che taaso doomra isráar kawey, no byaa wăr-pase pu dershum taaréekh maazeegúr Taaym ráashey.",
        a: "17d8"
      }]}
    </Dialogue>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`Another useful phrase is:`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "صبا به دې پسې راشم.",
        f: "sabáa ba de pase ráashum.",
        e: "I will come for it tomorrow.",
        a: "17e1"
      }]}
    </Examples>
    <h2 {...{
      "id": "men-ordering-a-pair-of-sandals"
    }}>{`Men: Ordering a pair of sandals`}</h2>
    <Dialogue audio="dialogue-17b" mdxType="Dialogue">
  {[{
        s: "L",
        p: "کاکا، سائيدارې څپلۍ به راله جوړې کړې او کۀ نه؟",
        f: "kaakáa, saaydáare tsaplúy ba ráa-la jóRe kRe aw ku na?",
        e: "Uncle, would you make a pair of sandals for me by order or not?",
        a: "17d9"
      }, {
        s: "H",
        p: "ولې نه صېب، داسې څپلۍ به درته جوړې کړم چې ياد به ساتې!",
        f: "wăle na seb, daase tsaplúy ba dăr-ta jóRe kRum che yaad ba saate!",
        e: "Why not sir, I will make shoes that you will remember!",
        a: "17d10"
      }, {
        s: "L",
        p: "ښه، نو ناپ مې واخله.",
        f: "xa, no naap me wáakhla.",
        e: "OK, then take my measurements.",
        a: "17d11"
      }, {
        s: "H",
        p: "راؤړه، پښه په دې کاغذ کېږده.",
        f: "ráawRa, xpa pu de kaagház ke(G)da.",
        e: "Put your foot on this paper.",
        a: "17d12"
      }, {
        s: "L",
        p: "خو يو خيال به کوې، چې څپلۍ مضبُوطې هم وى او ښکُلې هم.",
        f: "kho yo khyaal ba kawé, che tsaplúy mazbóote hum wee aw xkhÚle hum.",
        e: "But take care that the shoes are strong and also good looking.",
        a: "17d13"
      }, {
        s: "H",
        p: "بې غمه شه، صېب زۀ به درته چارسد والې څپلۍ جوړې کړم.",
        f: "be ghuma sha, seb zu ba dăr-ta charsadwáale tsaplúy jóRe kRum.",
        e: "Don't worry I will make Charsadda sandals for you.",
        a: "17d14"
      }, {
        s: "L",
        p: ["او چې څپلۍ په رنګ تورې وى.", "زما سرې څپلۍ نۀ وى خوښې."],
        f: ["aw che tsaplúy pu rang tóre wee.", "zmaa sre tsaplúy nu wee khwaxe."],
        e: ["And the sandals should be black.", "I don't like red sandals."],
        a: "17d15"
      }, {
        s: "H",
        p: "فکر مۀ کوئ، چارسد والې څپلۍ ټولې تورې وى.",
        f: "fíkar mu kawey, charsadwáale tsaplúy Tóle tóre wee.",
        e: "Don't worry, all Charsadda sandals are black.",
        a: "17d16"
      }, {
        s: "L",
        p: "کله پورې به يې راته تيارې کړې؟",
        f: "kala pore ba ye raa-ta tayaare kRe?",
        e: "When will you have them ready for me?",
        a: "17d17"
      }, {
        s: "H",
        p: "يو مياشت پس يې تپوس وکړئ.",
        f: "yo myaasht pas ye tapós ókey.",
        e: "You inquire after one month.",
        a: "17d18"
      }, {
        s: "L",
        p: "نه يار، بس يوه هفته کښې راته تيارې کړه.",
        f: "nu yaar, bas yúwa háfta ke ráa-ta tayáaare kRa.",
        e: "No friend, just get it ready for me in one week.",
        a: "17d19"
      }]}
    </Dialogue>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p>
  <Word w={{
        p: "سائ",
        f: "saa'ee",
        e: "",
        a: "saay-f"
      }} mdxType="Word" /> is money paid in
  advance if goods are made to order. Hence{" "}
  <Word w={{
        p: "سائیدار",
        f: "saaydáar",
        e: "",
        a: ""
      }} mdxType="Word" /> means 'made to
  order'. Here the adjective is inflected to match the feminine plural{" "}
  <Word w={{
        p: "څپلۍ",
        f: "tsaplúy",
        e: "",
        a: ""
      }} mdxType="Word" /> so the speaker says{" "}
  <Word w={{
        p: "سائیدارې",
        f: "saaydáare",
        e: "",
        a: "saaydaare"
      }} mdxType="Word" />.
    </p>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation drill`}</h2>
    <p>{`👆 You have probably found that you are consistently getting some words corrected by helpful friends. Over this next week keep reminding everyone you talk to, to help you in pronunciation, and make a list of words you get wrong. Write them down and get your helper to drill you.`}</p>
    <p>{`One good idea is to either draw pictures or take photographs of the things which you have trouble pronouncing. Take these pictures with you for practice. Try to avoid translation back to your mother tongue.`}</p>
    <h5 {...{
      "id": "some-useful-phrases-are"
    }}>{`Some useful phrases are:`}</h5>
    <Examples mdxType="Examples">
  {[{
        p: "کُوم لفظ چې زۀ صفا نۀ شم وئيلے هغه به راته ټيک کړې؟",
        f: "kum lafz che zu safáa nu-shum wayulay haghá ba ráa-ta Teek kRe?",
        e: "Will you correct any words I can't speak clearly?",
        a: "17e2"
      }, {
        p: "دا به تۀ څنګه صفا وائى؟",
        f: "daa ba tu tsínga safáa wáaye?",
        e: "How do you say this clearly?",
        a: "17e3"
      }, {
        p: "تۀ زما په خبرو پوهېږې؟",
        f: "tu zmaa pu khaburo pohéGe?",
        e: "Do you understand my speech?",
        a: "17e4"
      }, {
        p: "زما ژبه ټيک ده؟",
        f: "zmaa jzíba Teek da?",
        e: "Is my pronunciation OK?",
        a: "17e5"
      }, {
        p: "ډېره مِهربانى چې تا ما ته دا ټيک کړو!",
        f: "Dera mirabaanee che taa maa ta daa Téek kRo!",
        e: "Thanks for correcting it for me!",
        a: "17e6"
      }]}
    </Examples>
    <h2 {...{
      "id": "reviewing-inflection"
    }}>{`Reviewing Inflection`}</h2>
    <p>{`Before moving on to the past tenses in Lesson Eighteen, it is worthwhile summarising the inflections used so far.`}</p>
    <Table bordered unset verticalContent titleRow={["Singular", "Plural", "Plural Inflected"]} mdxType="Table">
  {[[{
        p: "پلار",
        f: "plaar",
        e: "",
        a: "plaar"
      }, {
        p: "پلاران",
        f: "plaaráan",
        e: "",
        a: "plaaraan"
      }, {
        p: "پلارانو",
        f: "plaaráano",
        e: "",
        a: "plaaraano"
      }], [{
        p: "سړے",
        f: "saRáy",
        e: "",
        a: "sarray2"
      }, {
        p: "سړی",
        f: "saRée",
        e: "",
        a: "sarree2"
      }, {
        p: "سړو",
        f: "saRó",
        e: "",
        a: "sarro2"
      }], [{
        p: "کمره",
        f: "kamrá",
        e: "",
        a: "kamra"
      }, {
        p: "کمرې",
        f: "kamré",
        e: "",
        a: "kamre"
      }, {
        p: "کمرو",
        f: "kamró",
        e: "",
        a: "kamro"
      }], [{
        p: "ګُل",
        f: "gUl",
        e: "",
        a: "guul"
      }, {
        p: "ګُلونه",
        f: "gUlóoona",
        e: "",
        a: "guuloona"
      }, {
        p: "ګُلونو",
        f: "gUlóoono",
        e: "",
        a: "guuloono"
      }]]}
    </Table>
    <p>{`👆 Here are some examples of sentences with words that get inflected because they are connected to different pre/post-positions like `}<Word w={{
        p: "د",
        f: "du",
        e: "of",
        a: "du"
      }} mdxType="Word" />, <Word w={{
        p: "ته",
        f: "ta",
        e: "to",
        a: "ta"
      }} mdxType="Word" />{` etc. Look at just the English and try to say the following sentences. Some sentences without inflections are included to clarify the rules.`}</p>
    <h4 {...{
      "id": "1-possesion-word-w-p-د--f-du--e--a-du--"
    }}>{`1. Possesion (`}<Word w={{
        p: "د ...",
        f: "du ...",
        e: "",
        a: "du"
      }} mdxType="Word" />{`)`}</h4>
    <Examples mdxType="Examples">
  {[{
        p: "دا ښۀ کتاب دے.",
        f: "daa xu kitáab day.",
        e: "This is a good book.",
        a: "17e7"
      }, {
        p: "دا د مومن کتاب دے.",
        f: "daa du momín kitáab day.",
        e: "This is Momin's book.",
        a: "17e8"
      }, {
        p: "دا ښۀ کتابونه دى.",
        f: "daa xu kitaabóona dee.",
        e: "These are good books.",
        a: "17e9"
      }, {
        p: "د دې کتابونو قيمت څۀ دے؟",
        f: "du de kitaaboono qeemút tsu day?",
        e: "What is the price of these books?",
        a: "17e10"
      }, {
        p: "د مالټو درزن په څو دے؟",
        f: "du maalTo darzún pu tso day?",
        e: "What is the cost of a dozen oranges?",
        a: "17e11"
      }, {
        p: "د غواګانو پۍ خواږه دى؟",
        f: "du ghwaagáano puy kwaaGu dee?",
        e: "Is the cows' milk sweet?",
        a: "17e12"
      }, {
        p: "د ماشُومانو پلار مړ دے.",
        f: "du maashoomáano plaar muR day.",
        e: "The children's father is dead.",
        a: "17e13"
      }, {
        p: "د کابليانو وطن آزاد شو.",
        f: "du kaabuliyaano watún aazáad sho.",
        e: "The Kabulis' country (Afghan-istan) has become free.",
        a: "17e14"
      }, {
        p: "د دُوکاندارانو هړتال دے.",
        f: "du dookaandáano haRtáal day.",
        e: "It is the shopkeepers' strike.",
        a: "17e15"
      }, {
        p: "د انګرېزانو اختر دے.",
        f: "du angrezáano akhtár day.",
        e: "It is the English people's festival.",
        a: "17e16"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>
  <Word w={{
        p: "انګرېږ",
        f: "angréz",
        e: "",
        a: "angrez-f"
      }} mdxType="Word" /> is a general
  term for westerners.
    </p>
    <img src={park} />
    <h4 {...{
      "id": "2-to-word-w-p--ته-f-___-ta-e--a-ta---word-w-p--له-f--la-e--a-la-"
    }}>{`2. To (`}<Word w={{
        p: "... ته",
        f: "___ ta",
        e: "",
        a: "ta"
      }} mdxType="Word" />, <Word w={{
        p: "... له",
        f: "... la",
        e: "",
        a: "la"
      }} mdxType="Word" />{`)`}</h4>
    <Examples mdxType="Examples">
  {[{
        p: "زۀ ګُل له اوبۀ ورکوم.",
        f: "zu gÚl la oobú wăr-kawum.",
        e: "I am giving water to the flower.",
        a: "17e17"
      }, {
        p: "زۀ ګُلونو له اوبۀ ورکوم.",
        f: "zu gÚlóona la oobú wăr-kawum.",
        e: "I am giving water to the flowers.",
        a: "17e18"
      }, {
        p: "ماشُومانو ته ډوډۍ ورکئ!",
        f: "maashoomaano ta DoDúy wărkéy!",
        e: "Give food to the children!",
        a: "17e19"
      }, {
        p: "ماشُومان اسلام آباد ته ځى.",
        f: "maashoomáan islaamabáad ta dzee.",
        e: "The children are going to Islamabad.",
        a: "17e20"
      }]}
    </Examples>
    <h4 {...{
      "id": "3-of-word-w-p-د--f-du--e--a-du--"
    }}>{`3. Of (`}<Word w={{
        p: "د ...",
        f: "du ...",
        e: "",
        a: "du"
      }} mdxType="Word" />{`)`}</h4>
    <Examples mdxType="Examples">
  {[{
        p: "کُلونه مهٔ شوکوه!",
        f: "gUlóona mu shookawá!",
        e: "Don't pick the flowers!",
        a: "17e21"
      }, {
        p: "د ګُلونو خیال کوه!",
        f: "du gUlóono khyaal kawa!",
        e: "Take care of the flowers!",
        a: "17e22"
      }]}
    </Examples>
    <h4 {...{
      "id": "4-with-word-w-p-د--سره-f-du--sara-e--a-du-sara--"
    }}>{`4. With (`}<Word w={{
        p: "د ... سره",
        f: "du ... sara",
        e: "",
        a: "du-sara"
      }} mdxType="Word" />{`)`}</h4>
    <Examples mdxType="Examples">
  {[{
        p: "د ګُلُونو سره ازغى وى.",
        f: "du gUlóono sara azghee wee.",
        e: "Flowers have thorns.",
        a: "17e23"
      }, {
        p: "د ماشُومانو سره مينه کوه!",
        f: "du maashoomáano sara méena kawa!",
        e: "Love the children!",
        a: "17e24"
      }, {
        p: "د ماشُومانو څۀ کار نۀ وى.",
        f: "du maashoomaano tsu kaar nu wee.",
        e: "With children there is no work.",
        a: "17e25"
      }]}
    </Examples>
    <h4 {...{
      "id": "5-on-word-w-p-په--باندې-f-pu--baande-e--a-pu-baande--"
    }}>{`5. On (`}<Word w={{
        p: "په ... باندې",
        f: "pu ... baande",
        e: "",
        a: "pu-baande"
      }} mdxType="Word" />{`)`}</h4>
    <Examples mdxType="Examples">
  {[{
        p: "په کرسو باندې ګدۍ کېږده!",
        f: "pu kUrsó baande gadúy ké(G)da!",
        e: "Put cushions on the chairs!",
        a: "17e26"
      }, {
        p: "په مېزونو باندې مالټې دى.",
        f: "pu mezóono baande maalTe dee.",
        e: "Oranges are on the tables.",
        a: "17e27"
      }, {
        p: "په لارو باندې خټې دى.",
        f: "pu laar baande khúTe dee.",
        e: "Mud is on the roads.",
        a: "17e28"
      }, {
        p: "په اُستاذانو باندې سخته ده.",
        f: "pu Ustaazaano baande sakhta da.",
        e: "Teachers face hardship.",
        a: "17e29"
      }]}
    </Examples>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`In (`}<Word w={{
          p: "په ... کښې",
          f: "pu ... ke",
          e: "",
          a: "pu-ke"
        }} mdxType="Word" />{`)`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "په دې کمرو کښې ماران دى.",
        f: "pu kamró ke maaráan dee.",
        e: "There are snakes in these rooms.",
        a: "17e30"
      }]}
    </Examples>
    <h2 {...{
      "id": "expressing-the-ability-to-do-something-word-w-p-کولے-شی-f-kawulay-shee-e--a---"
    }}>{`Expressing the ability to do something `}<Word w={{
        p: "کولے شی",
        f: "kawulay shee",
        e: "",
        a: ""
      }} mdxType="Word" /></h2>
    <Examples mdxType="Examples">
  {[{
        p: "تۀ د هغوى مدد کولے شې؟",
        f: "tu du haghwée madúd kawúlay she?",
        e: "Can you help them?",
        a: "17e31"
      }, {
        p: "آو، کولے شم.",
        f: "aw, kawúlay shum.",
        e: "Yes, I can.",
        a: "17e32"
      }, {
        p: "هغه ښه ډوډۍ پخولے شى؟",
        f: "hagha xa DoDúy pakhawulay shee?",
        e: "Can she cook good food?",
        a: "17e33"
      }, {
        p: "نه، نۀ شې پخولے.",
        f: "na, nu shee pukhawulay.",
        e: "No, she can't.",
        a: "17e34"
      }, {
        p: "هغوى صبا زما کور ته نۀ شى راتلے؟",
        f: "haghwee sabaa zmaa kor ta nu shee raatlay?",
        e: "Can't they come to my house tomorrow?",
        a: "17e35"
      }, {
        p: "راتلے شى.",
        f: "raatlay shee.",
        e: "They can come.",
        a: "17e36"
      }, {
        p: "کۀ تۀ نۀ شې کولے نو زۀ کولے شم.",
        f: "ku tu nu she kawulay no zu kawulay shum.",
        e: "If you can't do it, I can.",
        a: "17e37"
      }, {
        p: "تۀ کپړه ګنډلے شې؟",
        f: "tu kapRá ganDulay shee?",
        e: "Can you sew cloth?",
        a: "17e38"
      }, {
        p: "آو، ګنډلے شم.",
        f: "aw, gunDulay shum.",
        e: "Yes, I can sew.",
        a: "17e39"
      }, {
        p: "مُونږ صبا نه مخکښې دا سبق نۀ شُو خلاصولے!",
        f: "moonG sabaa na makhke daa sabúq nu shoo khilaasawulay!",
        e: "We can't finish this lesson by tomorrow!",
        a: "17e40"
      }, {
        p: "تۀ سى- ايس-ايس کولے شې؟",
        f: "tu c.s.s. kawulay she?",
        e: "You can do the C.S.S.?",
        a: "17e41"
      }, {
        p: "آو، کولے شم.",
        f: "aw, kawulay shum.",
        e: "Yes, I can.",
        a: "17e42"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-3"
    }}>{`NOTE:`}</h4>
    <p>{`The C.S.S. stands for Civil Service Exam.`}</p>
    <p>{`You can express ability or possibility by adding `}<Word w={{
        p: "ـے",
        f: "-ay",
        e: "",
        a: ""
      }} mdxType="Word" /> on the end of the infinitive and then adding <Word w={{
        p: "شـ",
        f: "sh--",
        e: "",
        a: ""
      }} mdxType="Word" /> plus the present verb endings. Here's how to do that with the verb <Word w={{
        p: "کول",
        f: "kawul",
        e: "",
        a: ""
      }} mdxType="Word" />{`.`}</p>
    <Table bordered unset titleRow={["", "singular", "plural"]} mdxType="Table">
  {[[{
        e: "1st"
      }, {
        p: "کولے شم",
        f: "kawúlay shum",
        e: "",
        a: ""
      }, {
        p: "کولے شُو",
        f: "kawúlay shoo",
        e: "",
        a: ""
      }], [{
        e: "2nd"
      }, {
        p: "کولے شې",
        f: "kawúlay she",
        e: "",
        a: ""
      }, {
        p: "کولے شئ",
        f: "kawúlay shey",
        e: "",
        a: ""
      }], [{
        e: "3rd"
      }, {
        p: "کولے شی",
        f: "kawúlay shee",
        e: "",
        a: ""
      }, {
        p: "کولے شی",
        f: "kawúlay shee",
        e: "",
        a: ""
      }]]}
    </Table>
    <p>{`Note that the `}<Word w={{
        p: "ـے",
        f: "-ay",
        e: "",
        a: "ay-sound"
      }} mdxType="Word" />{` on the end of the the infinitive never inflects, regardless of gender or number.`}</p>
    <p>{`In the negative, the `}<Word w={{
        p: "شـ",
        f: "sh--",
        e: "",
        a: "sh-sound"
      }} mdxType="Word" />{` is usually flipped up in front of the infinitive, but can also be said in the regular order to make a firm statement or emphasize the negativity.`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "نهٔ شم کولے.",
        f: "nu shum kawulay.",
        e: "I can't. (more common)",
        a: "17e43"
      }, {
        p: "زهٔ کولے نهٔ شم.",
        f: "zu kawulay nu shum.",
        e: "I can not! (emphasis)",
        a: ""
      }]}
    </Examples>
    <h4 {...{
      "id": "more-examples-of-expressing-ability"
    }}>{`More examples of expressing ability`}</h4>
    <Examples mdxType="Examples">
  {[{
        p: "تۀ اوس ښار ته تلے شې؟",
        f: "tu oos xaar ta tlay she?",
        e: "Are you able to go to the city now?",
        a: "17e44"
      }, {
        p: "نه، نۀ شم تلے.",
        f: "nu, nu shum tlay.",
        e: "No, I am not able to go.",
        a: "17e45"
      }, {
        p: "دا پښتو هم وئيلے شى؟",
        f: "daa puxto hum wayulay shee?",
        e: "Is she able to speak Pukhto too?",
        a: "17e46"
      }, {
        p: "آو، دا وئيلے شى.",
        f: "aw, daa wayulay shee.",
        e: "Yes, she can.",
        a: "17e47"
      }, {
        p: "زۀ دننه راتلے شم؟",
        f: "zu dununa raatlay shum?",
        e: "Can I come in?",
        a: "17e48"
      }, {
        p: "آو، راتلے شئ.",
        f: "aw, raatlay shey.",
        e: "Yes, you can come in.",
        a: "17e49"
      }, {
        p: "تۀ احمد ډاکټر له بوتلے شې؟",
        f: "tu ahmad DakTúr la botláy she?",
        e: "Can you take Ahmad to the doctor?",
        a: "17e50"
      }, {
        p: "نۀ شم بوتلے.",
        f: "nu shum botláy.",
        e: "No, I can't take him.",
        a: "17e51"
      }, {
        p: "زۀ نن هغه نۀ شم ليدے.",
        f: "zu nun haghá nu shum leedáy.",
        e: "I can't see him today.",
        a: "17e52"
      }, {
        p: "زۀ نن دا کتابونه لائبرېرۍ ته نۀ شم وړلے.",
        f: "zu nun daa kitaabóona laaybrerúy ta nu shum oRúlay.",
        e: "I am not able to take these books to the library today.",
        a: "17e53"
      }, {
        p: "هغه دا لرګى ماتولے شى.",
        f: "haghá daa largee maatawulay shee.",
        e: "He is able to break this wood.",
        a: "17e54"
      }, {
        p: "هغه ګاډے چلولے شى؟",
        f: "hagha gaaDáy chalawulay shee?",
        e: "Can he drive a car?",
        a: "17e55"
      }, {
        p: "هغه لامبو وهلے شى؟",
        f: "haghá laambó wahulay shee?",
        e: "Can he swim?",
        a: "17e56"
      }]}
    </Examples>
    <p>{`👆 Following the above pattern, continue practicing with other verbs that have previously been introduced. Have your helper say a verb and a noun, then construct a sentence using both.`}</p>
    <h2 {...{
      "id": "days-of-the-week"
    }}>{`Days of the week`}</h2>
    <img src={boyOnLedge} style={{
      maxWidth: "8rem",
      float: "right"
    }} />
    <Table bordered mdxType="Table">
  {[[{
        p: "اِتوار",
        f: "itwááar",
        e: "Sunday",
        a: "itwaar"
      }], [{
        p: "پير",
        f: "peer",
        e: "Monday",
        a: "peer"
      }], [{
        p: "نهه",
        f: "núha",
        e: "Tuesday",
        a: ""
      }], [{
        p: "شورو",
        f: "shoró",
        e: "Wednesday",
        a: "shoro"
      }], [{
        p: "زیارت",
        f: "zyaarăt",
        e: "Thursday",
        a: "zyaarat"
      }], [{
        p: "جعمه",
        f: "jUmá",
        e: "Friday",
        a: "juuma"
      }], [{
        p: "خالی",
        f: "khaalée",
        e: "Saturday",
        a: "khaalee"
      }]]}
    </Table>
    <h4 {...{
      "id": "note-4"
    }}>{`NOTE:`}</h4>
    <p>{`In Khyber Pukhtunkhwa the Urdu names for the days of the week are widely used, in addition to Pukhto ones.`}</p>
    <h4 {...{
      "id": "use-days-of-the-week-in-sentences"
    }}>{`Use days of the week in sentences`}</h4>
    <Examples mdxType="Examples">
  {[
      // checkwrudze pronunc
      {
        p: "نن کُومه ورځ ده؟",
        f: "nun kumawrudz da?",
        e: "What day is it today?",
        a: "17e57"
      }, {
        p: "نن شورو ده.",
        f: "nun shoro da.",
        e: "Today is Wednesday.",
        a: "17e58"
      }, {
        p: "صبا کُومه ورځ ده؟",
        f: "sabáa kúmawrudz da?",
        e: "Which day is it tomorrow?",
        a: "17e59"
      }, {
        p: "صبا زيارت دے.",
        f: "sabáa zyaarăt day.",
        e: "Tomorrow is Thursday.",
        a: "17e60"
      }, {
        p: "صبا نه بله ورځ به کُومه ورځ وى؟",
        f: "sabaa na búlawrudz ba kúmawrudze wee?",
        e: "Which day will it be the day after tomorrow?",
        a: "17e61"
      }, {
        p: "صبا نه بله ورځ جُمعه ده.",
        f: "sabaa na búlawrudz jUmá da.",
        e: "The day after tomorrow is Friday.",
        a: "17e62"
      }, {
        p: "خالی په وړځ به چرته ځې؟",
        f: "khaalee puwrudz ba chărta dze?",
        e: "Where will you go on Saturday?",
        a: "17e63"
      }, {
        p: "خالى په ورځ چرته نۀ ځم.",
        f: "khaalee puwrudz chărta nu dzum.",
        e: "I'm not going anywhere on Saturday.",
        a: "17e64"
      }, {
        p: "دے هره ورځ په کور کښې وى؟",
        f: "day hărawrudz pu kor ke wee?",
        e: "Is he at home every day?",
        a: "17e65"
      }, {
        p: "نه، صرف د جُمعې په ورځ کور کښې وى.",
        f: "na, sirf du jUmé puwrudz pu kor ke wee.",
        e: "No, he's only home on Friday.",
        a: "17e66"
      }, {
        p: "د جُمعې په ورځ چُټى ده؟",
        f: "du jUmé puwrudz chUTee da?",
        e: "Is it a holiday on Friday?",
        a: "17e67"
      }, {
        p: "هره جُمعه چُټى وى.",
        f: "hăra jUmá chUTée wee.",
        e: "Every Friday is a holiday.",
        a: "17e68"
      }, {
        p: "زۀ هېڅ نۀ کوم، اوزګار يم، ستا کور ته به درځم.",
        f: "zu hets nu kawum, oozgáar yim, staa kor ta ba dărdzum.",
        e: "I am not doing any thing, I'm free, I will come to your house.",
        a: "17e69"
      }, {
        p: "هغه به د راتلُونککى ګُل په ورځ پنډۍ ته ځى؟",
        f: "hagha ba du raatlóonkee gUl puwrudze pinDúy to dzee?",
        e: "Will she go to Rawalpindi next Monday?",
        a: "17e70"
      }, {
        p: "نه، هغه به د نهې په ورځ ځى.",
        f: "na, hagha ba du núhe puwrudz dzee.",
        e: "No, she will go on Tuesday.",
        a: "17e71"
      }, {
        p: "يوې ورځې پسې بله ورځ.",
        f: "yowewrudze paso bulawrudz.",
        e: "One day follows another.",
        a: "17e72"
      }]}
    </Examples>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The days of are inflected just as any other nouns are when used with pre/post-positions like `}<Word w={{
            p: "د",
            f: "du",
            e: "",
            a: "du"
          }} mdxType="Word" /> and <Word w={{
            p: "په",
            f: "pu",
            e: "",
            a: "pu"
          }} mdxType="Word" />{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In Pukhtun thought, the new day begins at sunset, so the name of the night is attached to the day that follows. If you want to invite someone over on Thursday night, ask him for Friday night, or you will have your guest arrive on Wednesday night! For example:`}</p>
      </li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "تۀ د پير په شپه څۀ کوې؟",
        f: "tu du peer pu shpa tsu kawe?",
        e: "What are you doing Sunday night?",
        a: "17e73"
      }]}
    </Examples>
    <h2 {...{
      "id": "negatives-word-w-p-هېجرته-f-hechărta-e-nowhere-a-hecharta---word-w-p-هېڅ-کله-f-hetskala-e-never---for-people-a-hetskala---word-w-p-هېچرې-f-hechăre-e-never---for-things-a-hechare---word-w-p-هېڅوک-f-hetsok-e-no-one-a-hetsok---word-w-p-هېچا-f-hechaa-e-no-one---inflected-a-hechaa--"
    }}>{`Negatives `}<Word w={{
        p: "هېجرته",
        f: "hechărta",
        e: "nowhere",
        a: "hecharta"
      }} mdxType="Word" />, <Word w={{
        p: "هېڅ کله",
        f: "hetskala",
        e: "never - for people",
        a: "hetskala"
      }} mdxType="Word" />, <Word w={{
        p: "هېچرې",
        f: "hechăre",
        e: "never - for things",
        a: "hechare"
      }} mdxType="Word" />, <Word w={{
        p: "هېڅوک",
        f: "hetsok",
        e: "no one",
        a: "hetsok"
      }} mdxType="Word" />, <Word w={{
        p: "هېچا",
        f: "hechaa",
        e: "no one - inflected",
        a: "hechaa"
      }} mdxType="Word" /></h2>
    <Examples mdxType="Examples">
  {[{
        p: "هېچرته نۀ ځُو.",
        f: "hechărta nu dzoo.",
        e: "We are not going anywhere.",
        a: "17e74"
      }, {
        p: "زۀ به هېڅ کله کراچى ته نۀ ځم.",
        f: "zu ba hets kala karaachúy ta nu dzum.",
        e: "I will never go to Karachi.",
        a: "17e75"
      }, {
        p: "هېڅ کله غلطى مۀ کوئ!",
        f: "hets kala ghalatée mu kawey!",
        e: "Never make mistakes!",
        a: "17e76"
      }, {
        p: "زۀ به هېچرې ګُناه ونۀ کړم.",
        f: "zu ba hechăre gUnáa oo-nu-kRum.",
        e: "I will never sin (lit. 'do sin').",
        a: "17e77"
      }, {
        p: "هېچا ته مۀ وايه!",
        f: "hechaa ta mu waaya!",
        e: "Don't tell anyone!",
        a: "17e78"
      }, {
        p: "هېچا ته نۀ دے معلُوم.",
        f: "hechaa ta nu day maalóom.",
        e: "No one knows.",
        a: "17e79"
      }]}
    </Examples>
    <h2 {...{
      "id": "comprehension-drill-learning-antonyms"
    }}>{`Comprehension drill: Learning antonyms`}</h2>
    <p>{`We tend to want to write things down all the time, but this may not be too helpful in the long run. Try to conduct this drill entirely in Pukhto and not write anything down. Use it anytime you want to find out a new descriptive word.`}</p>
    <p>{`👆 Find two contrasting items that illustrate common descriptives such as sweet–bitter, hot–cold, clean–dirty, new–old, thin–thick, heavy–light, dull–sharp, etc.`}</p>
    <h5 {...{
      "id": "a-learning-descriptives"
    }}>{`A. Learning descriptives`}</h5>
    <p>{`Put some paired objects on a tray and ask these questions:`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "د دې په باره کښې څهٔ وائی؟",
        f: "du de pu baara ke tsu waaye?",
        e: "How would you describe this? (What do you say bout this?)",
        a: "17e80"
      }, {
        p: "دا ____ دي.",
        f: "daa ____ dee.",
        e: "These are _____.",
        a: "17e81"
      }, {
        p: "د _____ خِلاف څۀ ټکے دے؟",
        f: "du _____ khiláaf tsu Tukay day?",
        e: "What is the opposite word for _______?",
        a: "17e82"
      }, {
        p: "د ____ خِلاف _____ دے.",
        f: "du _____ khiláaf ____ day.",
        e: "The oppossite of _____ is _____.",
        a: "17e83"
      }]}
    </Examples>
    <h5 {...{
      "id": "a-antonym-soup"
    }}>{`A. Antonym soup`}</h5>
    <p>{`👆 Put all the objects out on a tray and pick up the correct one when your helper describes it.`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "زۀ به ډېر خوشحاله شم کۀ تۀ يوه لوبه ما سره وکې ...",
        f: "zu ba Der khosháala shum ku tu yuwa lóba maa sara óoke ...",
        e: "I would be very happy if you would play a game with me ...",
        a: "17f1"
      }, {
        p: "چې زۀ په هغې کښې د پښتو نوى ټکى زده کم.",
        f: "che zu pu haghe ke du puxtó nuwee Túkee zda kawum.",
        e: "so that from it I can learn new Pukhto words.",
        a: "17f2"
      }, {
        p: "تۀ به ما له اِشاره راکې، زۀ به ګورم چې هغه نشانى په کوُم څيز کښې ده او زۀ به هغه راوچتوم.",
        f: "tu ba maa la ishaara raake, zu ba gorum che hágha nishaane pu kum tseez ke da aw zu ba hágha raa-oochutawúm.",
        e: "You will give me instructions, and I will look to see which object has that characteristic and pick it up.",
        a: "17f3"
      }]}
    </Examples>
    <h2 {...{
      "id": "memorising-moments"
    }}>{`Memorising moments`}</h2>
    <h4 {...{
      "id": "mataloona"
    }}>{`mataloona`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[17]}</Examples>
    <h4 {...{
      "id": "note-5"
    }}>{`NOTE:`}</h4>
    <p>{`Shabaz Gara is a famous village near Mardan.`}</p>
    <h2 {...{
      "id": "exercises-for-lesson-seventeen"
    }}>{`Exercises for Lesson Seventeen`}</h2>
    <ol>
      <li parentName="ol">{`Answer the following questions in the positive:`}</li>
    </ol>
    <Quiz mdxType="Quiz">
  {[{
        q: {
          p: "تۀ پښتو وئيلے شې؟",
          f: "tu puxtó wayulay she?"
        },
        answer: [{
          p: "او، زهٔ پښتو وئیلے شم.",
          f: "aw, zu puxto wayulay shum."
        }, {
          p: "آو، زهٔ پښتو وئیلے شم.",
          f: "aaw, zu puxto wayulay shum."
        }, {
          p: "او، زهٔ پښتو وائیلے شم.",
          f: "aw, zu puxto waayulay shum."
        }, {
          p: "آو، زهٔ پښتو وائیلے شم.",
          f: "aaw, zu puxto waayulay shum."
        }]
      }, {
        q: {
          p: "هغه کولے شى چې خط وليکى؟",
          f: "haghá kawúlay shee che khat óoleekee?"
        },
        answer: [{
          p: "او، هغه کولے شی چې خط ولیکی.",
          f: "aw, hagha kawulay shee che khat óoleekee."
        }, {
          p: "آو، هغه کولے شی چې خط ولیکی.",
          f: "aaw, hagha kawulay shee che khat óoleekee."
        }, {
          p: "آو، هغه خط لیکلے شی.",
          f: "aaw, hagha khat leekulay shee."
        }]
      }, {
        q: {
          p: "تۀ نن جامې وينځلے شې؟",
          f: "tu nun jaame weendzulay shee?"
        },
        answer: [{
          p: "او، زهٔ نن جامې وینځلے شم.",
          f: "aw, zu nun jaame weendzulay shum."
        }, {
          p: "آو، زهٔ نن جامې وینځلے شم.",
          f: "aaw, zu nun jaame weendzulay shum."
        }]
      }, {
        q: {
          p: "زۀ اوس بازار ته تلے شم؟",
          f: "zu oos baazáar ta tlay shum?"
        },
        answer: [{
          p: "او، تهٔ اوس بازار ته تلے شې.",
          f: "aw, tu oos baazáar ta tlay she."
        }, {
          p: "او، تهٔ اوس بازار ته تللے شې.",
          f: "aw, tu oos baazáar ta tlulay she."
        }, {
          p: "آو، تهٔ اوس بازار ته تلے شې",
          f: "aaw, tu oos baazáar ta tlay she."
        }, {
          p: "آو، تهٔ اوس بازار ته تللے شې.",
          f: "aaw, tu oos baazáar ta tlulay she."
        }]
      }, {
        q: {
          p: "هغوى مدد کولے شى؟",
          f: "haghwée madúd kawúlay shee?"
        },
        answer: [{
          p: "او، هغوی مدد کولے شس.",
          f: "aw, haghwée madud kawúlay shee."
        }, {
          p: "آو، هغوی مدد کولے شس.",
          f: "aaw, haghwée madud kawúlay shee."
        }]
      }]}
    </Quiz>
    <p>{`Now answer the same questions with `}<em parentName="p">{`negative`}</em>{` answers.`}</p>
    <Quiz mdxType="Quiz">
  {[{
        q: {
          p: "تۀ پښتو وئيلے شې؟",
          f: "tu puxtó wayulay she?"
        },
        answer: [{
          p: "نه، زهٔ پښتو نهٔ شم وئیلے.",
          f: "na, zu puxto nu shum wayulay."
        }, {
          p: "نه، زهٔ پښتو وئیلے نهٔ شم.",
          f: "na, zu puxto wayulay nu shum."
        }, {
          p: "نه، زهٔ پښتو نهٔ شم وائیلے.",
          f: "na, zu puxto nu shum waayulay."
        }, {
          p: "نه، زهٔ پښتو وائیلے نهٔ شم.",
          f: "na, zu puxto waayulay nu shum."
        }]
      }, {
        q: {
          p: "هغه کولے شى چې خط وليکى؟",
          f: "haghá kawúlay shee che khat óoleekee?"
        },
        answer: [{
          p: "نه، هغه نه شی کولے چې خط ولیکی.",
          f: "na, haghá nu shee kawulay che khat ooleekee."
        }, {
          p: "نه، هغه خط نه شی لیکلے.",
          f: "na, hagha khat nu shee leekúlay."
        }, {
          p: "نه، هغه خط لیکلے نه شی.",
          f: "na, hagha khat leekulay nu shee."
        }]
      }, {
        q: {
          p: "تۀ نن جامې وينځلے شې؟",
          f: "tu nun jaame weendzulay shee?"
        },
        answer: [{
          p: "نه، زهٔ نن جامې نهٔ شم وینځلے.",
          f: "na, zu nun jaame nu shum weendzulay."
        }, {
          p: "نه، زهٔ نن جامې وینځلے نهٔ شم.",
          f: "na, zu nun jaame weendzulay nu shum."
        }]
      }, {
        q: {
          p: "زۀ اوس بازار ته تلے شم؟",
          f: "zu oos baazáar ta tlay shum?"
        },
        answer: [{
          p: "نه، زهٔ اوس بازار ته نهٔ شم تلے",
          f: "na, zu oos baazáar ta nu shum tlay."
        }, {
          p: "نه، زهٔ اوس بازار ته تلے نهٔ شم.",
          f: "na, zu oos baazáar ta tlay nu shum."
        }, {
          p: "نه، زهٔ اوس بازار ته نهٔ شم تللے",
          f: "na, zu oos baazáar ta nu shum tlulay."
        }, {
          p: "نه، زهٔ اوس بازار ته تللے نهٔ شم.",
          f: "na, zu oos baazáar ta tlulay nu shum."
        }]
      }, {
        q: {
          p: "هغوى مدد کولے شى؟",
          f: "haghwée madúd kawúlay shee?"
        },
        answer: [{
          p: "نه، هغوی مدد نهٔ شی کولے.",
          f: "na, haghwée madud nú shee kawulay."
        }, {
          p: "نه، هغوی مدد کولے نهٔ شی.",
          f: "na, haghwée madud kawulay nu shee."
        }, {
          p: "نه، هغوی مدد نهٔ شی کوے.",
          f: "na, haghwée madud nú shee kaway."
        }, {
          p: "نه، هغوی مدد کوے نهٔ شی.",
          f: "na, haghwée madud kaway nu shee."
        }]
      }]}
    </Quiz>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Using the list below, discover the Pukhto for each word plus their opposites. Write them down in Pukhto script or phonetics. Find objects that illustrate these common descriptives, and then practice them according to the method found in the Comprehension drill for this Lesson (Learning antonyms).`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        e: "big",
        blank: true,
        store: "17ant1"
      }, {
        e: "sharp",
        blank: true,
        store: "17ant2"
      }, {
        e: "rough",
        blank: true,
        store: "17ant3"
      }, {
        e: "dirty",
        blank: true,
        store: "17ant4"
      }, {
        e: "loose",
        blank: true,
        store: "17ant5"
      }, {
        e: "soft",
        blank: true,
        store: "17ant6"
      }, {
        e: "fat",
        blank: true,
        store: "17ant7"
      }, {
        e: "wide",
        blank: true,
        store: "17ant8"
      }, {
        e: "wet",
        blank: true,
        store: "17ant9"
      }, {
        e: "empty",
        blank: true,
        store: "17ant10"
      }, {
        e: "shiny",
        blank: true,
        store: "17ant11"
      }, {
        e: "hollow",
        blank: true,
        store: "17ant12"
      }, {
        e: "thin",
        blank: true,
        store: "17ant13"
      }, {
        e: "flat",
        blank: true,
        store: "17ant14"
      }, {
        e: "hot",
        blank: true,
        store: "17ant15"
      }, {
        e: "alive",
        blank: true,
        store: "17ant16"
      }, {
        e: "heavy",
        blank: true,
        store: "17ant17"
      }, {
        e: "dark",
        blank: true,
        store: "17ant18"
      }, {
        e: "sweet",
        blank: true,
        store: "17ant19"
      }, {
        e: "uneven",
        blank: true,
        store: "17ant20"
      }, {
        e: "full",
        blank: true,
        store: "17ant21"
      }, {
        e: "fresh",
        blank: true,
        store: "17ant22"
      }, {
        e: "sticky",
        blank: true,
        store: "17ant23"
      }, {
        e: "whole",
        blank: true,
        store: "17ant24"
      }]}
    </Examples>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Find out how new words work. Use this exercise to start developing your own skills in Pukhto grammar.`}</li>
    </ol>
    <p>{`👆 With a helper fill out the following chart in Pukhto (either script or phonetics). As far as possible just use Pukhto to do your investigation.`}</p>
    <Table bordered unset wide titleRow={["", "Bitter", "Sweet", "Sour"]} mdxType="Table">
  {[[{
        e: "m. s."
      }, {
        blank: true,
        store: "17ms1"
      }, {
        blank: true,
        store: "17ms2"
      }, {
        blank: true,
        store: "17ms3"
      }], [{
        e: "m. pl."
      }, {
        blank: true,
        store: "17mp1"
      }, {
        blank: true,
        store: "17mp2"
      }, {
        blank: true,
        store: "17mp3"
      }], [{
        e: "f. s."
      }, {
        blank: true,
        store: "17fs1"
      }, {
        blank: true,
        store: "17fs2"
      }, {
        blank: true,
        store: "17fs3"
      }], [{
        e: "f. pl."
      }, {
        blank: true,
        store: "17fp1"
      }, {
        blank: true,
        store: "17fp2"
      }, {
        blank: true,
        store: "17fp3"
      }]]}
    </Table>
    <p>{`Now pick some adjectives and match them with various items of food to test your ability to make sentences with them. Some to try are:.`}</p>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "سېو",
        f: "sew",
        e: "m.",
        a: ""
      }, {
        p: "مرچکے",
        f: "machakáy",
        e: "m.",
        a: ""
      }, {
        p: "نیمبو",
        f: "neembóo",
        e: "m.",
        a: ""
      }, {
        p: "الُوچه",
        f: "aloochá",
        e: "f.",
        a: ""
      }, {
        p: "کرېله",
        f: "karela",
        e: "f.",
        a: ""
      }, {
        p: "مالټه",
        f: "maalTá",
        e: "f.",
        a: ""
      }]}
    </Examples>
    <img src={tree} />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-seventeen-ladies-visit-to-the-tailor",
    level: 2,
    title: "Dialogue seventeen: Ladies’ visit to the tailor",
    children: [
        
      ]
  },
{
    id: "men-ordering-a-pair-of-sandals",
    level: 2,
    title: "Men: Ordering a pair of sandals",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation drill",
    children: [
        
      ]
  },
{
    id: "reviewing-inflection",
    level: 2,
    title: "Reviewing Inflection",
    children: [
        
      ]
  },
{
    id: "expressing-the-ability-to-do-something-word-w-p-کولے-شی-f-kawulay-shee-e--a---",
    level: 2,
    title: <React.Fragment>{`Expressing the ability to do something `}<Word w={{ p: "کولے شی", f: "kawulay shee", e: "", a: "" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "days-of-the-week",
    level: 2,
    title: "Days of the week",
    children: [
        
      ]
  },
{
    id: "negatives-word-w-p-هېجرته-f-hechărta-e-nowhere-a-hecharta---word-w-p-هېڅ-کله-f-hetskala-e-never---for-people-a-hetskala---word-w-p-هېچرې-f-hechăre-e-never---for-things-a-hechare---word-w-p-هېڅوک-f-hetsok-e-no-one-a-hetsok---word-w-p-هېچا-f-hechaa-e-no-one---inflected-a-hechaa--",
    level: 2,
    title: <React.Fragment>{`Negatives `}<Word w={{ p: "هېجرته", f: "hechărta", e: "nowhere", a: "hecharta" }} />, <Word w={{ p: "هېڅ کله", f: "hetskala", e: "never - for people", a: "hetskala" }} />, <Word w={{ p: "هېچرې", f: "hechăre", e: "never - for things", a: "hechare" }} />, <Word w={{ p: "هېڅوک", f: "hetsok", e: "no one", a: "hetsok" }} />, <Word w={{ p: "هېچا", f: "hechaa", e: "no one - inflected", a: "hechaa" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "comprehension-drill-learning-antonyms",
    level: 2,
    title: "Comprehension drill: Learning antonyms",
    children: [
        
      ]
  },
{
    id: "memorising-moments",
    level: 2,
    title: "Memorising moments",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-seventeen",
    level: 2,
    title: "Exercises for Lesson Seventeen",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Seventeen"
}

