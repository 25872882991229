
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"32 min read","minutes":31.87,"time":1912200,"words":6374}
/* @jsxRuntime classic */
/* @jsx mdx */
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import tribes from "../assets/images/tribes.png";
import mouthSounds from "../assets/images/mouth-sounds.png";
import manWithCane from "../assets/images/man-with-cane.png";
import deg from "../assets/images/deg.png";
import manualRickshaw from "../assets/images/manual-rickshaw.png";
import oneBook from "../assets/images/one-book.png";
import saw from "../assets/images/saw.png";
import camel from "../assets/images/camel.png";
import ox from "../assets/images/ox.png";
import oxCart from "../assets/images/ox-cart.png";
import domes from "../assets/images/domes.png";
export const PhoneticsTable = ({
  children
}) => <Table bordered verticalContent unset wide titleRow={["Phonetic symbol", "Pukhto letter", "Explanation of sounds", "Sample words"]} colGroup={[{}, {}, {
  style: {
    width: "45%"
  }
}]} mdxType="Table">
    {children}
  </Table>;
export const Abbreviations = ({
  children: abbreviations
}) => <table>
    <colgroup>
      <col style={{
      width: "6rem"
    }} />
    </colgroup>
    <tbody>
      {abbreviations.map((abrev, i) => <tr kay={i}>
          <td>
            <em>{abrev.abv}</em>
          </td>
          <td>{abrev.meaning}</td>
        </tr>)}
    </tbody>
  </table>;
export const Alphabet = ({
  children: letters
}) => {
  const joiner = "ـ";
  const ensureMidY = l => {
    if (l === "ے") {
      return "ی";
    }
    return l;
  };
  const prefix = letter => letter.prefixedBeginning ? "ا" : "";
  const endJoiner = letter => letter.noJoinAfter ? "" : joiner;
  const begLetter = letter => prefix(letter) + ensureMidY(letter.letter) + endJoiner(letter);
  const midLetter = letter => joiner + ensureMidY(letter.letter) + endJoiner(letter);
  const endLetter = letter => joiner + letter.letter;
  return <Table bordered unset titleRow={["Letter", "Beg.", "Mid.", "End", "Phonetic Sound"]} colGroup={[{}, {}, {}, {}, {
    style: {
      textAlign: "left"
    }
  }]} mdxType="Table">
      {letters.map(letter => [{
      p: letter.letter
    }, {
      p: begLetter(letter)
    }, {
      p: midLetter(letter)
    }, {
      p: endLetter(letter)
    }, {
      e: letter.sound,
      a: letter.a
    }])}
    </Table>;
};

const layoutProps = {
  PhoneticsTable,
Abbreviations,
Alphabet
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "how-to-use-this-book"
    }}>{`How to use this book`}</h2>
    <p>{`This book is intended to be a guide for those wishing to begin learning the Yusufzai dialect of Pakistani Pukhto that is spoken in and around Peshawar, Mardan and the Swat vallay.`}</p>
    <p><strong parentName="p">{`Click on words and phrases to hear them`}</strong>{`, and `}<strong parentName="p">{`try to imitate and memorize what you hear`}</strong>{`. It’s best to start by memorizing phrases that you want to be able to say, and then `}<strong parentName="p">{`practice by trying to talk to Pukhto speakers.`}</strong></p>
    <p>{`Feel free to jump around and let your curiosity guide you, but also try to work through the chapters. `}<strong parentName="p">{`Try to understand the different words and grammar forms`}</strong>{`. Don’t worry about memorizing every single word or phrase, but memorize the ones that you find interesting or useful. `}<strong parentName="p">{`Play around with the exercises and try to say different things using the patters and rules in the grammar`}</strong>{`.`}</p>
    <h2 {...{
      "id": "the-sounds-of-pukhto"
    }}>{`The sounds of Pukhto`}</h2>
    <p>{`The first challenge in learning Pukhto is to hear and then produce the different sounds that have to be mastered. These early exercises will have to be revisited frequently to teach the tongue new habits.`}</p>
    <p>{`A number of sounds found in Pukhto are not found in English. It is helpful to be able to write these new sounds in phonetic form. This book uses a modified version of the International Phonetic Alphabet to help you. Phonetics simply provide a guide to the sounds, which can only be learned from a Pukhtun patient enough to correct you. You can best best master the new sounds by repeating what you hear from a Pukhtun helper.`}</p>
    <p>{`In addition, the sounds of Pukhto vary according to region. There are some major differences in the sounds of the various Pukhto dialects, even within Khyber Pukhtunkhwa. The phonetic chart below represents the sounds of the language as spoken in and around Peshawar and the Swat vallay, although some indication of dialect differences has been given.`}</p>
    <img src={mouthSounds} style={{
      width: "80%",
      maxWidth: "80%"
    }} />
    <h3 {...{
      "id": "a-summary-of-the-sounds-of-yusufzai-pukhto"
    }}>{`A summary of the sounds of Yusufzai Pukhto`}</h3>
    <p>{`Pukhto has a reputation for being a harsh-sounding language. The harshness is generated by guttural sounds that are made at the back of the throat. Other “hard” or “retroflex” sounds are made when the tongue is held behind the hard palate and flicked forward. In this book the hard sounds appear in capital letters. `}<Word w={{
        p: "ړ",
        f: "R",
        e: "",
        a: "rr-sound"
      }} mdxType="Word" />, <Word w={{
        p: "ډ",
        f: "D",
        e: "",
        a: "dd-sound"
      }} mdxType="Word" />, <Word w={{
        p: "ټ",
        f: "T",
        e: "",
        a: "tt-sound"
      }} mdxType="Word" />, and <Word w={{
        p: "ڼ",
        f: "N",
        e: "",
        a: "nn-sound"
      }} mdxType="Word" />. With a little practice most learners can soon master these hard consonants, as well as the guttural sounds <Word w={{
        p: "kh",
        f: "خ / ښ",
        e: "",
        a: "kh-sound"
      }} mdxType="Word" /> and <Word w={{
        p: "غ",
        f: "gh",
        e: "",
        a: "gh-sound"
      }} mdxType="Word" />{`.`}</p>
    <p>{`But Pukhto is not all guttural or hard. There are also “soft” or “dental” sounds that are made with the tongue on the back of the teeth. Again, there are no exact equivalents of these sounds, which include `}<Word w={{
        p: "د",
        f: "d",
        e: "",
        a: "d-sound"
      }} mdxType="Word" />, <Word w={{
        p: "ت",
        f: "t",
        e: "",
        a: "t-sound"
      }} mdxType="Word" />{`. In fact to begin with many learners find they are unable to hear the difference between the hard and soft sounds, let alone make them.`}</p>
    <p>{`Again, lots of practice and attentive listening will be the kay to correct usage. (Please note that the charts below are arranged in an order similar to that of the English alphabet.)`}</p>
    <p>{`👆 Practice these sounds with your language helper using the following chart and tables. Repeat each of the words after your language helper. The meanings of the words are not important.`}</p>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`A 👆 indicates that action needs to be taken by the teacher or language helper, or by the student and teacher or helper working together.`}</p>
    <h4 {...{
      "id": "consonants"
    }}>{`Consonants`}</h4>
    <p>{`Few of the consonants are exactly the same as those used in English, and many are not found in English at all. It will take constant repetition to form new habits of pronunciation for these sounds, but working hard at it now will reap big dividends for years to come. These are the distinctive sounds that give Pukhto its special identity.`}</p>

    <p style={{
      color: "red"
    }}>
  Sorry, not all recordings for the following sounds and words are present. For
  all sounds and words check back soon.
    </p>
    <PhoneticsTable mdxType="PhoneticsTable">
  {[[{
        f: "b"
      }, {
        p: "ب"
      }, {
        e: "Similar to English / b / but less aspirated"
      }, {
        p: "بادام",
        f: "baadáam",
        e: "",
        a: ""
      }], [{
        f: "ch",
        a: ""
      }, {
        p: "چ",
        a: ""
      }, {
        e: "Like the consonant cluster / ch / as in 'chair'"
      }, {
        p: "چائې",
        f: "chaay",
        a: "chaay-m2"
      }], [{
        f: "d",
        a: "d-sound"
      }, {
        p: "د",
        a: "d-sound"
      }, {
        e: "The soft, dental / d /; a Spanish dental / d /; there is no English equivalent but close to the th in 'this'"
      }, [{
        p: "دوه",
        f: "dwa",
        a: ""
      }, {
        p: "وادۀ",
        f: "waadú",
        a: ""
      }, {
        p: "آزاد",
        f: "aazáad",
        a: ""
      }]], [{
        f: "D",
        a: "dd-sound"
      }, {
        p: "ډ",
        a: "dd-sound"
      }, {
        e: "The retroflex or hard / d /; the tongue is flexed behind the hard palate"
      }, [{
        p: "ډوډۍ",
        f: "DoDúy",
        a: ""
      }, {
        p: "ګډ",
        f: "guD",
        a: ""
      }]], [{
        f: "ف",
        a: ""
      }, {
        p: "f",
        a: ""
      }, {
        e: "Similar to the English / f /"
      }, {
        p: "فرمان",
        f: "farmáan",
        a: ""
      }], [{
        f: "g",
        a: ""
      }, {
        p: "ګ , ږ",
        a: ""
      }, {
        e: "Like the English / g / as in the word 'glove'"
      }, {
        p: "ګران",
        f: "graan",
        a: ""
      }], [{
        f: "gh",
        a: "gh-sound"
      }, {
        p: "غ",
        a: "gh-sound"
      }, {
        e: "The voiced counterpart of / kh / formed in the lower throat; no English equivalent; IPA ɣ"
      }, [{
        p: "غرمه",
        f: "gharmá",
        a: ""
      }, {
        p: "دغه",
        f: "dágha",
        a: ""
      }, {
        p: "باغ",
        f: "baagh",
        a: ""
      }]], [{
        f: "h",
        a: ""
      }, {
        p: "ح , هـ",
        a: ""
      }, {
        e: "Like English / h /"
      }, {
        p: "حالت",
        f: "haalát",
        a: ""
      }], [{
        f: "j",
        a: ""
      }, {
        p: "ج",
        a: ""
      }, {
        e: "Like English / j /"
      }, {
        p: "جادُو",
        f: "jaadóo",
        a: ""
      }], [{
        f: "jz",
        a: ""
      }, {
        p: "ژ",
        a: ""
      }, {
        e: "Often pronounced like an English / j /, but in some dialects similar to the middle consonant sound in the English words 'measure' and 'seizure'"
      }, {
        p: "ژوند",
        f: "jzwund",
        a: ""
      }], [{
        f: "ک",
        a: "k-sound"
      }, {
        p: "k",
        a: "k-sound"
      }, {
        e: "Like English / k /"
      }, {
        p: "کِتاب",
        f: "kitáab",
        a: ""
      }], [{
        f: "l",
        a: "l-sound"
      }, {
        p: "ل",
        a: "l-sound"
      }, {
        e: "Similar to an English / l / when at the beginning of a word, as in the word 'leave', but the tongue is higher in the mouth; also like a Spanish / l /"
      }, [{
        p: "لاس",
        f: "laas",
        a: ""
      }, {
        p: "لیکل",
        f: "leekul ",
        a: ""
      }]], [{
        f: "m",
        a: "m-sound"
      }, {
        p: "م",
        a: "m-sound"
      }, {
        e: "Like English / m /"
      }, {
        p: "مار",
        f: "maar",
        a: ""
      }], [{
        p: "n",
        a: "n-sound"
      }, {
        f: "ن",
        a: "n-sound"
      }, {
        e: "Like English / n / but with tongue on teeth"
      }, {
        p: "نیول",
        f: "neewúl",
        a: "neewul"
      }], [{
        f: "N",
        a: "nn-sound"
      }, {
        p: "ڼ",
        a: "nn-sound"
      }, {
        e: "The retroflex or hard / n /; tongue is flexed at the back of the hard palate and then brought down"
      }, [{
        p: "پاڼه",
        f: "paaNá",
        a: ""
      }, {
        p: "ترکاڼ",
        f: "tarkáaN",
        a: "tarkaann"
      }]], [{
        f: "p",
        a: ""
      }, {
        p: "پ",
        a: ""
      }, {
        e: "Similar to English / p / but less aspirated"
      }, {
        p: "پلار",
        f: "plaar",
        a: ""
      }], [{
        f: "q",
        a: "q-sound"
      }, {
        p: "ق",
        a: "q-sound"
      }, {
        e: "This sound is like the English / k / but much farther back in the throat. This sound is usually indistinguishable from / k /, though some literate people will make an effort at differentation"
      }, [{
        p: "قيصه",
        f: "qeesá",
        a: ""
      }, {
        p: "حقيقت",
        f: "haqeeqút",
        a: ""
      }, {
        p: "رفيق",
        f: "raféeq",
        a: ""
      }]], [{
        f: "r",
        a: "r-sound"
      }, {
        p: "ر",
        a: "r-sound"
      }, {
        e: "A trilled / r /; not a flat English / r /; similar to the rolling / r / used in Spanish"
      }, [{
        p: "رنګ",
        f: "rang",
        a: ""
      }, {
        p: "پرُون",
        f: "paróon",
        a: ""
      }, {
        p: "کور",
        f: "kor",
        a: ""
      }]], [{
        f: "R",
        a: "rr-sound"
      }, {
        p: "ړ",
        a: "rr-sound"
      }, {
        e: "The retroflex, or hard / r /; tongue is flexed at the back of the hard palate"
      }, [{
        p: "ړُوند",
        f: "Roond",
        a: ""
      }, {
        p: "سړے",
        f: "saRáy",
        a: ""
      }, {
        p: "جوړ",
        f: "joR",
        a: ""
      }]], [{
        f: "s",
        a: "s-sound"
      }, {
        p: "س ، ص ، ث",
        a: "s-sound"
      }, {
        e: "Like English / s /"
      }, {
        p: "سپک",
        f: "spuk",
        a: ""
      }], [{
        f: "sh",
        a: "sh-sound"
      }, {
        p: "ش",
        a: "sh-sound"
      }, {
        e: "Like the English consonant cluster / sh / in the word 'shoe' or 'sheep' but with more emphasis"
      }, [{
        p: "شپه",
        f: "shpa",
        a: ""
      }, {
        p: "پيشو",
        f: "peesho",
        a: ""
      }]], [{
        f: "t",
        a: "t-sound"
      }, {
        p: "ت",
        a: "t-sound"
      }, {
        e: "A dental or soft / t / with the tongue on the back of the upper teeth, not aspirated; like the Spanish dental / t /; no English equivalent"
      }, [{
        p: "تور",
        f: "tor",
        a: ""
      }, {
        p: "کِتاب",
        f: "kitáab",
        a: ""
      }, {
        p: "زيات",
        f: "zyaat",
        a: ""
      }]], [{
        f: "T",
        a: "tt-sound"
      }, {
        p: "ټ",
        a: "tt-sound"
      }, {
        e: "The retroflex or hard / t /. The tongue is flexed at the back of the hard palate; no English equivalent"
      }, [{
        p: "ټوپک",
        f: "Topúk",
        a: ""
      }, {
        p: "مالټه",
        f: "maalTá",
        a: ""
      }, {
        p: "پټ",
        f: "puT",
        a: ""
      }]], [{
        f: "ts",
        a: "ts-sound"
      }, {
        p: "څ",
        a: "ts-sound"
      }, {
        e: "Like the sound found in English words 'hats' and 'cats', but occurs in all three positions (beginning, middle and end of words); in some dialects it is reduced to a simple / s /"
      }, [{
        p: "څومره",
        f: "tsoomra",
        a: ""
      }, {
        p: "کوڅه",
        f: "kootsá",
        a: ""
      }, {
        p: "هېڅ",
        f: "hets",
        a: ""
      }]], [{
        f: "w",
        a: "w-sound-3"
      }, {
        p: "و",
        a: "w-sound-3"
      }, {
        e: "Between an English / v / and / w / (sounding more like 'victor'); in the initial position, similar to an English / w / though mouth is not rounded, in front of more closed vowels it has a move / v / like quality"
      }, [{
        p: "شوې",
        f: "shíwe",
        a: ""
      }, {
        p: "وږې",
        f: "wúGe",
        a: ""
      }, {
        p: "اووۀ",
        f: "oowú",
        a: ""
      }]], [{
        f: "kh , x",
        a: "kh-sound"
      }, {
        p: "خ , ښ",
        a: "kh-sound"
      }, {
        e: "Somewhat similar to the German or Scottish / ch / in words like 'bach' and 'loch'; or like the Spanish / j / in words like 'jefe' and 'ojo';"
      }, [{
        p: "خپل",
        f: "khpul",
        a: ""
      }, {
        p: "پښتو",
        f: "puxtó",
        a: ""
      }, {
        p: "اوښ",
        f: "oox",
        a: ""
      }]], [{
        f: "y",
        a: ""
      }, {
        p: "ی",
        a: ""
      }, {
        e: "Like English / y /"
      }, {
        p: "يار",
        f: "yaar",
        a: ""
      }], [{
        f: "z",
        a: ""
      }, {
        p: "ز , ځ , ذ , ظ , ض",
        a: ""
      }, {
        e: "Like English / z /"
      }, {
        p: "زُکام",
        f: "zUkáam",
        a: ""
      }]]}
    </PhoneticsTable>
    <h4 {...{
      "id": "vowels"
    }}>{`Vowels`}</h4>
    <h5 {...{
      "id": "short-vowels"
    }}>{`Short vowels`}</h5>
    <p>{`In Pashto script short vowels are not written in the middle of a word, but they are written with a ا at the beginning of a word and a ـه at the end of a word.`}</p>
    <PhoneticsTable mdxType="PhoneticsTable">
  {[[{
        f: "a",
        a: "a-sound"
      }, {
        p: "",
        a: "a-sound"
      }, {
        e: "A short / a /sound as in 'banana' (American pronunciation); not as long or open as / ā /; between the / a / of 'hat' and the / u / of 'but'; when stressed is closer to / a /, when unstressed is closer to / u /"
      }, [
      // add another word here
      {
        p: "هغه",
        f: "haghá",
        a: ""
      }, {
        p: "پوزه",
        f: "poza",
        a: ""
      }]], [{
        f: "i",
        a: ""
      }, {
        p: "",
        a: ""
      }, {
        e: "An / i / sound, as in the English words 'bit' and 'lit'"
      }, [{
        p: "اِسلام",
        f: "isláam",
        a: ""
      }, {
        p: "علم",
        f: "ílim",
        a: ""
      }]], [{
        f: "u",
        a: "u-sound"
      }, {
        e: "ۀ at end of words",
        a: "u-sound"
      }, {
        e: "The schwa sound, which is always unstressed in English, but commonly stressed in Pukhto; similar to the sound in English words 'another', and 'but'. When in final position it is similar to the sound in 'hook'"
      }, [{
        p: "وادهٔ",
        f: "waadú",
        e: "(stressed)",
        a: ""
      }, {
        p: "پښتو",
        f: "puxtó",
        e: "(unstressed)",
        a: ""
      }]], [{
        f: "U",
        a: ""
      }, {
        p: "",
        a: ""
      }, {
        e: "A shorter 'oo' sound"
      }, [{
        p: "مُمکن",
        f: "mUmkín",
        a: "muumkin-f"
      }, {
        p: "ګُلونه",
        f: "gUloona",
        a: "guuloona"
      }]]]}
    </PhoneticsTable>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <p>{`The short “a” can sometimes be very short, sounding almost like the “e” in “get” in English. In some of these cases, we will write the “a” as “ă” to emphasize the shortness. For example: `}<Word w={{
        p: "ور",
        f: "wăr",
        e: "",
        a: "war-dir"
      }} mdxType="Word" />, <Word w={{
        p: "سر",
        f: "săr",
        e: "",
        a: "sar"
      }} mdxType="Word" /></p>
    <h5 {...{
      "id": "long-vowels"
    }}>{`Long vowels`}</h5>
    <PhoneticsTable mdxType="PhoneticsTable">
  {[[{
        f: "ا",
        a: "aa-sound"
      }, {
        p: "aa",
        a: "aa-sound"
      }, {
        e: "A long / a / sound, similar to the vowel sound in English words 'father' and 'ahhhh' (open mouth)"
      }, [{
        p: "آسمان",
        f: "aasmáan",
        a: ""
      }, {
        p: "پاکِستان",
        f: "paakistáan",
        a: ""
      }, {
        p: "کاکا",
        f: "kaakaa",
        a: ""
      }, {
        p: "عام",
        f: "aam",
        a: ""
      }]], [{
        f: "ay",
        a: "ay-sound"
      }, {
        p: "ے",
        a: "ay-sound"
      }, {
        e: "As in the English words 'they' and 'day'; no diphthong; ے indicates masculine gender when at the end of words"
      }, [{
        p: "لرګے",
        f: "largáy",
        a: ""
      }, {
        p: "سړے",
        f: "saRáy",
        a: ""
      }]], [{
        f: "ee",
        a: "ee-sound"
      }, {
        p: "ی ، ي",
        a: "ee-sound"
      }, {
        e: "As in the English words 'beet' and 'meat'; no diphthong"
      }, [{
        p: "مینه",
        f: "méena",
        a: ""
      }, {
        p: "سړی",
        f: "saRée",
        a: ""
      }]], [{
        f: "e",
        a: "e-sound"
      }, {
        p: "ې",
        a: "e-sound"
      }, {
        e: "similar to the ee sound, but a little bit more open; no equivalent in English"
      }, [{
        p: "داسې",
        f: "daase",
        a: ""
      }, {
        p: "مېز",
        f: "mez",
        a: ""
      }, {
        p: "ښځې",
        f: "xúdze",
        a: ""
      }, {
        p: "کښېنه",
        f: "kéna",
        a: ""
      }]], [{
        f: "o",
        a: "o-sound"
      }, {
        p: "و",
        a: "o-sound"
      }, {
        e: "A pure / o / sound; no diphthong; similar to the vowel sound in the words 'boat', 'note' and 'bone'"
      }, [{
        p: "دوست",
        f: "dost",
        a: ""
      }, {
        p: "پيشو",
        f: "peeshó",
        a: ""
      }]], [{
        f: "oo",
        a: "oo-sound"
      }, {
        p: "و",
        a: "oo-sound"
      }, {
        e: "A pure / u / sound, no diphthong, as in 'food', 'boot' and 'rule'"
      }, [{
        p: "اُبۀ",
        f: "oobú",
        a: ""
      }, {
        p: "دُوکان",
        f: "dookáan",
        a: ""
      }, {
        p: "کدُو",
        f: "kadóo",
        a: ""
      }]]]}
    </PhoneticsTable>
    <h4 {...{
      "id": "notes-1"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The vowel chart is simply a guide to pronunciation. The letters listed for each vowel are only some of the possibilities, as vowel sounds in Pukhto are very fluid. In addition, vowels are not always written, but can be indicated by a diacritical mark (as in Arabic, Farsi, etc). For example, the most common vowel sound in Pukhto `}<Word w={{
            p: "",
            f: "u",
            e: "",
            a: "u-sound"
          }} mdxType="Word" />{` is usually not written as a separate letter, even though it exists as a distinct sound.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The three vowel sounds `}<Word w={{
            p: "",
            f: "aa",
            e: "",
            a: "aa-sound"
          }} mdxType="Word" />, <Word w={{
            p: "",
            f: "a",
            e: "",
            a: "a-sound"
          }} mdxType="Word" />, and <Word w={{
            p: "",
            f: "u",
            e: "",
            a: "u-sound"
          }} mdxType="Word" />, are quite fluid in Pashto, particularly between <Word w={{
            p: "",
            f: "aa",
            e: "",
            a: "aa-sound"
          }} mdxType="Word" /> and <Word w={{
            p: "",
            f: "a",
            e: "",
            a: "a-sound"
          }} mdxType="Word" /> and between <Word w={{
            p: "",
            f: "a",
            e: "",
            a: "a-sound"
          }} mdxType="Word" /> and <Word w={{
            p: "",
            f: "u",
            e: "",
            a: "u-sound"
          }} mdxType="Word" />. Regional differences in pronunciation mean that some speakers will use a clear <Word w={{
            p: "",
            f: "aa",
            e: "",
            a: "aa-sound"
          }} mdxType="Word" /> sound while a speaker from another area might pronounce the same letter with more of an <Word w={{
            p: "",
            f: "a",
            e: "",
            a: "a-sound"
          }} mdxType="Word" /> . Likewise, one speaker will use an <Word w={{
            p: "",
            f: "a",
            e: "",
            a: "a-sound"
          }} mdxType="Word" /> where another might use an <Word w={{
            p: "",
            f: "u",
            e: "",
            a: "u-sound"
          }} mdxType="Word" />{`. You will need to listen carefully to your tutor to catch these distinctions.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`An ́◌ accent is ádded on vówels to show what sýllable the áccent falls on.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`When it’s needed to show that to phonetics letters are seperate sounds and not a digraph, a backtick `}{`\``}{` is used. For instance `}<Word w={{
            p: "",
            f: "sh",
            e: "",
            a: "sh-sound"
          }} mdxType="Word" /> makes an <Word w={{
            p: "",
            f: "sh",
            e: "",
            a: "sh-sound"
          }} mdxType="Word" /> but <Word w={{
            p: "",
            f: "s\`h",
            e: "",
            a: ""
          }} mdxType="Word" /> is an <Word w={{
            p: "",
            f: "s",
            e: "",
            a: "s-sound"
          }} mdxType="Word" /> followed by a <Word w={{
            p: "",
            f: "h",
            e: "",
            a: ""
          }} mdxType="Word" />{` sound.`}</p>
      </li>
    </ol>
    <h4 {...{
      "id": "dipthongs"
    }}>{`Dipthongs`}</h4>
    <p>{`Diphthongs are made by gliding from one vowel sound to another vowel sound. Diphthongs are often found at the end of words in Pukhto.`}</p>
    <PhoneticsTable mdxType="PhoneticsTable">
  {[[{
        f: "aay",
        a: ""
      }, {
        p: "ائې ، ائ",
        a: ""
      }, {
        e: "This sound combines / ā / and / y / glide (dipthong); pronounced as the English word ‘lie’. In some dialects this sound is pronounced / e / or / ay /"
      }, [{
        p: "چائې",
        f: "chaay",
        a: ""
      }, {
        p: "ځای",
        f: "dzaay",
        a: ""
      }]], [{
        f: "uy",
        a: "uy-sound"
      }, {
        p: "ۍ",
        a: "uy-sound"
      }, {
        e: "This sound combines the schwa with a / y / glide (diphthong); there is no English equivalent; used only at the end of words and indicates feminine gender"
      }, [{
        p: "سپۍ",
        f: "spuy",
        a: ""
      }, {
        p: "نرۍ",
        f: "narúy",
        a: ""
      }]], [{
        f: "ey",
        a: "ey-sound"
      }, {
        p: "ئ",
        a: "ey-sound"
      }, {
        e: "Often very close to 'uy', but more like a 'ay' with a / y / glide; there is no English equivalent; used only at the end of words with 2nd person plural verbs"
      }, [{
        p: "کښېنئ",
        f: "kenéy",
        a: ""
      }, {
        p: "کوئ",
        f: "kawéy",
        a: ""
      }]], [{
        f: "ooy",
        a: ""
      }, {
        p: "وئ",
        a: ""
      }, {
        e: "A combination of 'oo' and a dipthong glide; there is no equivalent"
      }, {
        p: "لوئ",
        f: "looy",
        a: ""
      }]]}
    </PhoneticsTable>
    <h2 {...{
      "id": "sound-recognition"
    }}>{`Sound recognition`}</h2>
    <p>{`👆 The following exercises will help you differentiate between similar sounding letters. Have your helper read a word, and then you point to which of the two letters you think the word contains. The focus here is on the sounds, so ignore the meaning of the words altogether.`}</p>
    <h3 {...{
      "id": "focus-on-word-w-p-ر-f-r-e--a-r-sound---word-w-p-ړ-f-r-e--a-rr-sound--"
    }}>{`Focus on: `}<Word w={{
        p: "ر",
        f: "r",
        e: "",
        a: "r-sound"
      }} mdxType="Word" /> <Word w={{
        p: "ړ",
        f: "R",
        e: "",
        a: "rr-sound"
      }} mdxType="Word" /></h3>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "ورور",
        a: ""
      }, {
        p: "ورځ",
        a: ""
      }, {
        p: "کارُوبار",
        a: ""
      }, {
        p: "ړُوند",
        a: ""
      }, {
        p: "ماڼۍ",
        a: ""
      }, {
        p: "راشه",
        a: ""
      }, {
        p: "لاړ شه",
        a: ""
      }, {
        p: "روغ",
        a: ""
      }, {
        p: "ناجوړه",
        a: ""
      }, {
        p: "بيمار",
        a: ""
      }, {
        p: "تکړه",
        a: ""
      }, {
        p: "شرابى سترګې",
        a: ""
      }, {
        p: "مار",
        a: ""
      }, {
        p: "لړم",
        a: ""
      }, {
        p: "تيارۀ",
        a: ""
      }, {
        p: "رڼا",
        a: ""
      }, {
        p: "موړ",
        a: ""
      }, {
        p: "نهار",
        a: ""
      }, {
        p: "تور",
        a: ""
      }, {
        p: "زېړ",
        a: ""
      }, {
        p: "فکر",
        a: ""
      }, {
        p: "زاړهٔ",
        a: ""
      }, {
        p: "ښار",
        a: ""
      }, {
        p: "خوړل",
        a: ""
      }, {
        p: "خړخړ",
        a: ""
      }]}
    </Examples>
    <img src={manWithCane} style={{
      maxWidth: "6rem"
    }} />
    <h3 {...{
      "id": "focus-on-word-w-p-د-f-d-e--a-d-sound---word-w-p-ډ-f-d-e--a-dd-sound--"
    }}>{`Focus on `}<Word w={{
        p: "د",
        f: "d",
        e: "",
        a: "d-sound"
      }} mdxType="Word" /> <Word w={{
        p: "ډ",
        f: "D",
        e: "",
        a: "dd-sound"
      }} mdxType="Word" /></h3>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "درُوند",
        a: ""
      }, {
        p: "ډيوه",
        a: ""
      }, {
        p: "دوه",
        a: ""
      }, {
        p: "ډوډۍ",
        a: ""
      }, {
        p: "ډرامه",
        a: ""
      }, {
        p: "دوست",
        a: ""
      }, {
        p: "بنډل",
        a: ""
      }, {
        p: "بندول",
        a: ""
      }, {
        p: "ډېر",
        a: ""
      }, {
        p: "دشمن",
        a: ""
      }, {
        p: "لنډ",
        a: ""
      }, {
        p: "اُوږد",
        a: ""
      }, {
        p: "ډنډه",
        a: ""
      }, {
        p: "دنګه",
        a: ""
      }, {
        p: "انګلېنډ",
        a: ""
      }, {
        p: "ډز",
        a: ""
      }, {
        p: "ګنډل",
        a: ""
      }, {
        p: "ګډول",
        a: ""
      }, {
        p: "ګودام",
        a: ""
      }, {
        p: "دولس",
        a: ""
      }, {
        p: "ډُوب",
        a: ""
      }]}
    </Examples>
    <img src={deg} style={{
      maxWidth: "8rem"
    }} />
    <h3 {...{
      "id": "focus-on-word-w-p-ت-f-t-e--a-t-sound---word-w-p-ټ-f-t-e--a-tt-sound--"
    }}>{`Focus on `}<Word w={{
        p: "ت",
        f: "t",
        e: "",
        a: "t-sound"
      }} mdxType="Word" /> <Word w={{
        p: "ټ",
        f: "T",
        e: "",
        a: "tt-sound"
      }} mdxType="Word" /></h3>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "ټول",
        a: ""
      }, {
        p: "تول",
        a: ""
      }, {
        p: "ټېپر",
        a: ""
      }, {
        p: "توره",
        a: ""
      }, {
        p: "ټماټر",
        a: ""
      }, {
        p: "ترکارى",
        a: ""
      }, {
        p: "باتينګنړ",
        a: ""
      }, {
        p: "ټافى",
        a: ""
      }, {
        p: "غوټۍ",
        a: ""
      }, {
        p: "ناشپاتۍ",
        a: ""
      }, {
        p: "ټوپک",
        a: ""
      }, {
        p: "توره",
        a: ""
      }, {
        p: "ټېپ",
        a: ""
      }, {
        p: "فيته",
        a: ""
      }, {
        p: "ټاپ",
        a: ""
      }, {
        p: "ټاس",
        a: ""
      }, {
        p: "ترماس",
        a: ""
      }, {
        p: "اتۀ",
        a: ""
      }, {
        p: "آټ",
        a: ""
      }, {
        p: "تيکې",
        a: ""
      }, {
        p: "توپ",
        a: ""
      }, {
        p: "ټوټه",
        a: ""
      }, {
        p: "تُوت",
        a: ""
      }]}
    </Examples>
    <img src={manualRickshaw} style={{
      maxWidth: "10rem",
      marginBottom: "3rem"
    }} />
    <h3 {...{
      "id": "focus-on-word-w-p-ق-f-q-e--a-q-sound---word-w-p-ک-f-k-e--a---"
    }}>{`Focus on `}<Word w={{
        p: "ق",
        f: "q",
        e: "",
        a: "q-sound"
      }} mdxType="Word" /> <Word w={{
        p: "ک",
        f: "k",
        e: "",
        a: ""
      }} mdxType="Word" /></h3>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "قلم",
        a: ""
      }, {
        p: "قسم",
        a: ""
      }, {
        p: "قدم",
        a: ""
      }, {
        p: "قابِل",
        a: ""
      }, {
        p: "قاتِل",
        a: ""
      }, {
        p: "قاضى",
        a: ""
      }, {
        p: "قانُون",
        a: ""
      }, {
        p: "قُدرت",
        a: ""
      }, {
        p: "قبر",
        a: ""
      }, {
        p: "قافِله",
        a: ""
      }, {
        p: "کِتاب",
        a: ""
      }, {
        p: "کلام",
        a: ""
      }, {
        p: "کمال",
        a: ""
      }, {
        p: "کرم",
        a: ""
      }, {
        p: "کار",
        a: ""
      }, {
        p: "کابل",
        a: ""
      }, {
        p: "کُرسۍ",
        a: ""
      }, {
        p: "ليکل",
        a: ""
      }, {
        p: "مکار",
        a: ""
      }, {
        p: "سائيکل",
        a: ""
      }, {
        p: "ګِلکار",
        a: ""
      }, {
        p: "ترکاڼ",
        a: ""
      }, {
        p: "مقبره",
        a: ""
      }, {
        p: "مکمل",
        a: ""
      }, {
        p: "مُقابله",
        a: ""
      }, {
        p: "قِسمت",
        a: ""
      }, {
        p: "سکُول",
        a: ""
      }, {
        p: "دُوکان",
        a: ""
      }, {
        p: "امريکه",
        a: ""
      }, {
        p: "تُرکى",
        a: ""
      }]}
    </Examples>
    <img src={oneBook} style={{
      maxWidth: "10rem"
    }} />
    <h3 {...{
      "id": "focus-on-word-w-p-ا-آ-f-aa-e--a-aa-sound---word-w-p-ا-f-a-e--a-a-sound--"
    }}>{`Focus on `}<Word w={{
        p: "ا، آ",
        f: "aa",
        e: "",
        a: "aa-sound"
      }} mdxType="Word" /> <Word w={{
        p: "ا",
        f: "a",
        e: "",
        a: "a-sound"
      }} mdxType="Word" /></h3>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "کاهِله",
        a: ""
      }, {
        p: "بلا",
        a: ""
      }, {
        p: "نوکره",
        a: ""
      }, {
        p: "کمره",
        a: ""
      }, {
        p: "بنګله",
        a: ""
      }, {
        p: "لالا",
        a: ""
      }, {
        p: "فاصِله",
        a: ""
      }, {
        p: "وفاداره",
        a: ""
      }, {
        p: "خانسامه",
        a: ""
      }, {
        p: "ښځه",
        a: ""
      }, {
        p: "قابِله",
        a: ""
      }, {
        p: "هوښياره",
        a: ""
      }, {
        p: "نيکۀ",
        a: ""
      }, {
        p: "تابِعداره",
        a: ""
      }, {
        p: "حُسنداره",
        a: ""
      }, {
        p: "آسمانى",
        a: ""
      }, {
        p: "نارنجى",
        a: ""
      }, {
        p: "ماما",
        a: ""
      }, {
        p: "پاپا",
        a: ""
      }, {
        p: "بابا",
        a: ""
      }, {
        p: "ماريه",
        a: ""
      }, {
        p: "نائله",
        a: ""
      }, {
        p: "سونيا",
        a: ""
      }, {
        p: "سوميا",
        a: ""
      }, {
        p: "صبا",
        a: ""
      }]}
    </Examples>
    <img src={saw} style={{
      maxWidth: "10rem"
    }} />
    <h3 {...{
      "id": "focus-on-word-w-p-غ-f-gh-e--a-gh-sound---word-w-p-خ-f-kh-e--a-kh-sound--"
    }}>{`Focus on `}<Word w={{
        p: "غ",
        f: "gh",
        e: "",
        a: "gh-sound"
      }} mdxType="Word" /> <Word w={{
        p: "خ",
        f: "kh",
        e: "",
        a: "kh-sound"
      }} mdxType="Word" /></h3>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "غُصه",
        a: ""
      }, {
        p: "شُغل",
        a: ""
      }, {
        p: "مُغل",
        a: ""
      }, {
        p: "غُسل",
        a: ""
      }, {
        p: "باغ",
        a: ""
      }, {
        p: "داغ",
        a: ""
      }, {
        p: "غم",
        a: ""
      }, {
        p: "غاښ",
        a: ""
      }, {
        p: "غضب",
        a: ""
      }, {
        p: "مغز",
        a: ""
      }, {
        p: "ماښام",
        a: ""
      }, {
        p: "خِدمت",
        a: ""
      }, {
        p: "رُخصت",
        a: ""
      }, {
        p: "فارِغ",
        a: ""
      },
      // check pronoun
      {
        p: "غېبت",
        a: ""
      }, {
        p: "تاريخ",
        a: ""
      }, {
        p: "اغوستل",
        a: ""
      }, {
        p: "غوښه",
        a: ""
      }, {
        p: "خندل",
        a: ""
      }, {
        p: "خوږ",
        a: ""
      }, {
        p: "خوګ",
        a: ""
      }, {
        p: "تريخ",
        a: ""
      }, {
        p: "غوښتل",
        a: ""
      }, {
        p: "خارِج",
        a: ""
      }]}
    </Examples>
    <img src={camel} style={{
      maxWidth: "10rem"
    }} />
    <h3 {...{
      "id": "focus-on-word-w-p-ے-f-ay-e--a-ay-sound---word-w-p-ۍ-f-uy-e--a-uy-sound--"
    }}>{`Focus on `}<Word w={{
        p: "ے",
        f: "ay",
        e: "",
        a: "ay-sound"
      }} mdxType="Word" /> <Word w={{
        p: "ۍ",
        f: "uy",
        e: "",
        a: "uy-sound"
      }} mdxType="Word" /></h3>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "شپيلے",
        a: ""
      }, {
        p: "شپيلۍ",
        a: ""
      }, {
        p: "ژوندے",
        a: ""
      }, {
        p: "ژوندۍ",
        a: ""
      }, {
        p: "خاپيرے",
        a: ""
      }, {
        p: "خاپيرۍ",
        a: ""
      }, {
        p: "کابلے",
        a: ""
      }, {
        p: "کابلۍ",
        a: ""
      }, {
        p: "سپے",
        a: ""
      }, {
        p: "سپۍ",
        a: ""
      }, {
        p: "جينۍ",
        a: ""
      }, {
        p: "سړے",
        a: ""
      }, {
        p: "المارۍ",
        a: ""
      }, {
        p: "کړکۍ",
        a: ""
      }, {
        p: "لېونے",
        a: ""
      }, {
        p: "لېونۍ",
        a: ""
      }]}
    </Examples>
    <img src={ox} style={{
      maxWidth: "10rem"
    }} />
    <h2 {...{
      "id": "kay-to-abbreviations"
    }}>{`Kay to abbreviations`}</h2>
    <p>{`A number of abbreviations are used in this book to indicate gender and to classify words grammatically. A kay to these abbreviations is listed below:`}</p>

    <Abbreviations mdxType="Abbreviations">
  {[{
        abv: "1st pn.pl.",
        meaning: "first person plural"
      }, {
        abv: "1st pn.s.",
        meaning: "first person singular"
      }, {
        abv: "2nd pn.pl.",
        meaning: "second person plural"
      }, {
        abv: "2nd pn.s.",
        meaning: "second person singular"
      }, {
        abv: "3rd pn.pl.",
        meaning: "third person plural"
      }, {
        abv: "3rd pn.s.",
        meaning: "third person singular"
      }, {
        abv: "adj.",
        meaning: "adjective"
      }, {
        abv: "adv.",
        meaning: "adverb"
      }, {
        abv: "comp.",
        meaning: "compound"
      }, {
        abv: "conj.",
        meaning: "conjunction"
      }, {
        abv: "dem.",
        meaning: "demonstrative"
      }, {
        abv: "f.",
        meaning: "feminine"
      }, {
        abv: "fig.",
        meaning: "figuratively"
      }, {
        abv: "f.pl.",
        meaning: "feminine plural"
      }, {
        abv: "f.s.",
        meaning: "feminine singular"
      }, {
        abv: "ft.v.m.",
        meaning: "future verb marker"
      }, {
        abv: "inf.",
        meaning: "infinitive"
      }, {
        abv: "interject",
        meaning: "interjection"
      }, {
        abv: "lit.",
        meaning: "literally"
      }, {
        abv: "m.",
        meaning: "masculine"
      }, {
        abv: "m.pl.",
        meaning: "masculine plural"
      }, {
        abv: "m.s.",
        meaning: "masculine singular"
      }, {
        abv: "n.",
        meaning: "noun"
      }, {
        abv: "neg.",
        meaning: "negative"
      }, {
        abv: "obj.",
        meaning: "object"
      }, {
        abv: "ph.",
        meaning: "phrase"
      }, {
        abv: "pl.",
        meaning: "plural"
      }, {
        abv: "pa.p.",
        meaning: "past participle"
      }, {
        abv: "pn.",
        meaning: "person"
      }, {
        abv: "pos.",
        meaning: "possessive"
      }, {
        abv: "post.",
        meaning: "postposition"
      }, {
        abv: "prep.",
        meaning: "preposition"
      }, {
        abv: "pro.",
        meaning: "pronoun"
      }, {
        abv: "pr.t.",
        meaning: "present tense"
      }, {
        abv: "q.",
        meaning: "question"
      }, {
        abv: "s.",
        meaning: "singular"
      }, {
        abv: "v.",
        meaning: "verb"
      }, {
        abv: "v.i.",
        meaning: "intransitive verb"
      }, {
        abv: "v.t.",
        meaning: "transitive verb"
      }]}
    </Abbreviations>
    <p>{`In addition, a forward slash ( / ) is used to divide the singular and plural forms while a comma or a semicolon is used to separate different meanings. Alternative pronunciations or spellings are indicated by `}{`[ ]`}{`.`}</p>
    <img src={oxCart} />
    <h2 {...{
      "id": "the-pukhto-alphabet"
    }}>{`The Pukhto alphabet`}</h2>

    <Alphabet mdxType="Alphabet">
  {[{
        letter: "ا",
        starter: true,
        sound: <>
          <div>
            <strong>At beginning of word</strong>: aa, short vowel (a, i, u, U),
            or prefix before long vowel
          </div>
          <div>
            <strong>In middle or end of word</strong>: aa
          </div>
        </>,
        noJoinAfter: true
      }, {
        letter: "ب",
        sound: "b"
      }, {
        letter: "پ",
        sound: "p"
      }, {
        letter: "ت",
        sound: "t",
        a: "t-sound"
      }, {
        letter: "ټ",
        sound: "T",
        a: "tt-sound"
      }, {
        letter: "ث",
        sound: "s",
        a: "s-sound"
      }, {
        letter: "ج",
        sound: "j"
      }, {
        letter: "چ",
        sound: "ch"
      }, {
        letter: "ح",
        sound: "h"
      }, {
        letter: "خ",
        sound: "kh",
        a: "kh-sound"
      }, {
        letter: "څ",
        sound: "ts",
        a: "ts-sound"
      }, {
        letter: "ځ",
        sound: "z"
      }, {
        letter: "د",
        sound: "d",
        noJoinAfter: true,
        a: "d-sound"
      }, {
        letter: "ډ",
        sound: "D",
        noJoinAfter: true,
        a: "dd-sound"
      }, {
        letter: "ذ",
        sound: "z",
        noJoinAfter: true
      }, {
        letter: "ر",
        sound: "r",
        noJoinAfter: true,
        a: "r-sound"
      }, {
        letter: "ړ",
        sound: "R",
        noJoinAfter: true,
        a: "rr-sound"
      }, {
        letter: "ز",
        sound: "z",
        noJoinAfter: true
      }, {
        letter: "ژ",
        sound: "jz",
        noJoinAfter: true
      }, {
        letter: "ږ",
        sound: "G",
        noJoinAfter: true
      }, {
        letter: "س",
        sound: "s",
        a: "s-sound"
      }, {
        letter: "ش",
        sound: "sh",
        a: "sh-sound"
      }, {
        letter: "ښ",
        sound: "kh",
        a: "kh-sound"
      }, {
        letter: "ص",
        sound: "s",
        a: "s-sound"
      }, {
        letter: "ض",
        sound: "z"
      }, {
        letter: "ط",
        sound: "t",
        a: "t-sound"
      }, {
        letter: "ظ",
        sound: "z"
      }, {
        letter: "ع",
        sound: "silent or ' (Arabic sound - pharyngealization or glottal stop)"
      }, {
        letter: "ف",
        sound: "f"
      }, {
        letter: "ق",
        sound: "q",
        a: "q-sound"
      }, {
        letter: "ک",
        sound: "k",
        a: "k-sound"
      }, {
        letter: "ګ",
        sound: "g"
      }, {
        letter: "ل",
        sound: "l",
        a: "l-sound"
      }, {
        letter: "م",
        sound: "m",
        a: "m-sound"
      }, {
        letter: "ن",
        sound: "n",
        a: "n-sound"
      }, {
        letter: "ڼ",
        sound: "N",
        a: "nn-sound"
      }, {
        letter: "و",
        sound: <>
          <div>
            <strong>as a consonant:</strong> w
          </div>
          <div>
            <strong>as a vowel:</strong> o, oo
          </div>
        </>,
        noJoinAfter: true
      }, {
        letter: "ه",
        sound: <>
          <div>
            <strong>as a consonant:</strong> h
          </div>
          <div>
            <strong>as a vowel on the end of a word:</strong> short vowel (a, i,
            u, U)
          </div>
        </>
      }, {
        letter: "ی",
        sound: <>
          <div>
            <strong>as a consonant</strong> y
          </div>
          <div>
            <strong>as a vowel</strong> ee
          </div>
        </>
      }, {
        letter: "ې",
        sound: "e",
        prefixedBeginning: true,
        a: "e-sound"
      }, {
        letter: "ے",
        sound: "ay",
        prefixedBeginning: true,
        a: "ay-sound"
      }, {
        letter: "ۍ",
        sound: "uy (only appears on end of feminine words)",
        noJoinAfter: true,
        a: "uy-sound"
      }, {
        letter: "ئ",
        sound: "ey, y for glides / dipthongs"
      }]}
    </Alphabet>
    <h3 {...{
      "id": "diacritics"
    }}>{`Diacritics`}</h3>
    <p>{`Diacritics are sometimes used to show which vowels or sounds are used when it would otherwise be ambiguous or unwritten.`}</p>
    <Table unset bordered titleRow={["Diacritic", "Sound"]} mdxType="Table">
  {[[{
        p: "◌َ"
      }, {
        e: "a"
      }], [{
        p: "◌ِ"
      }, {
        e: "i, ee"
      }], [{
        p: "◌ُ"
      }, {
        e: "U, oo"
      }], [{
        p: "◌ّ"
      }, {
        e: "double consonant"
      }]]}
    </Table>
    <p>{`Words ending in a `}<Word w={{
        p: "",
        f: "u",
        e: "shwa",
        a: "u-sound"
      }} mdxType="Word" /> sound will often be written with a <Word w={{
        p: "ـهٔ",
        f: "",
        e: "",
        a: ""
      }} mdxType="Word" /> to show that the ـه on the end really is a <Word w={{
        p: "",
        f: "u",
        e: "",
        a: "u-sound"
      }} mdxType="Word" /> and not an <Word w={{
        p: "",
        f: "a",
        e: "",
        a: "a-sound"
      }} mdxType="Word" />{`.`}</p>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The letters ے and ی both appear as ـیـ in the middle of a word.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In Pashto script the `}<strong parentName="p">{`short vowels (a, i, u, U) are not written in the middle of words`}</strong>{`. They are written a `}<Word w={{
            p: "ا",
            f: "",
            e: "",
            a: ""
          }} mdxType="Word" /> at the beginning of a word and a <Word w={{
            p: "ـه",
            f: "",
            e: "",
            a: ""
          }} mdxType="Word" />{` at the end of a word. Diacritics can be optianally used to show which short vowel is being used.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`When a word starts with one of the long vowels (`}<Word w={{
            p: "ی",
            f: "ee",
            e: "",
            a: "ee-sound"
          }} mdxType="Word" />, <Word w={{
            p: "ې",
            f: "e",
            e: "",
            a: "e-sound"
          }} mdxType="Word" />, <Word w={{
            p: "و",
            f: "o",
            e: "",
            a: "o-sound"
          }} mdxType="Word" />, or <Word w={{
            p: "و",
            f: "oo",
            e: "",
            a: "oo-sound"
          }} mdxType="Word" />), it is prefixed with a silent <Word w={{
            p: "ا",
            f: "",
            e: "",
            a: ""
          }} mdxType="Word" />{` in front. For example:`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <Word w={{
              p: "ایسارېدل",
              f: "eesaaredul",
              e: "",
              a: "eesaaredul-m"
            }} mdxType="Word" />
          </li>
          <li parentName="ul">
            <Word w={{
              p: "اوسېدل",
              f: "osedúl",
              e: "",
              a: "osedul-m"
            }} mdxType="Word" />
          </li>
          <li parentName="ul">
            <Word w={{
              p: "اوږه",
              f: "ooGa",
              e: "",
              a: "ooga-m"
            }} mdxType="Word" />
          </li>
        </ul>
      </li>
    </ol>
    <p>{`The exception to this is when `}<Word w={{
        p: "و",
        f: "oo",
        e: "",
        a: "oo-sound"
      }} mdxType="Word" />{` is a prefix at the beginning of a verb.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`There is another very common standard found in most dictionaries and many printed materials which uses some letters differently:`}</li>
    </ol>
    <Table bordered titleRow={["Sound", "Yousufzai - SPP", "Afg. Standard"]} mdxType="Table">
  {[[{
        e: "ay",
        a: "ay-sound"
      }, {
        p: "ے",
        a: "ay-sound"
      }, {
        p: "ی",
        a: "ay-sound"
      }], [{
        e: "ee",
        a: "ee-sound"
      }, {
        p: "ی",
        a: "ee-sound"
      }, {
        p: "ي",
        a: "ee-sound"
      }]]}
    </Table>{" "}
    <img src={domes} />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "how-to-use-this-book",
    level: 2,
    title: "How to use this book",
    children: [
        
      ]
  },
{
    id: "the-sounds-of-pukhto",
    level: 2,
    title: "The sounds of Pukhto",
    children: [
        {
              id: "a-summary-of-the-sounds-of-yusufzai-pukhto",
              level: 3,
              title: "A summary of the sounds of Yusufzai Pukhto",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "sound-recognition",
    level: 2,
    title: "Sound recognition",
    children: [
        {
              id: "focus-on-word-w-p-ر-f-r-e--a-r-sound---word-w-p-ړ-f-r-e--a-rr-sound--",
              level: 3,
              title: <React.Fragment>{`Focus on: `}<Word w={{ p: "ر", f: "r", e: "", a: "r-sound" }} /> <Word w={{ p: "ړ", f: "R", e: "", a: "rr-sound" }} /></React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "focus-on-word-w-p-د-f-d-e--a-d-sound---word-w-p-ډ-f-d-e--a-dd-sound--",
              level: 3,
              title: <React.Fragment>{`Focus on `}<Word w={{ p: "د", f: "d", e: "", a: "d-sound" }} /> <Word w={{ p: "ډ", f: "D", e: "", a: "dd-sound" }} /></React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "focus-on-word-w-p-ت-f-t-e--a-t-sound---word-w-p-ټ-f-t-e--a-tt-sound--",
              level: 3,
              title: <React.Fragment>{`Focus on `}<Word w={{ p: "ت", f: "t", e: "", a: "t-sound" }} /> <Word w={{ p: "ټ", f: "T", e: "", a: "tt-sound" }} /></React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "focus-on-word-w-p-ق-f-q-e--a-q-sound---word-w-p-ک-f-k-e--a---",
              level: 3,
              title: <React.Fragment>{`Focus on `}<Word w={{ p: "ق", f: "q", e: "", a: "q-sound" }} /> <Word w={{ p: "ک", f: "k", e: "", a: "" }} /></React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "focus-on-word-w-p-ا-آ-f-aa-e--a-aa-sound---word-w-p-ا-f-a-e--a-a-sound--",
              level: 3,
              title: <React.Fragment>{`Focus on `}<Word w={{ p: "ا، آ", f: "aa", e: "", a: "aa-sound" }} /> <Word w={{ p: "ا", f: "a", e: "", a: "a-sound" }} /></React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "focus-on-word-w-p-غ-f-gh-e--a-gh-sound---word-w-p-خ-f-kh-e--a-kh-sound--",
              level: 3,
              title: <React.Fragment>{`Focus on `}<Word w={{ p: "غ", f: "gh", e: "", a: "gh-sound" }} /> <Word w={{ p: "خ", f: "kh", e: "", a: "kh-sound" }} /></React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "focus-on-word-w-p-ے-f-ay-e--a-ay-sound---word-w-p-ۍ-f-uy-e--a-uy-sound--",
              level: 3,
              title: <React.Fragment>{`Focus on `}<Word w={{ p: "ے", f: "ay", e: "", a: "ay-sound" }} /> <Word w={{ p: "ۍ", f: "uy", e: "", a: "uy-sound" }} /></React.Fragment>,
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "kay-to-abbreviations",
    level: 2,
    title: "Kay to abbreviations",
    children: [
        
      ]
  },
{
    id: "the-pukhto-alphabet",
    level: 2,
    title: "The Pukhto alphabet",
    children: [
        {
              id: "diacritics",
              level: 3,
              title: "Diacritics",
              children: [
                      
                    ]
            }
      ]
  }
]

export const frontMatter = {
  "title": "Introduction"
}

