
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"10 min read","minutes":9.03,"time":541800,"words":1806}
/* @jsxRuntime classic */
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import cart from "../assets/images/cart.PNG";
import rahmanBook from "../assets/images/rahman-book.PNG";
import childrenSquatting from "../assets/images/children-squatting.PNG";
import memorizingMoments from "./memorizing-moments";
import examples from "./lesson-two-examples.js";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Dialogue audio="dialogue-2" mdxType="Dialogue">
  {[{
        s: "L",
        p: "په خیر راغلې",
        f: "pu khayr raaghle!",
        e: "Welcome! (Lit. You came in peace!)",
        a: "pu-khay-raaghle"
      }, {
        s: "H",
        p: "خُدائ دې وبخښه",
        f: "xUdáay de oobakhxa",
        e: "May you live in peace!",
        a: "khuudaay-de-oob"
      }, {
        s: "L",
        p: "څنګه يې؟ صحت دې ښهٔ دے؟",
        f: "tsínga ye? sihút de xu day?",
        e: "How are you? Is your health good?",
        a: "tsunga-ye-sihhat-d-kh"
      }, {
        s: "H",
        p: "ښهٔ یم. ته څنګه یې؟",
        f: "xu yim, tu tsínga ye?",
        e: "I'm fine. How are you?",
        a: "xu-yim-tu-ts"
      }, {
        s: "L",
        p: ["د خُدائ فضل دے، شهٔ یم.", "زما نُوم جان دے.", "ستا نُوم څهٔ دے؟"],
        f: ["du khUdáay fúzul day, xu yim.", "zmáa noom jaan day.", "staa noom tsu day?"],
        e: ["By the grace of God, I'm well.", "My name is John.", "What is your name?"],
        a: "du-khuudaay-f-zmaa-noom-j"
      }, {
        s: "H",
        p: "زما نُوم امداد دے.",
        f: "zmaa noom imdáad day.",
        e: "My name is Imdad.",
        a: "zmaa-noom-imd"
      }, {
        s: "L",
        p: ["تهٔ د کُوم ځائ يې؟ (یا)", "تهٔ د کُوم ځائ نه راغلے یې؟"],
        f: ["tu du kum dzaay ye? (or)", "tu du kum dzaay na raaghulay ye?"],
        e: "Where are you from?",
        a: "tu-du-kum-dzaay-ye-ya-d"
      }, {
        s: "H",
        p: ["زهٔ د مردان یم. (یا)", "زهٔ د مردان نه راغلے یم."],
        f: ["zu du mardáan yim. (or)", "zu du mardáan na raaghulay yim."],
        e: "I'm from Mardan.",
        a: "zu-du-mardaan"
      }, {
        s: "L",
        p: "زهٔ د لندن یم.",
        f: "zu du landún yim.",
        e: "I'm from London.",
        a: "zu-du-landan-yim"
      }]}
    </Dialogue>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`Names are not usually exchanged until the initial conversation is almost finished.`}</p>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation Drill`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Contrasting `}<Word w={{
            p: "ټ",
            f: "T",
            a: "tt-sound"
          }} mdxType="Word" /> with <Word w={{
            p: "ډ",
            f: "D",
            a: "dd-sound"
          }} mdxType="Word" /></p>
        <Examples hideEnglish mdxType="Examples">
  {[{
            p: "زهٔ ټال لرم.",
            f: "zu Taal larúm",
            e: "I have a swing",
            a: "zu-ttaal-larum"
          }, {
            p: "زهٔ ډال لرم.",
            f: "zu Daal larúm",
            e: "I have a shield",
            a: "zu-ddaal-larum"
          }, {
            p: "ټول شته.",
            f: "Tol shta.",
            e: "All are here.",
            a: "ttol-shta"
          }, {
            p: "ډول شته.",
            f: "Dol shta.",
            e: "There is a drum.",
            a: "ddol-shta"
          }, {
            p: "هلته ټک شو.",
            f: "hálta Tak sho.",
            e: "There was a knock there.",
            a: "halta-ttak-sho"
          }, {
            p: "دا ډک شو",
            f: "daa Duk sho.",
            e: "That get full.",
            a: "daa-ddak-sho"
          }, {
            p: "دا تال دے.",
            f: "daa taal day.",
            e: "This is a copper basin.",
            a: "daa-taal-day"
          }, {
            p: "دا ټال دے.",
            f: "daa Taal day.",
            e: "This is a swing.",
            a: "daa-ttaal-day"
          }]}
        </Examples>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Contrasting `}<Word w={{
            p: "ا",
            f: "aa",
            a: "aa-sound"
          }} mdxType="Word" /> with <Word w={{
            p: "a",
            a: "a-sound"
          }} mdxType="Word" />{` (may not have a character in script form)`}</p>
        <Examples hideEnglish mdxType="Examples">
  {[{
            p: "دا ښار دے.",
            f: "daa xaar day.",
            e: "This is a city.",
            a: "daa-xaar-day"
          }, {
            p: "دا خر دے.",
            f: "daa khar day.",
            e: "This is a donkey.",
            a: "daa-khar-day"
          }, {
            p: "هغه غار دے.",
            f: "hagha ghaar day.",
            e: "That's a cave.",
            a: "hagha-ghaar-day"
          }, {
            p: "هغه غر دے.",
            f: "hagha ghar day.",
            e: "That's a mountain.",
            a: "hagha-ghar-day"
          }]}
        </Examples>
      </li>
    </ol>
    <img src={childrenSquatting} />
    <h2 {...{
      "id": "demonstrative-prouns-this-and-that"
    }}>{`Demonstrative Prouns: “this” and “that”`}</h2>
    <Examples mdxType="Examples">
  {[{
        p: "دا",
        f: "daa",
        e: "this",
        a: "daa-demons"
      }, {
        p: "دغه",
        f: "dágha",
        e: "that (nearby objects)",
        a: "dagha-demons"
      }, {
        p: "هغه",
        f: "hágha",
        e: "that (distant objects)",
        a: "hagha-demons"
      }]}
    </Examples>
    <h3 {...{
      "id": "this-and-that-with-masculine-nouns-and-adjectives"
    }}>{`“this” and “that” with masculine nouns and adjectives`}</h3>
    <Table bordered wide titleRow={["Pronoun", "Adjective", "Noun", "Verb"]} mdxType="Table">
  {[[{
        f: "daa"
      }, {
        f: "lunD"
      }, {
        f: "pinsúl"
      }, {
        f: "day"
      }, {
        span: 2,
        p: "دا لنډ پِنسل دے.",
        a: "daa-lundd-pinsul-day",
        e: "This is a short pencil."
      }], [{
        f: "daa"
      }, {
        f: "óoGud"
      }, {
        f: "qalúm"
      }, {
        f: "day"
      }, {
        span: 2,
        p: "دا اُوږد قلم دے.",
        a: "daa-oogud-qalum-day",
        e: "This is a long pen."
      }], [{
        f: "dágha"
      }, {
        f: "droond"
      }, {
        f: "mez"
      }, {
        f: "day"
      }, {
        span: 2,
        p: "دغه درُوند مېز دے.",
        a: "daa-droond-mez-day",
        e: "That is a heavy table."
      }], [{
        f: "dágha"
      }, {
        f: "spuk"
      }, {
        f: "kitáab"
      }, {
        f: "day"
      }, {
        span: 2,
        p: "دغه سپک کتاب دے.",
        a: "da-spuk-kitaab-day",
        e: "This is a light book."
      }], [{
        f: "hágha"
      }, {
        f: "tor"
      }, {
        f: "war"
      }, {
        f: "day"
      }, {
        span: 2,
        p: "هغه تور ور دے.",
        a: "hagha-tor-war-day",
        e: "This is a black door"
      }], [{
        f: "hágha"
      }, {
        f: "speen"
      }, {
        f: "kaghúz"
      }, {
        f: "day"
      }, {
        span: 2,
        p: "هغه سپین کاغذ دے.",
        a: "hagha-speen-kaaghuz-day",
        e: "This is a white paper"
      }]]}
    </Table>
    <Table bordered wide titleRow={["Pronoun", "Noun", "Adjective", "Verb"]} mdxType="Table">
  {[[{
        f: "daa"
      }, {
        f: "halúk"
      }, {
        f: "xu"
      }, {
        f: "day"
      }, {
        span: 2,
        p: "دا هلک ښهٔ دے.",
        a: "daa-haluk-xu-day",
        e: "This boy is good."
      }], [{
        f: "daa"
      }, {
        f: "kitáab"
      }, {
        f: "kharáab"
      }, {
        f: "day"
      }, {
        span: 2,
        p: "دا کتاب خراب دے.",
        a: "daa-kitaab-kharab-day",
        e: "This book is bad."
      }], [{
        f: "dágha"
      }, {
        f: "balákht"
      }, {
        f: "sheen"
      }, {
        f: "day"
      }, {
        span: 2,
        p: "دغه بالخت شین دے.",
        a: "daa-balakht-sheen-day",
        e: "This pillow is green."
      }], [{
        f: "hágha"
      }, {
        f: "spay"
      }, {
        f: "ghaT"
      }, {
        f: "day"
      }, {
        span: 2,
        p: "هغه سپے غټ دے.",
        a: "hagha-spay-ghatt-day",
        e: "That male dog is big."
      }], [{
        f: "hágha"
      }, {
        f: "saRáy"
      }, {
        f: "waRóokay"
      }, {
        f: "day"
      }, {
        span: 2,
        p: "هغه سړے وړوکے دے.",
        a: "hagha-sarray-warookay-day",
        e: "That man is small."
      }]]}
    </Table>
    <p>{`👆 Have the teacher point to objects that are close and far away, substituting nouns already introduced. Don’t introduce new vocabulary. Use the question `}<Word w={{
        p: "دا څه شے دے؟",
        f: "daa tsu shay day?",
        a: "daa-tsu-shay-day",
        e: "What is this?"
      }} mdxType="Word" />{` to elicit new vocabulary.`}</p>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <img src={rahmanBook} style={{
      float: "right",
      maxWidth: "35%"
    }} />
    <ol>
      <li parentName="ol">{`Demonstrative pronouns do not change in form for plural nouns.`}</li>
      <li parentName="ol">
        <Word w={{
          p: "دا",
          f: "daa",
          a: "daa-demons"
        }} mdxType="Word" /> is used for an object in the
hand, or within easy reach.
      </li>
      <li parentName="ol">
        <Word w={{
          p: "شین",
          f: "sheen",
          a: "sheen"
        }} mdxType="Word" /> can mean either 'green' or 'blue'.
      </li>
    </ol>
    <h3 {...{
      "id": "subject-pronouns-with-masculine-adjectives"
    }}>{`Subject pronouns with masculine adjectives`}</h3>
    <Table bordered titleRow={["Pronoun", "Adjective", "Verb"]} mdxType="Table">
  {[[{
        f: "zu"
      }, {
        f: "stúRay"
      }, {
        f: "yim."
      }, {
        span: 2,
        p: "زهٔ ستړے یم.",
        e: "I am tired.",
        a: "zu-sturray-yim"
      }], [{
        f: "hágha"
      }, {
        f: "xhÚlay"
      }, {
        f: "day."
      }, {
        span: 2,
        p: "هغه ښکُلے دے.",
        e: "He is handsome",
        a: "hagha-xkuulay-day"
      }], [{
        f: "tu"
      }, {
        f: "joR"
      }, {
        f: "ye?"
      }, {
        span: 2,
        p: "تهٔ جوړ یې؟",
        f: "Are you well?",
        a: "tu-jorr-ye"
      }], [{
        f: "hágha"
      }, {
        f: "ghaT"
      }, {
        f: "day."
      }, {
        span: 2,
        p: "هغه عټ دے.",
        e: "That/he is big.",
        a: "hagha-ghatt-day"
      }], [{
        f: "hágha"
      }, {
        f: "naráy"
      }, {
        f: "day."
      }, {
        span: 2,
        p: "هغه نرے دے.",
        e: "He is thin.",
        a: "day-naray-day"
      }], [{
        f: "daa"
      }, {
        f: "droond"
      }, {
        f: "day."
      }, {
        span: 2,
        p: "دا دروند دے.",
        e: "This is heavy.",
        a: "daa-droond-day"
      }]]}
    </Table>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p>{`When two consecutive adjectives are used, `}<Word w={{
        p: "او",
        f: "aaw",
        e: "and",
        a: "aaw"
      }} mdxType="Word" />{` is inserted between the two adjectives. For example:`}</p>
    <Examples mdxType="Examples">{examples.slice(0, 2)}</Examples>
    <h2 {...{
      "id": "questions-and-answers"
    }}>{`Questions and Answers`}</h2>
    <Examples mdxType="Examples">{examples.slice(2, 12)}</Examples>
    <h2 {...{
      "id": "questions-and-answers-with-what-and-how"
    }}>{`Questions and answers with: “what?” and “how?”`}</h2>
    <Examples mdxType="Examples">{examples.slice(12, 20)}</Examples>
    <h2 {...{
      "id": "memorizing-moment"
    }}>{`Memorizing Moment`}</h2>
    <h4 {...{
      "id": "word-w-p-متلونه-f-matulóona-a-matuloona--"
    }}><Word w={{
        p: "متلونه",
        f: "matulóona",
        a: "matuloona"
      }} mdxType="Word" /></h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[2]}</Examples>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>{`Be careful to listen to the pronunciation of place names like Kashmir. Many are quite different from the English way of saying them.`}</p>
    <p>{`👆 Find out and use the Pukhto pronunciations of Peshawar, Nowshera, Swat and Pakistan, etc.`}</p>
    <h2 {...{
      "id": "exercises-for-lesson-two"
    }}>{`Exercises for Lesson Two`}</h2>
    <ol>
      <li parentName="ol">{`Use the correct form of the equative in the following sentences.`}</li>
    </ol>
    <Quiz mdxType="Quiz">
  {[{
        beg: {
          p: "دغه مېز درُوند",
          f: "dágha mez droond"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "دے",
          f: "day"
        }]
      }, {
        beg: {
          p: "هغه موټر غټ",
          f: "hágha moTur ghaT"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "دے",
          f: "day"
        }]
      }, {
        beg: {
          p: "تۀ ډېر ستړے",
          f: "tu Der stúRay"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "یې",
          f: "ye"
        }]
      }, {
        beg: {
          p: "دا قلم تور نۀ",
          f: "daa qalúm tor nu"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "دے",
          f: "day"
        }]
      }, {
        beg: {
          p: "زۀ ډېر تږے نۀ",
          f: "zu Der túGay nu"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "یم",
          f: "yum"
        }, {
          p: "یم",
          f: "yim"
        }, {
          p: "یم",
          f: "yam"
        }]
      }, {
        beg: {
          p: "ستا نُوم څهٔ",
          f: "staa noom tsu"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "دے",
          f: "day"
        }]
      }]}
    </Quiz>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Use the correct pronoun to complete the following sentences:`}</li>
    </ol>
    <Quiz mdxType="Quiz">
  {[{
        end: {
          p: "وږے نۀ يم.",
          f: "wúGay nu yim."
        },
        answer: [{
          p: "زهٔ",
          f: "zu"
        }]
      }, {
        end: {
          p: "ښۀ سړے يې.",
          f: "xu saRáy ye."
        },
        answer: [{
          p: "تهٔ",
          f: "tu"
        }]
      }, {
        end: {
          p: "تور کتاب نۀ دے.",
          f: "tor kitáab nu day."
        },
        answer: [{
          p: "دا",
          f: "daa"
        }, {
          p: "هغه",
          f: "hágha"
        }, {
          p: "دغه",
          f: "dágha"
        }]
      }, {
        end: {
          p: "ډېر ستړے يم.",
          f: "Der stúRay yim."
        },
        answer: [{
          p: "زهٔ",
          f: "zu"
        }]
      }, {
        end: {
          p: "مېز اُوږد دے.",
          f: "mez óoGud day."
        },
        answer: [{
          p: "دا",
          f: "daa"
        }, {
          p: "هغه",
          f: "hágha"
        }, {
          p: "دغه",
          f: "dágha"
        }]
      }, {
        end: {
          p: "څنګه يې؟",
          f: "tsunga ye?"
        },
        answer: [{
          p: "تهٔ",
          f: "tu"
        }]
      }]}
    </Quiz>
    <img src={cart} />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation Drill",
    children: [
        
      ]
  },
{
    id: "demonstrative-prouns-this-and-that",
    level: 2,
    title: "Demonstrative Prouns: “this” and “that”",
    children: [
        {
              id: "this-and-that-with-masculine-nouns-and-adjectives",
              level: 3,
              title: "“this” and “that” with masculine nouns and adjectives",
              children: [
                      
                    ]
            },
      {
              id: "subject-pronouns-with-masculine-adjectives",
              level: 3,
              title: "Subject pronouns with masculine adjectives",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "questions-and-answers",
    level: 2,
    title: "Questions and Answers",
    children: [
        
      ]
  },
{
    id: "questions-and-answers-with-what-and-how",
    level: 2,
    title: "Questions and answers with: “what?” and “how?”",
    children: [
        
      ]
  },
{
    id: "memorizing-moment",
    level: 2,
    title: "Memorizing Moment",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-two",
    level: 2,
    title: "Exercises for Lesson Two",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Two"
}

