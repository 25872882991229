
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"28 min read","minutes":27.92,"time":1675200,"words":5584}
/* @jsxRuntime classic */
/* @jsx mdx */
import Media from "react-media";
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import cow from "../assets/images/cow.png";
import oneTree from "../assets/images/one-tree.png";
import twoTrees from "../assets/images/two-trees.png";
import oneBook from "../assets/images/one-book.png";
import twoBooks from "../assets/images/two-books.png";
import manWithCane from "../assets/images/man-with-cane.png";
import coveredWoman from "../assets/images/covered-woman.PNG";
import oldWomanThinking from "../assets/images/old-woman-thinking.png";
import womanWalking from "../assets/images/woman-walking.png";
import kettle from "../assets/images/kettle.png";
import oxen from "../assets/images/oxen.png";
import bodyPartsMan from "../assets/images/body-parts-man.png";
import memorizingMoment from "./memorizing-moments";
import examples from "./lesson-six-examples.js";
import PersistentBlank from "../components/persistent-blank";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-six-a-guest-at-your-door"
    }}>{`Dialogue six: A guest at your door`}</h2>
    <Dialogue audio="6-dialogue" mdxType="Dialogue">
  {[{
        s: "L",
        p: "په خیر راغلې.",
        f: "pu xayr ráaghle.",
        e: "Welcome.",
        a: "6-d1"
      }, {
        s: "L",
        p: "هر کله راشه.",
        f: "hăr kala ráasha.",
        e: "Always welcome.",
        a: "6-d2"
      }, {
        s: "L",
        p: "کښېنه! کښېنه!",
        f: "kéna! kéna!",
        e: "Sit! Sit!",
        a: "6-d3"
      }, {
        s: "L",
        p: "چائې خو به څښُو، خو اول راته ووایه چې ډوډۍ دې خوړلې ده، که نه؟",
        f: "chaay kho ba skoo, kho awál raa-ta oowaaya che DoDuy de khoRule da, ka na?",
        e: "We will drink tea, but first tell me if you have eated food, or not?",
        a: "6-d4"
      }, {
        s: "H",
        p: ["نهٔ مې ده خوړلې.", "کهٔ تا ته تکلیف نهٔ وی نو ټیک ده."],
        f: ["nu me da khoRule.", "ku taa ta tukléef nu wee, no Teek da."],
        e: ["I haven't eaten.", "If it's no trouble to you, then fine."],
        a: "6-d5"
      }, {
        s: "L",
        p: "چې څۀ تيار وى هغه د يار وى.",
        f: "che tsu tayaar wee haghá du yaar wee.",
        e: "Whatever is ready is for a friend.",
        a: "6-d6"
      }, {
        s: "H",
        p: "بس ټیک ده.",
        f: "bas Teek da.",
        e: "That's OK.",
        a: "6-d7"
      }, {
        s: "L",
        p: "تهٔ ښهٔ یې؟",
        f: "tu xu ye?",
        e: "Are you fine?",
        a: "6-d8"
      }, {
        s: "H",
        p: "آو، ښه یم.",
        f: "aaw, xu yim.",
        e: "Yes, I'm fine.",
        a: "6-d9"
      }, {
        s: "L",
        p: ["کور کښې خیر دے، بال بچ دې ښۀ دے؟", "خیر خو دے کنه، څنګه راغلې؟"],
        f: ["kor ke khay day, baal buch de xu day?", "khay kho day ka na, tsunga raaghle?"],
        e: ["Is everything OK at your house? Are the children OK?", "Is everything fine? Why did you come?"],
        a: "6-d10"
      }, {
        s: "H",
        p: "زما لږ کار دے نو زما خيال دے چې تا سره به شپه وکم.",
        f: "zmaa lug kaar day no zmaa khayáal day che taa sara ba shpa oókum.",
        e: "I have a little work and I think I will spend the night with you.",
        a: "6-d11"
      }, {
        s: "L",
        p: "ډېر دې ښۀ وکه.",
        f: "Der de xu ooka.",
        e: "You did very well.",
        a: "6-d12"
      }]}
    </Dialogue>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`Unstinting hospitality is a highly cherished virtue in Pukhtun society.`}</p>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation drill`}</h2>
    <ol>
      <li parentName="ol">{`Contrasting `}<Word w={{
          p: "غ",
          f: "gh",
          a: "gh-sound"
        }} mdxType="Word" /> and <Word w={{
          p: "خ",
          f: "kh",
          a: "kh-sound"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "غدار مې ولیدو.",
        f: "ghadáar me óoleedo.",
        e: "I saw a deceitful man.",
        a: "6-p1"
      }, {
        p: "خور مې ولیده.",
        f: "khor me ooleeda.",
        e: "I saw a sister.",
        a: "6-p2"
      }, {
        p: "دا غوا ده.",
        f: "daa ghwaa da.",
        e: "This is a cow.",
        a: "6-p3"
      }, {
        p: "دا خوا ده.",
        f: "da khwaa da.",
        e: "This is the side.",
        a: "6-p4"
      }, {
        p: "دغه غر دے.",
        f: "dágha ghur day.",
        e: "This is a mountain.",
        a: "6-p5"
      }, {
        p: "دغه خر دے.",
        f: "dágha khur day.",
        e: "This is a donkey.",
        a: "6-p6"
      }, {
        p: "دا غټه ده.",
        f: "daa gháTa da.",
        e: "This is big.",
        a: "6-p7"
      }, {
        p: "دا خټه ده.",
        f: "daa kháTa da.",
        e: "This is mud.",
        a: "6-p8"
      }]}
    </Examples>
    <p>{`Try this tongue twister:`}</p>
    <Examples memorizingMoment mdxType="Examples">
  {[{
        p: "زما غوا د کور په خوا کښې ده.",
        f: "zmaa ghwaa du kor pu khaa ke da.",
        e: "My cow is close to the house.",
        a: "6-mm1"
      }]}
    </Examples>
    <img src={cow} />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Contrasting `}<Word w={{
          p: "و",
          f: "o",
          a: "o-sound"
        }} mdxType="Word" /> and <Word w={{
          p: "هٔ or unwritten in script",
          f: "u",
          a: "u-sound"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "ځو لرې؟",
        f: "tso lare?",
        e: "How many do you have?",
        a: "6p8"
      }, {
        p: "څهٔ لرې؟",
        f: "tsu lare?",
        e: "What do you have?",
        a: "6p9"
      }, {
        p: "دے موړ شو.",
        f: "day moR sho.",
        e: "He is full (from eating).",
        a: "6p10"
      }, {
        p: "دے مړ شو.",
        f: "day muR sho.",
        e: "He died.",
        a: "6p11"
      }]}
    </Examples>
    <h2 {...{
      "id": "a-complete-listing-of-subject-pronouns-and-the-present-equative"
    }}>{`A complete listing of subject pronouns and the present equative`}</h2>
    <Table titleRow={["Person", {
      title: "Pronoun/Verb (Singular)",
      span: 3
    }, {
      title: "Pronoun/Verb (Plural)",
      span: 3
    }]} bordered wide mdxType="Table">
  {[[{
        e: "1st"
      }, {
        f: "zu yim",
        a: "zu-yim"
      }, {
        e: "I am"
      }, {
        p: "زه یم.",
        a: "zu-yim"
      }, {
        f: "moonG yoo",
        a: "moongg-yoo"
      }, {
        e: "we are"
      }, {
        p: "مُونږ یو",
        a: "moongg-yoo"
      }], [{
        e: "2st"
      }, {
        f: "tu ye",
        a: "tu-ye"
      }, {
        e: "you are"
      }, {
        p: "ته یې",
        a: "tu-ye"
      }, {
        f: "táaso yey",
        a: "taaso-yey"
      }, {
        e: "you are"
      }, {
        p: "تاسو یئ",
        a: "taaso-yey"
      }], [{
        e: "3rd",
        rowSpan: 2
      }, [{
        f: "haghá day",
        a: "hagha-day"
      }, {
        f: "day day",
        a: "day-day"
      }], {
        e: "he is"
      }, [{
        p: "هغه دے",
        a: "hagha-day"
      }, {
        p: "دے دے",
        a: "day-day"
      }], {
        p: "haghwée dee",
        a: "haghwee-dee"
      }, {
        e: "they are"
      }, {
        f: "هغوی دی",
        a: "haghwee-dee"
      }], [[{
        f: "haghá da",
        a: "hagha-da"
      }, {
        f: "daa da",
        a: "daa-da"
      }], {
        e: "she is"
      }, [{
        p: "هغه ده",
        a: "hagha-da"
      }, {
        p: "دا ده",
        a: "daa-da"
      }], {
        p: "dwee dee",
        a: "dwee-dee"
      }, {
        e: "they are"
      }, {
        f: "دوی دی",
        a: "dwee-dee"
      }]]}
    </Table>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`You will hear speakers using both `}<Word w={{
            p: "هغی",
            f: "haghee",
            a: "haghee"
          }} mdxType="Word" /> and <Word w={{
            p: "هغوی",
            f: "haghwee",
            a: "haghwee"
          }} mdxType="Word" />{` to mean 'they’; referring to people who are being talked about, but who are not present.`}</p>
      </li>
      <li parentName="ol">
        <Word w={{
          p: "دوی",
          f: "dwee",
          a: "dwee"
        }} mdxType="Word" /> is used to describe more than
one person or animal; referring to people / animals which are close at hand and
visible.
      </li>
      <li parentName="ol">
        <p parentName="li">{`Commonly anything close at hand is often called `}<Word w={{
            p: "دا",
            f: "daa",
            e: "demonstrative pronoun",
            a: "daa"
          }} mdxType="Word" />{`, but it is only by listening to the verb ending that you know whether this refers to 'he’, 'she’, 'they’, etc.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "feminine-plural-nouns"
    }}>{`Feminine Plural Nouns`}</h2>
    <Examples mdxType="Examples">{examples.slice(0, 5)}</Examples>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p>{`Feminine nouns ending in `}<Word w={{
        p: "ه",
        f: "a",
        a: "a-sound"
      }} mdxType="Word" /> change the ending to <Word w={{
        p: "ې",
        f: "e",
        a: "e-sound"
      }} mdxType="Word" /> in the plural. Feminine nouns ending in <Word w={{
        p: "ۍ",
        f: "uy",
        a: "uy-sound"
      }} mdxType="Word" /> do not change in the plural. Others use an <Word w={{
        p: "انې",
        f: "aane",
        a: "aane"
      }} mdxType="Word" />{` ending to show plurality.`}</p>
    <h2 {...{
      "id": "feminine-plural-adjectives"
    }}>{`Feminine plural adjectives`}</h2>
    <Table unset bordered wide titleRow={["Pronoun", "Adjective", "Verb"]} mdxType="Table">
  {[[{
        f: "moonG"
      }, {
        f: "stuRe"
      }, {
        f: "yoo"
      }, {
        p: "مُونږ ستړې یو.",
        e: "We are tired.",
        a: "6t1"
      }], [{
        f: "haghwee"
      }, {
        f: "xkÚle"
      }, {
        f: "dee"
      }, {
        p: "هغوی ښکُلې دی.",
        e: "They are beautiful.",
        a: "6t2"
      }], [{
        f: "táaso"
      }, {
        f: "jóRe"
      }, {
        f: "yey?"
      }, {
        p: "تاسو جوړې یئ؟",
        e: "Are you well?",
        a: "6t3"
      }], [{
        f: "hágha"
      }, {
        f: "gháTe"
      }, {
        f: "dee"
      }, {
        p: "هغه غټې دی.",
        f: "Those are big.",
        a: "6t4"
      }], [{
        f: "daa"
      }, {
        f: "drane"
      }, {
        f: "dee"
      }, {
        p: "دا درنې دی.",
        f: "They are heavy.",
        a: "6t5"
      }]]}
    </Table>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>{`The 3rd person plural form of the equative `}<Word w={{
        p: "دی",
        f: "dee",
        a: "dee"
      }} mdxType="Word" />{` is the same for both genders, so only the adjective ending indicates gender.`}</p>
    <img src={oldWomanThinking} />
    <h2 {...{
      "id": "some-useful-adjectives-in-their-masculine-and-feminine-forms"
    }}>{`Some useful adjectives in their masculine and feminine forms`}</h2>
    <p>{`It would help to memorize the following chart:`}</p>
    <Table unset bordered wide verticalContent titleRow={["Masculine singular", "Masculine plural", "Feminine singular", "Feminine plural"]} mdxType="Table">
  {[[{
        p: "ارزان",
        f: "arzáan",
        e: "cheap, inexpensive",
        a: "6a1"
      }, {
        p: "ارزان",
        f: "arzáan",
        a: "6a1"
      }, {
        p: "ارزانه",
        f: "arzáana",
        a: "6a2"
      }, {
        p: "ارزانې",
        f: "arzáane",
        a: "6a3"
      }], [{
        p: "بدرنګه",
        f: "badrúnga",
        e: "ugly",
        a: "6a4"
      }, {
        p: "بدرنګه",
        f: "badrúnga",
        a: "6a4"
      }, {
        p: "بدرنګه",
        f: "badrúnga",
        a: "6a4"
      }, {
        p: "بدرنګې",
        f: "badrúnge",
        a: "6a5"
      }], [{
        p: "بد",
        f: "bud",
        e: "bad, incorrect",
        a: "6a6"
      }, {
        p: "بد",
        f: "bud",
        a: "6a6"
      }, {
        p: "بده",
        f: "búda",
        a: "6a7"
      }, {
        p: "بدې",
        f: "búde",
        a: "6a8"
      }], [{
        p: "بُوډا",
        f: "booDáa",
        e: "elderly",
        a: "6a9"
      }, {
        p: "بُوډاګان",
        f: "booDaagáan",
        a: "6a10"
      }, {
        p: "بُوډۍ",
        f: "booDúy",
        a: "6a11"
      }, {
        p: "بُوډۍ ګانې",
        f: "booDúygáane",
        a: "6a12"
      }], [{
        p: "پخوانے",
        f: "pakhwaanáy",
        e: "old, ancient, former",
        a: "6a13"
      }, {
        p: "پخوانی",
        f: "pakhwaanée",
        a: "6a14"
      }, {
        p: "پخوانۍ",
        f: "pakhwaanúy",
        e: "",
        a: "6a15"
      }, {
        p: "پخوانۍ",
        f: "pakhwaanúy",
        e: "",
        a: "6a15"
      }], [{
        p: "تږے",
        f: "túGay",
        e: "thirsty",
        a: "6a16"
      }, {
        p: "تږی",
        f: "túGee",
        a: "6a17"
      }, {
        p: "تږې",
        f: "túGe",
        a: "6a18"
      }, {
        p: "تږې",
        f: "túGe",
        a: "6a18"
      }], [{
        p: "تکړه",
        f: "takRá",
        e: "strong",
        a: "6a19"
      }, {
        p: "تکړه",
        f: "takRá",
        a: "6a19"
      }, {
        p: "تکړه",
        f: "takRá",
        a: "6a19"
      }, {
        p: "تکړه",
        f: "takRá",
        a: "6a19"
      }], [{
        p: "خراب",
        f: "kharáab",
        e: "bad, ruined, faulty",
        a: "6a20"
      }, {
        p: "خراب",
        f: "kharáab",
        e: "",
        a: "6a20"
      }, {
        p: "خرابه",
        f: "kharáaba",
        e: "",
        a: "6a21"
      }, {
        p: "خرابې",
        f: "kharáabe",
        e: "",
        a: "6a22"
      }], [{
        p: "خفه",
        f: "khufá, khupá",
        e: "sad, mad",
        a: "6a23"
      }, {
        p: "خفه",
        f: "khufá, khupá",
        e: "",
        a: "6a23"
      }, {
        p: "خفه",
        f: "khufá, khupá",
        e: "",
        a: "6a23"
      }, {
        p: "خفه",
        f: "khufá, khupá",
        e: "",
        a: "6a23"
      }], [{
        p: "دروند",
        f: "droond",
        e: "heavy",
        a: "6a24"
      }, {
        p: "درانهٔ",
        f: "draanú",
        e: "",
        a: "6a25"
      }, {
        p: "درنه",
        f: "draná",
        e: "",
        a: "6a26"
      }, {
        p: "درنې",
        f: "drané",
        e: "",
        a: "6a27"
      }], [{
        p: "زوړ",
        f: "zoR",
        e: "old",
        a: "6a28"
      }, {
        p: "زاړه",
        f: "zaaRú",
        e: "",
        a: "6a29"
      }, {
        p: "زړه",
        f: "zaRá",
        e: "",
        a: "6a30"
      }, {
        p: "زړې",
        f: "zaRé",
        e: "",
        a: "6a31"
      }], [{
        p: "ژوندے",
        f: "jzwandáy",
        e: "alive",
        a: "6a32"
      }, {
        p: "ژوندی",
        f: "jzwandée",
        e: "",
        a: "6a33"
      }, {
        p: "ژوندۍ",
        f: "jzwandúy",
        e: "",
        a: "6a34"
      }, {
        p: "ژوندۍ",
        f: "jzwandúy",
        e: "",
        a: "6a34"
      }], [{
        p: "سپک",
        f: "spuk",
        e: "light",
        a: "6a35"
      }, {
        p: "سپک",
        f: "spuk",
        e: "",
        a: "6a35"
      }, {
        p: "سپکه",
        f: "spúka",
        e: "",
        a: "6a36"
      }, {
        p: "سپکې",
        f: "spúke",
        e: "",
        a: "6a37"
      }], [{
        p: "ستړے",
        f: "stúRay",
        e: "tired",
        a: "6a38"
      }, {
        p: "ستړی",
        f: "stúRee",
        e: "",
        a: "6a39"
      }, {
        p: "ستړې",
        f: "stúRe",
        e: "",
        a: "6a40"
      }, {
        p: "ستړې",
        f: "stúRe",
        e: "",
        a: "6a40"
      }], [{
        p: "سست",
        f: "sust",
        e: "weak, lazy",
        a: "6a41"
      }, {
        p: "سست",
        f: "sust",
        e: "",
        a: "6a41"
      }, {
        p: "سسته",
        f: "susta",
        e: "",
        a: "6a42"
      }, {
        p: "سستې",
        f: "suste",
        e: "",
        a: "6a43"
      }], [{
        p: "ښائسته",
        f: "xaaystá",
        e: "pretty",
        a: "6a44"
      }, {
        p: "ښائسته",
        f: "xaaystá",
        e: "",
        a: "6a44"
      }, {
        p: "ښائسته",
        f: "xaaystá",
        e: "",
        a: "6a44"
      }, {
        p: "ښائسته",
        f: "xaaystá",
        e: "",
        a: "6a44"
      }], [{
        p: "ښکُلے",
        f: "xkÚlay",
        e: "pretty",
        a: "6a45"
      }, {
        p: "ښکُلی",
        f: "xkÚlee",
        e: "",
        a: "6a46"
      }, {
        p: "ښکُلې",
        f: "xkÚle",
        e: "",
        a: "6a47"
      }, {
        p: "ښکُلې",
        f: "xkÚle",
        e: "",
        a: "6a47"
      }], [{
        p: "ښهٔ",
        f: "xu",
        e: "good",
        a: "6a48"
      }, {
        p: "ښهٔ",
        f: "xu",
        e: "",
        a: "6a48"
      }, {
        p: "ښه",
        f: "xa",
        e: "",
        a: "6a49"
      }, {
        p: "ښې",
        f: "xe",
        e: "",
        a: "6a50"
      }], [{
        p: "غټ",
        f: "ghaT",
        e: "big, large, fat",
        a: "6a51"
      }, {
        p: "غټ",
        f: "ghaT",
        e: "",
        a: "6a51"
      }, {
        p: "غټه",
        f: "gháTa",
        e: "",
        a: "6a52"
      }, {
        p: "غټې",
        f: "gháTe",
        e: "",
        a: "6a53"
      }], [{
        p: "کوڼ",
        f: "kooN",
        e: "deaf",
        a: "6a54"
      }, {
        p: "کاڼه",
        f: "kaaNú",
        e: "",
        a: "6a55"
      }, {
        p: "کڼه",
        f: "kaNá",
        e: "",
        a: "6a56"
      }, {
        p: "کڼې",
        f: "kaNé",
        e: "",
        a: "6a57"
      }], [{
        p: "ګران",
        f: "graan",
        e: "expensive, dear",
        a: "6a58"
      }, {
        p: "ګران",
        f: "graan",
        e: "",
        a: "6a58"
      }, {
        p: "ګرانه",
        f: "gráana",
        e: "",
        a: "6a59"
      }, {
        p: "ګرانې",
        f: "gráane",
        e: "",
        a: "6a60"
      }], [{
        p: "لږ",
        f: "luG",
        e: "little, few",
        a: "6a61"
      }, {
        p: "لږ",
        f: "luG",
        e: "",
        a: "6a61"
      }, {
        p: "لږه",
        f: "lúGa",
        e: "",
        a: "6a62"
      }, {
        p: "لږې",
        f: "lúGe",
        e: "",
        a: "6a63"
      }], [{
        p: "لنډ",
        f: "lunD",
        e: "short",
        a: "6a64"
      }, {
        p: "لنډ",
        f: "lunD",
        e: "",
        a: "6a64"
      }, {
        p: "لنډه",
        f: "lúnDa",
        e: "",
        a: "6a65"
      }, {
        p: "لنډې",
        f: "lúnDe",
        e: "",
        a: "6a66"
      }], [{
        p: "لوړ",
        f: "lwuR",
        e: "high, tall",
        a: "6a67"
      }, {
        p: "لوړ",
        f: "lwuR",
        e: "",
        a: "6a67"
      }, {
        p: "لوړه",
        f: "lwúRa",
        e: "",
        a: "6a68"
      }, {
        p: "لوړې",
        f: "lwúRe",
        e: "",
        a: "6a69"
      }], [{
        p: "لوئ",
        f: "looy",
        e: "big, great",
        a: "6a70"
      }, {
        p: "لوئ",
        f: "looy",
        a: "6a70"
      }, {
        p: "لویه",
        f: "lóoya",
        a: "6a71"
      }, {
        p: "لویې",
        f: "lóoye",
        a: "6a72"
      }], [{
        p: "مړ",
        f: "muR",
        e: "dead",
        a: "6a73"
      }, {
        p: "مړهٔ",
        f: "mRu",
        e: "",
        a: "6a74"
      }, {
        p: "مړه",
        f: "mRa",
        e: "",
        a: "6a75"
      }, {
        p: "مړې",
        f: "mRe",
        e: "",
        a: "6a76"
      }], [{
        p: "موړ",
        f: "moR",
        e: "full",
        a: "6a77"
      }, {
        p: "ماړهٔ",
        f: "maaRú",
        e: "",
        a: "6a78"
      }, {
        p: "مړه",
        f: "maRá",
        e: ""
      }, {
        p: "مړې",
        f: "maRé",
        e: ""
      }], [{
        p: "نرے",
        f: "naráy",
        e: "thin",
        a: "6a81"
      }, {
        p: "نری",
        f: "narée",
        e: "",
        a: "6a81"
      }, {
        p: "نرۍ",
        f: "narúy",
        e: "",
        a: "6a82"
      }, {
        p: "نرۍ",
        f: "narúy",
        e: "",
        a: "6a83"
      }], [{
        p: "وړُوکے",
        f: "waRóokay",
        e: "small",
        a: "6a84"
      }, {
        p: "واړهٔ",
        f: "waaRú",
        a: "6a85"
      }, {
        p: "وړه",
        f: "waRá",
        e: "",
        a: "6a86"
      }, {
        p: "وړې",
        f: "waRé",
        e: "",
        a: "6a87"
      }], [{
        p: "وږے",
        f: "wúGay",
        e: "hungry",
        a: "6a88"
      }, {
        p: "وږی",
        f: "wúGee",
        e: "",
        a: "6a89"
      }, {
        p: "وږې",
        f: "wúGe",
        e: "",
        a: "6a90"
      }, {
        p: "وږې",
        f: "wúGe",
        e: "",
        a: "6a90"
      }]]}
    </Table>
    <p>{`👆 Practice the use of these adjectives until they become second nature. Using the illustrations in `}<a href="https://www.interlitfoundation.org/learning-dari/picture-it-in-dari-and-pashto">{`Picture it in Dari and Pashto`}</a>{` or generating some simple pictures to go with this chart will enable you to practice these adjectives with your language helper as well as illiterate friends. Find pictures of masculine singular and plural, and feminine singular and plural objects, then get your helper or friend to drill you. Here is an example using trees and books with the adjectives `}<Word w={{
        p: "غټ",
        f: "ghaT",
        a: "6a51"
      }} mdxType="Word" /> and <Word w={{
        p: "ارزان",
        f: "arzáan",
        a: "6a1"
      }} mdxType="Word" />{`:`}</p>
    <div style={{
      display: "flex",
      flexDirection: "column"
    }}>
  <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
    <div style={{
          maxWidth: "13 rem"
        }}>
      <Examples substitutions mdxType="Examples">
        {[{
              p: "دا غټه ونه ده.",
              f: "daa gháTa wúna da. (f.s.)",
              a: "6e6"
            }]}
      </Examples>
    </div>
    <img src={oneTree} className="inline-img" style={{
          width: "8rem"
        }} />
  </div>
  <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
    <div style={{
          maxWidth: "13rem"
        }}>
      <Examples substitutions mdxType="Examples">
        {[{
              p: "دا غټې ونې دی.",
              f: "daa gháTe wúne dee. (f.pl.)",
              a: "6e7"
            }]}
      </Examples>
    </div>
    <img src={twoTrees} className="inline-img" style={{
          width: "8rem"
        }} />
  </div>
  <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
    <div style={{
          maxWidth: "13 rem"
        }}>
      <Examples substitutions mdxType="Examples">
        {[{
              p: "دا ارزان کتاب دے.",
              f: "daa arzáan kitáab day.",
              a: "6e8"
            }]}
      </Examples>
    </div>
    <img src={oneBook} className="inline-img" style={{
          width: "8rem"
        }} />
  </div>
  <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
    <div style={{
          maxWidth: "13rem"
        }}>
      <Examples substitutions mdxType="Examples">
        {[{
              p: "دا ارزان کتابونه دی.",
              f: "daa arzáan kitaabóona dee. (m.pl.)",
              a: "6e9"
            }]}
      </Examples>
    </div>
    <img src={twoBooks} className="inline-img" style={{
          width: "8rem"
        }} />
  </div>
    </div>
    <p>{`Now you can substitute other adjectives from the list.`}</p>
    <h2 {...{
      "id": "recognising-feminine-plural-nouns-and-adjectives"
    }}>{`Recognising feminine plural nouns and adjectives`}</h2>
    <ol>
      <li parentName="ol">{`Feminine singular and adjectives ending in `}<Word w={{
          p: "ه",
          f: "a",
          a: "a-sound"
        }} mdxType="Word" /> with the suffix <Word w={{
          p: "ې",
          f: "e",
          a: "e-sound"
        }} mdxType="Word" />{` for their plural forms.`}</li>
    </ol>
    <Examples mdxType="Examples">{examples.slice(5, 11)}</Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Feminine nouns ending in `}<Word w={{
          p: "ۍ",
          f: "uy",
          a: "uy-sound"
        }} mdxType="Word" />{` do not change in their plural forms.`}</li>
    </ol>
    <Examples mdxType="Examples">{examples.slice(11, 13)}</Examples>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Feminine nouns ending in `}<Word w={{
          p: "و",
          f: "o",
          a: "o-sound"
        }} mdxType="Word" /> and <Word w={{
          p: "ه",
          f: "a",
          a: "a-sound"
        }} mdxType="Word" /> add the suffix <Word w={{
          p: "ګانې",
          f: "gaane",
          a: ""
        }} mdxType="Word" />{` ګانې for their plural forms.`}</li>
    </ol>
    <Examples mdxType="Examples">{examples.slice(13, 17)}</Examples>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Words that end in a consonant are normally masculine, but there are a few feminine inanimate nouns that also end in a consonant. They become plural by adding `}<Word w={{
          p: "ې",
          f: "e",
          a: "e-sound"
        }} mdxType="Word" />{`.`}</li>
    </ol>
    <Examples mdxType="Examples">{examples.slice(17, 19)}</Examples>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Natural gender words for family members are declined as follows:`}</li>
    </ol>
    <Table bordered medium titleRow={["", "singular", "plural"]} mdxType="Table">
  {[[{
        e: "mother"
      }, {
        f: "mor",
        p: "مور",
        a: "mor"
      }, {
        f: "myande / myaande",
        p: "میندې / میاندې",
        a: "myaande"
      }], [{
        e: "sister"
      }, {
        p: "خور",
        f: "khor",
        a: "khor"
      }, {
        f: "khwende",
        p: "خوېندې",
        a: "khwende"
      }], [{
        e: "daughter"
      }, {
        f: "loor",
        p: "لُور",
        a: "loor"
      }, {
        f: "lóoNa / looryáane",
        p: "لُوڼه / لُوریانې",
        a: "loonnal"
      }]]}
    </Table>
    <h4 {...{
      "id": "note-3"
    }}>{`NOTE:`}</h4>
    <p>{`It is very easy to confuse the following:`}</p>
    <div style={{
      maxWidth: "400px"
    }}>
  <Examples mdxType="Examples">
    {[{
          p: "موړ",
          f: "moR",
          e: "full (m.s.)",
          a: "morrm"
        }, {
          p: "مړ",
          f: "muR",
          e: "dead (m.s.)",
          a: "murrm"
        }, {
          p: "مور",
          f: "mor",
          e: "mother",
          a: "morm"
        }]}
  </Examples>
    </div>
    <p>{`Try these sentences:`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "زه موړ یم.",
        f: "zu moR yim.",
        e: "I (m.) am full.",
        a: "6e24"
      }, {
        p: "زه مړ یم.",
        f: "zu muR yim.",
        e: "I (f.) am dead.",
        a: "6e25"
      }, {
        p: "دا زما مور ده.",
        f: "daa zmaa mor da.",
        e: "She is my mother.",
        a: "6e26"
      }, {
        p: "مونږ ماړه یو.",
        f: "moonG maaRú yoo.",
        e: "We (m.) are full.",
        a: "6e27"
      }, {
        p: "مونږ مړهٔ یو.",
        f: "moonG mRu yoo.",
        e: "We (m.) are dead.",
        a: "6e28"
      }]}
    </Examples>
    <h2 {...{
      "id": "the-use-of-word-w-p-شته-f-shta---and-word-w-p-نشته-f-nishta--"
    }}>{`The use of `}<Word w={{
        p: "شته",
        f: "shta"
      }} mdxType="Word" /> and <Word w={{
        p: "نشته",
        f: "nishta"
      }} mdxType="Word" /></h2>
    <p>
  <Word w={{
        p: "شته",
        f: "shta",
        a: "shta"
      }} mdxType="Word" /> and{" "}
  <Word w={{
        p: "نشته",
        f: "níshta",
        a: "nishta"
      }} mdxType="Word" /> are commonly used words
  expressing 'presence' and 'absence'.
    </p>
    <Examples mdxType="Examples">{examples.slice(19, 31)}</Examples>
    <h4 {...{
      "id": "notes-1"
    }}>{`NOTES:`}</h4>
    <img src={womanWalking} style={{
      float: "right",
      width: "7rem"
    }} />
    <ol>
      <li parentName="ol">
        <Word w={{
          p: "صېب",
          f: "seb",
          a: "seb"
        }} mdxType="Word" /> is a term of respect, rather like
the English word 'sir'. It is the contracted form of <Word w={{
          p: "صاخب",
          f: "saahíb",
          a: "saahib"
        }} mdxType="Word" />
.
      </li>
      <li parentName="ol">
        <p parentName="li">{`The expression `}<Word w={{
            p: "څوک زنانه نشته؟",
            f: "tsok zanaana nishta?",
            a: "tsok-z-k"
          }} mdxType="Word" />{` is useful for men to ask before entering a place where women might be present.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "the-verb-word-w-p-کېدل-f-kedúl---in-the-present-and-future-tenses"
    }}>{`The verb `}<Word w={{
        p: "کېدل",
        f: "kedúl"
      }} mdxType="Word" />{` in the present and future tenses`}</h2>
    <Examples mdxType="Examples">{examples.slice(31, 45)}</Examples>
    <h4 {...{
      "id": "notes-2"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The verb `}<Word w={{
            p: "کېدل",
            f: "kedúl",
            a: "kedul"
          }} mdxType="Word" /> is an auxiliary verb, used with nouns, adjectives, and adverbs to form intransitive (stative) compound verbs. For example, <Word w={{
            p: "مړ کېدل",
            f: "muR kedul",
            e: "to become dead",
            a: "murr-kedul"
          }} mdxType="Word" /> i.e. 'to die' and <Word w={{
            p: "وادهٔ کېدل",
            f: "waadu kedul",
            e: "to become married",
            a: "waadu-kedul"
          }} mdxType="Word" />{`. These types of combinations are common in Pukhto.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Transitive and intransitive verbs are introduced in more detail in Lessons Seven and Eight.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "recognising-plural-nouns"
    }}>{`Recognising plural nouns`}</h2>
    <p>{`There are several nouns, especially liquids and grains, which are considered plural in Pukhto. It is worth memorizing the more common ones. Here are a few of them:`}</p>
    <Examples twoColumns mdxType="Examples">
  {[{
        p: "چائې",
        f: "chaay [che]",
        e: "tea (f.pl.)",
        a: "6w1"
      }, {
        p: "چولې",
        f: "cholé",
        e: "beans (f.pl.)",
        a: "6w2"
      }, {
        p: "غنم",
        f: "ghanúm",
        e: "wheat (m.pl.)",
        a: "6w3"
      }, {
        p: "جامې",
        f: "jaamé",
        e: "clothes (f.pl.)",
        a: "6w4"
      }, {
        p: "جوار",
        f: "joo`áar",
        e: "maize (m.pl.)",
        a: "6w5"
      }, {
        p: "ماستهٔ",
        f: "maastú",
        e: "yogurt (m.pl.)",
        a: "6w6"
      }, {
        p: "مازغهٔ",
        f: "maazghú",
        e: "brain (m.pl.)",
        a: "6w7"
      }, {
        p: "وربشې",
        f: "orbúshe",
        e: "barlay (f.pl.)",
        a: "6w8"
      }, {
        p: "پۍ",
        f: "puy",
        e: "milk (f.pl.)",
        a: "6w9"
      }, {
        p: "شات",
        f: "shaat",
        e: "honay (m.pl.)",
        a: "6w10"
      }, {
        p: "شولې",
        f: "sholé",
        e: "rice (plant)",
        a: "6w11"
      }, {
        p: "شملې",
        f: "shUmlé",
        e: "curd (f.pl.)",
        a: "6w12"
      }, {
        p: "تېل",
        f: "tel",
        e: "oil (m.pl.)",
        a: "6w13"
      }, {
        p: "اُوبهٔ",
        f: "oobú",
        e: "water (f.pl.)",
        a: "6w14"
      }, {
        p: "زهر",
        f: "zúhar",
        e: "poison (n.pl.)",
        a: "6w15"
      }]}
    </Examples>
    <img src={kettle} style={{
      float: "right",
      width: "6rem",
      marginLeft: "1rem"
    }} />
    <p>{`These plural words include uncountable nouns like `}<Word w={{
        p: "چائې",
        f: "chaay",
        e: "tea",
        a: "chaay"
      }} mdxType="Word" /> and <Word w={{
        p: "پۍ",
        f: "puy",
        e: "milk",
        a: "puyf"
      }} mdxType="Word" /> and a few countable nouns such as <Word w={{
        p: "مازغهٔ",
        f: "maazghú",
        e: "brain",
        a: "maazghuf"
      }} mdxType="Word" />{`.`}</p>
    <h2 {...{
      "id": "comprehension-drill-identifying-numbers-on-a-calendar"
    }}>{`Comprehension drill: Identifying numbers on a calendar`}</h2>
    <p>{`Use a calendar to learn the numbers 1–31.`}</p>
    <p>{`👆 First learn the numbers in order, and then try practising them out of order. Start by pointing to the numbers as your teacher calls them out, then try to name the correct number as your teacher points. The new numbers are:`}</p>
    <Examples twoColumns mdxType="Examples">
  {[{
        p: "یوویشت ۲۱",
        f: "yo-eesh 21",
        a: "21"
      }, {
        p: "دوه ویشت ۲۲",
        f: "dwa-weesh 22",
        a: "22"
      }, {
        p: "درویشت ۲۳",
        f: "dúrweesht 23",
        a: "23"
      }, {
        p: "څلېريشت ۲۴",
        f: "tsalereesht 24",
        a: "24"
      }, {
        p: "پینځهٔ ویشت ۲۵",
        f: "peendz-eesht 25",
        a: "25"
      }, {
        p: "ښپږیشت ۲۶",
        f: "shpuGeesht 26",
        a: "26"
      }, {
        p: "اووهٔ ویشت ۲۷",
        f: "oowú-eesht 27",
        a: "27"
      }, {
        p: "اتهٔ ویشت ۲۸",
        f: "atú-weesht 28",
        a: "28"
      }, {
        p: "یو کم دېرش ۲۹",
        f: "yo kam dersh 29",
        a: "29"
      }, {
        p: "دېرش ۳۰",
        f: "dersh 30",
        a: "30"
      }, {
        p: "یو دېرش ۳۱",
        f: "yo dersh 31",
        a: "31"
      }]}
    </Examples>
    <h2 {...{
      "id": "more-parts-of-the-body"
    }}>{`More parts of the body`}</h2>
    <p>{`These examples are all feminine in gender:`}</p>
    <div style={{
      position: "relative",
      textAlign: "center"
    }}>
  <Media queries={{
        small: "(max-width: 599px)",
        large: "(min-width: 600px)"
      }} mdxType="Media">
    {matches => <>
        <img src={bodyPartsMan} style={{
            width: "100%"
          }} />
        <div style={{
            position: "absolute",
            top: "3%",
            right: matches.large ? "5%" : "0%"
          }}>
          <Word w={{
              p: "ورُوځه",
              f: "wroodza",
              e: "ayebrow",
              a: "wroodza"
            }} mdxType="Word" />
        </div>
        <div style={{
            position: "absolute",
            top: "13%",
            left: matches.large ? "20%" : "0%"
          }}>
          <Word w={{
              p: "سترګه",
              f: "sturga",
              e: "aye",
              a: "sturga"
            }} mdxType="Word" />
        </div>
        <div style={{
            position: "absolute",
            top: "20%",
            right: matches.large ? "15%" : "0%"
          }}>
          <Word w={{
              p: "پوزه",
              f: "poza",
              e: "nose",
              a: "poza"
            }} mdxType="Word" />
        </div>
        <div style={{
            position: "absolute",
            top: "35%",
            left: matches.large ? "15%" : "5%"
          }}>
          <Word w={{
              p: "سینه",
              f: "seená",
              e: "chest",
              a: "seena"
            }} mdxType="Word" />
        </div>
        <div style={{
            position: "absolute",
            bottom: "33%",
            left: matches.large ? "10%" : "0%"
          }}>
          <Word w={{
              p: "خېټه",
              f: "khéTa",
              e: "stomach",
              a: "khetta"
            }} mdxType="Word" />
        </div>
        <div style={{
            position: "absolute",
            bottom: "10%",
            right: matches.large ? "12%" : "0%"
          }}>
          <Word w={{
              p: "پښه",
              f: "xpa",
              e: "foot",
              a: "xpa"
            }} mdxType="Word" />
        </div>
      </>}
  </Media>
    </div>
    <h2 {...{
      "id": "memorising-moment"
    }}>{`Memorising Moment`}</h2>
    <h4 {...{
      "id": "word-w-p-متلونه-f-matalóona-a-mataloona--"
    }}><Word w={{
        p: "متلونه",
        f: "matalóona",
        a: "mataloona"
      }} mdxType="Word" /></h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoment[6]}</Examples>
    <p>{`👆 In collecting language information it is helpful to be able to write in phonetic script. Try writing the phonetics for this maˈtəl after your language helper has said it a few times:`}</p>
    <Examples memorizingMoment mdxType="Examples">
  {[{
        p: "لږ خوره تل خوره ډېر خوره ګنډېر خوره.",
        e: "Eat a little, eat forever; eat a lot, eat garbage.",
        a: "6mm5"
      }]}
    </Examples>
    <div style={{
      marginBottom: "2rem"
    }}>
  <PersistentBlank store="6-phonetics-practice" width="100%" mdxType="PersistentBlank" />
    </div>
    <p>{`Now memorise it and learn its usage.`}</p>
    <img src={oxen} />
    <h2 {...{
      "id": "exercises-for-lesson-six"
    }}>{`Exercises for Lesson Six`}</h2>
    <ol>
      <li parentName="ol">{`Practice describing people, animals and objects with the following sentences:`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "هغه سړے دے.",
        f: "haghá saRáy day.",
        e: "He is a man.",
        a: "6exc1"
      }, {
        p: "هغه څنګه سړے دے؟",
        f: "haghá tsínga saRáy day?",
        e: "What kind of man is he?",
        a: "6exc2"
      }, {
        p: "هغه تکړه سړے دے.",
        f: "haghá takRá saRáy day.",
        e: "He is a strong man.",
        a: "6exc3"
      }]}
    </Examples>
    <p>{`Now start with these sentences:`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "دا لُور ده.",
        f: "daa loor da.",
        a: "6exc5"
      }, {
        p: "په کور کښې سبے شته؟",
        f: "pu kor ke spay shta?",
        a: "6exc6"
      }, {
        p: "ستا اس شته؟",
        f: "staa aas shta?",
        a: "6exc7"
      }, {
        p: "دا ښځه ده.",
        f: "daa xudza da.",
        a: "6exc8"
      }, {
        p: "هغوی پښتنې دی.",
        f: "haghwee puxtane dee.",
        a: "6exc9"
      }]}
    </Examples>
    <p>{`👆 Find a children’s picture book or use `}<a href="https://www.interlitfoundation.org/learning-dari/picture-it-in-dari-and-pashto">{`Picture It in Dari and Pashto`}</a>{` and have friends give adjectives to describe the pictures. Make a list of the adjectives you have learned.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Replace the `}<strong parentName="li">{`bold`}</strong>{` word in the following sentence with the words listed below:`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: <>
          زما دا کتاب <strong>خوښ</strong> دے.
        </>,
        f: <>
          zmaa daa kitaab <strong>khwax</strong> day.
        </>,
        e: "I like this book.",
        a: "6exc10"
      }]}
    </Examples>
    <Quiz mdxType="Quiz">
  {[{
        q: {
          p: "قلم",
          f: "qalúm"
        },
        answer: [{
          p: "زما دا قلم خوښ دے.",
          f: "zmaa daa qalúm khwax day."
        }]
      }, {
        q: {
          p: "رنګ",
          f: "rang"
        },
        answer: [{
          p: "زما دا رنګ خوښ دے.",
          f: "zmaa daa rang khwax day."
        }]
      }, {
        q: {
          p: "موټر",
          f: "moTúr"
        },
        answer: [{
          p: "زما دا موټر خوښ دے.",
          f: "zmaa daa moTúr khwax day."
        }]
      }, {
        q: {
          p: "رنګ کور",
          f: "kor"
        },
        answer: [{
          p: "زما دا کور خوښ دے.",
          f: "zmaa daa kor khwax day."
        }]
      }, {
        q: {
          p: "ماشوم",
          f: "rang"
        },
        answer: [{
          p: "زما دا ماشوم خوښ دے.",
          f: "zmaa daa maashóom khwax day."
        }]
      }, {
        q: {
          p: "مېز",
          f: "mez"
        },
        answer: [{
          p: "زما دا مېز خوښ دے.",
          f: "zmaa daa mez khwax day."
        }]
      }]}
    </Quiz>
    <p>{`Then make the sentences negative.`}</p>
    <Quiz mdxType="Quiz">
  {[{
        q: {
          p: "قلم",
          f: "qalúm"
        },
        answer: [{
          p: "زما دا قلم خوښ نهٔ دے.",
          f: "zmaa daa qalúm khwax nu day."
        }, {
          p: "زما دا قلم نهٔ دے خوښ.",
          f: "zmaa daa qalúm nu day khwax."
        }]
      }, {
        q: {
          p: "رنګ",
          f: "rang"
        },
        answer: [{
          p: "زما دا رنګ خوښ نهٔ دے.",
          f: "zmaa daa rang khwax nu day."
        }, {
          p: "زما دا رنګ نهٔ دے خوښ.",
          f: "zmaa daa rang nu day khwax."
        }]
      }, {
        q: {
          p: "موټر",
          f: "moTúr"
        },
        answer: [{
          p: "زما دا موټر خوښ نهٔ دے.",
          f: "zmaa daa moTúr khwax nu day."
        }, {
          p: "زما دا موټر نهٔ دے خوښ.",
          f: "zmaa daa moTúr nu day khwax."
        }]
      }, {
        q: {
          p: "رنګ کور",
          f: "kor"
        },
        answer: [{
          p: "زما دا کور خوښ نهٔ دے.",
          f: "zmaa daa kor khwax nu day."
        }, {
          p: "زما دا کور نهٔ دے خوښ.",
          f: "zmaa daa kor nu day khwax."
        }]
      }, {
        q: {
          p: "ماشوم",
          f: "rang"
        },
        answer: [{
          p: "زما دا ماشوم خوښ نهٔ دے.",
          f: "zmaa daa maashóom khwax nu day."
        }, {
          p: "زما دا ماشوم نهٔ دے خوښ.",
          f: "zmaa daa maashóom nu day khwax."
        }]
      }, {
        q: {
          p: "مېز",
          f: "mez"
        },
        answer: [{
          p: "زما دا مېز خوښ نهٔ دے.",
          f: "zmaa daa mez khwax nu day."
        }, {
          p: "زما دا مېز نهٔ دے خوښ.",
          f: "zmaa daa mez nu day khwax."
        }]
      }]}
    </Quiz>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Repeat exercise 2. with these feminine nouns:`}</li>
    </ol>
    <Quiz mdxType="Quiz">
  {[{
        q: {
          p: "پیشو",
          f: "peeshó"
        },
        answer: [{
          p: "زما دا پیشو خوښه ده.",
          f: "zmaa daa peesho khwaxa da."
        }]
      }, {
        q: {
          p: "ډوډۍ",
          f: "DoDúy"
        },
        answer: [{
          p: "زما دا ډوډۍ خوښه ده.",
          f: "zmaa daa DoDúy khwaxa da."
        }]
      }, {
        q: {
          p: "ونه",
          f: "wúna"
        },
        answer: [{
          p: "زما دا ونه خوښه ده.",
          f: "zmaa daa wúna khwaxa da."
        }]
      }, {
        q: {
          p: "بړستن",
          f: "bRustún"
        },
        answer: [{
          p: "زما دا ونه خوښه ده.",
          f: "zmaa daa wúna khwaxa da."
        }]
      }]}
    </Quiz>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Make the following singular feminine nouns and adjectives plural.`}</li>
    </ol>
    <Quiz mdxType="Quiz">
  {[{
        q: {
          p: "پیشو",
          f: "peeshó"
        },
        answer: [{
          p: "پیشوګانې",
          f: "peeshogáane"
        }]
      }, {
        q: {
          p: "مالټه",
          f: "maalTá"
        },
        answer: [{
          p: "مالټې",
          f: "maalTé"
        }]
      }, {
        q: {
          p: "درنه",
          f: "draná"
        },
        answer: [{
          p: "درنې",
          f: "drané"
        }]
      }, {
        q: {
          p: "ملګرې",
          f: "malgúre"
        },
        answer: [{
          p: "ملګرې",
          f: "malgúre"
        }]
      }, {
        q: {
          p: "سپکه",
          f: "spúka"
        },
        answer: [{
          p: "سپکې",
          f: "spúke"
        }]
      }, {
        q: {
          p: "لُور",
          f: "loor"
        },
        answer: [{
          p: "لوڼه",
          f: "looNá"
        }, {
          p: "لوریانې",
          f: "looryáane"
        }]
      }, {
        q: {
          p: "پښتنه",
          f: "puxtaná"
        },
        answer: [{
          p: "پښتنې",
          f: "puxtané"
        }]
      }, {
        q: {
          p: "ښځه",
          f: "xúdza"
        },
        answer: [{
          p: "ښځې",
          f: "xúdze"
        }]
      }, {
        q: {
          p: "ارزانه",
          f: "arzáana"
        },
        answer: [{
          p: "ارزانه",
          f: "arzáane"
        }]
      }, {
        q: {
          p: "څپلۍ",
          f: "tsaplúy"
        },
        answer: [{
          p: "څپلۍ",
          f: "tsaplúy"
        }]
      }, {
        q: {
          p: "نرسه",
          f: "nársa"
        },
        answer: [{
          p: "نرسې",
          f: "nárse"
        }, {
          p: "نرسې",
          f: "núrse"
        }]
      }, {
        q: {
          p: "بړستن",
          f: "bRustún"
        },
        answer: [{
          p: "بړستنې",
          f: "bRustúne"
        }]
      }, {
        q: {
          p: "ډوډۍ",
          f: "DoDúy"
        },
        answer: [{
          p: "ډوډۍ",
          f: "DoDúy"
        },,]
      }, {
        q: {
          p: "ښکُلې",
          f: "xkUle"
        },
        answer: [{
          p: "ښکُلې",
          f: "xkUle"
        }]
      }, {
        q: {
          p: "مور",
          f: "mor"
        },
        answer: [{
          p: "میندې",
          f: "myande"
        }, {
          p: "میندې",
          f: "maynde"
        }, {
          p: "میاندې",
          f: "myaande"
        }]
      }, {
        q: {
          p: "مڼه",
          f: "maNá"
        },
        answer: [{
          p: "مڼې",
          f: "maNé"
        }]
      }, {
        q: {
          p: "خره",
          f: "khra"
        },
        answer: [{
          p: "خره ګانې",
          f: "khragaane"
        }]
      }, {
        q: {
          p: "کڼه",
          f: "kaNá"
        },
        answer: [{
          p: "کڼې",
          f: "kaNé"
        }]
      }, {
        q: {
          p: "خور",
          f: "khor"
        },
        answer: [{
          p: "خوېندې",
          f: "khwende"
        }]
      }]}
    </Quiz>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Using the question `}<Word w={{
          p: "دا څه شے دے؟",
          f: "daa tsu shay day?",
          a: ""
        }} mdxType="Word" />{` point to the following parts of the body:`}</li>
    </ol>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "پښه",
        f: "xpa",
        a: "xpa"
      }, {
        p: "خېټه",
        f: "kheTa",
        a: "khetta"
      }, {
        p: "سینه",
        f: "seená",
        a: "seena"
      }, {
        p: "پوزه",
        f: "póza",
        a: "poza"
      }, {
        p: "سترګه",
        f: "stúrga",
        a: "sturga"
      }, {
        p: "ورُوځه",
        f: "wróodza",
        a: "wroodza"
      }]}
    </Examples>
    <div style={{
      display: "flex",
      justifyContent: "space-between"
    }}>
  <img src={coveredWoman} style={{
        width: "10rem"
      }} />
  <img src={manWithCane} style={{
        width: "6rem"
      }} />
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-six-a-guest-at-your-door",
    level: 2,
    title: "Dialogue six: A guest at your door",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation drill",
    children: [
        
      ]
  },
{
    id: "a-complete-listing-of-subject-pronouns-and-the-present-equative",
    level: 2,
    title: "A complete listing of subject pronouns and the present equative",
    children: [
        
      ]
  },
{
    id: "feminine-plural-nouns",
    level: 2,
    title: "Feminine Plural Nouns",
    children: [
        
      ]
  },
{
    id: "feminine-plural-adjectives",
    level: 2,
    title: "Feminine plural adjectives",
    children: [
        
      ]
  },
{
    id: "some-useful-adjectives-in-their-masculine-and-feminine-forms",
    level: 2,
    title: "Some useful adjectives in their masculine and feminine forms",
    children: [
        
      ]
  },
{
    id: "recognising-feminine-plural-nouns-and-adjectives",
    level: 2,
    title: "Recognising feminine plural nouns and adjectives",
    children: [
        
      ]
  },
{
    id: "the-use-of-word-w-p-شته-f-shta---and-word-w-p-نشته-f-nishta--",
    level: 2,
    title: <React.Fragment>{`The use of `}<Word w={{ p: "شته", f: "shta" }} /> and <Word w={{ p: "نشته", f: "nishta" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "the-verb-word-w-p-کېدل-f-kedúl---in-the-present-and-future-tenses",
    level: 2,
    title: <React.Fragment>{`The verb `}<Word w={{ p: "کېدل", f: "kedúl" }} />{` in the present and future tenses`}</React.Fragment>,
    children: [
        
      ]
  },
{
    id: "recognising-plural-nouns",
    level: 2,
    title: "Recognising plural nouns",
    children: [
        
      ]
  },
{
    id: "comprehension-drill-identifying-numbers-on-a-calendar",
    level: 2,
    title: "Comprehension drill: Identifying numbers on a calendar",
    children: [
        
      ]
  },
{
    id: "more-parts-of-the-body",
    level: 2,
    title: "More parts of the body",
    children: [
        
      ]
  },
{
    id: "memorising-moment",
    level: 2,
    title: "Memorising Moment",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-six",
    level: 2,
    title: "Exercises for Lesson Six",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Six"
}

