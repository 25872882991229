
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"24 min read","minutes":23.65,"time":1419000,"words":4730}
/* @jsxRuntime classic */
/* @jsx mdx */
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Dialogue from "../components/Dialogue";
import AudioPiece from "../components/AudioPiece";
import memorizingMoments from "./memorizing-moments";
import PersistentBlank from "../components/persistent-blank";
import barberJoke from "../assets/images/barber-joke.PNG";
import gateway from "../assets/images/gateway.png";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-twenty-make-your-own-texts"
    }}>{`Dialogue twenty: Make your own texts`}</h2>
    <p>{`👆 It is a huge step forward in your learning when you are able to speak on a short topic. Work with your helper to develop three topics about yourself. To do this explain to your helper how you would talk about yourself in three diferent tenses. Then have your helper prepare three short talks – as you might say them. Record each one; transcribe them; and then memorize them before going out to use them.`}</p>
    <h4 {...{
      "id": "present"
    }}>{`Present:`}</h4>
    <p>{`A text answering the common question, `}<Word w={{
        p: "تهٔ دلته څهٔ کوې؟",
        f: "tu dălta tsu kawe?",
        e: "What do you do here?",
        a: "tu-dalta-tsu-kawe-f"
      }} mdxType="Word" /></p>
    <PersistentBlank store="20-present-text" rows={6} mdxType="PersistentBlank" />
    <h4 {...{
      "id": "future"
    }}>{`Future`}</h4>
    <p>{`A text telling of your plans in the near future.`}</p>
    <PersistentBlank store="20-future-text" rows={6} mdxType="PersistentBlank" />
    <h4 {...{
      "id": "past"
    }}>{`Past`}</h4>
    <p>{`A text that tells of some interesting event you saw or something that happened to you a few days ago. Try to keep it in the simple past tense.`}</p>
    <PersistentBlank store="20-past-text" rows={6} mdxType="PersistentBlank" />
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation drill`}</h2>
    <p>{`Have fun with this tongue twister:`}</p>
    <Examples memorizingMoment mdxType="Examples">
  {[{
        p: "د ګڼو مڼو په باغ کښې د رڼو اوبو په غاړه کڼه چڼ چڼه په کاڼى اووله.",
        f: "du guNo maNó pu baagh ke du raNó oobo pu gháaRa kaNa chaN chaNa pu káaNee óowula.",
        e: "",
        a: "20tt1"
      }]}
    </Examples>
    <h2 {...{
      "id": "transitive-verbs-in-the-simple-past-tense"
    }}>{`Transitive verbs in the simple past tense`}</h2>
    <p>{`The next two chapters deal with the simple past tense for transitive verbs. Unlike intransitive verbs, transitive verbs in the past tense agree with the object, not the subject of the sentence. It is this feature of Pukhto that makes it a challenging language to speak accurately in the past tense. Take your time as you study the next lessons.`}</p>
    <p>{`The `}<em parentName="p">{`simple past tense`}</em>{` is always built from the `}<em parentName="p">{`perfective root`}</em>{` of a verb.`}</p>
    <h3 {...{
      "id": "transitive-verbs-taking-word-w-p-و-f--oo---w-e--a-oo-sound--"
    }}>{`Transitive verbs taking `}<Word w={{
        p: "و",
        f: "-oo / -w",
        e: "",
        a: "oo-sound"
      }} mdxType="Word" /></h3>
    <p>{`For many verbs, the perfective root is formed by adding `}<Word w={{
        p: "و",
        f: "oo / w",
        e: "",
        a: "oo-sound"
      }} mdxType="Word" /> to the infinitive and moving the émphasis to the fírst sýllable. <Word w={{
        p: "خوړل",
        f: "khoRúl",
        e: "to bite, eat, consume",
        a: "khorrul"
      }} mdxType="Word" />{` is one example of a transitive verb that does this:`}</p>
    <Table bordered verticalContent titleRow={["Infinitive", "Perfective Root"]} mdxType="Table">
  {[[{
        p: "خوړل",
        f: "khoRúl",
        e: "",
        a: "khorrul-m"
      }, {
        p: "وخوړل",
        f: "óokhoRul",
        e: "",
        a: ""
      }]]}
    </Table>
    <p>{`Now we can build simple past sentences using the perfective root of `}<Word w={{
        p: "خوړل",
        f: "khoRúl",
        e: "to eat",
        a: ""
      }} mdxType="Word" />{`:`}</p>
    <Table bordered unset verticalContent titleRow={["Person", "Singular", "Plural"]} mdxType="Table">
  {[[{
        e: "1st"
      }, {
        p: "ما مڼه وخوړه [وخوړله]",
        f: "maa maNá óokhoRa [óokhoRula]",
        e: "I ate an apple (f.s.)",
        a: "20e1"
      }, {
        p: "مُونږ روټۍ وخوړه [وخوړله]",
        f: "moonG roTúy óokhoRa [óokhoRula]",
        e: "We ate food (f.s.)",
        a: "20e2"
      }], [{
        e: "2nd"
      }, {
        p: "تا کېله وخوړه [وخوړله]",
        f: "taa kelá óokhoRa [óokhoRula]",
        e: "You ate a banana (f.s.)",
        a: "20e3"
      }, {
        p: "تاسو ډوډۍ وخوړه [وخوړله]",
        f: "taaso DoDúy óokhoRa [óokhoRula].",
        e: "You ate food (f.s.)",
        a: "20e4"
      }], [{
        e: "3rd m."
      }, {
        p: "هغهٔ یو آلو وخوړو [وخوړلو]",
        f: "haghú yo aalóo óokhoRulo",
        e: "He ate one potato (m.s.)",
        a: "20e5"
      }, {
        p: "هغوی تاشپاتۍ وخوړې [وخوړلې]",
        f: "haghwee naashpaatúy óokhoRe [óokhoRule]",
        e: "They ate pears (f.pl.)",
        a: "20e6"
      }], [{
        e: "3rd f."
      }, {
        p: "هغې یو آم وخوړو [وخوړلو]",
        f: "haghé ya aam óokhoRo [óokhoRulo]",
        e: "She ate a mango (m.s.)",
        a: "20e7"
      }, {
        p: "هغوی ناشپاتۍ وخوړې [وخوړلې]",
        f: "haghwee nashpaatúy óokhoRe [óokhoRule]",
        e: "They ate pears (f.pl.)",
        a: "20e8"
      }]]}
    </Table>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">{`The `}<Word w={{
          p: "ـل",
          f: "-ul",
          e: "",
          a: ""
        }} mdxType="Word" />{` on the end of the perfective root can be kept or dropped. Both forms are used and this does not affect the meaning at all.`}</li>
      <li parentName="ol">{`If this verb `}<Word w={{
          p: "خوړل",
          f: "khoRúl",
          e: "to eat",
          a: "khorrul"
        }} mdxType="Word" /> is spoken without an object, then generally the feminine singular is used, implying <Word w={{
          p: "روټۍ",
          f: "roTúy",
          e: "",
          a: "rottuy-f"
        }} mdxType="Word" /> or <Word w={{
          p: "ډوډۍ",
          f: "DoDúy",
          e: "",
          a: "ddodduy-f"
        }} mdxType="Word" />{` both of which refer to “food” or 'a meal’. For example:`}</li>
    </ol>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "تا وخوړه?",
        f: "taa óokhoRa?",
        e: "Did you eat?",
        a: "20e9"
      }, {
        p: "او، ما وخوړه.",
        f: "aw, maa óokhoRa.",
        e: "Yes, I ate.",
        a: "20e10"
      }]}
    </Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`The masculine and feminine verb endings refer the `}<strong parentName="li">{`thing`}</strong>{` eatan (the object), not the gender of the one who ate (the subject).`}</li>
    </ol>
    <p>{`👆 Study these examples and then practice making lots of new sentences:`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "هغې يو آم وخوړو.",
        f: "haghé yo aam óokhoRo.",
        e: "She ate a mango. (m.s.)",
        a: "20e11"
      }, {
        p: "هغې ډېر آمونه وخوړل؟",
        f: "haghé Der aamóona óokhoRul?",
        e: "Did she eat many mangoes? (m.p.)",
        a: "20e12"
      }, {
        p: "دۀ يوه مڼه وخوړه؟",
        f: "du yuwa maNá óokhoRa?",
        e: "Did he eat an apple? (f.s.)",
        a: "20e13"
      }, {
        p: "دۀ ډېرې مڼې وخوړې.",
        f: "du Dére maNé óokhoRe.",
        e: "He ate a lot of apples. (f.p.)",
        a: "20e14"
      }]}
    </Examples>
    <h4 {...{
      "id": "notes-1"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In these sentences, fruit is the object (recipient of an action).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The verb form is based on the person, number and gender of the object.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The subject of transitive sentences is inflected in the past tense in the same way it does for when used with prepositions etc.`}</p>
      </li>
    </ol>
    <Table mdxType="Table">
  {[[{
        p: "زهٔ ← ما",
        f: "zu → maa",
        e: "",
        a: ""
      }], [{
        p: "تهٔ ← تا",
        f: "tu → taa",
        e: "",
        a: ""
      }], [{
        p: "هغه ← هغهٔ",
        f: "haghá haghú (m.)",
        e: "",
        a: ""
      }], [{
        p: "هغه ← هغې",
        f: "haghá haghé (f.)",
        e: "",
        a: ""
      }], [{
        p: "هغوی ← هغوی",
        f: "haghwee haghwee (pl. – no change)",
        e: "",
        a: ""
      }]]}
    </Table>
    <p style={{
      fontSize: "smaller"
    }}>
  Remember that plural prounouns can't be inflected.
    </p>
    <p>{`So to review the `}<strong parentName="p">{`basic rules for transitive past tense sentences`}</strong>{`:`}</p>
    <ol>
      <li parentName="ol">{`The verb agrees with the `}<em parentName="li">{`object`}</em>{`.`}</li>
      <li parentName="ol">{`The subject gets inflected (if possible).`}</li>
    </ol>
    <p>{`It’s also important to note that with transitive past tense sentences the enclitic pronouns `}<Word w={{
        p: "مې، دې، یې، مو",
        f: "me, de, ye, mU",
        e: "",
        a: ""
      }} mdxType="Word" />{` are used for `}<em parentName="p">{`subjects`}</em>{` in the past tense, but `}<em parentName="p">{`objects`}</em>{` in the present.`}</p>
    <p>{`👆 Practice the sentences below to become familiar with the way transitive verbs are used in the simple past tense. The information about the `}<em parentName="p">{`object`}</em>{` of each sentences is given in (brackets).`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "ما دولس کېلې وخوړې.",
        f: "maa dólus kelé óokhoRe.",
        e: "I ate twelve bananas. (3rd pers. f.pl.)",
        a: "20e15"
      }, {
        p: "ما يوه کېله وخوړه.",
        f: "maa yuwa kelá óokhoRa.",
        e: "I ate a banana. (3rd pers. f.s.)",
        a: "20e16"
      }, {
        p: "هغوى دولس کېلې وخوړې.",
        f: "haghwée dólus kelé óokhoRe.",
        e: "They ate twelve bananas. (3rd pers. f.pl.)",
        a: "20e17"
      }, {
        p: "هغوى يوه کېله وخوړه.",
        f: "haghwée yuwa kelá óokhoRa.",
        e: "They ate a banana. (3rd pers. f.s.)",
        a: "20e18"
      }, {
        p: "هغۀ يو امرُود وخوړو.",
        f: "haghú yo amróod óokhoRo.",
        e: "He ate a guava. (3rd pers. m.s.)",
        a: "20e19"
      }, {
        p: "ما لږ شات وخوړل.",
        f: "maa luG shaat óokhoRul.",
        e: "I ate some honay. (3rd pers. m.pl.)",
        a: "20e20"
      }, {
        p: "ما ورېژې وخوړې.",
        f: "maa wréjze óokhoRe.",
        e: "I ate rice. (3rd pers. f.pl.)",
        a: "20e21"
      }, {
        p: "هغۀ يو ټېپر وخوړو.",
        f: "haghú yo Tepúr óokhoRo.",
        e: "He ate a turnip. (3rd pers. m.s.)",
        a: "20e22"
      }, {
        p: "هغې دوه پياز وخوړل.",
        f: "haghé dwa pyaaz óokhoRul.",
        e: "She ate two onions. (3rd pers. m.pl.)",
        a: "20e23"
      }, {
        p: "هغې يو پياز وخوړو.",
        f: "haghé yo pyaaz óokhoRo.",
        e: "She ate an onion. (3rd pers. m.s.)",
        a: "20e24"
      }, {
        p: "زۀ سپى وخوړلم.",
        f: "zu spee óokhoRulum.",
        e: "The dog bit me. (1st pers. s.)",
        a: "20e25"
      }, {
        p: "هغه سپى وخوړو.",
        f: "haghá spee óokhoRo.",
        e: "The dog bit him. (3rd pers. m.s.)",
        a: "20e26"
      }, {
        p: "سپى سړى وخوړل.",
        f: "spee saRee óokhoRul.",
        e: "The dog bit the men. (3rd pers. m.pl.)",
        a: "20e27"
      }, {
        p: "سپى ښځې وخوړې.",
        f: "spee xúdze óokhoRe.",
        e: "The dog bit the ladies. (3rd pers. f.pl.)",
        a: "20e28"
      }, {
        p: "تۀ سپى وخوړې؟",
        f: "tu spee óokhoRe?",
        e: "Did the dog bite you? (2nd pers. s.)",
        a: "20e29"
      }, {
        p: "تاسو سپى وخوړئ؟",
        f: "taaso spee óokhoRey?",
        e: "Did the dog bite you? (2nd pers. p.)",
        a: "20e30"
      }, {
        p: "مُونږ لږ کباب وخوړل.",
        f: "moonG luG kabáab óokhoRul.",
        e: "We ate some kebabs. (3rd pers. m.pl.)",
        a: "20e31"
      }, {
        p: "هغې يو آم ونۀ خوړو.",
        f: "haghé yo aam oo-nu-khoRo.",
        e: "She didn't eat a mango. (3rd pers. m.s.)",
        a: "20e32"
      }]}
    </Examples>
    <h4 {...{
      "id": "notes-2"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">{`The object is not inflected.`}</li>
      <li parentName="ol">{`In the sentence, “The dog bit me” the object is `}<strong parentName="li">{`me`}</strong>{`, so the verb takes the first person singular ending `}<Word w={{
          p: "ـم",
          f: "-um",
          e: "",
          a: ""
        }} mdxType="Word" /></li>
      <li parentName="ol">{`When a dog is the subject (the one doing the biting), it’s inflected from `}<Word w={{
          p: "سپے",
          f: "spay",
          e: "",
          a: ""
        }} mdxType="Word" /> to <Word w={{
          p: "سبی",
          f: "spee",
          e: "",
          a: ""
        }} mdxType="Word" />{`. If there were multiple dogs biting, we would need to do a double inflection because the dogs are plural `}<em parentName="li">{`and`}</em>{` the subject of the sentence, for example:`}</li>
    </ol>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "زهٔ سپی وخوړلم.",
        f: "zu spee óokhoRulum.",
        e: "The dog bit me.",
        a: ""
      }, {
        p: "زهٔ سپو وخوړلم.",
        f: "zu spo óokhoRulum.",
        e: "The dogs bit me.",
        a: ""
      }]}
    </Examples>
    <h4 {...{
      "id": "transitive-verbs-that-take-the-word-w-p-و-f-oo--w-e--a-oo-sound---prefix-in-the-simple-past-tense"
    }}>{`Transitive verbs that take the `}<Word w={{
        p: "و",
        f: "oo / w",
        e: "",
        a: "oo-sound"
      }} mdxType="Word" />{` prefix in the simple past tense`}</h4>
    <Table bordered verticalContent unset titleRow={["Verb", "Infinitive", "Perfective Root"]} mdxType="Table">
  {[[{
        e: "to forgive"
      }, {
        p: "بخښل",
        f: "baxúl",
        e: "",
        a: "20v1"
      }, {
        p: "وبخښل",
        f: "óobaxul",
        e: "",
        a: "20v2"
      }], [{
        e: "to know (recognise)"
      }, {
        p: "پېژندل",
        f: "pejzandúl",
        e: "",
        a: "20v3"
      }, {
        p: "وپېژندل",
        f: "óopejzandul",
        e: "",
        a: "20v4"
      }], [{
        e: "to drink"
      }, {
        p: "څښل",
        f: "skul",
        e: "",
        a: "20v5"
      }, {
        p: "وڅښل",
        f: "óoskul",
        e: "",
        a: "20v6"
      }], [{
        e: "to want"
      }, {
        p: "غوښتل",
        f: "ghoxtúl",
        e: "",
        a: "20v7"
      }, {
        p: "وغوښتل",
        f: "óoghoxtul",
        e: "",
        a: "20v8"
      }], [{
        e: "to sow"
      }, {
        p: "کرل",
        f: "karúl",
        e: "",
        a: "20v9"
      }, {
        p: "وکرل",
        f: "óokarul",
        e: "",
        a: "20v10"
      }], [{
        e: "to look at"
      }, {
        p: "کتل",
        f: "katúl",
        e: "",
        a: "20v11"
      }, {
        p: "وکتل",
        f: "óokatul",
        e: "",
        a: "20v12"
      }], [{
        e: "to sew"
      }, {
        p: "ګنډل",
        f: "ganDúl",
        e: "",
        a: "20v13"
      }, {
        p: "وګنډل",
        f: "óoganDul",
        e: "",
        a: "20v14"
      }], [{
        e: "to turn on"
      }, {
        p: "لګول",
        f: "lugawúl",
        e: "",
        a: "20v15"
      }, {
        p: "ولګول",
        f: "óolugawul",
        e: "",
        a: "20v16"
      }], [{
        e: "to see"
      }, {
        p: "ليدل",
        f: "leedúl",
        e: "",
        a: "20v17"
      }, {
        p: "وليدل",
        f: "óoleedul",
        e: "",
        a: "20v18"
      }], [{
        e: "to send"
      }, {
        p: "لېږل",
        f: "leGúl",
        e: "",
        a: "20v19"
      }, {
        p: "ولېږل",
        f: "óoleGul",
        e: "",
        a: "20v20"
      }], [{
        e: "to write"
      }, {
        p: "ليکل",
        f: "leekúl",
        e: "",
        a: "20v21"
      }, {
        p: "وليکل",
        f: "óoleekul",
        e: "",
        a: "20v22"
      }], [{
        e: "to catch"
      }, {
        p: "نيول",
        f: "neewúl",
        e: "",
        a: "20v23"
      }, {
        p: "ونيول",
        f: "óoneewul",
        e: "",
        a: "20v24"
      }], [{
        e: "to hit"
      }, {
        p: "وهل",
        f: "wahúl",
        e: "",
        a: "20v25"
      }, {
        p: "ووهل",
        f: "óowahul",
        e: "",
        a: "20v26"
      }], [{
        e: "to extract"
      }, {
        p: "ويستل [وښکل]",
        f: "weestúl",
        e: "",
        a: "20v27"
      }, {
        p: "ويستل [ووښکل]",
        f: "óoweestul",
        e: "",
        a: "20v28"
      }], [{
        e: "to wash"
      }, {
        p: "وينځل",
        f: "weendzúl",
        e: "",
        a: "20v29"
      }, {
        p: "ووينځل",
        f: "óoweendzul",
        e: "",
        a: "20v30"
      }], [{
        e: "to say"
      }, {
        p: "وئيل",
        f: "wayúl",
        e: "",
        a: "20v31"
      }, {
        p: "ووئيل",
        f: "óowayul",
        e: "",
        a: "20v32"
      }]]}
    </Table>
    <Examples mdxType="Examples">
  {[{
        p: "ما سړے وبخښلو.",
        f: "maa saRáy óobakhxulo.",
        e: "I forgave the man. (m.s.)",
        a: "20e33"
      }, {
        p: "ما سړى وبخښل.",
        f: "maa saRáy óobakhúl.",
        e: "I forgave the men. (m.pl.)",
        a: "20e34"
      }, {
        p: "ما ښځه وبخښله.",
        f: "maa xudza óobakhula.",
        e: "I forgave the woman. (f.s.)",
        a: "20e35"
      }, {
        p: "ما ښځې وبخښلې.",
        f: "maa xudze óobakhule.",
        e: "I forgave the women. (f.pl.)",
        a: "20e36"
      }, {
        p: "تا خط وليکلو؟",
        f: "taa khat óoleekulo?",
        e: "Did you write the letter? (m.s.)",
        a: "20e37"
      }, {
        p: "تا خطُونه وليکل؟",
        f: "taa khatóona óoleekul?",
        e: "Did you write letters? (m.pl.)",
        a: "20e38"
      }, {
        p: "تا قيصه وليکله؟",
        f: "taa qeesá óoleekula?",
        e: "Did you write a story? (f.s.)",
        a: "20e39"
      }, {
        p: "تا قيصه ونۀ ليکله؟",
        f: "taa qeesá oo-nú-leekula?",
        e: "Didn't you write a story?",
        a: "20e40"
      }, {
        p: "تا قيصې وليکلې؟",
        f: "taa qeesé óoleekule?",
        e: "Did you write stories? (f.pl.)",
        a: "20e41"
      }, {
        p: "دۀ خپله ښځه ووهله.",
        f: "du khpula xudza óowahula.",
        e: "He hit his wife. (f.s.)",
        a: "20e42"
      }, {
        p: "ګُل رسول خپلې ښځې ووهلې.",
        f: "gUl rasool khpule xudze óowahule.",
        e: "Gul Rasoul hit his wives. (f.pl.)",
        a: "20e43"
      }, {
        p: "تاسو چائې وڅښلې؟",
        f: "taas chaay óoskule?",
        e: "Did you drink tea? (f.pl.)",
        a: "20e44"
      }, {
        p: "آو، مُونږ چائې وڅښلې.",
        f: "aw, moonG chaay óoskule.",
        e: "Yes, we drank tea.",
        a: "20e45"
      }, {
        p: "تا هغه جينۍ وليده؟",
        f: "taa hágha jeenúy óoleeda?",
        e: "Did you see that girl?",
        a: "20e46"
      }, {
        p: "آو، و مې ليده.",
        f: "aw, óo-me-leedá.",
        e: "Yes, I saw her.",
        a: "20e47"
      }, {
        p: "تا اخبار ولوستو؟",
        f: "taa akhbaar óolosto?",
        e: "Did you read the newspaper? (m.s.)",
        a: "20e48"
      }, {
        p: "آو، و مې لوستو.",
        f: "aw, óo-me-losto.",
        e: "Yes, I read it.",
        a: "20e49"
      }, {
        p: "ما خپل زوی ووهلو [وواههٔ].",
        f: "maa khpul zwee óowahulo [óowaahu]",
        e: "I hit my son. (m.s.)",
        a: "20e50"
      }, {
        p: "تا خپل ماشُومان ووهل؟",
        f: "taa khpul maashoomáan óowahul?",
        e: "Did you hit your children? (m.pl.)",
        a: "20e51"
      }, {
        p: "مُونږ خپله لُور ووهله.",
        f: "moonG khpula loor óowahula.",
        e: "We hit our daughter. (f.s.)",
        a: "20e52"
      }, {
        p: "ښځې يې ووهلې؟",
        f: "xudze ye óowahule?",
        e: "Did he hit (his) wives? (f.pl.)",
        a: "20e53"
      }, {
        p: "ما هلک ووهلو.",
        f: "maa haluk óowahulo.",
        e: "I hit the boy. (m.s.)",
        a: "20e54"
      }, {
        p: "تا زما قميص وګنډلو؟",
        f: "taa zmaa qaméez óoganDulo?",
        e: "Did you sew my shirt? (m.s.)",
        a: "20e55"
      }, {
        p: "آو، ومې ګنډلو.",
        f: "aw, óo-me-ganDulo.",
        e: "Yes, I sewed it.",
        a: "20e56"
      }, {
        p: "لړم وچيچلې؟",
        f: "laRum óocheechule?",
        e: "Did the scorpion sting you?",
        a: "20e57"
      }, {
        p: "پوليس مُونږ ونيولُو.",
        f: "polées moonG óoneewuloo.",
        e: "The police caught us.",
        a: "20e58"
      }, {
        p: "پرُون تا خپل زوی دُوکان ته ولېږلو؟",
        f: "paróon taa khpul zooy dookáan ta óoleGulo?",
        e: "Did you send your son to the shop yesterday?",
        a: "20e59"
      }, {
        p: "تا زۀ پرُون وليدلم؟",
        f: "taa zu paróom óoleedulum?",
        e: "Did you see me yesterday?",
        a: "20e60"
      }, {
        p: "آو، ما تۀ پرُون وليدلې.",
        f: "aw, maa tu paróon óoledule.",
        e: "Yes, I saw you yesterday.",
        a: "20e61"
      }, {
        p: "آو، تۀ مې پرُون وليدلې.",
        f: "aw, tu me paróon óoleedule.",
        e: "Yes, I saw you yesterday.",
        a: "20e62"
      }, {
        p: "تۀ مې ونۀ ليدلې.",
        f: "tu me óo-nu-leedule.",
        e: "I didn't see you.",
        a: "20e63"
      }]}
    </Examples>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`In transitive past tense sentences enclitic pronouns can be used as subjects.`}</p>
    <Table bordered verticalContent titleRow={["", "Regular Pronoun Inflected", "Enclitic Pronoun"]} mdxType="Table">
  {[[{
        e: "I (1st pers.)"
      }, {
        p: "ما",
        f: "maa",
        e: "",
        a: ""
      }, {
        p: "مې",
        f: "me",
        e: "",
        a: ""
      }], [{
        e: `"I saw you"`
      }, {
        p: "ما تهٔ ولیدلې",
        f: "maa tu óoleedule",
        e: "",
        a: ""
      }, {
        p: "تهٔ مې ولیدلې",
        f: "tu me óoleedule",
        e: "",
        a: ""
      }], [{
        e: "He (3rd pers. m.)"
      }, {
        p: "هغهٔ",
        f: "haghú",
        e: "",
        a: ""
      }, {
        p: "یې",
        f: "ye",
        e: "",
        a: ""
      }], [{
        e: `"He hit me"`
      }, {
        p: "هغهٔ زه ووهلم",
        f: "haghú zu óowahulum",
        e: "",
        a: ""
      }, {
        p: "زهٔ یې ووهلم",
        f: "zu ye óowahulum",
        e: "",
        a: ""
      }]]}
    </Table>
    <h4 {...{
      "id": "transitive-verbs-when-the-object-is-you-or-us-or-me"
    }}>{`Transitive verbs when the object is “you” or “us” or “me”`}</h4>
    <p>{`When the object of past tense sentences is 1st person singular, the “reversal” (ergativity) of the past tense in Pashto can seem really jarring for learners. Practice the following sentences, using substitution, until it begins to feel natural to form sentences this way.`}</p>
    <p>{`Remember, with transitive past tense sentences:`}</p>
    <ul>
      <li parentName="ul">{`the object is inflected`}</li>
      <li parentName="ul">{`the subject is not inflected`}</li>
      <li parentName="ul">{`the verb agrees with the object`}</li>
    </ul>
    <Examples mdxType="Examples">
  {[{
        p: "هغۀ زۀ ووهلم.",
        f: "haghú zu óowahulum.",
        e: "He hit me.",
        a: "20e64"
      }, {
        p: "مُونږ موټر ووهلو.",
        f: "moonG moTúr óowahuloo.",
        e: "A car hit us.",
        a: "20e65"
      }, {
        p: "ما تۀ ووهلې.",
        f: "maa tu óowahule.",
        e: "I hit you.",
        a: "20e66"
      }, {
        p: "لارۍ تاسو ووهلئ؟",
        f: "laaRúy taaso óowahuley?",
        e: "Did a truck hit you?",
        a: "20e67"
      }, {
        p: "زۀ سړى وليدم.",
        f: "zu saRée óoleedum.",
        e: "A man saw me.",
        a: "20e68"
      }, {
        p: "هلکانو مُونږ وليدُو؟",
        f: "halukaano moonG óoleedoo?",
        e: "Did the boys see us?",
        a: "20e69"
      }, {
        p: "تاسو هغوى ونۀ ليدئ؟",
        f: "taaso haghwee oo-nu-leedey?",
        e: "Didn't they see you?",
        a: "20e70"
      }, {
        p: "مُونږ تاسو ونۀ ليدئ؟",
        f: "moonG taaso oo-nu-leedey?",
        e: "We didn't see you?",
        a: "20e71"
      }, {
        p: "تا زۀ وپېژندم؟",
        f: "taa zu óopejzandum?",
        e: "Did you recognize me?",
        a: "20e72"
      }, {
        p: "تۀ مې وپېژندې.",
        f: "tu me óopejzande.",
        e: "I recognized you.",
        a: "20e73"
      }, {
        p: "تاسو مُونږ وپېژندُو؟",
        f: "taaso moonG óopejzandoo?",
        e: "Did you recognize us?",
        a: "20e74"
      }, {
        p: "مُونږ تاسو وپېژندئ.",
        f: "moonG taaso óopejzandey.",
        e: "We knew you.",
        a: "20e75"
      }]}
    </Examples>
    <h4 {...{
      "id": "notes-3"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">{`Compare the present and past tense forms of the following sentences:`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        // check pronunciation... accent on second syllable of verb?
        // also plural cups
        p: "احمد پيالۍ وينځى.",
        f: "ahmad pyaalúy weendzee.",
        e: "Ahmad is washing the cups.",
        a: "20e76"
      }, {
        p: "احمد پیالۍ ووینځلې",
        f: "ahmad pyaalúy óoweendzule.",
        e: "Ahmad washed the cups.",
        a: "20e77"
      }, {
        p: "تهٔ ما وینې.",
        f: "tu maa wéene.",
        e: "You see me.",
        a: "20e78"
      }, {
        p: "تا زهٔ ولیدلم.",
        f: "taa zu óoleedulum.",
        e: "You saw me.",
        a: "20e79"
      }]}
    </Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`The subject in the past tense is always inflected. If the subject is already inflected because it is plural, then it takes the double inflection. For example, `}<Word w={{
          p: "ښځې",
          f: "xudze",
          e: "women",
          a: "xudze-f"
        }} mdxType="Word" /> changes to <Word w={{
          p: "ښځو",
          f: "xudzo",
          e: "",
          a: "xudzo-f"
        }} mdxType="Word" />{`.`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "ښځې سړے وليدو.",
        f: "xúdze saRáy óoleedo.",
        e: "The woman saw the man. (m.s.)",
        a: "20e80"
      }, {
        p: "ښځو سړى وليدل.",
        f: "xúdzo saRée óoleedul.",
        e: "The women saw the men. (m.pl.)",
        a: "20e81"
      }, {
        p: "سړی ښځه ولیدله.",
        f: "saRée xudza óoleedula.",
        e: "The man saw the woman. (f.s.)",
        a: "20e82"
      }, {
        p: "سړو ښځې ولېدلې / ولېدې.",
        f: "saRó xúdze óoleedule / óoleede.",
        e: "The men saw the women. (f.pl.)",
        a: "20e83"
      }]}
    </Examples>
    <p>{`Here’s another example with the word `}<Word w={{
        p: "سپے",
        f: "spay",
        e: "dog",
        a: ""
      }} mdxType="Word" />{`:`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "سپی زهٔ وخوړلم.",
        f: "spee zu óokhoRulum.",
        e: "A dog bit me.",
        a: ""
      }, {
        p: "سپو زهٔ وخوړلم.",
        f: "spo zu óokhoRulum.",
        e: "Dogs bit me.",
        a: ""
      }]}
    </Examples>
    <img src={barberJoke} className="full-width-img" />
    <AudioPiece a="20j1" mdxType="AudioPiece" />
    <h3 {...{
      "id": "transitive-verbs-that-take-a-word-w-p-و-f-w-e--a----prefix"
    }}>{`Transitive verbs that take a `}<Word w={{
        p: "و",
        f: "w",
        e: "",
        a: ""
      }} mdxType="Word" />{` prefix`}</h3>
    <p>{`When we make the perfective root from some verbs beginning in a vowel, the `}<Word w={{
        p: "و",
        f: "",
        e: "",
        a: ""
      }} mdxType="Word" />{` prefix makes a `}<strong parentName="p">{`w`}</strong>{` sound instead of a `}<strong parentName="p">{`oo`}</strong>{` sound.`}</p>
    <Table bordered verticalContent unset titleRow={["Verb", "Infinitive", "Perfective Root"]} mdxType="Table">
  {[[{
        e: "to buy"
      }, {
        p: "اخستل",
        f: "aakhistúl",
        e: "",
        a: "20v33"
      }, {
        p: "واخستل",
        f: "wáakhistul",
        e: "",
        a: "20v34"
      }], [{
        e: "to wear"
      }, {
        p: "اغوستل",
        f: "aaghostúl",
        e: "",
        a: "20v35"
      }, {
        p: "واغوستل",
        f: "wáaghostul",
        e: "",
        a: "20v36"
      }], [{
        e: "to hear"
      }, {
        p: "اورېدل",
        f: "aawredúl",
        e: "",
        a: "20v37"
      }, {
        p: "واورېدل",
        f: "wáawredul",
        e: "",
        a: "20v38"
      }]]}
    </Table>
    <h3 {...{
      "id": "transitive-verbs-that-dont-take-a-word-w-p-وـ-f-oo--e--a----prefix"
    }}>{`Transitive verbs that don’t take a `}<Word w={{
        p: "وـ",
        f: "oo-",
        e: "",
        a: ""
      }} mdxType="Word" />{` prefix`}</h3>
    <p>{`There are a number of “seperable” verbs like `}<em parentName="p">{`“ke-naastul”`}</em>{` and `}<em parentName="p">{`“ke-xodul”`}</em>{` where you can shove particles in the middle of them. For instance the `}<Word w={{
        p: "کې",
        f: "ke",
        e: "",
        a: "kenaastul-m"
      }} mdxType="Word" /> in <Word w={{
        p: "کېناستل",
        f: "kenaastul",
        e: "",
        a: ""
      }} mdxType="Word" /> is seperable, so we can say: <Word w={{
        p: "کې به نُو",
        f: "ke-ba-noo",
        e: "We'll sit",
        a: ""
      }} mdxType="Word" />{`.`}</p>
    <p>{`These kinds of verbs can never take a `}<Word w={{
        p: "و",
        f: "oo",
        e: "",
        a: "oo-sound"
      }} mdxType="Word" />{` prefix. However, `}<em>{`the accent still moves to the front`}</em>{` with the perfective root.`}</p>
    <Table bordered verticalContent unset titleRow={["Verb", "Infinitive", "Perfective Root"]} mdxType="Table">
  {[[{
        e: "to take (animate)"
      }, {
        p: "بوتلل",
        f: "botlúl",
        e: "",
        a: "20v39"
      }, {
        p: "بوتلل",
        f: "bótlul",
        e: "",
        a: "20v40"
      }], [{
        e: "to bring (inanimate)"
      }, {
        p: "راوړل",
        f: "raawRúl",
        e: "",
        a: "20v41"
      }, {
        p: "راوړل",
        f: "ráawRul",
        e: "",
        a: "20v42"
      }], [{
        e: "to bring (animate)"
      }, {
        p: "راوستل",
        f: "raawustúl",
        e: "",
        a: "20v43"
      }, {
        p: "راوستل",
        f: "ráawustul",
        e: "",
        a: "20v44"
      }], [{
        e: "to put"
      }, {
        p: "کېښودل",
        f: "kexodúl",
        e: "",
        a: "20v45"
      }, {
        p: "کېښودل",
        f: "kéxodul",
        e: "",
        a: "20v46"
      }]]}
    </Table>
    <p>{`And here is an example of a very irregular verb, where the perfective root is totally different:`}</p>
    <Table bordered verticalContent unset titleRow={["Verb", "Infinitive", "Perfective Root"]} mdxType="Table">
  {[[{
        e: "to take (inanimate)"
      }, {
        p: "وړل",
        f: "oRúl",
        e: "",
        a: "20v47"
      }, {
        p: "یوړل",
        f: "yóRul",
        e: "",
        a: ""
      }]]}
    </Table>
    <h4 {...{
      "id": "some-sentences"
    }}>{`Some sentences`}</h4>
    <Examples mdxType="Examples">
  {[{
        p: "تا مڼې چرته کېښودلې؟",
        f: "taa maNe chărta kéxodule?",
        e: "Where did you put the apples? (f.pl.)",
        a: "20e84"
      }, {
        p: "ما په مېز باندې کېښودلې.",
        f: "maa pu mez baande kéxodule.",
        e: "I put them on the table.",
        a: "20e85"
      }, {
        p: "هغهٔ مُونږ خپل کور ته بوتلُو.",
        f: "haghu moonG khpul kor ta bótloo.",
        e: "He took us to his own home.",
        a: "20e86"
      }]}
    </Examples>
    <p>{`👆 Make more sentences with each of these verbs. Remember, to make a simple past sentence you use the `}<em parentName="p">{`perfective root`}</em>{` of the verb.`}</p>
    <PersistentBlank store="20-more-verbs" rows={11} mdxType="PersistentBlank" />
    <h4 {...{
      "id": "a-note-on-the-following-comprehension-drills"
    }}>{`A note on the following comprehension drills`}</h4>
    <p>{`👆 In this kind of drill your language helper gives a series of instructions on a topic, and demonstrates the activities. For example: “Put the onion on the board. Open the drawer, and take out the knife. Slice the onion.” Mimic the actions a couple of times and then obay the instructions without your helper doing the actions. Then have him or her give the instructions at random. For some activities you can use real props and for others do it in mime.`}</p>
    <p>{`You might find it helpful to record the drill on the first time through, and at a later time do the drill 'live’.`}</p>
    <p>{`The following is the first of several comprehension drills using these instructions. Learn this one by mime.`}</p>
    <h2 {...{
      "id": "comprehension-drill-cooking"
    }}>{`Comprehension drill: Cooking`}</h2>
    <Examples mdxType="Examples">
  {[{
        p: "بازار ته لاړ شه.",
        f: "baazáar ta laaR sha.",
        e: "Go to the market.",
        a: "20c1"
      }, {
        p: "يو کيلو قيمه راؤړه!",
        f: "yo keelo qeemá ráawRa!",
        e: "Bring one kilo of mince meat!",
        a: "20c2"
      }, {
        p: "ورسره يو کيلو پياز او نيم کيلو ټماټر هم واخله!",
        f: "wărsara yo keelo pyaaz aw neem keelo TamaaTúr hum waakhla!",
        e: "Also buy one kilo of onions and half a kilo of tomatoes!",
        a: "20c3"
      }, {
        p: "بيا پياز ټوټې ټوټې که!",
        f: "byaa pyaaz ToTé ToTé ka!",
        e: "Then chop the onions into pieces!",
        a: "20c4"
      }, {
        p: "ټماټر هم صفا که.",
        f: "TamaaTúr hum safáa ka.",
        e: "Also clean the tomatoes.",
        a: "20c5"
      }, {
        p: "بيا غوښه، اګۍ، پياز او ټماټر ورسره ګډ که.",
        f: "byaa ghwaxa, agúy, pyaaz aw TamaaTúr wărsara guD ka.",
        e: "Then mix the meat with an egg, onions and tomatoes.",
        a: "20c6"
      }, {
        p: "بيا يې په غوړو کښې ښۀ سرۀ که!",
        f: "byaa ye pu ghwaRo ke xu sru ka!",
        e: "Then fry it well in oil!",
        a: "20c7"
      }, {
        p: "بيا به يې مُونږ دولس بجې خورُو.",
        f: "byaa ba ye moonG dólus baje khoróo.",
        e: "Then we will eat it about twelve o'clock.",
        a: "20c8"
      }]}
    </Examples>
    <h2 {...{
      "id": "memorising-moment"
    }}>{`Memorising moment`}</h2>
    <h4 {...{
      "id": "mataloona"
    }}>{`mataloona`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[20].slice(0, 3)}</Examples>
    <h4 {...{
      "id": "rahman-baba"
    }}>{`Rahman Baba`}</h4>
    <Examples memorizingMoment mdxType="Examples">{[memorizingMoments[20][3]]}</Examples>
    <h2 {...{
      "id": "exercises-for-lesson-twenty"
    }}>{`Exercises for Lesson Twenty`}</h2>
    <ol>
      <li parentName="ol">{`Listen to your helper read the following story and see how much of it you understand. Work on it until you can tell it. Concentrate on the past tense verb endings.`}</li>
    </ol>
    <Dialogue audio="20story" mdxType="Dialogue">
  {[{
        p: "يو ځل د تازۀ ګُل درې زامن پټى ته لاړل.",
        a: "20d1"
      }, {
        p: "هلته يې د زيارت ګُل د زوئ سره خبرې ورانې شوې",
        a: "20d2"
      }, {
        p: "او دوئ يې ښۀ ووهل.",
        a: "20d3"
      }, {
        p: "دوئ کور ته په ژړا راغلل",
        a: "20d4"
      }, {
        p: "او پلار ته يې ووئيل چې مُونږ د زيارت ګُل زوئ ووهلُو.",
        a: "20d5"
      }, {
        p: "پلار يې حېران شو چې دا ولې او څنګه؟",
        a: "20d6"
      }, {
        p: "هغوى ووئيل چې مُونږ بِلکل يواځې وُو.",
        a: "20d7"
      }]}
    </Dialogue>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <p parentName="li">{`Take one verb at a time from the list of transitive verbs forms in the simple past tense. Have your language helper supply masculine singular, feminine singular, masculine plural and feminine plural tenses, and also the subjects and objects that are normally used with each verb. Make sentences accordingly. Try not to use any English in these drills.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Make a recording putting the verb through its paces with the different subjects and objects, making sure that your helper stresses the endings. Say the sentence in English, then have the helper say the sentence in Pukhto. Progress down the verb charts in the Lesson one verb at a time.`}</p>
      </li>
    </ol>
    <img src={gateway} />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-twenty-make-your-own-texts",
    level: 2,
    title: "Dialogue twenty: Make your own texts",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation drill",
    children: [
        
      ]
  },
{
    id: "transitive-verbs-in-the-simple-past-tense",
    level: 2,
    title: "Transitive verbs in the simple past tense",
    children: [
        {
              id: "transitive-verbs-taking-word-w-p-و-f--oo---w-e--a-oo-sound--",
              level: 3,
              title: <React.Fragment>{`Transitive verbs taking `}<Word w={{ p: "و", f: "-oo / -w", e: "", a: "oo-sound" }} /></React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "transitive-verbs-that-take-a-word-w-p-و-f-w-e--a----prefix",
              level: 3,
              title: <React.Fragment>{`Transitive verbs that take a `}<Word w={{ p: "و", f: "w", e: "", a: "" }} />{` prefix`}</React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "transitive-verbs-that-dont-take-a-word-w-p-وـ-f-oo--e--a----prefix",
              level: 3,
              title: <React.Fragment>{`Transitive verbs that don’t take a `}<Word w={{ p: "وـ", f: "oo-", e: "", a: "" }} />{` prefix`}</React.Fragment>,
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "comprehension-drill-cooking",
    level: 2,
    title: "Comprehension drill: Cooking",
    children: [
        
      ]
  },
{
    id: "memorising-moment",
    level: 2,
    title: "Memorising moment",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-twenty",
    level: 2,
    title: "Exercises for Lesson Twenty",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Twenty"
}

