import React, { useState } from "react";
import standardizeF from "../lib/standardize-f";
import Media from "react-media";
import classNames from "classnames";
import PersistentBlank from "./persistent-blank";
import { handleAudioPlay } from "../lib/play-audio";
import { useDKeyDown } from "../lib/useDKeyDown";
// import useInView from "../lib/useInView";

const Examples = ({
  children,
  hideEnglish,
  memorizingMoment,
  twoColumns,
  substitutions,
}: {
  children: IExample[];
  hideEnglish: boolean;
  memorizingMoment: boolean;
  twoColumns: boolean;
  substitutions: boolean;
}) => {
  const [englishHidden, setEnglishHidden] = useState(!!hideEnglish);
  const dKeyDown = useDKeyDown();
  if (memorizingMoment) {
    return (
      <div>
        {children.map((example, i) => (
          <div
            key={i}
            onClick={handleAudioPlay(example, dKeyDown)}
            style={{ textAlign: "center", marginBottom: "2rem", clear: "both" }}
          >
            {!(Array.isArray(example.p) && !React.isValidElement(example.p)) ? (
              <div dir="rtl">{example.p}</div>
            ) : (
              <div style={{ marginBottom: "0.5rem" }}>
                {example.p.map((pLine, i) => (
                  <div key={i} dir="rtl">
                    {pLine}
                  </div>
                ))}
              </div>
            )}
            {!(Array.isArray(example.f) && !React.isValidElement(example.f)) ? (
              <div>{standardizeF(example.f)}</div>
            ) : (
              <div style={{ marginBottom: "0.5rem" }}>
                {example.f.map((fLine, i) => (
                  <div key={i}>{standardizeF(fLine)}</div>
                ))}
              </div>
            )}
            {!englishHidden &&
              (!(
                Array.isArray(example.e) && !React.isValidElement(example.f)
              ) ? (
                <div>{example.e}</div>
              ) : (
                <div style={{ marginBottom: "0.5rem" }}>
                  {example.e.map((eLine, i) => (
                    <div key={i}>{eLine}</div>
                  ))}
                </div>
              ))}
            {hideEnglish && (
              <button
                style={{ marginTop: "1rem" }}
                onClick={() => setEnglishHidden(!englishHidden)}
              >
                {englishHidden ? "Show" : "Hide"} English
              </button>
            )}
          </div>
        ))}
      </div>
    );
  }
  if (substitutions) {
    return (
      <div
        style={{
          marginBottom: "2rem",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {children.map((sub, i) => (
          <div
            key={i}
            onClick={handleAudioPlay(sub, dKeyDown)}
            style={{
              textAlign: "center",
              padding: "0.75rem 1.25rem",
              border: "1px solid black",
              margin: "0.5rem",
            }}
          >
            <div dir="rtl">{sub.p}</div>
            <div>{standardizeF(sub.f)}</div>
            {sub.e && <div>{sub.e}</div>}
          </div>
        ))}
      </div>
    );
  }
  function drawTable(content: IExample[], includeRef?: boolean): any {
    return (
      <table>
        <tbody>
          {content.map((example, i) => (
            <Media
              key={i}
              queries={{
                small: "(max-width: 599px)",
              }}
            >
              {(matches) => {
                if (matches.small && twoColumns) {
                  return (
                    <tr
                      key={example.f}
                      onClick={handleAudioPlay(example, dKeyDown)}
                      className={classNames({ mb: example.mb })}
                    >
                      <td colSpan={example.e && example.f ? 3 : 2}>
                        {example.f && (
                          <div dir="ltr">{standardizeF(example.f)}</div>
                        )}
                        {example.e && <div dir="ltr">{example.e}</div>}
                        <div dir="ltr">{example.p}</div>
                        {example.blank && (
                          <PersistentBlank store={example.store || ""} />
                        )}
                      </td>
                    </tr>
                  );
                }
                return (
                  <tr
                    key={example.f}
                    onClick={handleAudioPlay(example, dKeyDown)}
                    className={classNames({ mb: example.mb })}
                  >
                    {example.f && <td dir="ltr">{standardizeF(example.f)}</td>}
                    {!hideEnglish && example.e && (
                      <td dir="ltr">{example.e}</td>
                    )}
                    <td dir="rtl">{example.p}</td>
                    {example.blank && (
                      <PersistentBlank store={example.store || ""} />
                    )}
                  </tr>
                );
              }}
            </Media>
          ))}
        </tbody>
      </table>
    );
  }
  if (twoColumns) {
    const halfwayThrough = Math.floor(children.length / 2);
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {drawTable(children.slice(0, halfwayThrough))}
        <div style={{ margin: "0 1.5rem" }}></div>
        {drawTable(children.slice(halfwayThrough, children.length))}
      </div>
    );
  }
  return drawTable(children, true);
};

export default Examples;
