
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"16 min read","minutes":15.115,"time":906900,"words":3023}
/* @jsxRuntime classic */
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import threeSittingTalk from "../assets/images/three-sitting-talk.PNG";
import sheep from "../assets/images/sheep.PNG";
import bodyPartsMan from "../assets/images/body-parts-man.png";
import Media from "react-media";
import money from "../assets/images/money.png";
import mosqueInCity from "../assets/images/mosque-in-city.png";
import memorizingMoments from "./memorizing-moments";
import examples from "./lesson-four-examples.js";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-four-a-learners-role"
    }}>{`Dialogue four: A learner’s role`}</h2>
    <Dialogue audio="dialogue-4" mdxType="Dialogue">
  {[{
        s: "L",
        p: "زهٔ پښتو زده کول غواړم.",
        f: "zu puxto zda kawul ghwaaRum.",
        e: "I want to learn Pukhto.",
        a: "d4-1"
      }, {
        s: "H",
        p: "ډېر ښهٔ، یار.",
        f: "Der xu, yaar.",
        e: "Very good, friend.",
        a: "d4-2"
      }, {
        s: "L",
        p: "اصل کښې زما پښتانۀ ډېر خوښ دى.",
        f: "ásul ke zmaa puxtaanú Der khwax dee.",
        e: "Actually I like Pukhtuns a lot.",
        a: "d4-3"
      }, {
        s: "L",
        p: "خو پښتو ډېره ګرانه ده.",
        f: "kho puxto Dera gráana da.",
        e: "But Pukhto is very difficult.",
        a: "d4-4"
      }, {
        s: "L",
        p: "زۀ روزانه يو دوه نوى ټکى زده کوم.",
        f: "zu rozaana yo dwa nuwee Tikee zda kawum.",
        e: "I learn a few new words every day.",
        a: "d4-5"
      }, {
        s: "H",
        p: "ښه! دانه دانه چې يو ځائ شى غر شى.",
        f: "xa! daana daana che yo dzaay shee ghur shee.",
        e: "Good! A speck on a speck makes a mountain.",
        a: "d4-6"
      }, {
        s: "L",
        p: "بالکل! زهٔ دلته بیا راتلل غواړم.",
        f: "bilkUl! zu dălta byaa raatlul ghwaaRum.",
        e: "Exactly! I want to come back here again.",
        a: "d4-7"
      }, {
        s: "H",
        p: "هر کله راشه. په مخه دې ښه!",
        f: "hăr kăla raasha. pu múkha de xu!",
        e: "Come anytime. May you face good!",
        a: "d4-8"
      }, {
        s: "L",
        p: "د خُدای په آمان!",
        f: "du khUdaay pu aamáan!",
        e: "May you be found in God's peace!",
        a: "d4-9"
      }]}
    </Dialogue>
    <img src={threeSittingTalk} />
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation Drill`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Contrasting `}<Word w={{
            p: "ټ",
            f: "T",
            a: "tt-sound"
          }} mdxType="Word" /> and <Word w={{
            p: "ت",
            f: "t",
            a: "t-sound"
          }} mdxType="Word" /></p>
        <Examples mdxType="Examples">
  {[{
            p: "دا ټال دے.",
            f: "daa Taal day.",
            e: "This is a swing.",
            a: "daa-ttaal"
          }, {
            p: "دا تار دے.",
            f: "daa taar day.",
            e: "This is a thread.",
            a: "daa-taar"
          }, {
            p: "دغه ټول شو.",
            f: "dágha Tol sho.",
            e: "That has rolled-up.",
            a: "dagha-ttol"
          }, {
            p: "دغه تور دے.",
            f: "dágha tor day.",
            e: "That is black.",
            a: "dagha-tor"
          }, {
            p: "ټوپ",
            f: "Top",
            e: "jump",
            a: "ttop"
          }, {
            p: "توپ",
            f: "top",
            e: "cannon",
            a: "top"
          }]}
        </Examples>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Contrasting `}<Word w={{
            p: "oo",
            f: "و",
            a: "oo-sound"
          }} mdxType="Word" /> and <Word w={{
            p: "o",
            f: "و",
            a: "o-sound"
          }} mdxType="Word" /></p>
        <Examples mdxType="Examples">
  {[{
            p: "دا تُوره ده.",
            f: "daa tóora da",
            e: "This is a sword",
            a: "daa-toora"
          }, {
            p: "دا توره ده.",
            f: "daa tóra da.",
            e: "This is black.",
            a: "daa-tora"
          }, {
            p: "هغه زما لُور ده.",
            f: "haghá zmaa loor da.",
            e: "That is my daughter.",
            a: "hagha-zmaa-loor"
          }, {
            p: "هغه زما لور دے.",
            f: "hagha zmaa lor day.",
            e: "That is my sickle.",
            a: "hagha-zmaa-lor"
          }, {
            p: "دا تُوت دے.",
            f: "daa toot day.",
            e: "this is a mulberry.",
            a: "daa-toot"
          }, {
            p: "دا تود دے.",
            f: "daa tod day.",
            e: "This is warm.",
            a: "daa-tod"
          }]}
        </Examples>
      </li>
    </ol>
    <h2 {...{
      "id": "plural-sentences"
    }}>{`Plural sentences`}</h2>
    <Table bordered wide titleRow={["Demonstrative adjective", "Noun", "Adjective", "Verb"]} mdxType="Table">
  {[[{
        f: "daa"
      }, {
        f: "kitaabóona"
      }, {
        f: "xu"
      }, {
        f: "dee."
      }, {
        span: 2,
        p: "دا کتابونه ښهٔ دی.",
        e: "These books are good.",
        a: "ps4-1"
      }], [{
        f: "daa"
      }, {
        f: "aaloogáan"
      }, {
        f: "kharáab"
      }, {
        f: "dee."
      }, {
        span: 2,
        p: "دا آلوګان خراب دی.",
        e: "These potatoes are bad.",
        a: "ps4-2"
      }], [{
        f: "dágha"
      }, {
        f: "qalumóona"
      }, {
        f: "narée"
      }, {
        f: "dee"
      }, {
        span: 2,
        p: "دغه قلمُونه نری دی.",
        f: "dágha qalumóona naree dee.",
        a: "ps4-3"
      }], [{
        f: "dágha"
      }, {
        f: "mungukáan"
      }, {
        f: "shaytaanáan"
      }, {
        f: "dee."
      }, {
        span: 2,
        p: "دغه منږکان شېطانان دی.",
        e: "These mice are bothersome.",
        a: "ps4-4"
      }], [{
        f: "hágha"
      }, {
        f: "waróona"
      }, {
        f: "speen"
      }, {
        f: "dee."
      }, {
        span: 2,
        p: "هغه ورونه سپین دی.",
        e: "Those doors are white.",
        a: "ps4-5"
      }], [{
        f: "hágha"
      }, {
        f: "ooxáan"
      }, {
        f: "ghaT"
      }, {
        f: "dee."
      }, {
        span: 2,
        p: "هغه اُوښان غټ دی.",
        e: "Those camels are big.",
        a: "ps4-6"
      }]]}
    </Table>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>
  <Word w={{
        p: "شېطان",
        f: "shetáan",
        e: "the devil",
        a: "shetaan"
      }} mdxType="Word" /> is
  also an adjective meaning 'naughty' or 'troublesome'.
    </p>
    <h2 {...{
      "id": "making-masculine-nouns-plural"
    }}>{`Making masculine nouns plural`}</h2>
    <h4 {...{
      "id": "inanimate-nouns-ending-in-a-consonant-are-inflected-to-word-w-p-ـونه-f---oona-a-oona--"
    }}>{`Inanimate nouns ending in a consonant are inflected to `}<Word w={{
        p: "ـونه",
        f: "--oona",
        a: "oona"
      }} mdxType="Word" /></h4>
    <Examples mdxType="Examples">{examples.slice(0, 2)}</Examples>
    <h4 {...{
      "id": "word-w-p-ے-f-ay-a-ay-sound---endings-become-word-w-p-ی-f-ee-a-ee-sound--"
    }}><Word w={{
        p: "ے",
        f: "ay",
        a: "ay-sound"
      }} mdxType="Word" /> endings become <Word w={{
        p: "ی",
        f: "ee",
        a: "ee-sound"
      }} mdxType="Word" /></h4>
    <Examples mdxType="Examples">{examples.slice(2, 4)}</Examples>
    <h4 {...{
      "id": "animate-nouns-ending-in-a-consonant-are-changed-to-word-w-p-ان-f-aan-a-aan---"
    }}>{`Animate nouns ending in a consonant are changed to `}<Word w={{
        p: "ان",
        f: "aan",
        a: "aan "
      }} mdxType="Word" /></h4>
    <Examples mdxType="Examples">{examples.slice(4, 8)}</Examples>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p>{`Notice how the adjective `}<Word w={{
        p: "لنډ",
        f: "lunD",
        a: "lundd"
      }} mdxType="Word" /> rhymes with the verb by adding <Word w={{
        p: "ی",
        f: "ee",
        a: "ee-sound"
      }} mdxType="Word" /> to become <Word w={{
        p: "لنډی",
        f: "lunDee",
        a: "lunddee"
      }} mdxType="Word" />{`. This type of agreement is like Cockney rhyming slang and is best learned by mimicry rather than by following rules.`}</p>
    <h4 {...{
      "id": "inanimate-nouns-ending-in-word-w-p-و-f-oo-a-oo-sound---are-changed-to-word-w--p-ګان-f-gaan-a----or-word-w-p-وان-f-waan-a---"
    }}>{`Inanimate nouns ending in `}<Word w={{
        p: "و",
        f: "oo",
        a: "oo-sound"
      }} mdxType="Word" /> are changed to <Word w={{
        p: "ګان",
        f: "gaan",
        a: ""
      }} mdxType="Word" /> or <Word w={{
        p: "وان",
        f: "waan",
        a: ""
      }} mdxType="Word" /></h4>
    <Examples mdxType="Examples">{examples.slice(8, 12)}</Examples>
    <h4 {...{
      "id": "an-exercise-in-making-masculine-nouns-plural"
    }}>{`An exercise in making masculine nouns plural`}</h4>
    <p>{`👆 Have your helper make simple singular sentences with the following nouns. Then try to make them plural.`}</p>
    <Examples twoColumns mdxType="Examples">
  {[{
        p: "ماشُوم",
        f: "maashóom",
        e: "child",
        a: "maashoom"
      }, {
        p: "سړے",
        f: "saRay",
        e: "man",
        a: "sarray"
      }, {
        p: "لښته",
        f: "lúxta",
        e: "stick",
        a: "luxta"
      }, {
        p: "ګُل",
        f: "gUl",
        e: "flower",
        a: "guul"
      }, {
        p: "بِستره",
        f: "bistúra",
        e: "mattress",
        a: "bistura"
      }, {
        p: "ګاډے",
        f: "gáaDay",
        e: "vehicle",
        a: "gaadday"
      }, {
        p: "مچ",
        f: "much",
        e: "fly",
        a: "much"
      }, {
        p: "ماشے",
        f: "máashay",
        e: "mosquito",
        a: "maashay"
      }, {
        p: "کلے",
        f: "kúlay",
        e: "village",
        a: "kulay"
      }, {
        p: "باغ",
        f: "baagh",
        e: "garden",
        a: "baagh"
      }, {
        p: "قلم",
        f: "qalúm",
        e: "pen",
        a: "qalum"
      }, {
        p: "مالی",
        f: "maalée",
        e: "gardener",
        a: "maalee"
      }, {
        p: "ګډه",
        f: "gúDa",
        e: "sheep",
        a: "gudda"
      }]}
    </Examples>
    <img src={sheep} />
    <h2 {...{
      "id": "subject-markers-for-verbs"
    }}>{`Subject markers for verbs`}</h2>
    <Table bordered wide titleRow={["Person", "Singular", "Person", "Plural"]} mdxType="Table">
  {[[{
        e: "I"
      }, {
        f: "--im",
        p: "ـم"
      }, {
        e: "we"
      }, {
        f: "--oo",
        p: "ـو",
        a: "oo-sound"
      }], [{
        e: "you"
      }, {
        f: "--e",
        p: "ـې",
        a: "e-sound"
      }, {
        e: "you (plural & for respect)"
      }, {
        p: "ـئ",
        f: "--ey",
        a: "ey-sound"
      }], [{
        e: "he, she, it"
      }, {
        f: "--ee",
        p: "ـي",
        a: "ee-sound"
      }, {
        e: "they"
      }, {
        f: "--ee",
        p: "ـي",
        a: "ee-sound"
      }]]}
    </Table>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The second person singular pronoun `}<Word w={{
            p: "تهٔ",
            f: "tu",
            a: "tu"
          }} mdxType="Word" /> and the second person singular subject marker <Word w={{
            p: "ې",
            f: "e",
            a: "e-sound"
          }} mdxType="Word" /> are used in informal conversation. <Word w={{
            p: "تهٔ",
            f: "tu",
            a: "tu"
          }} mdxType="Word" />{` is the normal pronoun used to speak to family, friends and children. It is also used in everyday conversation outside the house.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The second person plural form `}<Word w={{
            p: "تاسو",
            f: "taaso",
            a: "taaso"
          }} mdxType="Word" /> and the second person plural subject marker <Word w={{
            p: "ئ",
            f: "ey",
            a: "ey-sound"
          }} mdxType="Word" /> are used to address people with extra politeness. For example, <Word w={{
            p: "تاسو",
            f: "taaso",
            a: "taaso"
          }} mdxType="Word" /> might be used when talking to a respected leader or elder. Note, although <Word w={{
            p: "تاسو",
            f: "taaso",
            a: "taaso"
          }} mdxType="Word" />{` is plural in form, it can be used to address one person. The nearest English equivalent is the royal 'we’.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "asking-questions"
    }}>{`Asking Questions`}</h2>
    <h4 {...{
      "id": "who-word-w-p-څوک-f-tsok-a-tsok--"
    }}>{`Who? `}<Word w={{
        p: "څوک؟",
        f: "tsok?",
        a: "tsok"
      }} mdxType="Word" /></h4>
    <Examples mdxType="Examples">{examples.slice(12, 15)}</Examples>
    <p>{`It is common to drop the subject out of the sentence in colloquial speech. For example you will often hear sentences like: `}<Word w={{
        p: "پښتُون یم",
        f: "puxtoon yim",
        e: "I am a Pukhtoon",
        a: "puxtoon-yim"
      }} mdxType="Word" />, rather that the more formal <Word w={{
        p: "زهٔ پښتُون یم.",
        f: "zu puxtóon yim",
        a: "zu-puxtoon-yim"
      }} mdxType="Word" />{`. Therefore, it becomes important to recognise and use the subject markers – the only clue as to who the speaker is talking about:`}</p>
    <h4 {...{
      "id": "how-many-how-much-word-w-p-څو-څومره-f-tso-tsoomra-a-tso-tsoomra--"
    }}>{`How many? How much? `}<Word w={{
        p: "څو؟، څومره؟",
        f: "tso?, tsoomra?",
        a: "tso-tsoomra"
      }} mdxType="Word" /></h4>
    <Examples mdxType="Examples">{examples.slice(15, 18)}</Examples>
    <h4 {...{
      "id": "with-whom-word-w-p-چا-سره-f-chaa-sara-a-chaa-sara--"
    }}>{`With whom? `}<Word w={{
        p: "چا سره؟",
        f: "chaa sara?",
        a: "chaa-sara"
      }} mdxType="Word" /></h4>
    <Examples mdxType="Examples">{examples.slice(18, 22)}</Examples>
    <h4 {...{
      "id": "where-word-w-p-چرته-f-chărta-a-charta--"
    }}>{`Where? `}<Word w={{
        p: "چرته؟",
        f: "chărta?",
        a: "charta"
      }} mdxType="Word" /></h4>
    <Examples mdxType="Examples">{[examples[22]]}</Examples>
    <h2 {...{
      "id": "postpositions"
    }}>{`Postpositions`}</h2>
    <p>{`The preposition `}<Word w={{
        p: "په",
        f: "pu",
        e: "in",
        a: "pu"
      }} mdxType="Word" /> is often followed by a noun and another preposition to form a 'split' prepositional phrase, which is translated as a single preposition in English. The sentences below illustrate how a noun can be sandwiched between two prepositions. The preposition which follows the noun is called a postposition. The words <Word w={{
        p: "باندې",
        f: "baande",
        e: "above",
        a: "baande"
      }} mdxType="Word" /> and <Word w={{
        p: "لاندې",
        f: "laande",
        e: "below",
        a: "laande"
      }} mdxType="Word" />{` are examples of postpositions in Pukhto.`}</p>
    <h3 {...{
      "id": "in-word-w-p-په-___-کښې-f-pu-____-ke-a-pu-ke--"
    }}>{`“in” `}<Word w={{
        p: "په ___ کښې",
        f: "pu ____ ke",
        a: "pu-ke"
      }} mdxType="Word" /></h3>
    <Examples mdxType="Examples">{examples.slice(23, 28)}</Examples>
    <h3 {...{
      "id": "on-word-w-p-په-____-باندې-f-pu-_____-baande-a-pu-baande--"
    }}>{`“on” `}<Word w={{
        p: "په ____ باندې",
        f: "pu _____ baande",
        a: "pu-baande"
      }} mdxType="Word" /></h3>
    <Examples mdxType="Examples">{examples.slice(28, 32)}</Examples>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>{`Again, colloquial speech is often shortened. The common expression `}<Word w={{
        p: "چائې باندې دی",
        f: "chaay baande dee",
        a: "chaay-baande-dee"
      }} mdxType="Word" /> meaning 'The tea is on', may be a contraction of <Word w={{
        p: "چائې په چوله باندې دی",
        f: "chaar pu chola baande dee",
        e: "The tea is on the stove",
        a: "chaay-pu-chola-baande-dee"
      }} mdxType="Word" />{`.`}</p>
    <h3 {...{
      "id": "under-word-w-p-د-____-لاندې-f-du-____-laande-a-du-laande--"
    }}>{`“under” `}<Word w={{
        p: "د ____ لاندې",
        f: "du ____ laande",
        a: "du-laande"
      }} mdxType="Word" /></h3>
    <Examples mdxType="Examples">{examples.slice(32, 36)}</Examples>
    <p>{`👆 With the help of your teacher, use the above drill to ask the location of different objects that are at hand. Remember to make use of objects you already know the name of so that you are not learning names and postpositions at the same time.`}</p>
    <h2 {...{
      "id": "some-parts-of-the-body"
    }}>{`Some parts of the body`}</h2>
    <p>{`👆 Ask your helper to drill you in the names for these parts of the body. Respond to the words by touching the appropriate parts.`}</p>
    <div style={{
      position: "relative",
      textAlign: "center"
    }}>
  <Media queries={{
        small: "(max-width: 599px)",
        large: "(min-width: 600px)"
      }} mdxType="Media">
    {matches => <>
        <img src={bodyPartsMan} style={{
            width: "100%"
          }} />
        <div style={{
            position: "absolute",
            top: "5%",
            right: matches.large ? "18%" : "5%"
          }}>
          <Word w={{
              p: "سر",
              f: "săr",
              e: "head",
              a: "sar"
            }} mdxType="Word" />
        </div>
        <div style={{
            position: "absolute",
            top: "10%",
            left: matches.large ? "25%" : "10%"
          }}>
          <Word w={{
              p: "مخ",
              f: "mukh",
              e: "face",
              a: "mukh"
            }} mdxType="Word" />
        </div>
        <div style={{
            position: "absolute",
            top: "20%",
            right: matches.large ? "15%" : "0%"
          }}>
          <Word w={{
              p: "غوږ",
              f: "ghwuG",
              e: "ear",
              a: "ghwugg"
            }} mdxType="Word" />
        </div>
        <div style={{
            position: "absolute",
            top: matches.large ? "25%" : "28%",
            left: matches.large ? "15%" : "5%"
          }}>
          <Word w={{
              p: "مرۍ",
              f: "marúy",
              e: "throat",
              a: "maruy"
            }} mdxType="Word" />
        </div>
        <div style={{
            position: "absolute",
            bottom: "27%",
            left: matches.large ? "10%" : "0%"
          }}>
          <Word w={{
              p: "مړوند",
              f: "maRwánd",
              e: "wrist",
              a: "marrwand"
            }} mdxType="Word" />
        </div>
        <div style={{
            position: "absolute",
            bottom: "18%",
            right: matches.large ? "15%" : "0%"
          }}>
          <Word w={{
              p: "لاس",
              f: "laas",
              e: "hand",
              a: "laas"
            }} mdxType="Word" />
        </div>
      </>}
  </Media>
    </div>
    <h2 {...{
      "id": "working-with-money"
    }}>{`Working with money`}</h2>
    <p>{`👆 Collect a variety of notes and coins and practice using it with your helper, by taking turns asking and giving various amounts.`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "شپږ روپۍ راکه!",
        f: "shpuG roopúy ráaka!",
        e: "Give me six rupees!",
        a: "ps4-p27"
      }]}
    </Examples>
    <img src={money} style={{
      maxWidth: "8rem"
    }} />
    <h2 {...{
      "id": "memorising-moment"
    }}>{`Memorising Moment`}</h2>
    <h4 {...{
      "id": "word-w-p-متلونه-f-matulóona-a-matuloona--"
    }}><Word w={{
        p: "متلونه",
        f: "matulóona",
        a: "matuloona"
      }} mdxType="Word" /></h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[4]}</Examples>
    <h2 {...{
      "id": "exercises-for-lesson-four"
    }}>{`Exercises for lesson Four`}</h2>
    <ol>
      <li parentName="ol">{`Using the question `}<Word w={{
          p: "دا څه شے دے؟",
          f: "daa tsu shay day?",
          e: "What is this?",
          a: "daa-tsu-shay-day"
        }} mdxType="Word" />{`, point and reply to the following parts of the body:`}</li>
    </ol>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "سر",
        f: "săr",
        a: "sar"
      }, {
        p: "مړوند",
        f: "maRwánd",
        a: "marrwand"
      }, {
        p: "مخ",
        f: "mukh",
        a: "mukh"
      }, {
        p: "غوږ",
        f: "ghwuG",
        a: "ghwugg"
      }, {
        p: "لاس",
        f: "laas",
        a: "laas"
      }, {
        p: "مرۍ",
        f: "marúy",
        a: "maruy"
      }]}
    </Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Make the following sentences plural:`}</li>
    </ol>
    <Quiz oneBigBlank mdxType="Quiz">
  {[{
        q: {
          p: "هغه خطرناک مار دے.",
          f: "hagha khatarnáak maar day."
        },
        answer: [{
          p: "هغوی خطرناک ماران دی.",
          f: "haghwee khatarnáak maaraan dee."
        }]
      }, {
        q: {
          p: "دا سُور کتاب دے.",
          f: "daa soor kitáab day."
        },
        answer: [{
          p: "دوی سرهٔ کتابونه دی.",
          f: "dwee sru kitaaboona dee."
        }]
      }, {
        q: {
          p: "تهٔ اُستاذ یې؟",
          f: "tu Ustáaz ye?"
        },
        answer: [{
          p: "تاسو استاذان یئ؟",
          f: "taaso Ustaazáan yey?"
        }]
      }]}
    </Quiz>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Fill in the correct plural suffix in each sentence.`}</li>
    </ol>
    <Quiz mdxType="Quiz">
  {[{
        beg: {
          p: "دا قلمـ",
          f: "daa qalúm"
        },
        end: {
          p: "دی.",
          f: "dee."
        },
        answer: [{
          p: "ونه",
          f: "oona"
        }]
      }, {
        beg: {
          p: "مونږ مېلمـ",
          f: "moonG melm"
        },
        end: {
          p: "یو.",
          f: "yoo."
        },
        answer: [{
          p: "انهٔ",
          f: "aanu"
        }]
      }, {
        beg: {
          p: "هغه ور",
          f: "haghá war"
        },
        end: {
          p: "دی.",
          f: "dee."
        },
        answer: [{
          p: "ونه",
          f: "oona"
        }]
      }, {
        beg: {
          p: "تاسو سستـ",
          f: "taaso sust"
        },
        end: {
          p: "یئ؟",
          f: "yey?"
        },
        answer: [{
          p: "ان",
          f: "aan"
        }]
      }, {
        beg: {
          p: "دا کتابـ",
          f: "daa kitaab"
        },
        end: {
          p: "دی.",
          f: "dee."
        },
        answer: [{
          p: "ونه",
          f: "oona"
        }]
      }, {
        beg: {
          p: "هغه اُوښـ",
          f: "hagha oox"
        },
        end: {
          p: "دی.",
          f: "dee."
        },
        answer: [{
          p: "ان",
          f: "aan"
        }]
      }, {
        beg: {
          p: "دا آلو",
          f: "daa aaloo"
        },
        end: {
          p: "مزېدار دی.",
          f: "muzedaar dee."
        },
        answer: [{
          p: "ګان",
          f: "gaan"
        }]
      }, {
        beg: {
          p: "هغوی مشهُور سړ",
          f: "haghwee mushahóor saR"
        },
        end: {
          p: "دی.",
          f: "dee."
        },
        answer: [{
          p: "ی",
          f: "ee"
        }]
      }]}
    </Quiz>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">
        <p parentName="li">{`Substitute the following pairs of words for the underlined words in the sentence:`}</p>
        <Examples mdxType="Examples">
  {[{
            p: "اُستاذان په سکول کښې دی.",
            f: "Ustaazáan pu skool ke dee.",
            a: ""
          }]}
        </Examples>
      </li>
    </ol>
    <Quiz oneBigBlank mdxType="Quiz">
  {[{
        q: {
          p: "هغوی / موټر",
          f: "haghwee / moTur"
        },
        answer: [{
          p: "هغوی په موټر کښې دی.",
          f: "haghwee pu moTur ke dee."
        }]
      }, {
        q: {
          p: "هلکان / کلاس",
          f: "halukáan / klaas"
        },
        answer: [{
          p: "هلکان په کلاس کښې دی.",
          f: "halukaan pu klaas ke dee."
        },,]
      }, {
        q: {
          p: "مُلا / جُماعت",
          f: "mUláa / jUmáat"
        },
        answer: [{
          p: "مُلا په جُماعت کښې دے.",
          f: "mUláa pu jUmaat ke day."
        }]
      }, {
        q: {
          p: "مالی / باغ",
          f: "maalee / baagh"
        },
        answer: [{
          p: "مالی په باغ کښې دے.",
          f: "maalee pu baagh ke day."
        }]
      }, {
        q: {
          p: "منږکان / کور",
          f: "munGukáan / kor"
        },
        answer: [{
          p: "منږکان په کور کښې دی.",
          f: "munGukaan pu kor ke dee."
        }]
      }]}
    </Quiz>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">
        <p parentName="li">{`Use the words listed below to transform the following sentence:`}</p>
        <Examples mdxType="Examples">
  {[{
            p: "هغه ډاکټر دے.",
            f: "haghá DakTúr day.",
            a: ""
          }]}
        </Examples>
      </li>
    </ol>
    <Quiz oneBigBlank mdxType="Quiz">
  {[{
        q: {
          p: "تهٔ",
          f: "tu"
        },
        answer: [{
          p: "ته ډاکټر یې.",
          f: "tu DakTúr ye."
        }]
      }, {
        q: {
          p: "هغوی",
          f: "haghwee"
        },
        answer: [{
          p: "هغوی ډاکټران دی.",
          f: "haghwee DakTuraan dee."
        }]
      }, {
        q: {
          p: "ستړی",
          f: "stuRee"
        },
        answer: [{
          p: "هغوی ستړی دی.",
          f: "haghwee stuRee dee."
        }, {
          p: "تاسو ستړی یئ.",
          f: "taaso stuRee yey."
        }, {
          p: "مونږ ستړی یو.",
          f: "moonG stuRee yoo."
        }]
      }, {
        q: {
          p: "مونږ",
          f: "moonG"
        },
        answer: [{
          p: "مونږ ستړی یو.",
          f: "moonG stuRee yoo."
        }]
      }, {
        q: {
          p: "تاسو",
          f: "taaso"
        },
        answer: [{
          p: "تاسو ډاکټران یئ.",
          f: "taaso DaakTuráan yey."
        }]
      }, {
        q: {
          p: "پښتانهٔ",
          f: "puxtaanú"
        },
        answer: [{
          p: "هغوی پښتانهٔ دی.",
          f: "haghwee puxtaanú dee."
        }, {
          p: "تاسو پښتانهٔ یئ.",
          f: "taaso puxtaanú yey."
        }, {
          p: "مونږ پښتانهٔ یو.",
          f: "moonG puxtaanú yoo."
        }]
      }, {
        q: {
          p: "مېلمانهٔ",
          f: "melmaanú"
        },
        answer: [{
          p: "هغوی مېلمانهٔ دی.",
          f: "haghwee melmaanú dee."
        }, {
          p: "تاسو مېلمانهٔ یئ.",
          f: "taaso melmaanú yey."
        }, {
          p: "مونږ مېلمانهٔ یو.",
          f: "moonG melmaanú yoo."
        }]
      }, {
        q: {
          p: "مسلمانان",
          f: "mUsalmaanáan"
        },
        answer: [{
          p: "هغوی مسلمانان دی.",
          f: "haghwee mUsalmaanáan dee."
        }, {
          p: "تاسو مسلمانان یئ.",
          f: "taaso mUsalmaanáan yey."
        }, {
          p: "مونږ مسلمانان یو.",
          f: "moonG mUsalmaanáan yoo."
        }]
      }, {
        q: {
          p: "زهٔ",
          f: "zu"
        },
        answer: [{
          p: "زهٔ ډاکټر یم.",
          f: "zu DakTúr yim."
        }]
      }, {
        q: {
          p: "هغه عیسائی دے.",
          f: "haghá eesaaee day."
        }
      }]}
    </Quiz>
    <img src={mosqueInCity} />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-four-a-learners-role",
    level: 2,
    title: "Dialogue four: A learner’s role",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation Drill",
    children: [
        
      ]
  },
{
    id: "plural-sentences",
    level: 2,
    title: "Plural sentences",
    children: [
        
      ]
  },
{
    id: "making-masculine-nouns-plural",
    level: 2,
    title: "Making masculine nouns plural",
    children: [
        
      ]
  },
{
    id: "subject-markers-for-verbs",
    level: 2,
    title: "Subject markers for verbs",
    children: [
        
      ]
  },
{
    id: "asking-questions",
    level: 2,
    title: "Asking Questions",
    children: [
        
      ]
  },
{
    id: "postpositions",
    level: 2,
    title: "Postpositions",
    children: [
        {
              id: "in-word-w-p-په-___-کښې-f-pu-____-ke-a-pu-ke--",
              level: 3,
              title: <React.Fragment>{`“in” `}<Word w={{ p: "په ___ کښې", f: "pu ____ ke", a: "pu-ke" }} /></React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "on-word-w-p-په-____-باندې-f-pu-_____-baande-a-pu-baande--",
              level: 3,
              title: <React.Fragment>{`“on” `}<Word w={{ p: "په ____ باندې", f: "pu _____ baande", a: "pu-baande" }} /></React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "under-word-w-p-د-____-لاندې-f-du-____-laande-a-du-laande--",
              level: 3,
              title: <React.Fragment>{`“under” `}<Word w={{ p: "د ____ لاندې", f: "du ____ laande", a: "du-laande" }} /></React.Fragment>,
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "some-parts-of-the-body",
    level: 2,
    title: "Some parts of the body",
    children: [
        
      ]
  },
{
    id: "working-with-money",
    level: 2,
    title: "Working with money",
    children: [
        
      ]
  },
{
    id: "memorising-moment",
    level: 2,
    title: "Memorising Moment",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-four",
    level: 2,
    title: "Exercises for lesson Four",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Four"
}

