
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"33 min read","minutes":32.04,"time":1922400,"words":6408}
/* @jsxRuntime classic */
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import memorizingMoments from "./memorizing-moments";
import deg from "../assets/images/deg.png";
import ttaanga from "../assets/images/ttaanga.png";
import dishes from "../assets/images/dishes.png";
import scale from "../assets/images/scale.png";
import ladyCarryingWater from "../assets/images/lady-carrying-water.png";
import teaAndCup from "../assets/images/tea-and-cup.png";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-sixteen-ladies-finding-out-about-cooking"
    }}>{`Dialogue sixteen: Ladies finding out about cooking`}</h2>
    <Dialogue audio="dialogue-16a" mdxType="Dialogue">
  {[{
        s: "L",
        p: ["ډېر ښُکلے پولاؤ دے. دا مو څنګه پوخ کړے دے؟", "ما ته يې طريقه وښايه."],
        f: "Der xkhÚlay polaaw day. daa mU tsínga pokh kúRay day?",
        e: ["This is very nice pulao. How did you (pl.) cook it?", "Teach me the method."],
        a: "16d1"
      }, {
        s: "H",
        p: "اول به په غوړو کښې ټماټر او پياز د ګرمې مصالحې سره سرۀ کې. بيا به پ ه کښې صفا شوې wورېژې واچوې. اوبۀ به په کښې واچوې، او بيا به يې وويشې. چې اوبۀ څۀ وخت وچې شى نو وريژې به تيارې وى.",
        f: "awál ba pu ghwaRó ke TamaaTúr aw piyaaz du gárme masaale sara sru ke. byaa ba pu ke safaa shiwe wrejze wáachawe. oobú ba pu ke wáachawe, aw byaa ba ye óoyashawe. che oobú tsu wakht ó oche shee no wrejze ba tayaare wee.",
        e: "First you will put tomatoes and onions in oil and fry with hot spices. Then you will put in the cleaned rice. You will then put in water and boil it. When the water is dry the rice will be ready.",
        a: "16d2"
      }, {
        s: "L",
        p: "کُومې مصالحې به په کښې اچوم؟",
        f: "kÚme masaale ba pu ke áchawum?",
        e: "Which spices do I put in?",
        a: "16d3"
      }, {
        s: "H",
        p: "بازار کښې چې کُومه تياره ګرمه مصالحه وى هغه په کښې واچوه.",
        f: "baazáar ke che kuma tayáara gárma masaalá wee haghá pu ke wáachawa.",
        e: "Put in whatever prepared hot spices are in the bazaar.",
        a: "16d4"
      }, {
        s: "L",
        p: "اوږه او ادرک تاسو کټوۍ کښې ولې استعمالوئ؟",
        f: "óoGa aw adrák taaso kuTwúy ke wăle istimaalawéy?",
        e: "Why are you using garlic and ginger in the curry?",
        a: "16d5"
      }, {
        s: "H",
        p: "دې سره کټوئ ښۀ کېږى او مزېداره کېږى.",
        f: "de sara kuTwúy xu kéGee aw mazedaara keGee.",
        e: "They make the curry good and tasty.",
        a: "16d6"
      }, {
        s: "L",
        p: "غوښه د مېښې زر پخېږى او کۀ د غوا؟",
        f: "ghwáxa du méxe zur pakhéGee aw ku du ghwaa?",
        e: "Does buffalo meat cook faster or cow's meat?",
        a: "16d7"
      }, {
        s: "H",
        p: "د مېښې او د غوا خبره نۀ ده، غوښه چې د ځوان څاروى وى نو زر پخېږى.",
        f: "du méxe aw du ghwaa khabúra nu da, ghwáxa che du dzwaan tsarwée wee no zur pakhéGee.",
        e: "It is not a matter of whether if it is buffalo or cow, if it is the meat of a young animal it will cook quickly.",
        a: "16d8"
      }]}
    </Dialogue>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>
  <Word w={{
        p: "څاروے",
        f: "tsaarwáy",
        e: "",
        a: ""
      }} mdxType="Word" /> (
  <Word w={{
        p: "څاروی",
        f: "tsaarwée",
        e: "inflected or plural form",
        a: "tsaarwee"
      }} mdxType="Word" />) is a general tern referring to domestic animals kept for meat or milk.
    </p>
    <img src={deg} />
    <h2 {...{
      "id": "men-eating-at-a-restaurant"
    }}>{`Men: Eating at a restaurant`}</h2>
    <Dialogue audio="dialogue-16b" mdxType="Dialogue">
  {[{
        s: "L",
        p: "هلکه، څۀ دې پاخۀ کړى دى؟",
        f: "halúka, tsu de paakhe kuRee dee?",
        e: "Lad, what have you cooked?",
        a: "16d9"
      }, {
        s: "H",
        p: "چڼه، غوښه، ساګ، پولاؤ، سبزى،چرګ او تيکې. څۀ راؤړم، صېب؟",
        f: "chaNá, ghwáxa, saag, poláaw , sabzée, churg aw téeke. tsu ráawRum, seb?",
        e: "Chick peas, meat, spinach, pulao, vegetables, chicken and tikkas. What shall I bring, sir?",
        a: "16d10"
      }, {
        s: "L",
        p: "دا شان وکه! يو کړائ تيکه راؤړه او ورسره سبزى هم راوړه!",
        f: "daa shaan óoka! yo kaRáay téeka ráawRa aw wăr sara sabzée hum ráawRa!",
        e: "Do like this, bring a karai tikka and with it bring vegetables!",
        a: "16d11"
      }, {
        s: "L",
        p: "سلاد او چکه به مِلاؤ شى؟",
        f: "salaad aw chaka ba milaaw shee?",
        e: <>
          Do you have salad and <em>chaka</em> [a yoghurt sauce]?
        </>,
        a: "16d12"
      }, {
        s: "H",
        p: "بِلکل صېب، بې غمه شئ، هر څۀ به ملاؤ شى.",
        f: "bilkÚl seb, be ghúma shey, hăr tsu ba miláaw shee.",
        e: "Absolutely sir, don't worry everything will be available.",
        a: "16d13"
      }]}
    </Dialogue>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <h4 {...{
      "id": "during-the-meal"
    }}>{`During the meal`}</h4>
    <Dialogue audio="dialogue-16c" mdxType="Dialogue">
  {[{
        s: "L",
        p: "راشه خبره واوره! دوه روټۍ ګرمې ګرمې راوړه!",
        f: "raasha khabúra wáawra! dwa roTúy garme garme ráawRa!",
        e: "Come here, listen! Bring two hot, hot breads!",
        a: "16d14"
      }, {
        s: "H",
        p: "دا واخلئ ګرمې روټۍ. نور خو جى څۀ نۀ دى پکار؟",
        f: "daa wáakhley garme roTúy. noor kho jee tsu nu dee pu kaar?",
        e: "Take this, hot bread. You don't need anything else?",
        a: "16d15"
      }, {
        s: "L",
        p: "نه، مِهربانى، چې پکار شى نو آواز به درکړم.",
        f: "na, mirabaanee, che pu kaar shee no aawaaz ba dărkawum.",
        e: "No thanks, if I want something I will call.",
        a: "16d16"
      }, {
        s: "L",
        p: "ډېرې ښکُلې تيکې وې! دا واخله. پنځوس روپۍ ستا اِنعام شو. نيمې ستا شوې، نيمې د اُستاذ شوې.",
        f: "Dere xkÚle teeke we! daa wáakhla! pandzós roopúy staa ináam sho. néeme staa shwe, néeme du Ustáaz shwe.",
        e: "They were very good tikkas! Take this. Fifty rupees is your tip. Half for you, half for the cook.",
        a: "16d17"
      }, {
        s: "L",
        p: "ستاسو خوراک زمُونږ خوښ شو. مُونږ به بيا راځُو.",
        f: "staaso khwaráak zmoonG khwax sho. moonG ba byaa raadzoo.",
        e: "We liked your food. We will come again.",
        a: "16d18"
      }]}
    </Dialogue>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation drill`}</h2>
    <h4 {...{
      "id": "illustrating-word-w-p-خر-f-khr-e--a-khr-sound--"
    }}>{`Illustrating `}<Word w={{
        p: "خر",
        f: "khr",
        e: "",
        a: "khr-sound"
      }} mdxType="Word" /></h4>
    <Examples mdxType="Examples">
  {[{
        p: "خرکار ډېر خرۀ ساتى.",
        f: "khurkáar Der khru saatée.",
        e: <>
          The donkey-<em>wallah</em> keeps many donkeys.
        </>,
        a: "16p1"
      }, {
        p: "ډوډۍ په خرپ خرپ مۀ خوره!",
        f: "DoDúy pu khrap khrap mú-khora!",
        e: "Do not eat food noisily.",
        a: "16p2"
      }, {
        p: "زۀ خپله ږيره خرايم.",
        f: "zu khpula Géera khraayum.",
        e: "I am shaving my chin [lit. 'beard'].",
        a: "16p3"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>
  <Word w={{
        p: "خرکار",
        f: "khurkáar",
        e: "",
        a: "khurkaar"
      }} mdxType="Word" /> translated
  'donkey-<em>wallah</em>' is the man who keeps donkeys that are used to carry
  bricks in buiding sites, etc.
    </p>
    <h4 {...{
      "id": "a-tongue-twister"
    }}>{`A tongue twister`}</h4>
    <p>{`Try this `}<em parentName="p">{`matal`}</em>{`:`}</p>
    <Examples memorizingMoment mdxType="Examples">
  {[{
        p: "خر د خد په خوراک څۀ پوهېږى!",
        f: "khur du khid (kheed) pu khoráak tsu pohéGee!",
        e: "What does the donkey know about eating barlay!",
        a: "16tt1"
      }]}
    </Examples>
    <h2 {...{
      "id": "introducing-the-subjunctive-mood"
    }}>{`Introducing the subjunctive mood`}</h2>
    <p>{`The subjunctive mood is used to express possibilities, wishes, desires and obligations. While the present form talks about things that are definately true, the subjunctive talks about actions that are hypothetical, possible, or in some doubt.`}</p>
    <h3 {...{
      "id": "making-the-subjunctive-form"
    }}>{`Making the subjunctive form`}</h3>
    <p>{`As we saw in lessons 14 and 15, the subjunctive forms of verbs are used in many different grammar constructions, so it’s very important to understand how they are formed. Here are a few rules that will help you get started in recognizing them:`}</p>
    <ol>
      <li parentName="ol">{`For most regular verb stems, simply add a `}<Word w={{
          p: "و",
          f: "oo / w",
          e: "",
          a: "oo-sound-2"
        }} mdxType="Word" />{` to the front of the present verb stem, and bring the emphasis on to the first syllable.`}</li>
    </ol>
    <Table bordered unset verticalContent titleRow={["Verb", "Present", "Subjunctive"]} mdxType="Table">
  {[[{
        p: "لیدل",
        f: "leedúl",
        e: "to see",
        a: "leedul-m"
      }, {
        p: "وینم",
        f: "wéenum",
        a: ""
      }, {
        p: "ووينم",
        f: "óoweenum",
        a: ""
      }], [{
        p: "وهل",
        f: "wahúl",
        e: "to hit",
        a: "wahul-m"
      }, {
        p: "وهم",
        f: "wahúm",
        a: ""
      }, {
        p: "ووهم",
        f: "óowahum",
        a: ""
      }]]}
    </Table>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Some words can’t take a `}<Word w={{
          p: "و",
          f: "oo",
          e: "",
          a: "oo-sound-2"
        }} mdxType="Word" /> in front, either because they are a seperable verb like <Word w={{
          p: "کېناستل",
          f: "kenaastul",
          e: "",
          a: "kenaastul-f"
        }} mdxType="Word" /> or <Word w={{
          p: "بوتلل",
          f: "botlul",
          e: "",
          a: "botlul"
        }} mdxType="Word" /> or because they already start with an <Word w={{
          p: "و",
          f: "oo",
          e: "",
          a: "oo-sound2"
        }} mdxType="Word" /> in front like <Word w={{
          p: "ودرېدل",
          f: "oodredul",
          e: "",
          a: "oodredul-f"
        }} mdxType="Word" />{`. For these words you can make them subjunctive by taking the present form and `}<em parentName="li">{`bringing the emphasis to the front`}</em>{`.`}</li>
    </ol>
    <Table bordered unset verticalContent titleRow={["Verb", "Present", "Subjunctive"]} mdxType="Table">
  {[[{
        p: "بوتلل",
        f: "botlúl",
        e: "to send",
        a: "botlul-m"
      }, {
        p: "بوځم",
        f: "bodzúm",
        a: ""
      }, {
        p: "لوځم",
        f: "bódzum",
        a: ""
      }], [{
        p: "ودرېدل",
        f: "oodredúl",
        e: "to stop",
        a: ""
      }, {
        p: "ودرېږم",
        f: "oodréGum",
        a: ""
      }, {
        p: "ودرېږم",
        f: "óodreGum",
        a: ""
      }]]}
    </Table>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Other words like the `}<Word w={{
          p: "کول",
          f: "kawul",
          e: "",
          a: "kawul"
        }} mdxType="Word" /> and <Word w={{
          p: "کېدل",
          f: "kedul",
          e: "",
          a: "kedul"
        }} mdxType="Word" />{` auxillary verbs used in compound verbs are irregular. They have subjunctive forms that often look like a totally different verbs. (See charts at the end of the chapter for more info).`}</li>
    </ol>
    <Table bordered unset verticalContent titleRow={["Verb", "Present", "Subjunctive"]} mdxType="Table">
  {[[{
        p: "کول",
        f: "kawul",
        e: "to do",
        a: "kawul-m"
      }, {
        p: "کوی",
        f: "kawee",
        a: ""
      }, {
        p: "وکړی",
        f: "óokRee / óokee",
        a: ""
      }], [{
        p: "خبرې کول",
        f: "khabure kawul",
        e: "to talk (to do talk)",
        a: ""
      }, {
        p: "خبرې کوی",
        f: "khabure kawee",
        e: "",
        a: ""
      }, {
        p: "خبرې وکړی",
        f: "khabure óokRee / óokee",
        e: "",
        a: ""
      }], [{
        p: "کول",
        f: "kawul",
        e: "to make",
        a: "kawul-m"
      }, {
        p: "کوی",
        f: "kawum",
        a: ""
      }, {
        p: "کړی",
        f: "kRee / kee",
        a: ""
      }], [{
        p: "ماتول",
        f: "maatawul",
        e: "to break (make broken)",
        a: ""
      }, {
        p: "ماتوی",
        f: "maatawee",
        e: "",
        a: ""
      }, {
        p: "مات کړی",
        f: "maat kRee / kee",
        e: "",
        a: ""
      }], [{
        p: "کېدل",
        f: "kedúl",
        e: "to happen",
        a: ""
      }, {
        p: "کېږی",
        f: "kéGee",
        a: ""
      }, {
        p: "وشی",
        f: "óoshee",
        e: "",
        a: ""
      }], [{
        p: "کار کېدل",
        f: "kaar kedul",
        e: "for work to happen",
        a: ""
      }, {
        p: "کار کېږی",
        f: "kaar kéGee",
        e: "",
        a: ""
      }, {
        p: "کار وشی",
        f: "kaar óoshee",
        e: "",
        a: ""
      }], [{
        p: "کېدل",
        f: "kedúl",
        e: "to become",
        a: ""
      }, {
        p: "کېږی",
        f: "kéGee",
        a: ""
      }, {
        p: "شی",
        f: "shee",
        e: "",
        a: ""
      }], [{
        p: "ماتېدل",
        f: "maatedúl",
        e: "to break (become broken)",
        a: ""
      }, {
        p: "ماتېږی",
        f: "maatéGee",
        e: "",
        a: ""
      }, {
        p: "مات شی",
        f: "maat shee",
        e: "",
        a: ""
      }], [{
        p: "وړل",
        f: "oRul",
        e: "to take (inanimate)",
        a: ""
      }, {
        p: "وړی",
        f: "oRee",
        e: "",
        a: ""
      }, {
        p: "یوسی",
        f: "yósee",
        e: "",
        a: ""
      }], [{
        p: "تلل",
        f: "tlul",
        e: "to go",
        a: ""
      }, {
        p: "ځی",
        f: "dzee",
        e: "",
        a: ""
      }, {
        p: "لاړ شی",
        f: "laaR shee",
        e: "",
        a: ""
      }]]}
    </Table>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Equative verbs like can be subjunctive, but the 3rd person forms are always changed to `}<Word w={{
          p: "وی",
          f: "wee",
          e: "",
          a: "wee-f"
        }} mdxType="Word" />{` in the subjunctive.`}</li>
    </ol>
    <h3 {...{
      "id": "different-ways-to-use-the-subjunctive-mood"
    }}>{`Different ways to use the subjunctive mood`}</h3>
    <h4 {...{
      "id": "1-possibility"
    }}>{`1. Possibility:`}</h4>
    <p>{`Phrases that express possibility like `}<Word w={{
        p: "کېدے شی چې...",
        f: "kedáy shee che...",
        e: "It's possible that...",
        a: "keday-shee-f"
      }} mdxType="Word" />, <Word w={{
        p: "شاید",
        f: "shaayid",
        e: "perhaps",
        a: "shaayid-f"
      }} mdxType="Word" /> and <Word w={{
        p: "انشاءالله",
        f: "inshaallaa",
        e: "God willing",
        a: "inshaallaa-f2"
      }} mdxType="Word" />{` are often connected to a phrase (clause) with a subjunctive verb.`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "کېدے شى چې هغوى هلته وى.",
        f: "kedáy shee che haghwée hálta wee.",
        e: "It is possible that they are there.",
        a: "16e1"
      }, {
        p: "کېدے شى چې زۀ يې معلُوم نۀ کړم.",
        f: "keday shee che zu ye maalóom nu kRum.",
        e: "It is possible that I will not find it.",
        a: "16e2"
      }, {
        p: "کېدے شى چې زۀ هم لاړ شم.",
        f: "kedáy shee che zu hum laaR shum.",
        e: "It is possible that I will also go.",
        a: "16e3"
      }, {
        p: "کېدے شى چې دے هم وږے وى.",
        f: "kedáy shee che day hum wuGay wee.",
        e: "Perhaps he is also hungry",
        a: "16e4"
      }, {
        p: "شايد چې زۀ نن هغه ووينم.",
        f: "shaayid che zu nun hagha óoweenum.",
        e: "Maybe I will see him today.",
        a: "16e5"
      }, {
        p: "شايد چې هغه به ورسره خبرې وکى.",
        f: "shaayid che haghá ba wăr-sara khabure óokee.",
        e: "Perhaps he will talk with them.",
        a: "16e6"
      }, {
        p: "انشاءالله د بلې جُمعې پورې به زۀ واپس شوے يم.",
        f: "inshaallaa du búle jUme póre ba zu wapás shiway yim.",
        e: "God willing, I will have returned before next Friday.",
        a: "16e7"
      }, {
        p: "انشاءالله، بل کال پورې به زۀ ښۀ پښتو زده کړم.",
        f: "inshaallaa, bul kaal póre ba zu xa puxtó zda kRum.",
        e: "God willing, I will learn good Pukhto by next year.",
        a: "16e8"
      }]}
    </Examples>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <p>{`The last phrase includes the pre- and postpositions `}<Word w={{
        p: "د ___ پورې",
        f: "du ___ pore",
        e: "up to",
        a: "du-pore"
      }} mdxType="Word" />, although often the <Word w={{
        p: "د",
        f: "du",
        e: "",
        a: "du"
      }} mdxType="Word" />{` is left out. Note some of its uses in the following examples:`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "د ښار پورې څو روپۍ اخلى؟",
        f: "du xaar póre tso roopúy áakhlee?",
        e: "How many rupees does it take to get to the city?",
        a: "16ee1"
      }, {
        p: "شپږو بجو پورې دلته يم.",
        f: "shpuGo bajo pore dălta yim.",
        e: "I'm here up to six o'clock.",
        a: "16ee2"
      }, {
        p: "راتلُونکى کال پورې به څۀ کوې؟",
        f: "raatlóonkee kaal póre ba tsu kawe?",
        e: "What will you do until next year?",
        a: "16ee3"
      }]}
    </Examples>
    <img src={ttaanga} />
    <h4 {...{
      "id": "2-necessity-responsibility"
    }}>{`2. Necessity, responsibility:`}</h4>
    <p>
  Phrases with{" "}
  <Word w={{
        p: "پکار",
        f: "pu káar",
        e: "neccesary, have to, need to, should",
        a: "pu-kaar-f2"
      }} mdxType="Word" />
  , <Word w={{
        p: "ضرُور",
        f: "zaróor",
        e: "must",
        a: "zaroor-f"
      }} mdxType="Word" />, <Word w={{
        p: "لازم",
        f: "laazím",
        e: "necessary",
        a: "laazim-f"
      }} mdxType="Word" />, <Word w={{
        p: "مجبُور",
        f: "majbóor",
        e: "compelled to, forced to",
        a: "majboor-f"
      }} mdxType="Word" /> and <Word w={{
        p: "حاماخا",
        f: "khaamakháa",
        e: "definately, must",
        a: "khaamakhaa-f"
      }} mdxType="Word" /> often use the subjunctive mood.
    </p>
    <Examples mdxType="Examples">
  {[{
        p: "مُونږ له د هغوى مدد کول پکار دى.",
        f: "moonG la du haghwée madúd kawul pu káar dee.",
        e: "We should help them. (not subjunctive)",
        a: "16e9"
      }, {
        p: "پکار دى چې روزانه ورزش وکړى.",
        f: "pu kaar dee che rozaana warzísh óokRee.",
        e: "It is necessary for him to exercise daily.",
        a: "16e10"
      }, {
        p: "پکار دى چې ماشُومان اُودۀ شى.",
        f: "pu kaar dee che maashoomáan oodú shee.",
        e: "The children ought to go to sleep.",
        a: "16e11"
      }, {
        p: "تا ته پکار دى چې دا کار ونۀ کړې.",
        f: "taa ta pu kaar dee che daa kaar oo-nu-kRe.",
        e: "You should not do that job.",
        a: "16e12"
      }, {
        p: "مُونږ له د شاهد کور ته تلل پکار دى.",
        f: "moonG la du shaahid kor ta tlul pu kaar dee.",
        e: "We need to go to Shahid's house.",
        a: "16e13"
      }, {
        p: "تاله خپل کلى ته تلل پکار دى.",
        f: "taa la khpul khpul kúlee ta tlul pu kaar dee.",
        e: "You should go to your own village.",
        a: "16e14"
      }, {
        p: "تۀ ضرُور هسپتال ته لاړ شه!",
        f: "tu zaróor haspatáal ta laaR sha!",
        e: "You must go to the hospital.",
        a: "16e15"
      }, {
        p: "هغه به ضرُور نن په کور کښې وى.",
        f: "haghá ba zaróor nun pu kor ke wee.",
        e: "She has to be in the house today.",
        a: "16e16"
      }, {
        p: "تۀ به صبا ضرُور راشې!",
        f: "tu ba sabaa zaróor raashe!",
        e: "You must come tomorrow.",
        a: "16e17"
      }, {
        p: "لازِمه ده چې هره ورځ د رېډيو خبرُونه واورو.",
        f: "laazíma da che hăra wdradz du reDyo khabróona wáawroo.",
        e: "It is necessary for us to listen to the radio every day.",
        a: "16e18"
      }, {
        p: "لازِمه ده چې نن د کور د پاره سودا واخلُو.",
        f: "laazíma da che nun du kor du paara sodaa wáakhloo.",
        e: "It is necessary for us to buy groceries for the house today.",
        a: "16e19"
      }, {
        p: "هغه د مجبُورۍ نه دلته پاتې کېږى.",
        f: "haghá du majboorúy na dălta paate keGee.",
        e: "He is compelled to stay here. (not subjunctive)",
        a: "16e20"
      }, {
        p: "صبا خامخا ډاکټر ته لاړ شئ.",
        f: "sabáa khaamakhaa DakTúr ta laaR shey.",
        e: "You must go to the doctor tomorrow.",
        a: "16e21"
      }, {
        p: "تۀ خامخا هغه ووينه.",
        f: "tu khaamakhaa haghá óoweena.",
        e: "You must see him. (imperative, not subjunctive)",
        a: "16e22"
      }]}
    </Examples>
    <h4 {...{
      "id": "3-wishes-and-desires"
    }}>{`3. Wishes and desires:`}</h4>
    <p>
  <Word w={{
        p: "اُمید لرل",
        f: "Uméed larul",
        e: "to hope",
        a: "uumeed-larul-f"
      }} mdxType="Word" />
  , <Word w={{
        p: "شوق",
        f: "shoq",
        e: "interest, desire",
        a: "shoq-f"
      }} mdxType="Word" />, <Word w={{
        p: "خُدائ دې وکی",
        f: "khUdáay de óokee",
        e: "may God do it",
        a: "khuudaay-de-ookee-f"
      }} mdxType="Word" />, <Word w={{
        p: "زړهٔ کېدل",
        f: "zRu kedúl",
        e: "to want",
        a: "zrru-kedul-f"
      }} mdxType="Word" /> and <Word w={{
        p: "غوښتل",
        f: "ghoxtúl",
        e: "to want",
        a: "ghoxtul-f"
      }} mdxType="Word" /> are often used in connection with phrases made from subjunctive verbs.
    </p>
    <Examples mdxType="Examples">
  {[{
        p: "زۀ اُميد لرم چې هغه به د جېل نه راخلاص شى.",
        f: "zu Uméed larum che chagha ba du jel na raakhilaas shee.",
        e: "I hope he will be set free from jail.",
        a: "16e23"
      }, {
        p: "اُميد لرې چې زر به کار پېدا کې؟",
        f: "Uméed lare che zur ba kaar pedaa ke?",
        e: "Do you hope to find work quickly?",
        a: "16e24"
      }, {
        p: "زما زوئ اُميد لرى چې خپل تعليم په لندن کښې پُوره کړى.",
        f: "zmaa zooy Uméed laree che khpul taléem pu landan ke póora kRee.",
        e: "My son hopes to complete his education in London.",
        a: "16e25"
      }, {
        p: "زما شوق دے چې انګرېزى زده کړم.",
        f: "zmaa shoq day che angrezee zda kRum.",
        e: "It's my desire to learn English.",
        a: "16e26"
      }, {
        p: "زما شوق دے چې دُنيا ووينم.",
        f: "zmaa shoq day che dUnyáa óoweenum.",
        e: "My desire is to see the world.",
        a: "16e27"
      }, {
        p: "خُدائ دې باران وکى!",
        f: "khUdaay de baaraan óokee!",
        e: "May God make it rain!",
        a: "16e28"
      }, {
        p: "خُدائ دې وکړى چې بيا دې ووينُو!",
        f: "khUdaay de óokRee che byaa de óoweenum!",
        e: "May God grant that we see you again!",
        a: "16e29"
      }, {
        p: "خُدائ دې تا له د زړۀ ايمان درکړى!",
        f: "khUdaay de taa la du zRu eemáan dărkRee!",
        e: "May God give you belief of the heart!",
        a: "16e30"
      }, {
        p: "خُدائ دې تا په اِمتحان کښې پاس کى!",
        f: "khUdaay de taa pu imtiháan ke paas kee!",
        e: "May God grant that you pass the exam!",
        a: "16e31"
      }]}
    </Examples>
    <p>{`The following express similar ideas without using subjunctive verbs:`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "زړۀ ته دې څۀ کېږى؟",
        f: "zRu ta de tsu keGee?",
        e: "What would you like?",
        a: "16e32"
      }, {
        p: "ډوډۍ ته مې زړۀ کېږى.",
        f: "DoDúy to me zRu keGee.",
        e: "I would like some food.",
        a: "16e33"
      }, {
        p: "چائو ته مې زړۀ کېږى.",
        f: "chaayo to me zRu keGee.",
        e: "I would like tea.",
        a: "16e34"
      }, {
        p: "زۀ لامبو وهل غواړم.",
        f: "zu laambó wahúl ghwúaRum.",
        e: "I want to swim.",
        a: "16e35"
      }, {
        p: "دا ډېر کار کول غواړى.",
        f: "daa Der kaar kawul ghwaaRee.",
        e: "She wants to do a lot of work.",
        a: "16e36"
      }]}
    </Examples>
    <h4 {...{
      "id": "4-questions-and-inquiries"
    }}>{`4. Questions and inquiries:`}</h4>
    <p>{`The subjunctive mood is also used to ask or make statements about whether potential actions `}<em parentName="p">{`should`}</em>{` happen or not.`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "زۀ چا سره خبرې وکم؟",
        f: "zu chaa sara khabure óokum?",
        e: "Who should I talk with?",
        a: "16e37"
      }, {
        p: "تۀ مالِک سره خبرې وکه.",
        f: "tu maalík sara khabure óoka.",
        e: "You should talk to the owner. (imperative not subjunctive)",
        a: "16e38"
      }, {
        p: "زۀ کور ته لاړ شم کۀ دفتر ته؟",
        f: "zu kor ta laaR shum ku dăftur ta?",
        e: "Should I go to the house or to the office?",
        a: "16e39"
      }, {
        p: "ما له کور ته تلل پکار دى او کۀ دفتر ته؟",
        f: "maa la kor ta tlul pu kaar dee aw ku dăftur ta?",
        e: "Do I need to go to the house or to the office? (not subjunctive)",
        a: "16e40"
      }, {
        p: "خامخا کور ته لاړ شه.",
        f: "khaamakhaa kor ta laaR sha. (imperative not subjunctive)",
        e: "You should definitely go to the house.",
        a: "16e41"
      }, {
        p: "څۀ وخورُو؟ کباب او کۀ ورېژې؟",
        f: "tsu óokhoree? kabaab aw ku wréjze?",
        e: "What should we eat? Kababs or rice?",
        a: "16e42"
      }, {
        p: "ځه چې کباب وخورُو.",
        f: "dza che kabaab óokhoroo.",
        e: "Let's eat kababs.",
        a: "16e43"
      }, {
        p: "څنګه کتاب واخلُو؟",
        f: "tsunga kitáab wáakhloo?",
        e: "What kind of book should we buy?",
        a: "16e44"
      }, {
        p: "ځه چې د قصو کتاب واخلُو.",
        f: "dza che du qiso kitaab wáakhloo.",
        e: "Let's buy a story book.",
        a: "16e45"
      }]}
    </Examples>
    <h4 {...{
      "id": "5-permission"
    }}>{`5. Permission:`}</h4>
    <Examples mdxType="Examples">
  {[{
        p: "اِجازت دے چې لاړ شو؟",
        f: "ijaazăt day che laaR shoo?",
        e: "Is there permission for us to go?",
        a: "16e46"
      },
      // check emphasis on obaasum
      {
        p: "اِجازت دے چې ستا تصوير اوباسم؟",
        f: "ijaazát day che staa taswéer oobaasum?",
        e: "Is it OK that I take your picture?",
        a: "16e47"
      }, {
        p: "مُونږ ته د راتلو اِجازت دے؟",
        f: "moonG ta du raatlo ijaazăt day?",
        e: "Do we have permission for coming? (not subjunctive)",
        a: "16e48"
      }, {
        p: "ستا موټر بوتلے شم؟",
        f: "staa moTúr botlay shum?",
        e: "May I take your car? (not subjunctive)",
        a: "16e49"
      }, {
        p: "زۀ دننه راتلے شم؟",
        f: "zu dununa raatlay shum?",
        e: "May I come in? (not subjunctive)",
        a: "16e50"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-3"
    }}>{`NOTE:`}</h4>
    <p>{`In Pashto, a `}<Word w={{
        p: "ت",
        f: "t",
        e: "",
        a: ""
      }} mdxType="Word" /> is sometimes added to words of Arabic origin. Thus <Word w={{
        p: "اِجازه",
        f: "ijaazá",
        e: "permission",
        a: ""
      }} mdxType="Word" /> becomes <Word w={{
        p: "اِجازت",
        f: "ijaazăt",
        e: "",
        a: ""
      }} mdxType="Word" />, <Word w={{
        p: "شریعه",
        f: "shari'a",
        e: "Islamic law",
        a: ""
      }} mdxType="Word" /> becomes <Word w={{
        p: "شریعت",
        f: "shari'ăt",
        e: "",
        a: ""
      }} mdxType="Word" />{`, etc.`}</p>
    <h4 {...{
      "id": "6-exhortations-and-warnings"
    }}>{`6. Exhortations and warnings:`}</h4>
    <p>{`Subjunctive phrases are often used with `}<Word w={{
        p: "کوشش کوه!",
        f: "koshish kawa!",
        e: "try!",
        a: "koshish-kawa"
      }} mdxType="Word" />, <Word w={{
        p: "خیال کوه!",
        f: "khyáal kawa!",
        e: "be careful!, watch out!",
        a: "khayaal-kawa"
      }} mdxType="Word" />, <Word w={{
        p: "پام کوه!",
        f: "paam kawa!",
        e: "be careful!",
        a: "paam-kawa"
      }} mdxType="Word" />, <Word w={{
        p: "خبردار!",
        f: "khabardáar!",
        e: "beware!",
        a: "khabardaar"
      }} mdxType="Word" /> and <Word w={{
        p: "مهٔ کوه!",
        f: "don't do it!",
        e: "",
        a: "mu-kawa-f"
      }} mdxType="Word" />{`.`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "کوشش کوه چې په اِمتحان کښې کامياب شې.",
        f: "koshis kawa che pu imtiháan ke kaamyaab she.",
        e: "Try to be successful in the test.",
        a: "16e51"
      }, {
        p: "د زده کولو کوشش کوه!",
        f: "du zda kawulo koshis kawa!",
        e: "Try to learn! (not subjunctive)",
        a: "16e52"
      }, {
        p: "کوشش وکه چې پينځو بجو پورې هلته ورسې.",
        f: "koshis óoka che peendzo bajo pore hálta óorase.",
        e: "Try to reach there by five o'clock.",
        a: "16e53"
      }, {
        p: "کوشش کوه چې په هسپتال کښې هغه ووينې.",
        f: "koshis kawa che pu haspatáal ke haghá óoweene.",
        e: "Try to see him at the hospital.",
        a: "16e54"
      }, {
        p: "خيال کوه چې خوږ نۀ شې!",
        f: "khyaal kawa che khoG nu she!",
        e: "Be careful not to get hurt!",
        a: "16e55"
      }, {
        p: "خيال کوه چې په چايو باندې خپله ژبه ونۀ سوزوې!",
        f: "khyaal kawa che pu chaayo baande khpula jzíba oo-nu-swazawe!",
        e: "Be careful your tongue is not burned by the tea!",
        a: "16e56"
      }, {
        p: "خيال کوه چې دوکه در نۀ کى!",
        f: "khyáal kawa che doká dăr-nu-kee!",
        e: "Watch out that he doesn't trick you!",
        a: "16e57"
      }, {
        p: "خيال کوه چې سپے تا ونۀ خورى!",
        f: "khyaal kawa che spay taa oo-nu-khoree!",
        e: "Be careful the dog doesn't bite you!",
        a: "16e58"
      }, {
        p: "خيال کوه چې نشه ونۀ کې!",
        f: "khyaal kawa che nashá oo-nu-ke!",
        e: "Be careful not to take drugs!",
        a: "16e59"
      }, {
        p: "پام کوه چې موټر ونۀ جنګوې!",
        f: "paam kawa che moTúr oo-nu-jangawe!",
        e: "Be careful not to crash the car!",
        a: "16e60"
      }, {
        p: "خبردار! هلته مار دے.",
        f: "khabardáar! hálta maar day.",
        e: "Beware there's a snake there. (not subjunctive)",
        a: "16e61"
      }, {
        p: "مۀ کوه! ورک شې! خوږ به شې!",
        f: "mu kawa! wruk she! khoG ba she!",
        e: "Don't do it! Get lost! You will be hurt!",
        a: "16e62"
      }]}
    </Examples>
    <img src={dishes} />
    <h4 {...{
      "id": "7-conditional-clauses"
    }}>{`7. Conditional clauses:`}</h4>
    <p>
  <Word w={{
        p: "که ____ نو",
        f: "ku ___ no",
        e: "if ____ then",
        a: "ku-no"
      }} mdxType="Word" />
    </p>
    <Examples mdxType="Examples">
  {[{
        p: "کۀ دا رانۀ غله نو زۀ به لاړ شم.",
        f: "ku daa raa-nu-ghla no zu ba laaR shum.",
        e: "If she doesn't come, then I will go.",
        a: "16e63"
      }, {
        p: "کۀ ښۀ کار وکې نو نورې پیسې به درکم.",
        f: "ku xu kaar óoke no noore payse ba dărkum.",
        e: "If you do good work, then I will give you more money.",
        a: "16e64"
      }, {
        p: "کۀ ګړۍ دې خرابه وى نو بدله يې که!",
        f: "ku gaRúy de kharáaba wee, no badúla ye ka!",
        e: "If your watch is bad, then replace it!",
        a: "16e65"
      }, {
        p: "چې يو ځل دې نشه وکړه بيا به تر مرګه پورې خلاص نۀ شې.",
        f: "che yo dzal de nashá óokRa byaa ba tur múrga pore khiláas nu-she.",
        e: "If you take drugs one time then you will not be free until death.",
        a: "16e66"
      }, {
        p: "ياره، چې دا مسئله حل شى نو امداد به ډېر خوشحاله شى.",
        f: "yaara, che daa masulá haal shee no imdáad ba Der khosháala shee.",
        e: "Friend, if this problem is solved then Imdad will be very happy.",
        a: "16e67"
      }]}
    </Examples>
    <h2 {...{
      "id": "telephone-talk"
    }}>{`Telephone Talk`}</h2>
    <Examples mdxType="Examples">
  {[{
        p: "زۀ اشرف سره خبرې کول غواړم.",
        f: "zu ashraf sara khabure kawul ghwáaRum.",
        e: "I want to talk with Ashraf.",
        a: "16e68"
      }, {
        p: "زۀ د مردان نه خبرې کوم.",
        f: "zu du mardáan na khabure kawum.",
        e: "I am calling from Mardan.",
        a: "16e69"
      }, {
        p: "تۀ اشرف خبرې کوې څۀ؟",
        f: "tu ashraf khabure kawe tsu?",
        e: "Is this Ashraf speaking?",
        a: "16e70"
      }, {
        p: "تاسو چا سره خبرې کول غواړئ؟",
        f: "táaso chaa sara khabure kawul ghwaaRey?",
        e: "Who do you want to talk to?",
        a: "16e71"
      }, {
        p: "لږ اِنتظار وکئ زۀ هغه راغواړم.",
        f: "luG intizáar óokey zu haghá raaghwáaRum.",
        e: "Wait a little, I will get him.",
        a: "16e72"
      }, {
        p: "زما خبرې اورې؟",
        f: "zmaa khabure áawre?",
        e: "Can you hear me?",
        a: "16e73"
      }, {
        p: "زما آواز درځى؟",
        f: "zmaa aawáaz dărdzee?",
        e: "Is my voice reaching you?",
        a: "16e74"
      }, {
        p: "خو زۀ ستاسو آواز نۀ شم اورېدے.",
        f: "kho zu stáaso aawáaz nu shum awreday.",
        e: "But I can't hear you.",
        a: "16e75"
      }, {
        p: "معافى غواړم، مانه غلط نمبر مِلاؤ شو.",
        f: "maafee ghwáaRum, maa na ghalat numbúr miláaw sho.",
        e: "Sorry, I dialled the wrong number.",
        a: "16e76"
      }, {
        p: "هغۀ پرُون ما ته ټېېيفون کړے وو.",
        f: "haghú paróon maa ta Telefóon kuRay wo.",
        e: "Yesterday he had telephoned me.",
        a: "16e77"
      }, {
        p: "هغه په کور کښې نۀ وو.",
        f: "haghá pu kor ke nu wo.",
        e: "He was not at home.",
        a: "16e78"
      }, {
        p: "د خيبر هسپتال د ټېلېفون نمبر څۀ دے؟",
        f: "du khebur haspatáal du Telefóon numbúr tsu day?",
        e: "What is the telephone number for the Khyber Hospital?",
        a: "16e79"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-4"
    }}>{`NOTE:`}</h4>
    <img src={teaAndCup} style={{
      maxWidth: "10rem",
      float: "right"
    }} />
    <p>{`To say, `}<Word w={{
        p: "مانه غلط نمبر مِلاؤ شو",
        f: "maa na ghalat numbur miláaw sho",
        e: "",
        a: "maa-na-ghalat-nambur"
      }} mdxType="Word" /> literally means, 'I wrong number was dialled from me'. This way of talking, without admitting personal fault, is the norm in a culture which does not readily accept responsibility for error. For example, the excuse for breaking a cup is, <Word w={{
        p: "پیاله ماته شوه",
        f: "pyáala máata shwa",
        e: "the cup became broken",
        a: "pyaala-maata-shwa"
      }} mdxType="Word" />{`.`}</p>
    <h2 {...{
      "id": "memorising-moments"
    }}>{`Memorising moments`}</h2>
    <h4 {...{
      "id": "matalóona"
    }}>{`matalóona`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[16].slice(0, 3)}</Examples>
    <h5 {...{
      "id": "note-5"
    }}>{`NOTE:`}</h5>
    <p><em parentName="p">{`naswaar`}</em>{` is a wad of tobacco placed under the lip. `}<em parentName="p">{`Kheshko`}</em>{` is a village near Nowshera.`}</p>
    <img src={scale} />
    <h4 {...{
      "id": "rahman-baba"
    }}>{`Rahman Baba`}</h4>
    <Examples memorizingMoment mdxType="Examples">{[memorizingMoments[16][3]]}</Examples>
    <h2 {...{
      "id": "exercises-for-lesson-sixteen"
    }}>{`Exercises for Lesson Sixteen`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Role Play: Visit a restaurant or ask about cooking.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Make the following sentences negative:`}</p>
      </li>
    </ol>
    <Quiz mdxType="Quiz">
  {[{
        q: {
          p: "زهٔ به نن هغه ووینم.",
          f: "zu ba nun haghá ooweenum."
        },
        answer: [{
          p: "زه به نن هغه ونهٔ وینم.",
          f: "zu ba nun haghá oo-nu-weenum."
        }]
      }, {
        q: {
          p: "پکار دے چې ماشُومان اُودۀ شى.",
          f: "pukáar day che maashoomáan oodú shee."
        },
        answer: [{
          p: "پکار نهٔ دے چې ماشومان اوده شی",
          f: "pukáar nu day che maashoomáan oodu shee."
        }, {
          p: "پکار دے چې ماشومان اوده نهٔ شی",
          f: "pukáar day che maashoomáan oodu nu shee."
        }]
      }, {
        q: {
          p: "تهٔ به صبا ضرُور راشې.",
          f: "tu ba sabáa zaróor raashe."
        },
        answer: [{
          p: "تهٔ به صبا ضرور نهٔ راشې.",
          f: "tu ba sabaa zaroor nu raashe."
        }, {
          p: "تهٔ به صبا ضرور نهٔ رانهٔ شې.",
          f: "tu ba sabaa zaroor raa-nu-she."
        }]
      }, {
        q: {
          p: "اُمید لرم چې لور یې وشی.",
          f: "Uméed larum che loor ye ooshee."
        },
        answer: [{
          p: "اُمید لرم چې لور یې ونهٔ شی.",
          f: "Uméed larum che looy ye oo-nu-shee."
        }, {
          p: "اُمید لرم چې لور یې نهٔ وشی.",
          f: "Uméed larum che looy ye nu ooshee."
        }]
      }]}
    </Quiz>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Complete the following sentences with the appropriate subjunctive form of the verb:`}</li>
    </ol>
    <Quiz mdxType="Quiz">
  {[{
        pref: {
          p: "خبرې کول",
          f: "khabure kawul"
        },
        beg: {
          p: "کېدے شى چې هغه ورسره",
          f: "keday shee che haghá wăr-sara"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "خبرې وکړي",
          f: "khabure ookRee"
        }, {
          p: "خبرې وکي",
          f: "khabure ookee"
        }]
      }, {
        pref: {
          p: "تلل",
          f: "tlul"
        },
        beg: {
          p: "تهٔ ضرُور هسبتال ته",
          f: "tu zaróor haspatáal ta"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "لاړ شې",
          f: "لاړ شې"
        }]
      }, {
        pref: {
          p: "لیدل",
          f: "leedúl"
        },
        beg: {
          p: "زما شوق دے چې دُنیا",
          f: "zmaa shoq day che dUnyáa"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "ووینم",
          f: "ooweenum"
        }]
      }]}
    </Quiz>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Complete the following questions with the first person subjunctive form of the verb:`}</li>
    </ol>
    <Quiz mdxType="Quiz">
  {[{
        pref: {
          p: "راوړل",
          f: "raawRúl"
        },
        beg: {
          p: "کباب کهٔ ورېژې",
          f: "kabáab ku wrejze"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "راوړم",
          f: "ráawRum"
        }]
      }, {
        pref: {
          p: "اغوستل",
          f: "aghostul"
        },
        beg: {
          p: "نن ځه شے",
          f: "nun tsu shay"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "واغوندم",
          f: "waaghondum"
        }]
      }, {
        pref: {
          p: "ګرځېدل",
          f: "gurdzedúl"
        },
        beg: {
          p: "دلته ښی لاس ته",
          f: "dălta xee laas ta"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "وګرځم",
          f: "óogurdzum"
        }, {
          p: "وګرځېږم",
          f: "óogurdzeGum"
        }]
      }, {
        pref: {
          p: "تلل",
          f: "tlul"
        },
        beg: {
          p: "په کُومه لا",
          f: "pu kóoma laar"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "لاړ شم",
          f: "laaR shum"
        }]
      }]}
    </Quiz>
    <img src={ladyCarryingWater} />
    <h2 {...{
      "id": "the-subjunctive-form-of-some-irregular-verbs"
    }}>{`The subjunctive form of some irregular verbs`}</h2>
    <h4 {...{
      "id": "the-subjunctive-form-of-word-w-p-کېدل-f-kedúl-e-to-become-a-kedul-m--"
    }}>{`The subjunctive form of `}<Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to become",
        a: "kedul-m"
      }} mdxType="Word" /></h4>
    <Table bordered unset titleRow={["", "singular", "plural"]} mdxType="Table">
  {[[{
        e: "1st"
      }, {
        p: "شم",
        f: "shum",
        e: "",
        a: "shum-m"
      }, {
        p: "شُو",
        f: "shoo",
        e: "",
        a: "shoo-m"
      }], [{
        e: "2nd"
      }, {
        p: "شې",
        f: "she",
        e: "",
        a: "she-m"
      }, {
        p: "شُئ",
        f: "shey",
        e: "",
        a: "shey-m"
      }], [{
        e: "3rd"
      }, {
        p: "شی",
        f: "shee",
        e: "",
        a: "shee-m"
      }, {
        p: "شی",
        f: "shee",
        e: "",
        a: "shee-m"
      }]]}
    </Table>
    <h4 {...{
      "id": "the-subjunctive-form-of-word-w-p-کېدل-f-kedúl-e-to-happen-a-kedul-m--"
    }}>{`The subjunctive form of `}<Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to happen",
        a: "kedul-m"
      }} mdxType="Word" /></h4>
    <Table bordered unset titleRow={["", "singular", "plural"]} mdxType="Table">
  {[[{
        e: "1st"
      }, {
        p: "وشم",
        f: "óoshum",
        e: "",
        a: ""
      }, {
        p: "وشُو",
        f: "óoshoo",
        e: "",
        a: ""
      }], [{
        e: "2nd"
      }, {
        p: "وشې",
        f: "óoshe",
        e: "",
        a: ""
      }, {
        p: "وشُئ",
        f: "óoshey",
        e: "",
        a: ""
      }], [{
        e: "3rd"
      }, {
        p: "وشی",
        f: "óoshee",
        e: "",
        a: ""
      }, {
        p: "وشی",
        f: "óoshee",
        e: "",
        a: ""
      }]]}
    </Table>
    <h4 {...{
      "id": "the-subjunctive-form-of-word-w-p-کول-f-kawúl-e-to-do-a---"
    }}>{`The subjunctive form of `}<Word w={{
        p: "کول",
        f: "kawúl",
        e: "to do",
        a: ""
      }} mdxType="Word" /></h4>
    <Table bordered unset titleRow={["", "singular", "plural"]} mdxType="Table">
  {[[{
        e: "1st"
      }, {
        p: "وکړم / وکم",
        f: "óokRum / óokum",
        e: "",
        a: ""
      }, {
        p: "وکړو / وکو",
        f: "óokRoo / óokoo",
        e: "",
        a: ""
      }], [{
        e: "2nd"
      }, {
        p: "وکړې / وکې",
        f: "óokRe / óoke",
        e: "",
        a: ""
      }, {
        p: "وکړئ / وکئ",
        f: "óokRey / óokey",
        e: "",
        a: ""
      }], [{
        e: "3rd"
      }, {
        p: "وکړی / وکی",
        f: "óokRee / óokee",
        e: "",
        a: ""
      }, {
        p: "وکړی / وکی",
        f: "óokRee / óokee",
        e: "",
        a: ""
      }]]}
    </Table>
    <h4 {...{
      "id": "the-subjunctive-form-of-word-w-p-کول-f-kawúl-e-to-make-a-kawul-m--"
    }}>{`The subjunctive form of `}<Word w={{
        p: "کول",
        f: "kawúl",
        e: "to make",
        a: "kawul-m"
      }} mdxType="Word" /></h4>
    <Table bordered unset titleRow={["", "singular", "plural"]} mdxType="Table">
  {[[{
        e: "1st"
      }, {
        p: "کړم / کم",
        f: "kRum / kum",
        e: "",
        a: ""
      }, {
        p: "کړو / کو",
        f: "kRoo / koo",
        e: "",
        a: ""
      }], [{
        e: "2nd"
      }, {
        p: "کړې / کې",
        f: "kRe / ke",
        e: "",
        a: ""
      }, {
        p: "کړئ / کئ",
        f: "kRey / key",
        e: "",
        a: ""
      }], [{
        e: "3rd"
      }, {
        p: "کړی / کی",
        f: "kRee / kee",
        e: "",
        a: ""
      }, {
        p: "کړی / کی",
        f: "kRee / kee",
        e: "",
        a: ""
      }]]}
    </Table>
    <h4 {...{
      "id": "the-subjunctive-form-of-word-w-p-تلل-f-tlul-e-to-go-a-kawul-m--"
    }}>{`The subjunctive form of `}<Word w={{
        p: "تلل",
        f: "tlul",
        e: "to go",
        a: "kawul-m"
      }} mdxType="Word" /></h4>
    <h5 {...{
      "id": "with-masculine-subjects"
    }}>{`With masculine subjects`}</h5>
    <Table bordered unset titleRow={["", "singular", "plural"]} mdxType="Table">
  {[[{
        e: "1st"
      }, {
        p: "لاړ شم",
        f: "laaR shum",
        e: "",
        a: ""
      }, {
        p: "لاړ شو",
        f: "laaR shoo",
        e: "",
        a: ""
      }], [{
        e: "2nd"
      }, {
        p: "لاړ شې",
        f: "laaR she",
        e: "",
        a: ""
      }, {
        p: "لاړ شئ",
        f: "laaR shey",
        e: "",
        a: ""
      }], [{
        e: "3rd"
      }, {
        p: "لاړ شی",
        f: "laaR shee",
        e: "",
        a: ""
      }, {
        p: "لاړ شی",
        f: "laaR shee",
        e: "",
        a: ""
      }]]}
    </Table>
    <h5 {...{
      "id": "with-feminine-subjects"
    }}>{`With feminine subjects`}</h5>
    <Table bordered unset titleRow={["", "singular", "plural"]} mdxType="Table">
  {[[{
        e: "1st"
      }, {
        p: "لاړه شم",
        f: "laaRa shum",
        e: "",
        a: ""
      }, {
        p: "لاړې شو",
        f: "laaRe shoo",
        e: "",
        a: ""
      }], [{
        e: "2nd"
      }, {
        p: "لاړه شې",
        f: "laaRa she",
        e: "",
        a: ""
      }, {
        p: "لاړې شئ",
        f: "laaRe shey",
        e: "",
        a: ""
      }], [{
        e: "3rd"
      }, {
        p: "لاړه شی",
        f: "laaRa shee",
        e: "",
        a: ""
      }, {
        p: "لاړې شی",
        f: "laaRe shee",
        e: "",
        a: ""
      }]]}
    </Table>
    <h4 {...{
      "id": "the-subjunctive-form-of-word-w-p-راتلل-f-raatlul-e-to-come-a-raatlul-m--"
    }}>{`The subjunctive form of `}<Word w={{
        p: "راتلل",
        f: "raatlul",
        e: "to come",
        a: "raatlul-m"
      }} mdxType="Word" /></h4>
    <Table bordered unset titleRow={["", "singular", "plural"]} mdxType="Table">
  {[[{
        e: "1st"
      }, {
        p: "راشم",
        f: "ráashum",
        e: "",
        a: ""
      }, {
        p: "راشو",
        f: "ráashoo",
        e: "",
        a: ""
      }], [{
        e: "2nd"
      }, {
        p: "راشې",
        f: "ráashe",
        e: "",
        a: ""
      }, {
        p: "راشئ",
        f: "ráashey",
        e: "",
        a: ""
      }], [{
        e: "3rd"
      }, {
        p: "راشی",
        f: "ráashee",
        e: "",
        a: ""
      }, {
        p: "راشی",
        f: "ráashee",
        e: "",
        a: ""
      }]]}
    </Table>
    <h4 {...{
      "id": "the-subjunctive-form-of-word-w-p-وړل-f-orul-e-to-take-inanimate-objects-a---"
    }}>{`The subjunctive form of `}<Word w={{
        p: "وړل",
        f: "oRul",
        e: "to take (inanimate objects)",
        a: ""
      }} mdxType="Word" /></h4>
    <Table bordered unset titleRow={["", "singular", "plural"]} mdxType="Table">
  {[[{
        e: "1st"
      }, {
        p: "یوسم",
        f: "yósum",
        e: "",
        a: ""
      }, {
        p: "یوسو",
        f: "yósoo",
        e: "",
        a: ""
      }], [{
        e: "2nd"
      }, {
        p: "یوسې",
        f: "yóse",
        e: "",
        a: ""
      }, {
        p: "یوسئ",
        f: "yósey",
        e: "",
        a: ""
      }], [{
        e: "3rd"
      }, {
        p: "یوسی",
        f: "yósee",
        e: "",
        a: ""
      }, {
        p: "یوسی",
        f: "yósee",
        e: "",
        a: ""
      }]]}
    </Table>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-sixteen-ladies-finding-out-about-cooking",
    level: 2,
    title: "Dialogue sixteen: Ladies finding out about cooking",
    children: [
        
      ]
  },
{
    id: "men-eating-at-a-restaurant",
    level: 2,
    title: "Men: Eating at a restaurant",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation drill",
    children: [
        
      ]
  },
{
    id: "introducing-the-subjunctive-mood",
    level: 2,
    title: "Introducing the subjunctive mood",
    children: [
        {
              id: "making-the-subjunctive-form",
              level: 3,
              title: "Making the subjunctive form",
              children: [
                      
                    ]
            },
      {
              id: "different-ways-to-use-the-subjunctive-mood",
              level: 3,
              title: "Different ways to use the subjunctive mood",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "telephone-talk",
    level: 2,
    title: "Telephone Talk",
    children: [
        
      ]
  },
{
    id: "memorising-moments",
    level: 2,
    title: "Memorising moments",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-sixteen",
    level: 2,
    title: "Exercises for Lesson Sixteen",
    children: [
        
      ]
  },
{
    id: "the-subjunctive-form-of-some-irregular-verbs",
    level: 2,
    title: "The subjunctive form of some irregular verbs",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Sixteen"
}

