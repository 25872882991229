
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"7 min read","minutes":6.92,"time":415200,"words":1384}
/* @jsxRuntime classic */
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import boySitting from "../assets/images/boy-sitting.PNG";
import memorizingMoments from "./memorizing-moments";
import examples from "./lesson-one-examples.js";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-one-greetings"
    }}>{`Dialogue One: Greetings`}</h2>
    <p>{`Where L is you, the Language `}<strong parentName="p">{`Learner`}</strong>{`, and H is your Language `}<strong parentName="p">{`Helper`}</strong></p>
    <Dialogue audio="dialogue-1" mdxType="Dialogue">
  {[{
        s: "L",
        f: "ásalaam aláykUm!",
        p: "السلام علیکم!",
        e: "Peace be upon you!",
        a: "asalaam-alaykuum"
      }, {
        s: "H",
        p: "وعلیکم السلام!",
        f: "wa'álaykUm a'saláam!",
        e: "And peace be upon you!",
        a: "walaykuum-salaam"
      }, {
        s: "L",
        p: "څنګه یې؟ جوړ يې؟",
        f: "tsinga ye? joR ye?",
        e: "How are you? Are you well?",
        a: "tsunga-ye-jorr"
      }, {
        s: "H",
        p: "مهربانی، ښهٔ یم.",
        f: "mirabaanee xu yum",
        e: "Thanks, I'm fine",
        a: "miribaanee-xu-y"
      }, {
        s: "L",
        p: "زه پښتو زده کوم.",
        f: "zu puxto zda kawum",
        e: "I'm learning Pashto",
        a: "zu-puxto-izda-k"
      }, {
        s: "H",
        p: "ډېر ښهٔ!",
        f: "Der xu!",
        e: "Very good!",
        a: "dder-xu"
      }, {
        s: "L",
        p: "زما هم دومره زده ده.",
        f: "zmaa hum dóomra zdá da.",
        e: "This is all I know",
        a: "zmaa-hum-doomra-izda"
      }, {
        s: "H",
        p: "چائې څښې؟",
        f: "chaay ske?",
        e: "Would you like to drink tea?",
        a: "chaay-ske-d"
      }, {
        s: "L",
        p: "مِهربانى، بيا به راشم.",
        f: "mirabaanee, byaa ba raashum",
        e: "Thanks, I will come again.",
        a: "mirabaane-byaa-bu-raashum"
      }, {
        s: "L",
        p: "تکلیف مهٔ کوه. اِجازت دے؟",
        f: "tukléef múkawa. ijaazut day?",
        e: "Don't go to any trouble. Is there permission (to leave?)",
        a: "tukleef-mukawu-ij"
      }, {
        s: "H",
        p: "په مخه دې ښه!",
        f: "pu múkha de xa!",
        e: "May you face only good!",
        a: "pu-mukha-de-xa-d"
      }, {
        s: "L",
        p: "د خُدای په آمان!",
        f: "du khUdáay pu aamáan",
        e: "May you go in God's peace!",
        a: "du-khuudaay-pu-amaan-d"
      }]}
    </Dialogue>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">{`The question `}<Word w={{
          p: "چائې څښې؟",
          f: "chaay ske?",
          a: "chaay-ske-q"
        }} mdxType="Word" /> is an invitation to drink tea. It is polite to automatically refuse the first invitation by saying <Word w={{
          f: "mirabaanee",
          p: "مهربانی",
          a: "mirabaanee"
        }} mdxType="Word" /> If the offer is repeated several times the invitation is sincere and can be accepted by saying <Word w={{
          p: "تکلیف مهٔ کوه",
          f: "tukléef múkawa",
          a: "tukleef-mukawa"
        }} mdxType="Word" /></li>
    </ol>
    <p>{`In addition to chaay ske? the expression che ske? is also commonly heard.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`The phrase `}<Word w={{
          p: "په مخه دې ښه",
          f: "pu mukha de xa",
          a: "pu-mukha-de-xa"
        }} mdxType="Word" />{` is useful for saying goodbye to someone who is leaving.`}</li>
    </ol>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation Drill`}</h2>
    <p>{`☝️ Repeat these sentences after your teacher. Then, contrast the words in focus. The meaning of the sentences is not important.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Contrasting `}<Word w={{
            p: "ت",
            f: "t",
            a: "t-sound"
          }} mdxType="Word" /> with <Word w={{
            p: "د",
            f: "d",
            a: "d-sound"
          }} mdxType="Word" /></p>
        <Examples hideEnglish mdxType="Examples">
  {[{
            p: "دا تُوره ده",
            f: "daa tóora da",
            e: "This is a sword",
            a: "daa-toora-da"
          }, {
            p: "دا دُوړه ده",
            f: "daa dóoRa da",
            e: "This is a dust",
            a: "daa-doorra-da"
          }, {
            p: "دا ترس دے",
            f: "daa tărs day",
            e: "This is pity",
            a: "daa-tars-day"
          }, {
            p: "دا درس دے",
            f: "daa dărs day",
            e: "This is a lesson",
            a: "daa-dars-day"
          }]}
        </Examples>
        <p parentName="li">{`Make a conscious effort to make these sounds 'dental’, with the tongue well forward on the teeth. Old grammar books would often characterize the sounds / t / and / d / as / th / or / dh / to emphasise ho far forward the tongue needs to be placed.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Contrasting `}<Word w={{
            p: "ې",
            f: "e",
            a: "e-sound"
          }} mdxType="Word" /> with <Word w={{
            p: "ي",
            f: "ee",
            a: "ee-sound"
          }} mdxType="Word" /></p>
        <Examples hideEnglish mdxType="Examples">
  {[{
            p: "مینه لرم",
            f: "méena larúm",
            e: "I have love",
            a: "meena-larum"
          }, {
            p: "وېره مهٔ کوه",
            f: "wéra mú kawa",
            e: "Don't be afraid",
            a: "wera-mukawa"
          }, {
            p: "ویر مهٔ کوه",
            f: "weer mú kawa",
            e: "don't grieve",
            a: "weer-mukawa"
          }]}
        </Examples>
      </li>
    </ol>
    <h2 {...{
      "id": "singular-subject-pronouns-and-the-verb-to-be"
    }}>{`Singular subject pronouns and the verb “to be”`}</h2>
    <Table bordered titleRow={["Person", "Pronoun", "Verb"]} mdxType="Table">
  {[[{
        e: "First person singular"
      }, {
        p: "زهٔ",
        f: "zu",
        e: "I",
        a: "zu"
      }, {
        p: "یم",
        f: "yim",
        e: "am",
        a: "yim"
      }], [{
        e: "Second person singular"
      }, {
        p: "تهٔ",
        f: "tu",
        e: "you",
        a: "tu"
      }, {
        p: "یې",
        f: "ye",
        e: "are",
        a: "ye"
      }], [{
        e: "Third person singular masculine"
      }, [{
        p: "دے",
        f: "day",
        e: "he",
        a: "day"
      }, {
        p: "هغه",
        f: "haghá",
        e: "he",
        a: "hagha"
      }], {
        p: "دے",
        f: "day",
        e: "is",
        a: "day-end"
      }], [{
        e: "Third person singular feminine"
      }, [{
        p: "دا",
        f: "daa",
        e: "she",
        a: "daa"
      }, {
        p: "هغه",
        f: "haghá",
        e: "she",
        a: "hagha"
      }], {
        p: "ده",
        f: "da",
        e: "is",
        a: "da"
      }]]}
    </Table>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`The pronouns for “he” and “she” are are `}<Word w={{
        p: "دے",
        f: "day",
        a: "day"
      }} mdxType="Word" /> and <Word w={{
        p: "ده",
        f: "da",
        a: "da"
      }} mdxType="Word" /> when the person is nearby, either physically or in conversation. In their absence or distance both 'he' and 'she' become <Word w={{
        p: "هغه",
        f: "haghá",
        a: "hagha"
      }} mdxType="Word" />{`.`}</p>
    <h2 {...{
      "id": "gender-in-pukhto"
    }}>{`Gender in Pukhto`}</h2>
    <p>{`Gender is an important issue in both Pukhtun culture and language. The clue to a word’s gender is its ending. For example, nouns ending in `}<Word w={{
        p: "ے",
        f: "ay",
        a: "ay-sound"
      }} mdxType="Word" />{` and those ending with a consonant are always masculine.`}</p>
    <h2 {...{
      "id": "pronouns-used-with-masculine-nouns"
    }}>{`Pronouns used with masculine nouns`}</h2>
    <Examples mdxType="Examples">{examples.slice(0, 4)}</Examples>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p>{`There is no definite article in Pukhto, but the number “one” `}<Word w={{
        p: "یو",
        f: "yo",
        a: "yo"
      }} mdxType="Word" />{` is used to convey the idea of indefiniteness.`}</p>
    <h2 {...{
      "id": "recognising-masculine-singular-noun-endings"
    }}>{`Recognising masculine singular noun endings`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`As seen in the example above, nouns ending in the suffix `}<Word w={{
            p: "ے",
            f: "ay",
            a: "ay-sound"
          }} mdxType="Word" />{` are masculine.`}</p>
        <Examples mdxType="Examples">{[examples[4]]}</Examples>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Most nouns ending in a consonant are also masculine`}</p>
        <Examples mdxType="Examples">{examples.slice(5, 8)}</Examples>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Nouns referring to a profession and ending in `}<Word w={{
            p: "ی",
            f: "i",
            e: "ee",
            a: "ee-sound"
          }} mdxType="Word" />{` are masculine.`}</p>
        <Examples mdxType="Examples">{examples.slice(8, 10)}</Examples>
      </li>
    </ol>
    <h2 {...{
      "id": "questions-with-positive-and-negative-replies"
    }}>{`Questions with positive and negative replies`}</h2>
    <Examples mdxType="Examples">{examples.slice(10, 14)}</Examples>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>{`Unlike English, the Pukhto negative verb marker `}<Word w={{
        p: "نهٔ",
        f: "nu",
        a: "nu"
      }} mdxType="Word" />{` comes in front of the equative.`}</p>
    <p>{`☝️ Practice simple questions and answers using the words that have been introduced in this lesson.`}</p>
    <h2 {...{
      "id": "memorising-moment"
    }}>{`Memorising moment`}</h2>
    <Word w={{
      p: "متل",
      f: "matúl",
      e: "proverb",
      a: "matul"
    }} mdxType="Word" />
    <p>{`Nothing expresses deeply held cultural values more aptly than a proverb `}<Word w={{
        p: "متل",
        f: "matúl",
        a: "matul"
      }} mdxType="Word" />{`. Using thee appropriately will always elicit a warm smile.`}</p>
    <p>{`☝️ Memorise these proverbs after you have discussed the meaning and usage with your helper.`}</p>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[1]}</Examples>
    <h2 {...{
      "id": "exercises-for-lesson-one"
    }}>{`Exercises for Lesson One`}</h2>
    <ol>
      <li parentName="ol">{`Use the correct pronoun to complete the following sentences:`}</li>
    </ol>
    <Quiz mdxType="Quiz">
  {[{
        end: {
          p: "هلک دے.",
          f: "halúk day."
        },
        answer: [{
          p: "هغه",
          f: "haghá"
        }, {
          p: "دے",
          f: "day"
        }]
      }, {
        end: {
          p: "قاضی یې؟",
          f: "qaazée ye?"
        },
        answer: [{
          p: "تهٔ",
          f: "tu"
        }]
      }, {
        end: {
          p: "ماشُوم دے.",
          f: "maashóom day."
        },
        answer: [{
          p: "هغه",
          f: "haghá"
        }, {
          p: "دے",
          f: "day"
        }]
      }, {
        end: {
          p: "مالی یم.",
          f: "maalée yim."
        },
        answer: [{
          p: "زهٔ",
          f: "zu"
        }]
      }]}
    </Quiz>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Use the correct form of the “to be” verb in the following sentences:`}</li>
    </ol>
    <Quiz mdxType="Quiz">
  {[{
        beg: {
          p: "دے طالبعلم",
          f: "day taalibílum"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "دے",
          f: "day"
        }]
      }, {
        beg: {
          p: "تهٔ سړے",
          f: "tu saRáy"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "یې",
          f: "ye"
        }]
      }, {
        beg: {
          p: "زه مالی",
          f: "zu maalée"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "یم",
          f: "yim"
        }, {
          p: "یم",
          f: "yum"
        }, {
          p: "یم",
          f: "yum"
        }]
      }, {
        beg: {
          p: "هغه شپُونکی",
          f: "haghá shpoonkáy"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "دے",
          f: "day"
        }]
      }]}
    </Quiz>
    <img src={boySitting} />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-one-greetings",
    level: 2,
    title: "Dialogue One: Greetings",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation Drill",
    children: [
        
      ]
  },
{
    id: "singular-subject-pronouns-and-the-verb-to-be",
    level: 2,
    title: "Singular subject pronouns and the verb “to be”",
    children: [
        
      ]
  },
{
    id: "gender-in-pukhto",
    level: 2,
    title: "Gender in Pukhto",
    children: [
        
      ]
  },
{
    id: "pronouns-used-with-masculine-nouns",
    level: 2,
    title: "Pronouns used with masculine nouns",
    children: [
        
      ]
  },
{
    id: "recognising-masculine-singular-noun-endings",
    level: 2,
    title: "Recognising masculine singular noun endings",
    children: [
        
      ]
  },
{
    id: "questions-with-positive-and-negative-replies",
    level: 2,
    title: "Questions with positive and negative replies",
    children: [
        
      ]
  },
{
    id: "memorising-moment",
    level: 2,
    title: "Memorising moment",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-one",
    level: 2,
    title: "Exercises for Lesson One",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson One"
}

