
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"29 min read","minutes":28.875,"time":1732500,"words":5775}
/* @jsxRuntime classic */
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import memorizingMoments from "./memorizing-moments.jsx";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import PosNegAnswers from "../components/PosNegAnswers";
import oldWomanThinking from "../assets/images/old-woman-thinking.png";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-twenty-five-pukhto"
    }}>{`Dialogue twenty-five: Pukhto`}</h2>
    <Dialogue audio="dialogue-25a" mdxType="Dialogue">
  {[{
        s: "L",
        p: "د پښتُون ټکى مطلب څۀ دے؟",
        f: "du puxtoon Túkee matláb tsu day?",
        e: "What is the meaning of the word Pukhtun?",
        a: "25d1"
      }, {
        s: "H",
        p: ["پ د پت د پاره،", "ښ د ښيګړې د پاره،", "ت د تورزنتوب د پاره،", "و د وفا د پاره", "او ن د ننګيالى د پاره."],
        f: ["pe du put du paara", "xin du xeguRe du paara", "te du torzantób dupáara,", "waw du wafáa du páara", "aw nun du nangyaalée du paara."],
        e: "p is for dignity, kh for help, t for bravery, w for faithful and n for self-sacrifice.",
        a: "25d2"
      }, {
        s: "L",
        p: "ښه، نو په صحيح پښتُون کښې به دا صِفت وى.",
        f: "xa, nu pu sahee puxtoon ke ba daa sifút wee.",
        e: "Ah, so a good Pukhtun will have these characteristics.",
        a: "25d3"
      }, {
        s: "H",
        p: "پکار دے چې وي په کښې.",
        f: "pu kaar day che wee pu ke.",
        e: "It is necessary that they have them.",
        a: "25d4"
      }, {
        s: "L",
        p: "د پښتُون په نظر کښې غيرت څۀ دے؟",
        f: "du puxtoon pu năzúr ke ghayrát tsu day?",
        e: "What do Pukhtuns understand by 'ghairat'?",
        a: "25d5"
      }, {
        s: "H",
        p: "د پښتُون په نظر کښې، غيرت د هر يو ناجائزه کار نه مُخالفت دے.",
        f: "du puxtoon pu năzur ke, ghayrát du hăr yo naajaa`iza kaar na mUkhaalifút day.",
        e: "From the Pukhtun's point of view, 'ghairat' is opposed to everything that is not allowed.",
        a: "25d6"
      }, {
        s: "L",
        p: "ناجائزه کار څۀ ته وائى؟",
        f: "naajaa`iza kaar tsu ta waayee?",
        e: "What is the meaning of things not allowed?",
        a: "25d7"
      }, {
        s: "H",
        p: "ناجائزه هر هغه کار ته وئيلے شى کُوم چې د معاشرې او د مذهب د اصولو نه خِلاف وى.",
        f: "naajaayiz hăr hágha kaar-ta wayulay shee, kum che du maasharé aw du mazhub du Usóolo na khiláaf wee.",
        e: "Unfair things are everything which opposes the rules of society and religion.",
        a: "25d8"
      }]}
    </Dialogue>
    <h2 {...{
      "id": "life-story"
    }}>{`Life story`}</h2>
    <Dialogue audio="dialogue-25b" mdxType="Dialogue">
  {[{
        s: "L",
        p: "ته زما ډېره ښۀ ملګرې يې.",
        f: "tu zmaa Déra xa malɡúre ye.",
        e: "You are a very good friend of mine.",
        a: "25d9"
      }, {
        s: "H",
        p: "زۀ نن غواړم چې ستا د زندګۍ قيصه واؤرم او دا به زما په پښتو کښې مدد وکى. دا صرف زما د اورېدو د پاره ده.",
        f: "zu nun ghwaaRum che staa du zindagúy qeesá wáawrum aw daa ba zmaa pu puxtó ke madúd ookee. daa sírf zmaa du aawredó du paara da.",
        e: "Today I want to hear the story of your life and this will help my Pukhto. It is just for my listening.",
        a: "25d10"
      }, {
        s: "L",
        p: "ټيک ده.",
        f: "Teek da.",
        e: "OK, fine.",
        a: "25d11"
      }, {
        s: "H",
        p: "زۀ به سوالُونه کوم او تاسو به جوابُونه راکوئ.",
        f: "zu ba swaalóona kawúm aw táaso ba jawabóona raakawéy.",
        e: "I will ask questions and you will give the answers.",
        a: "25d12"
      }]}
    </Dialogue>
    <img src={oldWomanThinking} />
    <h3 {...{
      "id": "some-questions"
    }}>{`Some questions`}</h3>
    <Dialogue audio="dialogue-25c" mdxType="Dialogue">
  {[{
        s: "",
        p: "ستا په ژوند کښې اولنې واقعه څۀ ده؟",
        f: "staa pu jzwund ke awalanúy waaqi`a tsu da?",
        e: "What is the first event you remember in your life?",
        a: "25d13"
      }, {
        p: "هغه وخت تۀ د څو کالو وې؟",
        f: "hágha wakht du tso kaalo we?",
        e: "How old were you then?",
        a: "25d14"
      }, {
        p: "ستا په وړوکوالى کښې څۀ خوشحالۍ وې؟",
        f: "staa pu waRookwáalee ke tsu khoshaalúy we?",
        e: "What happiness did you have in childhood?",
        a: "25d15"
      }, {
        p: "تا ته په وړوکوالى کښې څۀ تکليف وو؟",
        f: "taa ta pu waRookwáalee ke tsu tukléef wo?",
        e: "What trouble did you have as a child?",
        a: "25d16"
      }, {
        p: "تا کله وادۀ وکړو؟",
        f: "taa kala waadu óokRo?",
        e: "When did you get married?",
        a: "25d17"
      }, {
        p: "ستا د وادۀ نه پس ژوند څنګه دے؟",
        f: "staa du waadu na pas jzwund tsunga day?",
        e: "What is life after your marriage like?",
        a: "25d18"
      }, {
        p: "تا ته اوس څۀ مُشکلات شته؟",
        f: "taa ta oos tsu mUshkiláat shta?",
        e: "Do you have any difficulties now?",
        a: "25d19"
      }, {
        p: "ستا د مُستقبل د پاره څۀ خواهشات دى؟",
        f: "staa du mUstaqbíl du paara tsu khwaahisháat dee?",
        e: "What are your future wishes?",
        a: "25d20"
      }]}
    </Dialogue>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation drill`}</h2>
    <p>{`Practice this tongue twister:`}</p>
    <Examples memorizingMoment mdxType="Examples">
  {[{
        p: "چې د تورو ورځو نه واؤره ورېده نو ورور مې ورو ورو د ورندارې خواله وراغے.",
        f: "che du toro waréedzo na wáawra waredá no wror mu wro wro du wrandáare khwaa la wăraghay.",
        e: "When it was snowing from the black clouds then my brother slowly went to my sister-in-law's.",
        a: "25tt1"
      }]}
    </Examples>
    <h2 {...{
      "id": "had-done-the-past-perfect-tense"
    }}>{`'had done’: The past perfect tense`}</h2>
    <p>{`Pukhto, like many Indo-European languages, has a past perfect tense, which is used to show which of two past events happened first. For example, “she had arrived when …”. The tense is formed by adding a past equative to a past participle.`}</p>
    <h3 {...{
      "id": "intransitive-verbs-in-the-past-perfect"
    }}>{`Intransitive verbs in the past perfect`}</h3>
    <p>{`The verb `}<Word w={{
        p: "رسېدل",
        f: "rasedúl",
        e: "to arrive",
        a: "rasedul-m"
      }} mdxType="Word" />{` is a good example of how most regular intransitive verbs are conjugated in the past perfect:`}</p>
    <Table bordered unset verticalContent titleRow={["Person", "Singular", "Plural"]} mdxType="Table">
  {[[{
        e: "1st m."
      }, {
        p: "رسېدلے وم",
        f: "rasedúlay wum",
        e: "I had arrived",
        a: "25v1f"
      }, {
        p: "رسېدلی وو",
        f: "rasedúlee woo",
        e: "We had arrived",
        a: "25v2"
      }], [{
        e: "1st f."
      }, {
        p: "رسېدلې وم",
        f: "rasedúle wum",
        e: "I had arrived",
        a: "25v3"
      }, {
        p: "رسېدلې وو",
        f: "rasedúle woo",
        e: "We had arrived",
        a: "25v4"
      }], [{
        e: "2nd m."
      }, {
        p: "رسېدلے وې",
        f: "rasedúlay we",
        e: "You had arrived",
        a: "25v5"
      }, {
        p: "رسېدلی وئ",
        f: "rasedúlee wey",
        e: "You had arrived",
        a: "25v6"
      }], [{
        e: "2nd f."
      }, {
        p: "رسېدلې وې",
        f: "rasedúle we",
        e: "You had arrived",
        a: ""
      }, {
        p: "رسېدلې وئ",
        f: "rasedúle wey",
        e: "You had arrived",
        a: ""
      }], [{
        e: "3rd m."
      }, {
        p: "رسېدلے وو",
        f: "rasedúlay wo",
        e: "He has arrived",
        a: ""
      }, {
        p: "رسېدلی وُو",
        f: "rasedúlee woo",
        e: "They had arrived",
        a: ""
      }], [{
        e: "3rd f."
      }, {
        p: "رسېدلې وه",
        f: "rasedúle wa",
        e: "She has arrived",
        a: "25v7"
      }, {
        p: "رسېدلې وې",
        f: "rasedúle we",
        e: "They had arrived",
        a: "25v8"
      }]]}
    </Table>
    <h4 {...{
      "id": "example-sentences-using-intransitive-verbs-in-the-past-perfect-tense"
    }}>{`Example sentences using intransitive verbs in the past perfect tense:`}</h4>
    <Examples mdxType="Examples">
  {[{
        p: "پرُون تۀ چرته تلے وې؟",
        f: "paróon tu chărta tulay we?",
        e: "Where had you (m.) gone yesterday?",
        a: "25e1"
      }, {
        p: "زۀ ښار ته تلے وم.",
        f: "zu xaar to túlay wum.",
        e: "I had gone to the city.",
        a: "25e2"
      }, {
        p: "هغه د شپې نه مخکښې ښار ته رسېدلې وه؟",
        f: "haghá du shpe na makhke xaar ta rasedúle wa?",
        e: "Had she reached the city before night?",
        a: "25e3"
      }, {
        p: "نه، هغه نۀ وه رسېدلې.",
        f: "na, haghá nu wa rasedúle.",
        e: "No, she had not arrived.",
        a: "25e4"
      }, {
        p: "يوڅو ورځې مخکښې تاسو هلته تلى وئ؟",
        f: "yo tso wradzo makhke taaso halta túlee wey?",
        e: "Had you (m.pl.) gone there a few days ago?",
        a: "25e5"
      }, {
        p: "آو، مُونږ تلى وُو.",
        f: "aw, moonG túlee woo.",
        e: "Yes, we had gone.",
        a: "25e6"
      }, {
        p: "داؤد چا سره راغلے وو؟",
        f: "daawÚd chaa sara ráaghulay wo?",
        e: "Who had David come with?",
        a: "25e7"
      }, {
        p: "هغه د خپلې خور سره راغلے وو.",
        f: "haghá da khpule khor sara ráaghulay wo.",
        e: "He had come with his sister.",
        a: "25e8"
      }, {
        p: "مخکښې ته د فوج نه تښتېدلے وې؟",
        f: "makhke to du foj na tuxtedúlay we?",
        e: "Had you (m.) run away from the army before?",
        a: "25e9"
      }, {
        p: "آو، تېر کال هم.",
        f: "aw, ter kaal hum.",
        e: "Yes, last year also.",
        a: "25e10"
      }, {
        p: "هغوى تا سره خبرې کړې وې؟",
        f: "haghwée taa sara khabúray kúRe we?",
        e: "Had they talked with you?",
        a: "25e11"
      }, {
        p: "نه، هغوى ما سره نۀ وې کړې.",
        f: "na, haghwée maa sara nu we kúRe.",
        e: "No, they hadn't talked with me.",
        a: "25e12"
      }, {
        p: "چې تۀ سوات ته تلے وې تا هلته څومره وخت تېر کړے وو؟",
        f: "che tu swaat ta túlay we, taa halta tsomra wakht ter kúRay wo?",
        e: "When you (m.) had gone to Swat, how long did you stay there?",
        a: "25e13"
      }, {
        p: "ما هلته يو کال تېر کړے وو.",
        f: "maa halta yo kaal ter kúRay wo.",
        e: "I stayed there for one year.",
        a: "25e14"
      }, {
        p: "غل زمُونږ کور ته راغلے وو.",
        f: "ghul zmoonG koor ta ráaghulay wo.",
        e: "The thief had come to our house.",
        a: "25e15"
      }, {
        p: "تاسو پخوا په مينګوره کښې اوسېدلى وئى؟",
        f: "taaso pakhwáa pu meengorá ke osedúlee wey?",
        e: "Had you lived in Mingora long ago?",
        a: "25e16"
      }, {
        p: "نه، مُونږ هلته نۀ وُو اوسېدلى.",
        f: "na, moonG halta nu woo osedúlee.",
        e: "No, we had not lived there.",
        a: "25e17"
      }, {
        p: "ستاسو پردې شلېدلې وې؟",
        f: "staaso pardé shledúle we?",
        e: "Had your curtains been torn? (f.pl.)",
        a: "25e26"
      }]}
    </Examples>
    <h2 {...{
      "id": "compound-intransitive-verbs-in-the-past-perfect"
    }}>{`Compound intransitive verbs in the past perfect`}</h2>
    <p>{`In the past perfect, as with all intransitive verbs, the past participle of the verb agrees with the number and gender of the subject:`}</p>
    <Table bordered unset verticalContent titleRow={["Person", "Singular", "Plural"]} mdxType="Table">
  {[[{
        e: "1st m."
      }, {
        p: "سنړے شوے وم",
        f: "stúRay shíway wum",
        e: "I had become tired",
        a: "25v13"
      }, {
        p: "ستړی شوی وو",
        f: "stúRee shíwee woo",
        e: "We had become tired",
        a: "25v14"
      }], [{
        e: "1st f."
      }, {
        p: "ستړې شوې وم",
        f: "stúRe shíwe wum",
        e: "I had become tired",
        a: ""
      }, {
        p: "ستړې شوې وو",
        f: "stúRe shíwe woo",
        e: "We had become tired",
        a: ""
      }], [{
        e: "2nd m."
      }, {
        p: "سنړے شوے وې",
        f: "stúRay shíway we",
        e: "You had become tired",
        a: "25v17"
      }, {
        p: "ستړی شوی وئ",
        f: "stúRee shíwee wey",
        e: "You  had become tired",
        a: "25v18"
      }], [{
        e: "2nd f."
      }, {
        p: "ستړې شوې وې",
        f: "stúRe shíwe we",
        e: "You had become tired",
        a: ""
      }, {
        p: "ستړې شوې وئ",
        f: "stúRe shíwe wey",
        e: "You had become tired",
        a: ""
      }], [{
        e: "3rd m."
      }, {
        p: "سنړے شوے وو",
        f: "stúRay shíway wo",
        e: "He had become tired",
        a: ""
      }, {
        p: "ستړی شوی وُو",
        f: "stúRee shíwee woo",
        e: "They had become tired",
        a: ""
      }], [{
        e: "3rd f."
      }, {
        p: "ستړې شوې وه",
        f: "stúRe shíwe wa",
        e: "She had become tired",
        a: "25v23"
      }, {
        p: "ستړې شوې وې",
        f: "stúRe shíwe we",
        e: "They had become tired",
        a: "25v24"
      }]]}
    </Table>
    <Examples mdxType="Examples">
  {[{
        p: "موټر پخوا خراب شوے وو؟",
        f: "moTúr pakhwáa kharáab shíway wo?",
        e: "Had the car gone bad before? (m.s.)",
        a: "25e18"
      }, {
        p: "آو، هغه تېر کال هم خراب شوے وو.",
        f: "aw, haghá tek kaal hum kharáab shíway wo.",
        e: "Yes, it had also gone bad last year.",
        a: "25e19"
      }, {
        p: "ته هغوى ته په قهر شوے وې؟",
        f: "tu haghwée tu pu qahur shíway we?",
        e: "Had you become angry with them?",
        a: "25e20"
      }, {
        p: "نه، مُونږ هغوى ته نۀ وُو په قهر شوى.",
        f: "na, moonG haghwée ta nu woo pu qahur shíwee.",
        e: "No, we had not been angry with them.",
        a: "25e21"
      }, {
        p: "تاسو مخکښې وادۀ شوې وئ؟",
        f: "taaso makhke waadu shíwe wey?",
        e: "Had you (f.pl.) been married before?",
        a: "25e22"
      }, {
        p: "آو، زۀ وادۀ شوې وم خو خاوند مې مړ شو.",
        f: "aw, zu waadu shíwe wum kho khaawún me muR sho.",
        e: "Yes, I had been married but my husband died.",
        a: "25e23"
      }, {
        p: "هغه وخت کار ختم شوے وو؟",
        f: "hágha wakht kaar khátum shíway wo?",
        e: "Had the job been completed at that time? (m.s.)",
        a: "25e24"
      }, {
        p: "آو، ختم شوے وو.",
        f: "aw, khatum shíway wo.",
        e: "Yes, it had been completed.",
        a: "25e25"
      }, {
        p: "ستا زوئ کله ناجوړه شوے وو؟",
        f: "staa zooy kala naajóRa shíway wo?",
        e: "When had your son become sick? (m.s.)",
        a: "25e27"
      }, {
        p: "هغه تېر کال ناجوړه شوے وو.",
        f: "haghá ter kaal naajóRa shíway wo.",
        e: "He had become sick last year.",
        a: "25e28"
      }, {
        p: "کاپى ډېره مخکښې ورکه شوې وه؟",
        f: "kaapée Dera makhke wrúka shíwe wa?",
        e: "Had the notebook been lost long before? (f.s.)",
        a: "25e29"
      }, {
        p: "ټول لرګى مات شوى وُو.",
        f: "Tool largée maat shíwee woo.",
        e: "All the wood had been cut. (m.pl.)",
        a: "25e32"
      }]}
    </Examples>
    <h3 {...{
      "id": "passive-sentences-in-the-past-perfect"
    }}>{`Passive sentences in the past perfect`}</h3>
    <p>{`As always, when transitive verbs are written in a passive construction, the verb agrees with the subject, because there is no object.`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "ضياء ويشتلے شوے وو؟",
        f: "zee`aa weeshtulay shíway wo?",
        e: "Had Zia been shot? (m.s.)",
        a: "25e30"
      }, {
        p: "آو، ويشتلے شوے وو، خو قاتِل په هغه ساعت باندې ونيولے شو.",
        f: "aw, weeshtúlay shíway wo, kho qaatíl pu hágha saat baande ooneewúlay sho.",
        e: "Yes, he had been shot, but the assassin was caught at the same time. (m.s.)",
        a: "25e31"
      }, {
        p: "تۀ پخوا نيولے شوے وې؟",
        f: "tu pakhwáa neewulay shíway we?",
        e: "Had you been caught before? (m.s.)",
        a: "25e33"
      }, {
        p: "نه، زۀ نۀ وم نيولے شوے.",
        f: "na, zu nu wum neewulay shíway.",
        e: "No, I hadn't been caught.",
        a: "25e34"
      }]}
    </Examples>
    <h3 {...{
      "id": "transitive-verbs-in-the-past-perfect"
    }}>{`Transitive verbs In the past perfect`}</h3>
    <p>{`As with other past tenses, agreement is with the object:`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "کتاب مې ليکلے وو.",
        f: "kitaab me leekúlay wo.",
        e: "I had written a book. (m.s.)",
        a: "25e35"
      }, {
        p: "کتابونه مې ليکلى وُو.",
        f: "kitaabóona me leekúlee woo.",
        e: "I had written books. (m.pl.)",
        a: "25e36"
      }, {
        p: "يوه لطيفه مې ليکلې وه.",
        f: "yuwa lateefá me leekúle wa.",
        e: "I had written a joke. (f.s.)",
        a: "25e37"
      }, {
        p: "لطيفې مې ليکلې وې.",
        f: "lateefé me leekúle we.",
        e: "I had written jokes. (f.pl.)",
        a: "25e38"
      }, {
        p: "ښوروا يې څښلې نۀ وه.",
        f: "xorwáa ye skúle nu wa.",
        e: "He hadn't drunk the soup. (f.s.)",
        a: "25e39"
      }, {
        p: "پئ مې څښلى نۀ وُو.",
        f: "puy me skúle nu woo.",
        e: "I hadn't drunk the milk. (m.pl.)",
        a: "25e40"
      }]}
    </Examples>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>
  <Word w={{
        p: "پۍ",
        f: "puy",
        e: "",
        a: ""
      }} mdxType="Word" /> is a feminine plural noun,
  but here it seems to be used as if it was masculine plural.
    </p>{" "}
    <h2 {...{
      "id": "substitution-drill"
    }}>{`Substitution drill`}</h2>
    <p>{`👆 Have your helper call out the names of different objects and subjects for you to substitute in the sentence below. You can use `}<a parentName="p" {...{
        "href": "https://www.interlitfoundation.org/learning-dari/picture-it-in-dari-and-pashto"
      }}>{`Picture It in Dari and Pashto`}</a>{` or a magazine to help you.`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "هغۀ ډوډۍ خوړلې وه.",
        f: "haghú DoDúy khoRúle wa.",
        e: "He had eaten food.",
        a: "25s1"
      }, {
        p: "هغوى ولې تر اوسه پورې کور نۀ وو اخستے؟",
        f: "haghwée wăle tur oosa pore kor nu wo akhistáy?",
        e: "Why hadn't they bought a home until now?",
        a: "25s2"
      }, {
        p: "هغوى سره پیسې نۀ وې.",
        f: "haghwée sara payse nu we.",
        e: "They had no money.",
        a: "25s3"
      }, {
        p: "تاسو خپلې جامې پاکې وينځلې وې؟",
        f: "taaso khpule jaame paake weendzule we?",
        e: "Had you washed your own clothes?",
        a: "25s4"
      }, {
        p: "نه، دوبى وينځلې وې.",
        f: "na, dobée weendzúle we.",
        e: "No, the washerman had washed them.",
        a: "25s5"
      }, {
        p: "تا مخکښې کجُورې خوړلې وې؟",
        f: "taa makhke kajóore khoRúle we?",
        e: "Had you eaten dates before?",
        a: "25s6"
      }, {
        p: "تا ځان سره کجُورې راوړلې وې؟",
        f: "taa dzaan sara kajóore raawRúle we?",
        e: "Had you brought dates with you?",
        a: "25s7"
      }, {
        p: "نه، ما سره ځائ نۀ وو.",
        f: "na, maa sara dzaay nu wo.",
        e: "No, I didn't have room.",
        a: "25s8"
      }, {
        p: "هغوى ځان سره ډېر سامان راوړے وو؟",
        f: "haghwée dzaan sara Der saamáan raawúRay wo?",
        e: "Had they brought a lot of luggage with them?",
        a: "25s9"
      }, {
        p: "نه، هر يو سره دوه بېګُونه وُو.",
        f: "na, hăr yo sara dwa begóona wo.",
        e: "No, they had only brought two bags each.",
        a: "25s10"
      }, {
        p: "تاسو خپل ملګرے په آخرنۍ پېره کله ليدلے وو؟",
        f: "taaso khpul malgúray pu akhiranúy perá kala leedúlay wo?",
        e: "When is the last time you had seen your friend? (m.s.)",
        a: "25s11"
      }, {
        p: "هغه ما درې کاله مخکښې ليدلے وو.",
        f: "haghá maa dre kaala makhke leedúlay wo.",
        e: "I had seen him three years ago.",
        a: "25s12"
      }, {
        p: "خپل بنين دې پخپله جوړ کړے وو؟",
        f: "khpul banén de pukhpúla joR kúRay wo?",
        e: "Had you knitted your own sweater?",
        a: "25s13"
      }, {
        p: "آو، جوړ کړے مې وو.",
        f: "aw, joR kúRay me wo.",
        e: "Yes, I had.",
        a: "25s14"
      }, {
        p: "هغې زما بکس ښۀ تړلے وو؟",
        f: "haghé zmaa baks xu tuRúlay wo?",
        e: "Had she wrapped my box well? (m.s.)",
        a: "25s15"
      }, {
        p: "نه، هغې سم نۀ وو تړلے.",
        f: "na, haghé sum nu wo taRúlay.",
        e: "No, she hadn't wrapped it well.",
        a: "25s16"
      }, {
        p: "تاسو مخکښې د هغې په باره کښې څۀ اورېدلى وُو ؟",
        f: "taaso makhke du haghé pu baara ke tsu aawredúlee woo?",
        e: "What had you heard about her before?",
        a: "25s17"
      }, {
        p: "آو، اورېدلى مې وُو خو باور مې پې [پرې] نۀ راتلو.",
        f: "aw, aawredúlee me wo, kho baawúr me pe nu raatló.",
        e: "Yes, I had heard about it, but I didn't believe it was true.",
        a: "25s18"
      }, {
        p: "هغوى پخوا تۀ پېژندلے وې؟",
        f: "haghwée pakhwáa tu pejzandúlay we?",
        e: "Had they known you before? (m.s.)",
        a: "25s19"
      }, {
        p: "نه، هغوى زۀ نۀ وم پېژندلے.",
        f: "na, haghwée zu nu wum pejzandúlay.",
        e: "No, they had not known me.",
        a: "25s20"
      }, {
        p: "ډېر وخت کېږى چې داسې مې نۀ وُو خاندلى!",
        f: "Der wakht kéGee che daase me nu woo khandúlee.",
        e: "It has been a long time since I had laughed like this!",
        a: "25s21"
      }, {
        p: "ډېر مهيان دې نيولى وُو؟",
        f: "Der mahyáan de neewúlee woo?",
        e: "Had you caught many fish?",
        a: "25s22"
      }, {
        p: "تا تېر کال رباب وهلے وو؟",
        f: "taa ter kaal rabáab wahúlay wo?",
        e: "Had you played rabab last year?",
        a: "25s23"
      }, {
        p: "نه، ما سره وخت نۀ وو.",
        f: "na, maa sara wakht nu wo.",
        e: "No, I didn't have time.",
        a: "25s24"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p>{`👆 Colloquial expressions like, `}<Word w={{
        p: "باور مې پرې نهٔ راتلو",
        f: "baawúr me pre nu raatló",
        e: "",
        a: "baawur-me-nu"
      }} mdxType="Word" />{` should be practiced with your helper in different sentences. However, you will only get to really remember this expression if you then use it in real settings.`}</p>
    <h4 {...{
      "id": "compound-transitive-verbs-in-the-past-perfect"
    }}>{`Compound transitive verbs in the past perfect`}</h4>
    <Examples mdxType="Examples">
  {[{
        p: "ستا نيکۀ د مرګ نه مخکښې کُوم آخیرنے کار کړے وو؟",
        f: "staa neekú du murg na makhke kum akhiranáy kaar kúRay wo?",
        e: "What was the last thing your grandfather had done before he died?",
        a: "25e41"
      }, {
        p: "زما نيکۀ خپل ټوپک صفا کړے وو.",
        f: "zmaa neekú khpul Topák safáa kúRay wo.",
        e: "My grandfather had cleaned his gun.",
        a: "25e42"
      }, {
        p: "تاسو په پېښور کښې څو کاله تېر کړى وُو؟",
        f: "taaso pu pexawúl ke tso kaala ter kúRee woo?",
        e: "How many years had you spent in Peshawar?",
        a: "25e43"
      }, {
        p: "مُونږ يو کال هلته تېر کړے وو.",
        f: "moonG yo kaal halta ter kúRay woo.",
        e: "We had spent a year there.",
        a: "25e44"
      }, {
        p: "هغۀ کړکۍ جوړه کړې وه؟",
        f: "haghú kirkúy jóRa kúRe wa?",
        e: "Had he made the window? (f.s.)",
        a: "25e45"
      }, {
        p: "نه، هغۀ نۀ وه جوړه کړې.",
        f: "na, haghú nu wa joRá kúRe.",
        e: "No, he had not made it.",
        a: "25e46"
      }, {
        p: "هغۀ څۀ قسم کُرسۍ ستا د پاره جوړې کړې وې؟",
        f: "haghú tsu qísum kUrsúy staa du paara joRe kúRe we?",
        e: "What type of chairs had he made for you? (f.pl.)",
        a: "25e47"
      }, {
        p: "هغۀ د لرګى کُرسۍ جوړې کړې وې.",
        f: "haghú du largée kUrsúy joRe kúRe we.",
        e: "He had made wooden chairs.",
        a: "25e48"
      }, {
        p: "خپل موټر دې پاړه چنار کښې خرڅ کړے وو؟",
        f: "khpul moTúr de paaRa chináar ke kharts kúRay wo?",
        e: "Had you sold your car in Parachinar?",
        a: "25e49"
      }, {
        p: "آو، ما خرڅ کړے وو.",
        f: "aw, maa kharts kúRay wo.",
        e: "Yes, I had sold it.",
        a: "25e50"
      }, {
        p: "تا بيا دا هېر کړے وو؟",
        f: "taa byaa daa her kúRay wo?",
        e: "Had you forgotten it again?",
        a: "25e51"
      }, {
        p: "نه، ما نۀ وو هېر کړے.",
        f: "na, maa nu wo her kúRay.",
        e: "No, I hadn't forgotten it.",
        a: "25e52"
      }, {
        p: "هغۀ دا ياد کړے وو؟",
        f: "haghú daa yaad kúRay wo?",
        e: "Had he remembered it?",
        a: "25e53"
      }, {
        p: "آو، هغۀ ياد کړے وو.",
        f: "aw, haghá yaad kúRay wo.",
        e: "Yes, he had remembered.",
        a: "25e54"
      }, {
        p: "تا د هغې مدد کړے وو؟",
        f: "taa du haghé madúd kúRay wo?",
        e: "Had you given her help? (m.s.)",
        a: "25e55"
      }, {
        p: "نه، ما د هغې مدد نۀ وو کړے.",
        f: "na, maa du haghé madúd nu wo kúRay.",
        e: "No, I hadn't given her help.",
        a: "25e56"
      }]}
    </Examples>
    <h2 {...{
      "id": "are-you-busy-word-w-p-مصرُوف-f-masróof-e--a-masroof-f---word-w-p-اخته-f-akhtá-e--a-akhta-f---word-w-p-لګیا-f-ligyáa-e--a-lugyaa-f--"
    }}>{`Are you busy? `}<Word w={{
        p: "مصرُوف",
        f: "masróof",
        e: "",
        a: "masroof-f"
      }} mdxType="Word" />, <Word w={{
        p: "اخته",
        f: "akhtá",
        e: "",
        a: "akhta-f"
      }} mdxType="Word" />, <Word w={{
        p: "لګیا",
        f: "ligyáa",
        e: "",
        a: "lugyaa-f"
      }} mdxType="Word" /></h2>
    <Examples mdxType="Examples">
  {[{
        p: "ستا خاوند ثابته شپه په څۀ مصرُوف وو؟",
        f: "staa khaawúnd saabúta shpa pu tsu masróof wo?",
        e: "What was your husband busy doing all night long?",
        a: "25e57"
      }, {
        p: "هغه ټوله شپه په جوارۍ باندې مصرُوف وو.",
        f: "haghá Tola shpa pu jawaarúy baande masróof wo.",
        e: "He was busy gambling all night long.",
        a: "25e58"
      }, {
        p: "تۀ شپه ورځ په چائې څښلو اخته يې!",
        f: "tu shpa, wradz pu chaay skúlo akhtá ye!",
        e: "You are busy drinking tea night and day!",
        a: "25e59"
      }, {
        p: "زۀ په خپل نوے کار کښې مصرُوف شوے يم.",
        f: "zu pu khpul núway kaar ke masróof shíway yim.",
        e: "I have become busy with my new job.",
        a: "25e60"
      }, {
        p: "د غرمې نه په کتاب وئيلو لګيا شوے يې!",
        f: "du gharmé na pu kitáab wayúlo ligyáa shíway ye!",
        e: "You have been busy reading the book since noon!",
        a: "25e61"
      }, {
        p: "هغه ښځې په څۀ کار کښې مصرُوفې دى؟",
        f: "hágha xúdze pu tsu kaar ke masróofe dee?",
        e: "What have those women been busy with?",
        a: "25e62"
      }, {
        p: "هغوى په ګُلکارۍ د سحر نه لګيا دى.",
        f: "haghwée pu gUlkáaruy du sahár na ligyáa dee.",
        e: "They have been busy embroidering since morning.",
        a: "25e63"
      }]}
    </Examples>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`All three adjectives are interchangeable in most contexts.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Although `}<Word w={{
            p: "اخته",
            f: "akhta",
            e: "",
            a: "akhta-f"
          }} mdxType="Word" /> and <Word w={{
            p: "لګیا",
            f: "ligyáa",
            e: "",
            a: "lugyaa-f"
          }} mdxType="Word" />{` are adjectives, the are not inflectable and do not change according to gender.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In addition to meaning “busy’, 'engaged” and 'occupied’, the words `}<Word w={{
            p: "اخته",
            f: "akhta",
            e: "",
            a: "akhta-f"
          }} mdxType="Word" /> and <Word w={{
            p: "لګیا",
            f: "ligyáa",
            e: "",
            a: "lugyaa-f"
          }} mdxType="Word" />{` can also refer to someone who has a bad habit, or is suffering from a disease. In that context they carry a negative meaning.`}</p>
      </li>
    </ol>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "ياران دې په نشو تماشو لګيا دى.",
        f: "yaaráan de pu nashó tamaashó ligyáa dee.",
        e: "Your friends are involved in bad things.",
        a: "25e64"
      }, {
        p: "هغه د ډېرې مودې نه په رنځ اخته دے.",
        f: "haghá du Dére moodé na pu randz akhta day.",
        e: "He has been sick for a long time.",
        a: "25e65"
      }]}
    </Examples>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">
        <Word w={{
          p: "اخته",
          f: "akhta",
          e: "",
          a: "akhta-f"
        }} mdxType="Word" /> is often used with
a negative meaning in Pukhto idioms. The idiom below is used by someone who has
been offended of the person who offended them. It can be used by anyone, but it
is most commonly said by women:
      </li>
    </ol>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "د زړۀ په رنځ اخته شې!",
        f: "du zRu pu randz akhta she!",
        e: "May you stay sad for ever! (Lit. May you suffer the sickness of the heart!)",
        a: "25e66"
      }]}
    </Examples>
    <h2 {...{
      "id": "memorising-moment"
    }}>{`Memorising moment`}</h2>
    <h4 {...{
      "id": "rahman-baba"
    }}>{`Rahman Baba`}</h4>
    <Examples memorizingMoment mdxType="Examples">{[memorizingMoments[25][0]]}</Examples>
    <h4 {...{
      "id": "matalóona"
    }}>{`matalóona`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[25].slice(1, 4)}</Examples>
    <h2 {...{
      "id": "comprehension-drill-tense-production"
    }}>{`Comprehension drill: Tense production`}</h2>
    <p>{`In this drill you will talk about an activity in different tenses.`}</p>
    <p>{`👆 Your helper will give you an instruction and then you will say what you are about to do (future), what you are doing (present), what you did (simple past) and then add the other past tenses.`}</p>
    <p>{`For example your helper might say:`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "قلم په بکس کښې کېږده!",
        f: "qalúm pu bakus ke kéGda!",
        e: "Put the pen in the box!",
        a: "25c1"
      }]}
    </Examples>
    <p>{`You respond with a series of sentences:`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "زۀ به قلم په بکس کښې کېږدم.",
        f: "zu ba qalúm pu bákus ke keGdúm.",
        e: "I will put the pen in the box.",
        a: "25c2"
      }, {
        p: "زۀ قلم په بکس کښې کېدم.",
        f: "zu qalúm pu bákus keGdúm",
        e: "I am putting the pen in the box.",
        a: "25c3"
      }, {
        p: "ما قلم په بکس کښې کېښودو.",
        f: "maa qalúm pu bákus ke kéxodo.",
        e: "I put the pen in the box.",
        a: "25c4"
      }, {
        p: "ما قلم په بکس کښې کېښودلے دے.",
        f: "maa qalúm pu bákus ke kexodúlay day.",
        e: "I have put the pen in the box.",
        a: "25c5"
      }, {
        p: "ما قلم په بکس کښې کېښودلے وو.",
        f: "maa qalúm pu bákus ke kexodúlay wo.",
        e: "I had put the pen in the box.",
        a: "25c6"
      }, {
        p: "د سبق نه پس ما خپل قلم په بکس کښې کېښودو.",
        f: "du sabúq na pas maa khpul qalúm pu bákus ke kéxodo.",
        e: "After class I used to put the pen in the box.",
        a: "25c7"
      }]}
    </Examples>
    <p>{`Try the same drill by talking about someone else doing the same thing: he is, she is, they (m./f.) are, etc. Then try the same process using different verbs. For example,`}</p>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "راؤباسه",
        f: "raa-óobaasa",
        e: "take out",
        a: "raa-oobaasa-m1"
      }, {
        p: "ورکه",
        f: "wăr-ka",
        e: "give",
        a: "war-ka-m1"
      }, {
        p: "ځه",
        f: "dza",
        e: "go",
        a: "dza-m1"
      }, {
        p: "راشه",
        f: "ráasha",
        e: "come",
        a: "raasha-m1"
      }, {
        p: "ودرېږه",
        f: "óodreGa",
        e: "stand up",
        a: "oodregga-m1"
      }, {
        p: "کښېنه",
        f: "kéna",
        e: "sit down",
        a: "kena-m1"
      }]}
    </Examples>
    <p>{`Look at list of verbs from past chapters and drill these for a few days. Remember to keep the object the same and keep the sentence very simple. You are trying to learn verbs not new vocabulary.`}</p>
    <h2 {...{
      "id": "exercises-for-lesson-twenty-five"
    }}>{`Exercises for Lesson Twenty-Five`}</h2>
    <ol>
      <li parentName="ol">{`In the following sentences change the following present perfect verbs to their past perfect verb forms. For example:`}</li>
    </ol>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "ما تر اوسه پورې کابل نۀ دے ليدلے.",
        f: "maa tur óosa pore kaabul nu day leedúlay.",
        e: "",
        a: ""
      }]}
    </Examples>
    <p>{`becomes:`}</p>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "ما تر اوسه پورې کابل نۀ وو ليدلے.",
        f: "maa tur óosa pore kaabul nu wo leedúlay.",
        e: "",
        a: ""
      }]}
    </Examples>
    <Quiz mdxType="Quiz">
  {[{
        q: {
          p: "کُوم وخت دې ليدلے دی؟",
          f: "kum wakht de leedúlay dee?"
        },
        answer: [{
          p: "کُوم وخت دې ليدلے وُو؟",
          f: "kum wakht de leedúlay woo?"
        }]
      }, {
        q: {
          p: "دوه ورځې کېږى چې راغلى يُو.",
          f: "dwa wradze keGee che ráaghulee yoo."
        },
        answer: [{
          p: "دوه ورځې کېدې چې راغلی وُو.",
          f: "dwa wradze kedé che ráaghulee woo."
        }, {
          p: "دوه ورځې کېدلې چې راغلی وُو.",
          f: "dwa wradze kedúle che ráaghulee woo."
        }]
      }, {
        q: {
          p: "دۀ پخپله جامې وينځلې دى.",
          f: "du pukhpúla jaame weendzúle dee."
        },
        answer: [{
          p: "دۀ پخپله جامې وينځلې وو.",
          f: "du pukhpúla jaame weendzúle woo."
        }]
      }, {
        q: {
          p: "زيبا څو بجې تلې ده؟",
          f: "zeebáa tso baje túle da?"
        },
        answer: [{
          p: "زيبا څو بجې تلې وه؟",
          f: "zeebáa tso baje túle wa?"
        }, {
          p: "زيبا څو بجې تللې وه؟",
          f: "zeebáa tso baje tlúle wa?"
        }]
      }, {
        q: {
          p: "تا ژړلى دى؟",
          f: "taa jzaRúlee dee?"
        },
        answer: [{
          p: "تا ژړلى وو؟",
          f: "taa jzaRúlee woo?"
        }]
      }, {
        q: {
          p: "تا پرُون زۀ ليدلے يم؟",
          f: "taa paróon zu leedúlay yim?"
        },
        answer: [{
          p: "تا پرُون زۀ ليدلے وم؟",
          f: "taa paróon zu leedúlay wum?"
        }]
      }]}
    </Quiz>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Give both negative and affirmative short answers to the following questions. For example:`}</li>
    </ol>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "ځان سره دې بوتلې وه؟",
        f: "dzaan sara de botúle wa?",
        e: "",
        a: "25q1"
      }]}
    </Examples>
    <p>{`becomes:`}</p>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "او، بوتلې مې وه.",
        f: "aw, botúle me wa.",
        e: "",
        a: "25q2"
      }, {
        p: "نه، نه مې وه بوتلې.",
        f: "na, nu me wa botúle.",
        e: "",
        a: "25q3"
      }]}
    </Examples>
    <PosNegAnswers storeSet="a" mdxType="PosNegAnswers">
  {[{
        p: "کتاب دې لائبرېرئ ته راؤړلے وو؟",
        f: "kitáab de laaybrerúy ta raawRúlay wo?",
        e: "",
        a: "25q4"
      }, {
        p: "تا ورسره کار کړے وو؟",
        f: "taa wăr-sara kaar kúRay wo?",
        e: "",
        a: "25q5"
      }, {
        p: "پرُون دې لامبو وهلې وه؟",
        f: "paróon de láambo wahúle wa?",
        e: "",
        a: "25q6"
      }, {
        p: "هغوى آلُوګان سپين کړى و و؟",
        f: "haghwée aaloogáan speen kúRee woo?",
        e: "",
        a: "25q7"
      }]}
    </PosNegAnswers>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Complete the following questions with the past perfect forms of the verbs given in the right-hand column and then answer them. For example:`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "تلل",
        f: "tlul",
        e: "",
        a: ""
      }, {
        p: "هغه څهٔ وخت ـــــ ؟",
        f: "haghá tsu wakht ______ ?",
        e: "",
        a: ""
      }]}
    </Examples>
    <p>{`becomes`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "هغه څهٔ وخت تلے وو؟",
        f: "haghá tsu wakht túlay wo?",
        e: "",
        a: ""
      }]}
    </Examples>
    <Quiz mdxType="Quiz">
  {[{
        pref: {
          p: "لیکل",
          f: "leekúl"
        },
        beg: {
          p: "کتاب دې څو کاله مخکښې",
          f: "kitaab de tso kaala makhke"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "لیکلے وو",
          f: "leekúlay wo"
        }]
      }, {
        pref: {
          p: "مدد کول",
          f: "madúd kawúl"
        },
        beg: {
          p: "تاسو ورسره هم",
          f: "taaso wăr-sara hum"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "مدد کړے وو",
          f: "madúd kúRay wo"
        }]
      }, {
        pref: {
          p: "صفا کول",
          f: "safáa kawúl"
        },
        beg: {
          p: "هغۀ پخپله زما کوټه",
          f: "haghú pukhpúla zmaa koTá"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "صفا کړه",
          f: "safáa kRa"
        }, {
          p: "صفا که",
          f: "safáa ka"
        }, {
          p: "صفا کړله",
          f: "safáa kRula"
        }]
      }, {
        pref: {
          p: "اورېدل",
          f: "aawredul"
        },
        beg: {
          p: "تر اوسه دې خبر نۀ",
          f: "tur oosa de khabúr nu"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "دے اورېدلے",
          f: "day aawredulay"
        }]
      }, {
        pref: {
          p: "نیول",
          f: "neewúl"
        },
        beg: {
          p: "په کلاس دې ډېر ښۀ نوټونه",
          f: "pu klaas de Der xu noTóona"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "نیولی وُو",
          f: "neewúlee woo"
        }]
      }, {
        pref: {
          p: "لیدل",
          f: "leedúl"
        },
        beg: {
          p: "تا پخوا هم زۀ",
          f: "taa pakhwaa hum zu"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "لیدلے وم",
          f: "leedúlay wum"
        }, {
          p: "لیدلې  وم",
          f: "leedúle wum"
        }]
      }, {
        pref: {
          p: "لیدل",
          f: "leedúl"
        },
        beg: {
          p: "په څۀ شى لګيا وې کله چې مونږ",
          f: "pu tus shee ligyáa we kala che moonG"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "لیدلی وُو",
          f: "leedúlee woo"
        }, {
          p: "لیدلې وُو",
          f: "leedúle woo"
        }]
      }, {
        pref: {
          p: "وهل",
          f: "wahúl"
        },
        beg: {
          p: "ولې دې هغه هلک",
          f: "wăle de hágh halúk"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "وهلے وو",
          f: "wahúlay wo"
        }]
      }, {
        pref: {
          p: "رسېدل",
          f: "rasedúl"
        },
        beg: {
          p: "تاسو ډېر مخکښې دلته",
          f: "taaso Der makhke dălta"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "راسېدلی یئ",
          f: "raasedúlee yey"
        }, {
          p: "راسېدلې یئ",
          f: "raasedúle yey"
        }]
      }, {
        pref: {
          p: "اخستل",
          f: "akhistúl"
        },
        beg: {
          p: "تا ځو کیلو مڼې",
          f: "taa tso keelá maNe"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "اخستې دي",
          f: "akhíste dee"
        }, {
          p: "اخستلې دي",
          f: "akhistúle dee"
        }]
      }]}
    </Quiz>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-twenty-five-pukhto",
    level: 2,
    title: "Dialogue twenty-five: Pukhto",
    children: [
        
      ]
  },
{
    id: "life-story",
    level: 2,
    title: "Life story",
    children: [
        {
              id: "some-questions",
              level: 3,
              title: "Some questions",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation drill",
    children: [
        
      ]
  },
{
    id: "had-done-the-past-perfect-tense",
    level: 2,
    title: "'had done’: The past perfect tense",
    children: [
        {
              id: "intransitive-verbs-in-the-past-perfect",
              level: 3,
              title: "Intransitive verbs in the past perfect",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "compound-intransitive-verbs-in-the-past-perfect",
    level: 2,
    title: "Compound intransitive verbs in the past perfect",
    children: [
        {
              id: "passive-sentences-in-the-past-perfect",
              level: 3,
              title: "Passive sentences in the past perfect",
              children: [
                      
                    ]
            },
      {
              id: "transitive-verbs-in-the-past-perfect",
              level: 3,
              title: "Transitive verbs In the past perfect",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "substitution-drill",
    level: 2,
    title: "Substitution drill",
    children: [
        
      ]
  },
{
    id: "are-you-busy-word-w-p-مصرُوف-f-masróof-e--a-masroof-f---word-w-p-اخته-f-akhtá-e--a-akhta-f---word-w-p-لګیا-f-ligyáa-e--a-lugyaa-f--",
    level: 2,
    title: <React.Fragment>{`Are you busy? `}<Word w={{ p: "مصرُوف", f: "masróof", e: "", a: "masroof-f" }} />, <Word w={{ p: "اخته", f: "akhtá", e: "", a: "akhta-f" }} />, <Word w={{ p: "لګیا", f: "ligyáa", e: "", a: "lugyaa-f" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "memorising-moment",
    level: 2,
    title: "Memorising moment",
    children: [
        
      ]
  },
{
    id: "comprehension-drill-tense-production",
    level: 2,
    title: "Comprehension drill: Tense production",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-twenty-five",
    level: 2,
    title: "Exercises for Lesson Twenty-Five",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Twenty-Five"
}

