
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"23 min read","minutes":22.34,"time":1340400,"words":4468}
/* @jsxRuntime classic */
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import memorizingMoments from "./memorizing-moments.jsx";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import deg from "../assets/images/deg.png";
import rababPlayer from "../assets/images/rabab-player.png";
import doctorConversation from "../assets/images/doctor-conversation.png";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-twenty-one-what-happened-yesterday"
    }}>{`Dialogue twenty-one: What happened yesterday?`}</h2>
    <Dialogue audio="dialogue-12" mdxType="Dialogue">
  {[{
        s: "L",
        p: "پرُون څۀ وخت راپاڅېدې؟",
        f: "paróon tsu wakht raapáatsede?",
        e: "What time did you get up yesterday?",
        a: "21d1"
      }, {
        s: "H",
        p: "شپږ بجې.",
        f: "shpuG báje",
        e: "Six o'clock.",
        a: "21d2"
      }, {
        s: "L",
        p: "بيا دې څۀ وکړل؟",
        f: "byaa de tsu ookRul?",
        e: "Then what did you do?",
        a: "21d3"
      }, {
        s: "H",
        p: "بيا مې په برش باندې غاښونه صفا کړل.",
        f: "byaa me pu brush baande gaaxóona safáa kRul.",
        e: "Then I brushed my teeth.",
        a: "21d4"
      }, {
        s: "L",
        p: "پرُون دې چرته ډوډۍ وخوړله؟",
        f: "paróon de chărta DoDúy óokhoRula?",
        e: "Where did you eat food yesterday?",
        a: "21d5"
      }, {
        s: "H",
        p: "په کور کښې مې وخوړله.",
        f: "pu kor ke me óokhoRula.",
        e: "I ate at home.",
        a: "21d6"
      }, {
        s: "L",
        p: "پرُون دفتر ته لاړې؟",
        f: "paroon dăftur ta láaRe?",
        e: "Did you go to the office yesterday?",
        a: "21d7"
      }, {
        s: "H",
        p: "آو، تر يوې بجې پورې مې کار وکړو.",
        f: "aw, tur yuwe báje póre me kaar óokRo.",
        e: "Yes, I worked until one o'clock yesterday.",
        a: "21d8"
      }, {
        s: "L",
        p: "ماښام دې څۀ وکړل؟",
        f: "maaxáam de tsu ookRul?",
        e: "What did you do in the evening?",
        a: "21d9"
      }, {
        s: "H",
        p: "ماښام مې سبزى واخسته او کور ته راغلم.",
        f: "maaxáam me sabzée wáaxista aw kor ta ráaghlum.",
        e: "In the evening I bought some vegetables and I came home.",
        a: "21d10"
      }, {
        s: "L",
        p: "بېګا دې خبرُونه واؤرېدل؟",
        f: "begáa de khabróona waawredul?",
        e: "Did you listen to the news last night?",
        a: "21d11"
      }, {
        s: "H",
        p: "نه، وامې نۀ ورېدل.",
        f: "na, waa-me-nu-wredul.",
        e: "No, I didn't listen to it.",
        a: "21d12"
      }, {
        s: "L",
        p: "بېګا څۀ وخت اُودۀ شوې؟",
        f: "begáa tsu wakht oodú shwe?",
        e: "What time did you go to sleep last night?",
        a: "21d13"
      }, {
        s: "H",
        p: "يوولس بجې.",
        f: "yólus báje",
        e: "Eleven o'clock.",
        a: "21d14"
      }]}
    </Dialogue>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`Look carefully through the dialogue and identify which verbs are transitive and which are intransitive. See if the verb endings make sense to you.`}</p>
    <h2 {...{
      "id": "pronunciation-practice"
    }}>{`Pronunciation practice`}</h2>
    <p>{`Focus on the `}<Word w={{
        p: "ت",
        f: "t",
        e: "",
        a: "t-sound"
      }} mdxType="Word" /> and <Word w={{
        p: "ټ",
        f: "T",
        e: "",
        a: "tt-sound"
      }} mdxType="Word" />{` sounds in this tongue twister:`}</p>
    <Examples memorizingMoment mdxType="Examples">
  {[{
        p: "زما ټبر تبه په تبر ووهله!",
        f: "zmaa Tábar túba pu tábar óowahula!",
        e: "",
        a: "21tt1"
      }]}
    </Examples>
    <h2 {...{
      "id": "compound-transitive-verbs-in-the-simple-past-tense"
    }}>{`Compound transitive verbs in the simple past tense`}</h2>
    <p>{`There two kinds of compounds that use two different `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "",
        a: "kawul"
      }} mdxType="Word" />{` verbs:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Stative compounds`}</em>{` use `}<Word w={{
            p: "کول",
            f: "kawúl",
            e: "to make ____ ____ ",
            a: "kawul"
          }} mdxType="Word" /> to talk about changing an object or making it something -- ie. make the shirt (object) ironed. In the simple past tense these verbs use the perfective root <Word w={{
            p: "کړل",
            f: "kRul",
            e: "",
            a: "krrul"
          }} mdxType="Word" />{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Dynamic compounds`}</em>{` use `}<Word w={{
            p: "کول",
            f: "kawúl",
            e: "to do ____ ",
            a: "kawul"
          }} mdxType="Word" /> to talk about doing an action -- ie. to do work. The action or object of the sentence is a noun inside the compound. In the simple past tense these verbs use the perfective root <Word w={{
            p: "وکړل",
            f: "óokRul",
            e: "",
            a: ""
          }} mdxType="Word" />{`.`}</p>
      </li>
    </ol>
    <h4 {...{
      "id": "simple-past-endings-of-word-w-p-کول-f-kawúl-e-to-make-a------using-perfective-root-word-w-p-کړل-f-krul-e--a---"
    }}>{`Simple past endings of `}<Word w={{
        p: "کول",
        f: "kawúl",
        e: "to make",
        a: ""
      }} mdxType="Word" /> - using perfective root <Word w={{
        p: "کړل",
        f: "kRul",
        e: "",
        a: ""
      }} mdxType="Word" /></h4>
    <p>{`The simple past is formed from the perfective root `}<Word w={{
        p: "کړل",
        f: "kRul",
        e: "",
        a: "krrul"
      }} mdxType="Word" /> just as with any other verb is formed, but in spoken Pashto the <Word w={{
        p: "ړ",
        f: "R",
        e: "",
        a: "rr-sound"
      }} mdxType="Word" />{` is often left out for an even shorter form.`}</p>
    <Table bordered unset wide titleRow={["Person", "Short", "Medium", "Full"]} mdxType="Table">
  {[[{
        e: "1st pers. s."
      }, {
        p: "کم",
        f: "kum",
        e: "",
        a: ""
      }, {
        p: "کړم",
        f: "kRum",
        e: "",
        a: ""
      }, {
        p: "کړلم",
        f: "kRulum",
        e: "",
        a: ""
      }], [{
        e: "2nd pers. s."
      }, {
        p: "کې",
        f: "ke",
        e: "",
        a: "ke-m"
      }, {
        p: "کړې",
        f: "kRe",
        e: "",
        a: "krre-m"
      }, {
        p: "کړلې",
        f: "kRule",
        e: "",
        a: ""
      }], [{
        e: "3rd pers. m.s."
      }, {
        p: "کو",
        f: "ko",
        e: "",
        a: "ko-m"
      }, {
        p: "کړو",
        f: "kRo",
        e: "",
        a: "krro-m"
      }, {
        p: "کړلو",
        f: "kRulo",
        e: "",
        a: ""
      }], [{
        e: "3rd pers. f.s."
      }, {
        p: "که",
        f: "ka",
        e: "",
        a: "ka-m"
      }, {
        p: "کړه",
        f: "kRa",
        e: "",
        a: "krra-m"
      }, {
        p: "کړله",
        f: "kRula",
        e: "",
        a: ""
      }], [{
        e: "1st pers. pl."
      }, {
        p: "کو",
        f: "koo",
        e: "",
        a: ""
      }, {
        p: "کړو",
        f: "kRoo",
        e: "",
        a: ""
      }, {
        p: "کړلو",
        f: "kRuloo",
        e: "",
        a: ""
      }], [{
        e: "2nd pers. pl."
      }, {
        p: "کئ",
        f: "key",
        e: "",
        a: ""
      }, {
        p: "کړئ",
        f: "kRey",
        e: "",
        a: ""
      }, {
        p: "کړلئ",
        f: "kRuley",
        e: "",
        a: ""
      }], [{
        e: "3rd pers. m.pl."
      }, {
        p: "کړل",
        f: "kRul",
        e: "",
        a: ""
      }, {
        p: "کړل",
        f: "kRul",
        e: "",
        a: ""
      }, {
        p: "کړل",
        f: "kRul",
        e: "",
        a: ""
      }], [{
        e: "3rd pers f.pl."
      }, {
        p: "کې",
        f: "ke",
        e: "",
        a: "ke-m"
      }, {
        p: "کړې",
        f: "kRe",
        e: "",
        a: "krre-m"
      }, {
        p: "کړلې",
        f: "kRule",
        e: "",
        a: ""
      }]]}
    </Table>
    <h3 {...{
      "id": "stative-compounds-with-word-w-p-کول-f-kawul-e-to-make-a----in-the-simple-past"
    }}>{`Stative compounds with `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to make",
        a: ""
      }} mdxType="Word" />{` in the simple past`}</h3>
    <Examples mdxType="Examples">
  {[{
        p: "مسترى ستا ګاډے ټيک کړو او کۀ نه؟",
        f: "mistrée staa gáaDay Teek kRo aw ku na?",
        e: "Did the mechanic fix your car or not (lit. yes or no)? (m.s.)",
        a: "21e27"
      }, {
        p: "نه، ټيک يې نۀ کړو.",
        f: "na, Teek ye nu kRo.",
        e: "No, he did not fix it.",
        a: "21e28"
      }, {
        p: "احمد ګنى پرې کړل؟",
        f: "ahmád ganée pre kRul?",
        e: "Did Ahmad cut the sugar cane? (m.pl.)",
        a: "21e29"
      }, {
        p: "آو، مات يې کړل.",
        f: "aw, maat ye kRul.",
        e: "Yes, he cut it. (lit. he broke it)",
        a: "21e30"
      }, {
        p: "خانسامه غوښه پخه کړه؟",
        f: "khaansaamá ghwaxa pakhá kRa?",
        e: "Did the cook cook the meat? (f.s.)",
        a: "21e31"
      }, {
        p: "نه، پخه يې نۀ کړه.",
        f: "na, pakhá ya nú-kRa.",
        e: "No, he didn't cook it.",
        a: "21e32"
      }, {
        p: "پخوانۍ څپلۍ دې زړې کړې؟",
        f: "pakhwaanúy tsaplúy de zaRé kRe?",
        e: "Did you wear out your old sandals? (f.pl.)",
        a: "21e33"
      }, {
        p: "آو، زړې مې کړې.",
        f: "aw, zaRé me kRe.",
        e: "Yes, I wore them out.",
        a: "21e34"
      }, {
        p: "پروسکال دې مېوې هم خرڅې کړې؟",
        f: "paróskaal de mewé hum khártse kRe?",
        e: "Did you also sell fruit last year? (f.pl.)",
        a: "21e35"
      }, {
        p: "آو، مېوې مې هم خرڅې کړې.",
        f: "aw, mewé me hum khártse kRe.",
        e: "Yes, I also sold fruit.",
        a: "21e36"
      }, {
        p: "هغې د غرمې ډوډۍ د پاره څۀ تيار کړل؟",
        f: "haghé d gharmé DoDúy dupáara tsu tayáar kRul.",
        e: "What did she prepare for lunch? (m.pl.)",
        a: "21e37"
      }, {
        p: "ورېژې او قورمه يې تياره کړه.",
        f: "wrejze aw qorma ye tayáara kRa.",
        e: "She prepared rice and a meat sauce. (f.s.)",
        a: "21e38"
      }]}
    </Examples>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The last sentence has several potential objects for the verb to agree with. Usually the verb agrees with the last object named (in this case `}<Word w={{
            p: "قورمه",
            f: "qormá",
            e: "",
            a: "qorma-f"
          }} mdxType="Word" />. Some sentences will group all the objects together to make the verb plural (in this case <Word w={{
            p: "تیار کړل",
            f: "tayáar kRul",
            e: "",
            a: "tayaar-krrul-f"
          }} mdxType="Word" />{` may be used).`}</p>
      </li>
      <li parentName="ol">
        <Word w={{
          p: "قورمه",
          f: "qormá",
          e: "",
          a: "qorma-f"
        }} mdxType="Word" /> is a meat sauce prepared
with chikpeas <Word w={{
          p: "چڼه",
          f: "chaNá",
          e: "",
          a: "channa-f"
        }} mdxType="Word" /> and
either served with rice or eaten with <Word w={{
          p: "روټۍ",
          f: "roTúy",
          e: "",
          a: "rottuy-f"
        }} mdxType="Word" />
.
      </li>
    </ol>
    <h4 {...{
      "id": "simple-past-endings-of-word-w-p-کول-f-kawúl-e-to-do-a----with-prefective-root-word-w-p-وکړل-f-óokrul-e--a---"
    }}>{`Simple past endings of `}<Word w={{
        p: "کول",
        f: "kawúl",
        e: "to do",
        a: ""
      }} mdxType="Word" /> (with prefective root <Word w={{
        p: "وکړل",
        f: "óokRul",
        e: "",
        a: ""
      }} mdxType="Word" />{`)`}</h4>
    <Table bordered unset wide titleRow={["Person", "Short", "Medium", "Full"]} mdxType="Table">
  {[[{
        e: "3rd pers. m.s."
      }, {
        p: "وکو",
        f: "óoko",
        e: "",
        a: ""
      }, {
        p: "وکړو",
        f: "óokRo",
        e: "",
        a: ""
      }, {
        p: "وکړلو",
        f: "óokRulo",
        e: "",
        a: ""
      }], [{
        e: "3rd pers. f.s."
      }, {
        p: "وکه",
        f: "óoka",
        e: "",
        a: ""
      }, {
        p: "وکړه",
        f: "óokRa",
        e: "",
        a: ""
      }, {
        p: "وکړله",
        f: "óokRula",
        e: "",
        a: ""
      }], [{
        e: "3rd pers. m.pl."
      }, {
        p: "وکړل",
        f: "óokRul",
        e: "",
        a: ""
      }, {
        p: "وکړل",
        f: "óokRul",
        e: "",
        a: ""
      }, {
        p: "وکړل",
        f: "óokRul",
        e: "",
        a: ""
      }], [{
        e: "3rd pers f.pl."
      }, {
        p: "وکې",
        f: "óoke",
        e: "",
        a: ""
      }, {
        p: "وکړې",
        f: "óokRe",
        e: "",
        a: ""
      }, {
        p: "وکړلې",
        f: "óokRule",
        e: "",
        a: ""
      }]]}
    </Table>
    <p>{`With these ones the object will only ever be third person, because the object is always a noun/action that is done.`}</p>
    <h3 {...{
      "id": "dynamic-compounds-with-word-w-p-کول-f-kawul-e-to-do-a----in-the-simple-past"
    }}>{`Dynamic compounds with `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to do",
        a: ""
      }} mdxType="Word" />{` in the simple past`}</h3>
    <Examples mdxType="Examples">
  {[{
        p: "دلته يې کار درسره وکړو؟",
        f: "dălta ye kaar dăr-sara óokRo?",
        e: "Did he work here with you? (m.s.)",
        a: "21e1"
      }, {
        p: "نه، کار يې راسره ونۀ کړو.",
        f: "na, kaar ye raa-sara oo-nu-kRo.",
        e: "No, he didn't work with me.",
        a: "21e2"
      }, {
        p: "پروسکال دې څۀ کارُونه وکړل؟",
        f: "parosakaal de tsu kaaroona óokRul?",
        e: "What jobs did you do last year? (m.pl.)",
        a: "21e3"
      }, {
        p: "ډېر کارُونه مې وکړل.",
        f: "Der kaaróona me óokRul.",
        e: "I did many jobs.",
        a: "21e4"
      }, {
        p: "تا ورسره امداد ونۀ کړو؟",
        f: "taa wăr-sara imdáad oo-nu-kRo?",
        e: "Didn't you help them? (m.s.)",
        a: "21e5"
      }, {
        p: "آو، امداد مې ورسره وکړو.",
        f: "aw, imdáad me wăr-sara óokRo.",
        e: "Yes, I helped them.",
        a: "21e6"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p>{`The masculine singular verb ending `}<Word w={{
        p: "وکړو",
        f: "óokRo",
        e: "",
        a: "ookrro-f"
      }} mdxType="Word" /> is commonly shortened to <Word w={{
        p: "وکو",
        f: "óko",
        e: "",
        a: "ooko-f"
      }} mdxType="Word" />{` in ordinary speech.`}</p>
    <h3 {...{
      "id": "practice"
    }}>{`Practice`}</h3>
    <p>{`You are probably getting the hang of transitive verbs by now.`}</p>
    <p>{`👆 Try the verbs below to see if you have fluency. Ask your helper to supply words for you to make into sentences. Here is an example of how the drill might work.`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "هغې / اِستری / قمیص",
        f: "H: haghé / istrée / kamées",
        e: " ",
        a: "21e7"
      }, {
        p: "هغې قمیص استری کړو.",
        f: "L: haghé kamées istrée kRo.",
        e: "She ironed the shirt. (m.s.)",
        a: "21e8"
      }, {
        p: "قميصُونه",
        f: "qameesóona",
        e: " ",
        a: "21e9"
      }, {
        p: "هغې قميصُونه اِسترى کړل.",
        f: "L: haghé qameesóona istrée kRul.",
        e: "She ironed the shirts. (m.pl.)",
        a: "21e10"
      }, {
        p: "پرده",
        f: "pardá",
        e: " ",
        a: "21e11"
      }, {
        p: "هغې پرده اِسترى کړه.",
        f: "L: haghé pardá istrée kRa.",
        e: "She ironed the curtain. (f.s.)",
        a: "21e12"
      }, {
        p: "پردې",
        f: "pardé",
        e: " ",
        a: "21e13"
      }, {
        p: "هغې پردې اِسترى کړې.",
        f: "L: haghé parde istrée kRe.",
        e: "She ironed the curtains. (f.pl.)",
        a: "21e14"
      }]}
    </Examples>
    <h3 {...{
      "id": "questions-and-answers"
    }}>{`Questions and answers`}</h3>
    <p>{`Try to answer each of the following questions, first in the affirmative, then in the negative.`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "نسيمې دا فرش جارُو کړو؟",
        f: "naséeme daa farsh jaaróo kRo?",
        e: "Did Nasima sweep this floor? (m.s.)",
        a: "21e15"
      }, {
        p: "ډاکټر هلکان چېک کړل؟",
        f: "DakTúr halukáan chek kRul?",
        e: "Did the doctor examine the boys? (m.pl.)",
        a: "21e16"
      }, {
        p: "تا غوښه پرې کړه؟",
        f: "taa ghwaxa pre kRa?",
        e: "Did you cut the meat? (m.s.)",
        a: "21e17"
      }, {
        p: "يو سړى دوؤ ښځو سره وادۀ وکړو؟",
        f: "yo saRay dwuo xudzo sara waadú óokRo?",
        e: "Did one man marry two wives? (m.s.)",
        a: "21e18"
      }]}
    </Examples>
    <h3 {...{
      "id": "more-dynamic-compound-examples"
    }}>{`More dynamic compound examples`}</h3>
    <Examples mdxType="Examples">
  {[{
        p: "تاسو رباب ښۀ ووهلو.",
        f: "taaso rabáab xu óowahulo.",
        e: "You played the rabab well. (m.s.)",
        a: "21e19"
      }, {
        p: "ډېر کبان مې ونيول.",
        f: "Der kabáan me óoneewul.",
        e: "I caught a lot of fish. (m.pl.)",
        a: "21e20"
      }, {
        p: "لامبو دې ولې ونۀ وهله؟",
        f: "laambo de wăle oo-nu-wawula.",
        e: "Why didn't you swim? (f.s.)",
        a: "21e21"
      }, {
        p: "هغۀ بېګا مجلس وکړو؟",
        f: "haghú begáa majlís ookRo?",
        e: "Did he sing last night? (m.s.)",
        a: "21e22"
      }, {
        p: "تاسو نن منډې ووهلې؟",
        f: "taaso nun munDe óowahule?",
        e: "Did you run today? (f.pl.)",
        a: "21e23"
      }, {
        p: "نه، منډې مو ونۀ وهلې.",
        f: "na, munDe mU oo-nu-wahule.",
        e: "No , we didn't run.",
        a: "21e24"
      }, {
        p: "تاسو پرُون چکر ووهلو؟",
        f: "taaso paróon chakar óowahulo?",
        e: "Did you take an outing yesterday?",
        a: "21e25"
      }, {
        p: "نه، پرُون مو چکر ونۀ وهلو.",
        f: "na, paróon mU chakar oo-nu-wahulo.",
        e: "No, we didn't take an outing yesterday.",
        a: "21e26"
      }]}
    </Examples>
    <img src={doctorConversation} />
    <Dialogue audio="12joke" mdxType="Dialogue">
  {[{
        s: "Patient",
        p: "ګېډه مې خوږېږى.",
        f: "",
        e: "",
        a: "21j1"
      }, {
        s: "Doctor",
        p: "ولې؟",
        f: "",
        e: "",
        a: "21j2"
      }, {
        s: "Patient",
        p: "سوې روټۍ مې خوړلې ده.",
        f: "",
        e: "",
        a: "21j3"
      }, {
        s: "Doctor",
        p: "اول لاړ شه د سترګو علاج وکړه او بيا د ګېډې.",
        f: "",
        e: "",
        a: "21j4"
      }]}
    </Dialogue>
    <h2 {...{
      "id": "some-irregular-transitive-verbs"
    }}>{`Some irregular transitive verbs`}</h2>
    <p>{`Some transitive verbs have no object, and always used the in 3rd pers. masculine plural form. This makes them the easiest past tense transitive verbs to use!`}</p>
    <p>{`Note that although there’s no object, these are still considered transitive, and the subject still gets inflected with these verbs.`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "ما ډېر وخندل.",
        f: "maa Der óokhaandul.",
        e: "I laughed a lot.",
        a: "21e39"
      }, {
        p: "سړو راپورې وخندل.",
        f: "saRó raapóre óokhaandul.",
        e: "The men laughed at me.",
        a: "21e40"
      }, {
        p: "دې ښځې ولې وژړل؟",
        f: "de xúdze wăle óojzaRul?",
        e: "Why did this woman cry?",
        a: "21e41"
      }, {
        p: "هغوى بېګا وژړل.",
        f: "haghwée begáa óojzaRul.",
        e: "They cried last night.",
        a: "21e42"
      }, {
        p: "سپو ټوله شپه وغپل.",
        f: "spo Tóla shpa óoghapul.",
        e: "The dogs barked all night.",
        a: "21e43"
      }, {
        p: "ماشُومانو په غولى کښې ودنګل.",
        f: "maashoomáano pu ghólee ke óodangul.",
        e: "The children jumped in the courtyard.",
        a: "21e44"
      }, {
        p: "مُونږ په پارک کښې وزنګل.",
        f: "moonG pu paark ke óozangul.",
        e: "We swung in the park.",
        a: "21e45"
      }, {
        p: "ما په سيند کښې ولامبل.",
        f: "maa pu seend ke óolaambul.",
        e: "I bathed in the river.",
        a: "21e46"
      }]}
    </Examples>
    <p>{`In Pukhto laughing is done at someone, and is considered transitive.`}</p>
    <h2 {...{
      "id": "phrases-that-express-time"
    }}>{`Phrases that express time`}</h2>
    <Examples mdxType="Examples">
  {[{
        p: "بل کال به بهر ځم.",
        f: "bul kaal ba bahár dzum.",
        e: "Next year I am going abroad.",
        a: "21e47"
      }, {
        p: "په راتلُونکى کال کښې به بهر ځم.",
        f: "pu raatlóokee kaal ke ba bahár dzum.",
        e: "In the coming year I am going abroad.",
        a: "21e48"
      }, {
        p: "په راتلُونکى مياشت کښې به موسم ګرم شى.",
        f: "pu raatlóonkee myaasht ke ba momúm garm shee.",
        e: "In the coming month the weather will become warm.",
        a: "21e49"
      }, {
        p: "د اوس نه پس به دا کار دا شان کوې.",
        f: "du oos na pas ba daa kaar daa shaa kawe.",
        e: "From now on, do this work like this.",
        a: "21e50"
      }, {
        p: "په راتلُونکى ورځو کښې ستا د ليدو کوشش کوم.",
        f: "pu raatlóonkee wradzo ke staa de leedó koshis kawúm.",
        e: "In the coming days I will try to see you.",
        a: "21e51"
      }, {
        p: "په آئينده کښې به سخت کوشش کوم.",
        f: "pu aayndá ke ba sakht koshís kawúm.",
        e: "I will try harder in the future.",
        a: "21e52"
      }, {
        p: "په دې ورځو کښې ډېر خلق وژلی کېږى.",
        f: "pu de wradzo ke Der khalq wajzulee keGee.",
        e: "Many people are being killed these days.",
        a: "21e53"
      }, {
        p: "نن صبا زۀ ډېر مصرُوف يم.",
        f: "nun sabáa zu Der masróof yim.",
        e: "I am very busy these days.",
        a: "21e54"
      }, {
        p: "په دې شپو او ورځو کښې موسم ډېر يخ دے.",
        f: "pu de shpo aw wradzo ke mosúm Der yux day.",
        e: "These days and nights the weather is very cold.",
        a: "21e55"
      }, {
        p: "په دې وخت کښې زۀ ډېر ستړے يم.",
        f: "pu de wakht ke zu Der stúRay yim.",
        e: "At this time I am very tired.",
        a: "21e56"
      }]}
    </Examples>
    <h2 {...{
      "id": "common-responses-to-questions"
    }}>{`Common responses to questions`}</h2>
    <Examples mdxType="Examples">
  {[{
        p: "دا کار دې ولې وکړو؟",
        f: "daa kaar de wăle óokRo?",
        e: "Why did you do that?",
        a: "21e57"
      }, {
        p: "ځکه چې زۀ ډېر غريب يم.",
        f: "dzuka che zu Der gharéeb yim.",
        e: "Because I am very poor.",
        a: "21e58"
      }, {
        p: "هلته ولې ځې؟",
        f: "halta wăle dze?",
        e: "Why are you going there?",
        a: "21e59"
      }, {
        p: "ځکه چې زما پلار دلته نۀ راځى.",
        f: "dzuka che zmaa plaar dălta nu raadze.",
        e: "Because my father is not coming here.",
        a: "21e60"
      }, {
        p: "دلته ولې راغلې؟",
        f: "dălta wăle ráaghle?",
        e: "Why did you come here?",
        a: "21e61"
      }, {
        p: "زۀ ستا د پاره راغلم.",
        f: "zu staa du paara ráaghlum.",
        e: "I came for you.",
        a: "21e62"
      }, {
        p: "هغه دې ولې وانۀ غستو؟",
        f: "haghá de wăle waa-nu-ghusto.",
        e: "Why didn't you buy it?",
        a: "21e63"
      }, {
        p: "ډېر ګران وو، په دې وجه مې وانۀ غستو (وانهٔ خستو).",
        f: "Der graan wo, pu de wúja me waa-nu-ghisto. (waa-nu-khisto)",
        e: "It was very expensive, so for this reason I didn't buy it.",
        a: "21e64"
      }, {
        p: "تۀ اسلام آباد ته ولې لانۀ ړې؟",
        f: "tu islaam aabaad to wăle laa-nu-Re?",
        e: "Why didn't you go to Islamabad?",
        a: "21e65"
      }, {
        p: "ځکه چې ما سره پیسې نۀ وې.",
        f: "dzuka che maa sara payse nu we.",
        e: "Because I didn't have money.",
        a: "21e66"
      }, {
        p: "پرُون دې کار ولې ونۀ کړو؟",
        f: "paróon de kaar wăle oo-nu-kRo?",
        e: "Why didn't you work yesterday?",
        a: "21e67"
      }, {
        p: "ځکه چې زۀ ناجوړه وم.",
        f: "dzuka che zu naajóRa wum.",
        e: "Because I was sick.",
        a: "21e68"
      }, {
        p: "ولې ورسره خبرې نۀ کوې؟",
        f: "wăle wăr-sara khabure nu kawe?",
        e: "Why are you not speaking with them?",
        a: "21e69"
      }, {
        p: "ځکه چې ورنه خفه يم.",
        f: "dzuka che wăr-na khufa yim.",
        e: "Because I am upset with them.",
        a: "21e70"
      }, {
        p: "هغه ولې رانۀ غلو؟",
        f: "haghá wăle raa-nu-ghlo?",
        e: "Why didn't he come?",
        a: "21e71"
      }, {
        p: "ځکه چې اوزګار نۀ وو.",
        f: "dzuka che oozgáar nu wo.",
        e: "Because he was not free.",
        a: "21e72"
      }]}
    </Examples>
    <img src={deg} style={{
      maxWidth: "8rem"
    }} />
    <h2 {...{
      "id": "compehension-drill-housework"
    }}>{`Compehension drill: Housework`}</h2>
    <Examples mdxType="Examples">
  {[{
        p: "جارُو واخله او باورچى خانه جارُو که!",
        f: "jaaróo wáakhla aw báawachee khaana jaaróo ka!",
        e: "Take the broom and sweep the kitchen!",
        a: "21c1"
      }, {
        p: "دسترخوان او لوښى ووينځه!",
        f: "dastarkháan aw lóoxee óoweendza!",
        e: "Wash the table cloth and the dishes!",
        a: "21c2"
      }, {
        p: "بيا بېټک ته لاړ شه، مېزونه او کُرسۍ صفا که.",
        f: "byaa beTúk ta laaR sha, mezoona aw kUrsúy safáa ka.",
        e: "Then go to the guest room and clean the table and chairs.",
        a: "21c3"
      }, {
        p: "فرش جارُو که، بيا يې ووينځه!",
        f: "fash jaaróo ka, byaa ye óoweendza!",
        e: "Sweep the floor, then wash it!",
        a: "21c4"
      }, {
        p: "د بېټک قالين بهر وڅنډه!",
        f: "du beTúk qaaléen bahár óotsanDa!",
        e: "Beat the betuk rug outside!",
        a: "21c5"
      }, {
        p: "بيا يې واپس راؤړه او وېې غوړوه!",
        f: "byaa ye waapás ráawRa aw oo-ye-ghwaRawa!",
        e: "Then bring it back and spread it out!",
        a: "21c6"
      }, {
        p: "پوړۍ هم جارُو که او خزله يې په ټوکرۍ کښې واچوه.",
        f: "poRúy hum jaaróo ka aw khazúla ye pu Tokrúy ke wáachawa.",
        e: "Sweep the stairs and put the rubbish in the dustbin.",
        a: "21c7"
      }, {
        p: "بيا راشه او ځان ته چائې جوړې کړه!",
        f: "byaa ráasha aw dzaan ta chaay jóRe ka!",
        e: "Then come and make yourself some tea!",
        a: "21c8"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>{`When the enclitic pronoun `}<Word w={{
        p: "یې",
        f: "ye",
        e: "",
        a: "ye-clitic"
      }} mdxType="Word" /> is used right after the <Word w={{
        p: "و",
        f: "oo",
        e: "",
        a: "oo-sound"
      }} mdxType="Word" /> prefix, the two sounds blend together to make something like a <Word w={{
        p: "وې",
        f: "we",
        e: "",
        a: "we"
      }} mdxType="Word" />{` sound.`}</p>
    <h2 {...{
      "id": "memorising-memorizingmoment"
    }}>{`Memorising memorizingMoment`}</h2>
    <h4 {...{
      "id": "matalóona"
    }}>{`matalóona`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[21].slice(0, 2)}</Examples>
    <h4 {...{
      "id": "tapa"
    }}>{`Tapa`}</h4>
    <Examples memorizingMoment mdxType="Examples">{[memorizingMoments[21][2]]}</Examples>
    <h2 {...{
      "id": "exercises-for-lesson-twenty-one"
    }}>{`Exercises for Lesson Twenty-One`}</h2>
    <ol>
      <li parentName="ol">{`Translate the following sentences into Pukhto:`}</li>
    </ol>
    <Quiz mdxType="Quiz">
  {[{
        q: {
          e: "The dog barked all night."
        },
        answer: [{
          p: "سپی ټوله شپه وغپل.",
          f: "spee Tola shpa óoghapul."
        }]
      }, {
        q: {
          e: "Why did you laugh at me?"
        },
        answer: [{
          p: "تهٔ ولې ما پورې وخندل.",
          f: "tu wăle maa pore óokhandul?"
        }]
      }, {
        q: {
          e: "The children swung in the park."
        },
        answer: [{
          p: "ماشومانو په پارک کښې وزنګل.",
          f: "maashoomaano pu paark ke óozangul."
        }]
      }, {
        q: {
          e: "She cried last night."
        },
        answer: [{
          p: "هغې تېره شپه وژړل.",
          f: "haghé tera shpa óojzaRul."
        }]
      }]}
    </Quiz>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Change the following into simple past tense.`}</li>
    </ol>
    <Quiz mdxType="Quiz">
  {[{
        q: {
          p: "هغوى په کلى کښې جنګ کوى.",
          f: "haghwee pu kulee ke jang kawée."
        },
        answer: [{
          p: "هغوی په کلی کښې جنګ وکړو",
          f: "haghwee pu kulee ke jang ookRo."
        }, {
          p: "هغوی په کلی کښې جنګ وکړلو",
          f: "haghwee pu kulee ke jang ookRulo."
        }, {
          p: "هغوی په کلی کښې جنګ وکو",
          f: "haghwee pu kulee ke jang ooko."
        }]
      }, {
        q: {
          p: "ولې هغه کړکۍ بندوى؟",
          f: "wăle haghá kuRkúy bandawee?"
        },
        answer: [{
          p: "ولې هغهٔ کړکۍ بنده کړه؟",
          f: "wăle haghú kuRkúy banda kRa?"
        }, {
          p: "ولې هغهٔ کړکۍ بنده کړله؟",
          f: "wăle haghú kuRkúy banda kRula?"
        }, {
          p: "ولې هغهٔ کړکۍ بنده که؟",
          f: "wăle haghú kuRkúy banda ka?"
        }, {
          p: "ولې هغې کړکۍ بنده کړه؟",
          f: "wăle haghé kuRkúy banda kRa?"
        }, {
          p: "ولې هغې کړکۍ بنده کړله؟",
          f: "wăle haghé kuRkúy banda kRula?"
        }, {
          p: "ولې هغې کړکۍ بنده که؟",
          f: "wăle haghé kuRkúy banda ka?"
        }]
      }, {
        q: {
          p: "سخکال هغه وادۀ کوى.",
          f: "sakáal haghá waadu kawée."
        },
        answer: [{
          p: "سخکال هغهٔ وادهٔ وکړو.",
          f: "sakáak haghú waadu ookRo."
        }, {
          p: "سخکال هغهٔ وادهٔ وکړلو.",
          f: "sakáak haghú waadu ookRulo."
        }, {
          p: "سخکال هغهٔ وادهٔ وکو.",
          f: "sakáak haghú waadu ooko."
        }]
      }, {
        q: {
          p: "غوښه پرې کوى.",
          f: "ghwaxa pre kawee."
        },
        answer: [{
          p: "غوښه پرې کړه.",
          f: "ghwaxa pre kRa."
        }, {
          p: "غوښه پرې کړله.",
          f: "ghwaxa pre kRula."
        }, {
          p: "غوښه پرې که.",
          f: "ghwaxa pre ka."
        }]
      }, {
        q: {
          p: "ترکاڼ زما مېز څۀ وخت جوړوى؟",
          f: "tarkáaN zmaa mez tsu wakht joRawee?"
        },
        answer: [{
          p: "ترکاڼ زما مېز څهٔ وخت جوړ کړو.",
          f: "tarkáaN zmaa me tsu wakht joR kRo?"
        }, {
          p: "ترکاڼ زما مېز څهٔ وخت جوړ کړلو.",
          f: "tarkáaN zmaa me tsu wakht joR kRulo?"
        }, {
          p: "ترکاڼ زما مېز څهٔ وخت جوړ کو.",
          f: "tarkáaN zmaa me tsu wakht joR ko?"
        }]
      }, {
        q: {
          p: "تۀ د غرمې ډوډۍ د پاره څۀ پخوې؟",
          f: "tu du gharme DoDuy du paara tsu pakhawe?"
        },
        answer: [{
          p: "تا د غرمې ډوډۍ د پاره څهٔ پاخهٔ کړل.",
          f: "taa du gharme DoDúy du paara tsu paakhu kRul?"
        }, {
          p: "د غرمې ډوډۍ د پاره دې څهٔ پاخهٔ کړل.",
          f: "du gharme DoDúy du paara de tsu paakhu kRul?"
        }]
      }, {
        q: {
          p: "هغه ډېرې ښې سندرې وائى.",
          f: "haghá Dere xe sandúre waayee."
        },
        answer: [{
          p: "هغهٔ ډېرې ښې سندرې ووائلې.",
          f: "haghú Dere xe sandúre óowaayule."
        }, {
          p: "هغهٔ ډېرې ښې سندرې ووائې.",
          f: "haghú Dere xe sandúre óowaaye."
        }, {
          p: "هغې ډېرې ښې سندرې ووائلې.",
          f: "haghé Dere xe sandúre óowaayule."
        }, {
          p: "هغې ډېرې ښې سندرې ووائې.",
          f: "haghé Dere xe sandúre óowaaye."
        }]
      }, {
        q: {
          p: "نوکر پردې اِسترى کوى.",
          f: "nokúr pardé istrée kawee."
        },
        answer: [{
          p: "نوکر پردې استری کړې.",
          f: "nokúr pardé istrée kRe."
        }, {
          p: "نوکر پردې استری کړلې.",
          f: "nokúr pardé istrée kRule."
        }, {
          p: "نوکر پردې استری کې.",
          f: "nokúr pardé istrée ke."
        }]
      }, {
        q: {
          p: "زما نيکۀ نن مهيان نيسى.",
          f: "zmaa neekú nun mayáan neesee."
        },
        answer: [{
          p: "زما نیکهٔ نن مهیان ونیول.",
          f: "zmaa neekú nun mayáan óoneewul."
        }]
      }, {
        q: {
          p: "تاسو رباب ښۀ وهئ.",
          f: "taaso rabáab xu wahéy."
        },
        answer: [{
          p: "تاسو ربار ښهٔ ووهلو.",
          f: "taaso rabáab xu óowahulo."
        }, {
          p: "تاسو ربار ښهٔ ووهاههٔ.",
          f: "taaso rabáab xu óowaahu."
        }]
      }]}
    </Quiz>
    <img src={rababPlayer} />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-twenty-one-what-happened-yesterday",
    level: 2,
    title: "Dialogue twenty-one: What happened yesterday?",
    children: [
        
      ]
  },
{
    id: "pronunciation-practice",
    level: 2,
    title: "Pronunciation practice",
    children: [
        
      ]
  },
{
    id: "compound-transitive-verbs-in-the-simple-past-tense",
    level: 2,
    title: "Compound transitive verbs in the simple past tense",
    children: [
        {
              id: "stative-compounds-with-word-w-p-کول-f-kawul-e-to-make-a----in-the-simple-past",
              level: 3,
              title: <React.Fragment>{`Stative compounds with `}<Word w={{ p: "کول", f: "kawul", e: "to make", a: "" }} />{` in the simple past`}</React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "dynamic-compounds-with-word-w-p-کول-f-kawul-e-to-do-a----in-the-simple-past",
              level: 3,
              title: <React.Fragment>{`Dynamic compounds with `}<Word w={{ p: "کول", f: "kawul", e: "to do", a: "" }} />{` in the simple past`}</React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "practice",
              level: 3,
              title: "Practice",
              children: [
                      
                    ]
            },
      {
              id: "questions-and-answers",
              level: 3,
              title: "Questions and answers",
              children: [
                      
                    ]
            },
      {
              id: "more-dynamic-compound-examples",
              level: 3,
              title: "More dynamic compound examples",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "some-irregular-transitive-verbs",
    level: 2,
    title: "Some irregular transitive verbs",
    children: [
        
      ]
  },
{
    id: "phrases-that-express-time",
    level: 2,
    title: "Phrases that express time",
    children: [
        
      ]
  },
{
    id: "common-responses-to-questions",
    level: 2,
    title: "Common responses to questions",
    children: [
        
      ]
  },
{
    id: "compehension-drill-housework",
    level: 2,
    title: "Compehension drill: Housework",
    children: [
        
      ]
  },
{
    id: "memorising-memorizingmoment",
    level: 2,
    title: "Memorising memorizingMoment",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-twenty-one",
    level: 2,
    title: "Exercises for Lesson Twenty-One",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Twenty-One"
}

