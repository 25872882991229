
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"19 min read","minutes":18.23,"time":1093800,"words":3646}
/* @jsxRuntime classic */
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import memorizingMoments from "./memorizing-moments";
import miniLandscape from "../assets/images/mini-landscape.png";
import grave from "../assets/images/grave.png";
import baba from "../assets/images/baba.png";
import handToEar from "../assets/images/hand-to-ear.png";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-thirten-talking-about-the-weather"
    }}>{`Dialogue Thirten: Talking about the weather`}</h2>
    <h4 {...{
      "id": "a-cloudy-day"
    }}>{`A cloudy day`}</h4>
    <Dialogue audio="dialogue-13a" mdxType="Dialogue">
  {[{
        s: "L",
        p: "نن موسم ښۀ دے.",
        f: "nun mosúm xu day.",
        e: "Today the weather is good.",
        a: "13d1"
      }, {
        s: "H",
        p: "او، د پرُون نه ښهٔ دے.",
        f: "aw, du paróon na xu day.",
        e: "Yes, it's been good since yesterday.",
        a: "13d2"
      }, {
        s: "L",
        p: "ورېځې دى. ستا څۀ خيال دے باران به وشى؟",
        f: "wredze dee. staa tsu khayaal day, baaráan ba óoshee?",
        e: "There are clouds. What do you think, will it rain?",
        a: "13d3"
      }, {
        s: "H",
        p: "باران سره به فصل نۀ خرابېږى؟",
        f: "baaraan sara ba fásul nu kharaabéGee?",
        e: "Won't the rain spoil the crops?",
        a: "13d4"
      }, {
        s: "L",
        p: "نه، که ږلۍ ونۀ شى، نو باران به فصل ته ډېره فائده ورکوى.",
        f: "na, ku Galúy oo-nu-shee, no baaraan ba fásul ta Dera faaydá wărkawee.",
        e: "No, if it doesn't hail then rain will give great benefit to the crops.",
        a: "13d5"
      }, {
        s: "H",
        p: "خیر دے، د ږلۍ موسم نۀ دے.",
        f: "khayr day, du Galúy mosúm nu day.",
        e: "Don't worry, it's not the season for hail.",
        a: "13d6"
      }]}
    </Dialogue>
    <h4 {...{
      "id": "a-hot-day"
    }}>{`A hot day`}</h4>
    <Dialogue audio="dialogue-13b" mdxType="Dialogue">
  {[{
        s: "L",
        p: "نن ګرمى ده!",
        f: "nun garmée da!",
        e: "It's hot today!",
        a: "13d7"
      }, {
        s: "H",
        p: "ياره ډېره ګرمى شوه.",
        f: "yaara Déra garmée shwa.",
        e: "Friend, it's become very hot.",
        a: "13d8"
      }, {
        s: "L",
        p: "او کنه، د هاړ مياشت ده، نمر ډېر تېز دے.",
        f: "aw ku na, du haaR myaast da, nwar Der tez day.",
        e: "Yes it is, it's the month of Har, the sun is very strong.",
        a: "13d9"
      }, {
        s: "H",
        p: "رُوم کُولر در کره شته؟",
        f: "room koolúr dăr kara shta?",
        e: "Do you have a room cooler in your house?",
        a: "13d10"
      }, {
        s: "L",
        p: "او، پروسکال مې يو اخستے و.",
        f: "aw, parósakaal me yo akhístay wo.",
        e: "Yes, I had bought one last year.",
        a: "13d11"
      }, {
        s: "H",
        p: "کمره څنګه ده؟ يخه ده؟",
        f: "kamrá tsínga da? yúkha da?",
        e: "How is the room? Is it cold?",
        a: "13d12"
      }, {
        s: "L",
        p: "او، کمره يخه ده.",
        f: "aw, kamrá yúkha da.",
        e: "Yes, the room is cold.",
        a: "13d13"
      }, {
        s: "H",
        p: "خو چې بجلى لاړه شى نو بيا ډېر بد حالت جوړ شى.",
        f: "kho che bijlée láaRa shee, no byaa Der bud haalát joR shee.",
        e: "But when the electricity goes then the situation becomes very bad.",
        a: "13d14"
      }, {
        s: "L",
        p: "لوډ شېډنګ مو ډېر زيات تنګوى.",
        f: "loD shéDing mU Der zyaat tangawée.",
        e: "Load-shedding disturbs us a lot.",
        a: "13d15"
      }]}
    </Dialogue>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`The word for “sun” `}<Word w={{
        p: "نمر",
        f: "nwar",
        e: "",
        a: "nwar"
      }} mdxType="Word" />{` has a traditional bookish way of being written in Pukhto that is pronounced in a different way than it is spelt. This is one of several words like this. Ask a friend what some of the others are.`}</p>
    <h2 {...{
      "id": "some-useful-vocabulary-about-the-weather"
    }}>{`Some useful vocabulary about the weather:`}</h2>
    <Examples mdxType="Examples">
  {[{
        p: "درُوند باران (یا) تېز باران",
        f: "droond baaráan (yaa) tez baaráan",
        e: "heavy rain",
        a: "13i1"
      }, {
        p: "هسې معمُولى باران",
        f: "'áse mamoolee baaráan",
        e: "only a light shower",
        a: "13i2"
      }, {
        p: "هسې نرے باران",
        f: "'áse naráy baaráan",
        e: "only a light rain",
        a: "13i3"
      }, {
        p: "باد (یا) هوا چلېږى",
        f: "baad (yaa) hawáa chaléGee",
        e: "wind blows",
        a: "13i4"
      }, {
        p: "طوفان",
        f: "toofáan",
        e: "storm",
        a: "toofaan"
      }, {
        p: "واؤره",
        f: "wáawra",
        e: "snow",
        a: "waawra"
      }, {
        p: "پرخه",
        f: "párkha",
        e: "dew",
        a: "parkha"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p>{`You will see both adjective and noun forms of “hot” and “cold.”`}</p>
    <Table bordered unset titleRow={["Adjective", "Noun"]} mdxType="Table">
  {[[{
        p: "ګرم دے",
        f: "gúram day.",
        e: "It's hot (adj.)",
        a: "garm-day"
      }, {
        p: "ګرمي ده",
        f: "garmée da.",
        e: "It's hot (noun.)",
        a: "garmee-da"
      }], [{
        p: "یخ دے.",
        f: "yukh day.",
        e: "It's hot (adj.)",
        a: "yukh-day"
      }, {
        p: "یخني ده",
        f: "yukhnée da.",
        e: "It's hot (noun.)",
        a: "yukhnee-da"
      }]]}
    </Table>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation drill`}</h2>
    <ol>
      <li parentName="ol">{`Illustrating `}<Word w={{
          p: "رګ",
          f: "rg",
          e: "",
          a: "rg-sound"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "يو زبرګ سړے په ګُلبرګ کښې چرګان ساتى.",
        f: "yo zburg saRay pu gUlbarg ke churgáan saatée.",
        e: "A saintly man keeps chickens in Gulberg.",
        a: "13p1"
      }, {
        p: "دوی کره ډېر چرګان دى.",
        f: "dwoo kara Der churgáan dee.",
        e: "They have a lot of chickens at their house.",
        a: "13p2"
      }]}
    </Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Illustrating `}<Word w={{
          p: "یا",
          f: "yaa",
          e: "",
          a: "yaa"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "بيا دې پياله راؤړه؟",
        f: "byaa de pyaalá ráawuRa?",
        e: "Did you bring the cup again?",
        a: "13p3"
      }, {
        p: "امير جان رښتيا وائى.",
        f: "ameer jaan rixtyáa waayee.",
        e: "Amir Jan is telling the truth.",
        a: "13p4"
      }, {
        p: "زيات کار مۀ کوه!",
        f: "zyaat kaar mú kawa!",
        e: "Don't work too hard.",
        a: "13p5"
      }]}
    </Examples>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Practicing `}<Word w={{
          p: "غ",
          f: "gh",
          e: "",
          a: "gh-sound"
        }} mdxType="Word" /> and <Word w={{
          p: "kh",
          f: "خ",
          e: "",
          a: "kh-sound"
        }} mdxType="Word" /></li>
    </ol>
    <p>{`👆 Work on this tongue twister with your helper. The goal is to say the phrase clearly.`}</p>
    <Examples memorizingMoment mdxType="Examples">
  {[{
        p: "نۀ غم او نۀ غُصه بس هر وخت خفه.",
        f: "nu ghum aw nu ghUsá bas hăr wakht khufá.",
        e: "",
        a: "13p6"
      }]}
    </Examples>
    <img src={grave} />
    <h2 {...{
      "id": "changing-verbs-to-the-passive-voice"
    }}>{`Changing verbs to the passive voice`}</h2>
    <p><em parentName="p">{`Transitive verbs can be changed to the passive voice`}</em>{` by adding the auxiliary verb `}<Word w={{
        p: "کېدل",
        f: "kedúl",
        e: "to become",
        a: "kedul"
      }} mdxType="Word" />{` to the infinitive form of the verb.`}</p>
    <p>{`The auxiliary verb is conjugated depending on what tense you want to use. In the examples below we will use the present tense, `}<Word w={{
        p: "کېږی",
        f: "keGee",
        e: "",
        a: "keggee"
      }} mdxType="Word" />{`.`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "هغه ډوډۍ خوړلې کېږى؟",
        f: "hágha DoDúy khoRúle kéGee?",
        e: "Is that bread being eaten?",
        a: "13e1"
      }, {
        p: "آو، ورو ورو خوړلې کېږى.",
        f: "aw, wro wro khoRúle kéGee.",
        e: "Yes, it is being eaten slowly.",
        a: "13e2"
      }, {
        p: "زما خط نن ليکلے کېږى؟",
        f: "zmaa khat nun leekúlay kéGee?",
        e: "Is my letter being written today?",
        a: "13e3"
      }, {
        p: "آو، نن ستا خط ليکلے کېږى.",
        f: "aw, nun staa khat leekúlay kéGee.",
        e: "Yes your letter is being written today.",
        a: "13e4"
      }, {
        p: "دا کتاب لوستلے کېږى؟",
        f: "daa kitáab lostúlay kéGee?",
        e: "Is this book being read?",
        a: "13e5"
      }, {
        p: "نه، دا هېڅ کله نۀ لوستلے کېږى.",
        f: "na, daa hets kala nu lostúlay kéGee.",
        e: "No, it will never be read. (Lit. '... is never being read'.)",
        a: "13e6"
      }, {
        p: "قميص مې نن ګنډلے کېږى؟",
        f: "qamées me nun ganDúlay keGee?",
        e: "Is my shirt being sewn today?",
        a: "13e7"
      }, {
        p: "آو، نن ګنډلے کېږى.",
        f: "aw, nun ganDúlay kéGee.",
        e: "Yes, it is being sewn today.",
        a: "13e8"
      }, {
        p: "کپړې څۀ وخت وينځلې کېږى؟",
        f: "kapRé tsu wakht weendzúle kéGee?",
        e: "When are the clothes being washed?",
        a: "13e9"
      }, {
        p: "اوس وينځلې کېږى.",
        f: "oos weendzúle kéGee.",
        e: "They are being washed now.",
        a: ""
      }, {
        p: "ټيکټُونه چرته ورکولی کېږى؟",
        f: "TeekiTóona chărta wărkawúlee kéGee?",
        e: "Where are the tickets being given out?",
        a: ""
      }, {
        p: "په دې دُوکان کښې ورکولی کېږى.",
        f: "pu de dookáan ke wărkawúlee kéGee.",
        e: "They are being given out in this shop.",
        a: ""
      }, {
        p: "مُونږ ليدلی کېږُو.",
        f: "moonG leedúlee kéGoo.",
        e: "We are being seen.",
        a: ""
      }, {
        p: "تاسو وهلی کېږئ؟",
        f: "taaso wahulee kéGey?",
        e: "Are you being hit?",
        a: ""
      }]}
    </Examples>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In the passive voice the recipient of the action is the subject of the sentence.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Unlike English, the agent is not mentioned. For example, the phrase “the clothes are washed by Mary” becomes “the clothes are washed” in Pukhto. The verb indicates a change in the state of the clothes, not who is performing the action.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In Pakistani Pukhto when making the passive voice we add a tail (`}<Word w={{
            p: "ـے",
            f: "-ay",
            e: "",
            a: "ay-sound"
          }} mdxType="Word" />{`) on the end of the verb root. `}<em parentName="p">{`This tail is inflected based on the gender and number of the subject`}</em>{`. These inflections work `}<em parentName="p">{`exactly the same`}</em>{` as they adjectives inflections that we saw earlier, and as the past participle inflections that we will see later.`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <Word w={{
              p: "ـے",
              f: "-ay",
              e: "",
              a: "ay-sound"
            }} mdxType="Word" /> if the subject is
masculine singular
          </li>
          <li parentName="ul">
            <Word w={{
              p: "ـی",
              f: "-ee",
              e: "",
              a: "ee-sound"
            }} mdxType="Word" /> if the subject is
masculine plural
          </li>
          <li parentName="ul">
            <Word w={{
              p: "ـې",
              f: "-e",
              e: "",
              a: "e-sound"
            }} mdxType="Word" /> if the subject is feminine
(sing. or plur.)
          </li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Compound transitive verbs are made passive in exactly the same way in the present, by adding `}<Word w={{
            p: "کېدل",
            f: "kedul",
            e: "to beome",
            a: "kedul"
          }} mdxType="Word" />{`, (as seen in the first two examples below), or by using an intransitive equivalent of the compound verb (as seen in the third and fourth examples below).`}</p>
      </li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "زۀ دا غوښه نۀ پرې کوم.",
        f: "zu daa ghwáxa nu pre kawum.",
        e: "I am not going to cut this meat.",
        a: "13e15"
      }, {
        p: "اوس دا غوښه نۀ پرې کولې کېږى.",
        f: "oos daa ghwáxa nu pre kawule kéGee.",
        e: "This meat is not being cut now. (passive)",
        a: "13e16"
      }, {
        p: "زما قميص اوس اِسترى کوى؟",
        f: "zmaa qamées oos istrée kawée?",
        e: "Is he ironing my shirt now?",
        a: "13e17"
      }, {
        p: "آو، ستا قميص اوس اِسترى کېږى.",
        f: "aw, staa qamées oos istrée kéGee.",
        e: "Yes, your shirt is being ironed now. (passive)",
        a: "13e18"
      }]}
    </Examples>
    <h2 {...{
      "id": "passive-ideas-using-intransitive-verbs"
    }}>{`Passive ideas using intransitive verbs`}</h2>
    <p>{`To express passive ideas, people often use the intransitive forms of compound verbs instead of making the transitive forms passive.`}</p>
    <p>{`For example, to make the transitive compound verb `}<Word w={{
        p: "جوړول",
        f: "joRawul",
        e: "to make/fix",
        a: "jorrawul"
      }} mdxType="Word" /> passive you would say <Word w={{
        p: "جوړولے کېږی",
        f: "joRawulay kéGee",
        e: "it is being fixed"
      }} mdxType="Word" />. However, you can also express a similar idea by using an intransitive compound verb <Word w={{
        p: "جوړېدل",
        f: "joRedúl",
        e: "to be made/fixed",
        a: "jorredul2"
      }} mdxType="Word" />{`.`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "موټر دې جوړوم.",
        f: "moTúr de joRawúm.",
        e: "I am fixing your car.",
        a: "13e19"
      }, {
        p: "موټر دې جوړېږى؟",
        f: "moTúr de joRéGee?",
        e: "Is your car being fixed?",
        a: "13e20"
      }, {
        p: "هغه اوس لرګى ماتوى.",
        f: "haghá oos largée maatawée.",
        e: "He is cutting the wood now.",
        a: "13e21"
      }, {
        p: "لرګى ماتېږى.",
        f: "largée maatéGee.",
        e: "The wood is being broken.",
        a: "13e22"
      }, {
        p: "خانسامه غوښه پخوى.",
        f: "khaansaamá ghwáwa pakawée.",
        e: "The cook is cooking the meat.",
        a: "13e23"
      }, {
        p: "غوښه پخېږى.",
        f: "ghwáxa pakhéGee.",
        e: "The meat is being cooked.",
        a: "13e24"
      }, {
        p: "نن سپياکې سوزوم.",
        f: "nun sipyáake swazawúm.",
        e: "I am burning dung-cakes today.",
        a: "13e25"
      }, {
        p: "ستا سپياکې سوزېږى؟",
        f: "staa sipyáake swazéGee?",
        e: "Are your dung-cakes burning?",
        a: "13e26"
      }, {
        p: "لطيفه ورېژې تياروى.",
        f: "lateefá wréjze tayaarawée.",
        e: "Latifa is preparing the rice.",
        a: "13e27"
      }, {
        p: "ورېژې تيارېږى.",
        f: "wréjze tayaaréGe.",
        e: "The rice is being made ready.",
        a: "13e28"
      }, {
        p: "تۀ مېوه هم خرڅوې؟",
        f: "tu mewa hum khartsawé?",
        e: "Do you also sell fruit?",
        a: "13e29"
      }, {
        p: "په دې بازار کښې مېوه هم خرڅېږى؟",
        f: "pu de baazáar ke mewa hum khartséGee?",
        e: "Is fruit also being sold in this market?",
        a: "13e30"
      }]}
    </Examples>
    <h4 {...{
      "id": "a-comparison-of-transitive-and-intransitive-forms-of-compound-verbs"
    }}>{`A comparison of transitive and intransitive forms of compound verbs`}</h4>
    <Table bordered wide unset titleRow={["Transitive", "Intransitive"]} mdxType="Table">
  {[[{
        p: "اِستعمالول",
        f: "istimaalawúl",
        e: "to use",
        a: "istimaalawul"
      }, {
        p: "اِستعمالېدل",
        f: "istimaaledúl",
        e: "to be used",
        a: "istimaaledul"
      }], [{
        p: "پخول",
        f: "pakhawúl",
        e: "to cook",
        a: "pakhawul2"
      }, {
        p: "پخېدل",
        f: "pakhedúl",
        e: "to be cooked",
        a: "pakhedul2"
      }], [{
        p: "تيارول",
        f: "tayaarawúl",
        e: "to prepare",
        a: "tayaarawul2"
      }, {
        p: "تيارېدل",
        f: "tayaaredúl",
        e: "to be prepared",
        a: "tayaaredul2"
      }], [{
        p: "جوړول",
        f: "joRawúl",
        e: "to fix",
        a: "jorrawul2"
      }, {
        p: "جوړېدل",
        f: "joRedúl",
        e: "to become fixed",
        a: "jorredul2"
      }], [{
        p: "خرڅول",
        f: "khartsawúl",
        e: "to sell",
        a: "khartsawul2"
      }, {
        p: "خرڅېدل",
        f: "khatsedúl",
        e: "to be sold",
        a: "khartsedul2"
      }], [{
        p: "زړول",
        f: "zaRawúl",
        e: "to make old",
        a: "zarrawul2"
      }, {
        p: "زړېدل",
        f: "zaRedúl",
        e: "to get old",
        a: "zarredul2"
      }], [{
        p: "ماتول",
        f: "maatawúl",
        e: "to break",
        a: "maatawul2"
      }, {
        p: "ماتېدل",
        f: "maatedúl",
        e: "to become broken",
        a: "maatedul2"
      }]]}
    </Table>
    <h4 {...{
      "id": "using-transitive-and-intransitive-compound-verbs"
    }}>{`Using transitive and intransitive compound verbs`}</h4>
    <Examples mdxType="Examples">
  {[{
        p: "زۀ د خوب نه پاڅېږم.",
        f: "zu du khob na paatséGum.",
        e: "I am getting up from sleep.",
        a: "13e31"
      }, {
        p: "زۀ هغه د خوب نه پاڅوم.",
        f: "zu haghá du khob na paastawúm.",
        e: "I am waking him up from sleep.",
        a: "13e32"
      }, {
        p: "زوئ دې ولې ډېر شرمېږى؟",
        f: "zooy de wăle Der shărméGee?",
        e: "Why is your son being so shy?",
        a: "13e33"
      }, {
        p: "ولې هغه هلک شرموى؟",
        f: "wăle hágha halúk shărmawee?",
        e: "Why is he shaming that boy?",
        a: "13e34"
      }, {
        p: "زما لُور اُودۀ کېږى.",
        f: "zmaa loor óodu kéGee.",
        e: "My daughter is going to sleep.",
        a: "13e35"
      }, {
        p: "زۀ خپله لُور اُودۀ کوم.",
        f: "zu khpúla loor óodu kawúm.",
        e: "I am putting my daughter to sleep.",
        a: "13e36"
      }, {
        p: "زۀ له تانه خفه کېږم.",
        f: "zu la taa na khufa kéGum.",
        e: "I am getting upset at you.",
        a: "13e37"
      }, {
        p: "تۀ ولې ما خفه کوې؟",
        f: "tu wăle maa khufa kawé?",
        e: "Why are you making me upset?",
        a: "13e38"
      }]}
    </Examples>
    <h2 {...{
      "id": "the-prepositions-word-w-p-په-شان-f-pu-shaan-e--a-pu-shaan---word-w-p-غوندې-f-ghwunde-e--a-ghonde-f---word-w-p-لکه-f-lăka-e-lafa-a-laka-f---word-w-p-داسې-f-daase-e--a-daase-f---and-word-w-p-په-رنګ-f-pu-rang-e--a-pu-rang---like-as"
    }}>{`The prepositions `}<Word w={{
        p: "په شان",
        f: "pu shaan",
        e: "",
        a: "pu-shaan"
      }} mdxType="Word" />, <Word w={{
        p: "غوندې",
        f: "ghwunde",
        e: "",
        a: "ghonde-f"
      }} mdxType="Word" />, <Word w={{
        p: "لکه",
        f: "lăka",
        e: "lafa",
        a: "laka-f"
      }} mdxType="Word" />, <Word w={{
        p: "داسې",
        f: "daase",
        e: "",
        a: "daase-f"
      }} mdxType="Word" /> and <Word w={{
        p: "په رنګ",
        f: "pu rang",
        e: "",
        a: "pu-rang"
      }} mdxType="Word" />{` (like, as)`}</h2>
    <Examples mdxType="Examples">
  {[{
        p: "تۀ زما په شان يې.",
        f: "tu zmaa pu shaan ye.",
        e: "You are like me.",
        a: "13e39"
      }, {
        p: "زوئ دې زما د زوئ په شان دے.",
        f: "zooy de zmaa du zooy pu shaan day.",
        e: "Your son is like mine.",
        a: "13e40"
      }, {
        p: "د دې قلم په شان نور قلمُونه شته؟",
        f: "du de qalúm pu shaan noor qalumóona shta?",
        e: "Do you have other pens like this one?",
        a: "13e41"
      }, {
        p: "سپوږمۍ د نمر غوندې نۀ ده.",
        f: "spoGmúy du nwar ghwunde nu da.",
        e: "The moon is not like the sun.",
        a: "13e42"
      }, {
        p: "سپے مې لکه د شرمخ دے.",
        f: "spay me lăka du shărmúkh day.",
        e: "My dog is like a wolf.",
        a: "13e43"
      }, {
        p: "لکه د وږى ازمری ډوډۍ خورى.",
        f: "lăka du wuGee azmarée DoDúy khorée.",
        e: "He eats food like a hungry lion.",
        a: "13e44"
      }, {
        p: "لکه د لېونى خبرې کوى.",
        f: "lăka du lewanée khabúre kawée.",
        e: "He talks like a madman.",
        a: "13e45"
      }, {
        p: "ستا لُور زما د لُور په شان ده.",
        f: "staa loor zmaa du loor pu shaan da.",
        e: "Your daughter is like mine.",
        a: "13e46"
      }, {
        p: "هغه ستا په رنګ دے.",
        f: "haghá staa pu rang day.",
        e: "He is like you.",
        a: "13e47"
      }, {
        p: "ستا عادتُونه زما په شان دى.",
        f: "staa aadutóona zmaa pu shaan dee.",
        e: "Your habits are like mine.",
        a: "13e48"
      }, {
        p: "ستا په ملک کښې هم داسې يخنى شته؟",
        f: "staa pu mUlk ke hum daase yukhnee shta?",
        e: "Is it as cold as this in your country?",
        a: "13e49"
      }, {
        p: "ورېژې به ورله داسې پخوې.",
        f: "wrejze ba wăr-la daase pakhawe.",
        e: "Make the rice for him like this.",
        a: "13e50"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>{`Both `}<Word w={{
        p: "په شان",
        f: "pu shaan",
        e: "",
        a: "pu-shaan"
      }} mdxType="Word" /> and <Word w={{
        p: "په رنګ",
        f: "pu rang",
        e: "",
        a: "pu-rang"
      }} mdxType="Word" />{` can describe similarities in behavious or physical appearance.`}</p>
    <img src={handToEar} />
    <h2 {...{
      "id": "comprehension-drill-learning-colours"
    }}>{`Comprehension drill: Learning colours`}</h2>
    <p>{`👆 Arrange several objects of different colours around the room and have your teacher instruct you to do different things with the objects dependent on their colour. For example, put a yellow pencil in your pocket! Throw the red ball to me!`}</p>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "اودى",
        f: "oodée",
        e: "blue",
        a: "oodeef"
      }, {
        p: "آسمانى",
        f: "aasmaanée",
        e: "blue",
        a: "aasmaaneef"
      }, {
        p: "باتينګڼى",
        f: "baateenguNée",
        e: "purple",
        a: "baateengunnee"
      }, {
        p: "بانجانى",
        f: "baanjaanée",
        e: "purple",
        a: "baanjaanee"
      }, {
        p: "تور",
        f: "tor",
        e: "black",
        a: "torf"
      }, {
        p: "خړ",
        f: "khuR",
        e: "gray",
        a: "khurrf"
      },
      // check pronunciation and spelling
      {
        p: "زیړ",
        f: "zyaR",
        e: "yellow",
        a: "zerr"
      }, {
        p: "سپين",
        f: "speen",
        e: "white",
        a: "speenf"
      }, {
        p: "سُور",
        f: "soor",
        e: "red",
        a: "soorf"
      }, {
        p: "شين",
        f: "sheen",
        e: "blue or green",
        a: "sheenf"
      }, {
        p: "ګازری",
        f: "gaazurée",
        e: "purple",
        a: "gaazureef"
      }, {
        p: "ګلابى",
        f: "gUlaabée",
        e: "pink",
        a: "guulaabeef"
      }, {
        p: "نسوارى",
        f: "naswaarée",
        e: "brown",
        a: "naswaareef"
      }]}
    </Examples>
    <h4 {...{
      "id": "modifiers"
    }}>{`Modifiers:`}</h4>
    <h5 {...{
      "id": "light"
    }}>{`Light`}</h5>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "کچه",
        f: "kachá",
        e: "",
        a: "kachaf"
      }, {
        p: "سپک",
        f: "spuk",
        e: "",
        a: "spukf"
      }]}
    </Examples>
    <h5 {...{
      "id": "dark"
    }}>{`Dark`}</h5>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "پوخ",
        f: "pokh",
        e: "",
        a: "pokhf"
      }, {
        p: "تک",
        f: "tuk",
        e: "",
        a: "tukf"
      }]}
    </Examples>
    <h2 {...{
      "id": "memorising-moments"
    }}>{`Memorising moments`}</h2>
    <h4 {...{
      "id": "colours-in-different-genders-and-number"
    }}>{`Colours in different genders and number`}</h4>
    <Table bordered unset wide verticalContent titleRow={["", "m. s.", "m. pl.", "f. s.", "f. pl."]} mdxType="Table">
  {[[{
        e: "black"
      }, {
        p: "تور",
        f: "tor",
        e: "",
        a: "torm"
      }, {
        p: "تور",
        f: "tor",
        e: "",
        a: "torm"
      }, {
        p: "توره",
        f: "tóra",
        e: "",
        a: "toram"
      }, {
        p: "تورې",
        f: "tóre",
        e: "",
        a: "torem"
      }], [{
        e: "gray"
      }, {
        p: "خړ",
        f: "khuR",
        e: "",
        a: "khurrm"
      }, {
        p: "خړ",
        f: "khuR",
        e: "",
        a: "khurrm"
      }, {
        p: "خړه",
        f: "khuRa",
        e: "",
        a: "khurram"
      }, {
        p: "خړې",
        f: "khuRe",
        e: "",
        a: "khurrem"
      }], [{
        e: "yellow"
      }, {
        p: "زيړ",
        f: "zyaR",
        e: "",
        a: "zyarrm"
      }, {
        p: "زيړ",
        f: "zyaR",
        e: "",
        a: "zyarrm"
      }, {
        p: "زيړه",
        f: "zyaRa",
        e: "",
        a: "zyarram"
      }, {
        p: "زيړې",
        f: "zyaRe",
        e: "",
        a: "zyarrem"
      }], [{
        e: "white"
      }, {
        p: "سپين",
        f: "speen",
        e: "",
        a: "speenm"
      }, {
        p: "سپين",
        f: "speen",
        e: "",
        a: "speenm"
      }, {
        p: "سپينه",
        f: "spéena",
        e: "",
        a: "speenam"
      }, {
        p: "سپينې",
        f: "spéene",
        e: "",
        a: "speenem"
      }], [{
        e: "red"
      }, {
        p: "سُور",
        f: "soor",
        e: "",
        a: "soorm"
      }, {
        p: "سُور",
        f: "sru",
        e: "",
        a: "srum"
      }, {
        p: "سره",
        f: "sra",
        e: "",
        a: "sram"
      }, {
        p: "سرې",
        f: "sre",
        e: "",
        a: "srem"
      }], [{
        e: "green / blue"
      }, {
        p: "شين",
        f: "sheen",
        e: "",
        a: "sheenm"
      }, {
        p: "شنۀ",
        f: "shnu",
        e: "",
        a: "shnum"
      }, {
        p: "شنه",
        f: "shna",
        e: "",
        a: "shnam"
      }, {
        p: "شنې",
        f: "shne",
        e: "",
        a: "shnem"
      }]]}
    </Table>
    <h4 {...{
      "id": "matalóona"
    }}>{`matalóona`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[13].slice(0, 3)}</Examples>
    <p>{`note: `}<em parentName="p">{`Sodum`}</em>{` is a vilage near Mardan.`}</p>
    <h4 {...{
      "id": "rahman-baba"
    }}>{`Rahman Baba`}</h4>
    <Examples memorizingMoment mdxType="Examples">{[memorizingMoments[13][3]]}</Examples>
    <img src={baba} />
    <h2 {...{
      "id": "exercise-for-lesson-thirteen"
    }}>{`Exercise for Lesson Thirteen`}</h2>
    <Quiz mdxType="Quiz">
  {[{
        q: {
          p: "مُونږ نن ډېره سودا نۀ اخلُو.",
          f: "moonG nun Déra sodaa nú akhloo."
        },
        answer: [{
          p: "نن ډېره سودا نهٔ اخستلې کېږی.",
          f: "nun Déra sodáa nu akhistúle kéGee."
        }, {
          p: "نن ډېره سودا نهٔ اخیستلې کېږی.",
          f: "nun Déra sodáa nu akheestúle kéGee."
        }]
      }, {
        q: {
          p: "تۀ هره هفته هغه سړے وينې؟",
          f: "tu hăra haftá hágha saRáy wéene?"
        },
        answer: [{
          p: "هره هفته هغه سړے لیدلے کېږی؟",
          f: "hăra haftá hágha saRáy leedúlay keGee?"
        }]
      }, {
        q: {
          p: "تاسو خپل لاس سوزوئ!",
          f: "táaso khpul laas swazawéy!"
        },
        answer: [{
          p: "ستاسو لاس سوزولے کېږی!",
          f: "staaso laas swazawúlay kéGee!"
        }]
      }, {
        q: {
          p: "مېز او کُرسۍ نن جوړوم.",
          f: "mez aw kUrsúy nun joRawúm."
        },
        answer: [{
          p: "مېز او کرسۍ نن جوړېږی.",
          f: "mez aw kUrsúy nun joRéGee."
        }, {
          p: "مېز او کرسۍ نن جوړلې کېږی",
          f: "mez aw kUrsúy nun joRawule kéGee."
        }]
      }]}
    </Quiz>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-thirten-talking-about-the-weather",
    level: 2,
    title: "Dialogue Thirten: Talking about the weather",
    children: [
        
      ]
  },
{
    id: "some-useful-vocabulary-about-the-weather",
    level: 2,
    title: "Some useful vocabulary about the weather:",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation drill",
    children: [
        
      ]
  },
{
    id: "changing-verbs-to-the-passive-voice",
    level: 2,
    title: "Changing verbs to the passive voice",
    children: [
        
      ]
  },
{
    id: "passive-ideas-using-intransitive-verbs",
    level: 2,
    title: "Passive ideas using intransitive verbs",
    children: [
        
      ]
  },
{
    id: "the-prepositions-word-w-p-په-شان-f-pu-shaan-e--a-pu-shaan---word-w-p-غوندې-f-ghwunde-e--a-ghonde-f---word-w-p-لکه-f-lăka-e-lafa-a-laka-f---word-w-p-داسې-f-daase-e--a-daase-f---and-word-w-p-په-رنګ-f-pu-rang-e--a-pu-rang---like-as",
    level: 2,
    title: <React.Fragment>{`The prepositions `}<Word w={{ p: "په شان", f: "pu shaan", e: "", a: "pu-shaan" }} />, <Word w={{ p: "غوندې", f: "ghwunde", e: "", a: "ghonde-f" }} />, <Word w={{ p: "لکه", f: "lăka", e: "lafa", a: "laka-f" }} />, <Word w={{ p: "داسې", f: "daase", e: "", a: "daase-f" }} /> and <Word w={{ p: "په رنګ", f: "pu rang", e: "", a: "pu-rang" }} />{` (like, as)`}</React.Fragment>,
    children: [
        
      ]
  },
{
    id: "comprehension-drill-learning-colours",
    level: 2,
    title: "Comprehension drill: Learning colours",
    children: [
        
      ]
  },
{
    id: "memorising-moments",
    level: 2,
    title: "Memorising moments",
    children: [
        
      ]
  },
{
    id: "exercise-for-lesson-thirteen",
    level: 2,
    title: "Exercise for Lesson Thirteen",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Thirteen"
}

