import React from "react";
import ChapterFooter from "../chapter-footer/chapter-footer.js";
import ShareableTitle from "../shareable-title/sharable-title.js";
import SEO from "../seo/seo.js";
import Media from "react-media";
import InChapterNav from "../in-chapter-nav/in-chapter-nav.js";
import "./chapter.css";

function Chapter(props) {
  const { chapter, toggleSidebar } = props;
  const Component = chapter.component;
  const { title, description, keywords, hideTitle } = chapter.frontMatter;
  const { tableOfContents } = chapter;
  function handleShare() {
    if (!navigator.share) {
      alert("Sorry, Sharing links are not supported on your device.");
      return;
    }
    navigator.share &&
      navigator.share({
        title: title + " | Speaking Pakistani Pukhto",
        url: "https://www.speakingpashto.com" + chapter.path,
      });
  }
  return (
    <div className="wrapper-for-content-and-chapter-nav">
      <div className="main-content">
        <main>
          <SEO title={title} description={description} keywords={keywords} />
          {!hideTitle && (
            <ShareableTitle
              title={title}
              titleIcon={chapter.titleIcon}
              shareFunction={handleShare}
            />
          )}
          {!hideTitle && (
            <div style={{ textAlign: "right", marginBottom: "2rem" }}>
              <small className="text-muted">
                To listen to the audio, click a phrase/word. To download, hold
                {` `}down 'd' and click.
              </small>
            </div>
          )}
          <Component />
          <ChapterFooter
            previous={chapter.previous}
            next={chapter.next}
            toggleSidebar={toggleSidebar}
          />
        </main>
      </div>
      <div className="in-chapter-nav">
        <Media query={`(min-width: 1200px)`}>
          {(matches) =>
            matches && <InChapterNav tableOfContents={tableOfContents} />
          }
        </Media>
      </div>
    </div>
  );
}

export default Chapter;
