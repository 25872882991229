
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"35 min read","minutes":34.22,"time":2053200,"words":6844}
/* @jsxRuntime classic */
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import memorizingMoments from "./memorizing-moments";
import womenWithBaby from "../assets/images/women-with-baby.png";
import drippingCeiling from "../assets/images/dripping-ceiling.png";
import girlEating from "../assets/images/girl-eating.png";
import manLookingDown from "../assets/images/man-looking-down.png";
import praying from "../assets/images/praying.png";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-twelve-ladies-visiting-a-newborn"
    }}>{`Dialogue twelve: Ladies visiting a newborn`}</h2>
    <Dialogue audio="dialogue-12a" mdxType="Dialogue">
  {[{
        s: "L",
        p: ["اسلام عليکم! مُبارک شه!", "ستاسو کره هلک شوے دے."],
        f: ["aasalaam aláykUm! mUbaarák sha!", "staaso kara halúk shiway day."],
        e: ["Peace be on you! Congratulations!", "A male child has come in your house."],
        a: "12d1"
      }, {
        s: "H",
        p: "خُدائ دې وبخښه. خوشحاله اوسې!",
        f: "khUdáay de óobakhxa. khosháala ose",
        e: "May God forgive you. Be happy!",
        a: "12d2"
      }, {
        s: "L",
        p: "ککے کُوم دے؟",
        f: "kakáy kum day?",
        e: "Where is the boy?",
        a: "12d3"
      }, {
        s: "H",
        p: "اُودۀ دے.",
        f: "oodú day.",
        e: "He's asleep.",
        a: "12d4"
      }]}
    </Dialogue>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <img src={womenWithBaby} style={{
      float: "right",
      maxWidth: "20rem"
    }} />
    <p>{`Much tradition surrounds the birth of sons. (This dialogue is just the start of your investigation into this aspect of the culture.) Congratulations are due to all in the household. Observe that the conversation above ended with an excuse to stop you from seeing the newborn. This is to prevent the 'evil eye’. If you are allowed to see him, then a conversation similar to the one below may take place. The first statement (below) is accompanied with a spitting of air rather like spitting an imaginary orange pip.`}</p>
    <Dialogue audio="dialogue-12b" mdxType="Dialogue">
  {[{
        s: "L",
        p: "ماشاءالله! ډېر ښُکلے ماشُوم دے.",
        f: "maashaaláa! Der xkÚlay maashóom day.",
        e: "Thanks be to God! He's a pretty boy.",
        a: "12d5"
      }, {
        s: "H",
        p: "خُدائ دې لوئ کړى!",
        f: "khUdaay de looy kRee!",
        e: "May God grow him up!",
        a: "12d6"
      }, {
        s: "L",
        p: "تاسو کُوم يو نُوم خوښ کړے دے؟",
        f: "taaso kum yo noom khwax kuRay day?",
        e: "What name have you chosen for him?",
        a: "12d7"
      }, {
        s: "H",
        p: "لا يو نُوم مو هم نۀ دے خوښ کړے، خو کشر ترۀ يې ورته سعد وائى.",
        f: "laa yo noom mU hum nu day khwax kuRay, kho kúshar tru ye wăr-ta sa'ad waayee.",
        e: "We haven't even chosen a name for him yet, but his youngest uncle calls him Saad.",
        a: "12d8"
      }, {
        s: "L",
        p: "مور یې ځنګه ده؟",
        f: "mor ye tsunga da?",
        e: "How is his mother?",
        a: "12d9"
      }, {
        s: "H",
        p: "ښه ده.",
        f: "xa da.",
        e: "She's fine.",
        a: "12d10"
      }]}
    </Dialogue>
    <p>{`A few rupees are put in the infant’s hand without comment, leading to the following dialogue:`}</p>
    <Dialogue audio="dialogue-12c" mdxType="Dialogue">
  {[{
        s: "H",
        p: "نه، دا ډېرې دى، دا مۀ کوه!",
        f: "na, daa Dere dee, daa mu-kawa!",
        e: "No, it's too much, don't do it!",
        a: "12d11"
      }, {
        s: "L",
        p: "ستا پکښې څۀ دى؟ دا خو زما او د سعد په مينځ کښې معامله ده.",
        f: "staa pu ku tsu dee? daa kho zmaa aw du saad pu mendz ke maamilá da.",
        e: "What is it to you? This is an issue between me and Saad.",
        a: "12d12"
      }, {
        s: "H",
        p: "وګوره، هغۀ خو پیسې واخستې!",
        f: "oogora, haghú kho payse waakhuste!",
        e: "Look, he took the money!",
        a: "12d13"
      }]}
    </Dialogue>
    <Examples memorizingMoment hideEnglish mdxType="Examples">
  {[{
        p: ["کرايه دار مالِک ته:", `"ستا دا کور ټول څاڅې بېګا مې ټول چرګان مړۀ شُو."`, "مالِک کرايه دار ته:", `"بطحې اوساته."`],
        f: ["karaayadáar maalík ta:", `"staa daa kor Tol tsaatse begaa me Tol churgáan mRu shoo."`, `maalík karaayadáar ta:`, `"baté óosaata."`],
        e: ["Tenant to Landlordː", `"Your whole house is leakinɡ. Last niɡht every one of my hens died."`, `Landlord to Tenantː`, `"Keep ducks."`],
        a: "12j1"
      }]}
    </Examples>
    <p>{`👆 See if you can understand this joke without looking at the English. Then press the button to check your comprehension.`}</p>
    <img src={drippingCeiling} />
    <h2 {...{
      "id": "renting-a-house"
    }}>{`Renting a house`}</h2>
    <Dialogue audio="dialogue-12d" mdxType="Dialogue">
  {[{
        s: "L",
        p: "خواؤشا کښې د کرايه کورونه شته؟",
        f: "khwaa-U-shaa ke du karaaya koroona shta?",
        e: "Are there any houses for rent around here?",
        a: "12d14"
      }, {
        s: "H",
        p: "او، دلته يو کور شته.",
        f: "aw, dălta yo kor shta.",
        e: "Yes, one house is here.",
        a: "12d15"
      }, {
        s: "L",
        p: "دا کور ستاسو دے؟",
        f: "daa kor staaso day?",
        e: "Is this house yours?",
        a: "12d16"
      }, {
        s: "H",
        p: "نه، زۀ څوکيدار يم.",
        f: "na, zu tsokeedáar yum.",
        e: "No, I am a watchman.",
        a: "12d17"
      }, {
        s: "L",
        p: "دا کور خالى دے؟",
        f: "daa kor khaalee day?",
        e: "Is this house empty?",
        a: "12d18"
      }, {
        s: "H",
        p: "او، خالی دے.",
        f: "aw, khaalee day.",
        e: "Yes, it's empty.",
        a: "12d19"
      }, {
        s: "L",
        p: "اوبۀ په کښې شته؟",
        f: "oobú pu ke shta?",
        e: "Does it have water?",
        a: "12d20"
      }, {
        s: "H",
        p: "او، خپل کُوهے په کښې دے.",
        f: "aw, khpul koohay pu ke day.",
        e: "Yes, it has its own well.",
        a: "12d21"
      }, {
        s: "L",
        p: "څو کمرې په کښې دى؟",
        f: "tso kamre pu ke dee?",
        e: "How many rooms are in it?",
        a: "12d22"
      }, {
        s: "H",
        p: "پينځۀ کمرې او دوه غُسلخانې دى په کښې.",
        f: "peendzu kamre aw dwa ghUsUlkhaane dee pu ke.",
        e: "It has five rooms and two bathrooms in it.",
        a: "12d23"
      }, {
        s: "L",
        p: "باورچى خانه څنګه ده؟",
        f: "baawarchee khaana tsunga da?",
        e: "How's the kitchen?",
        a: "12d24"
      }, {
        s: "H",
        p: "راځه، وګوره!",
        f: "raadza, óogora!",
        e: "Come and look at it!",
        a: "12d25"
      }, {
        s: "L",
        p: "کور خو ښۀ دے، خو کرايه څومره ده؟",
        f: "kor kho xu day, kho karaaya tsoomra da?",
        e: "The house is good, but how much is the rent?",
        a: "12d26"
      }, {
        s: "H",
        p: "د مياشتې څلوېښت زره روپۍ دى.",
        f: "du myaashte tsalwext zara roopúy dee.",
        e: "Forty thousand rupees a month.",
        a: "12d27"
      }, {
        s: "L",
        p: "دا ډېر ګران دے.",
        f: "daa Day graan day.",
        e: "It is very expensive!",
        a: "12d28"
      }, {
        s: "H",
        p: "د کور مالِک سره خبرې وکه.",
        f: "du kor maalík sara khabure óoka.",
        e: "Talk with the landlord about it.",
        a: "12d29"
      }, {
        s: "L",
        p: "د هغۀ د ټېلفون نمبر څۀ دے؟",
        f: "du haghú du Telifóon nambúr tsu day?",
        e: "What is his telephone number?",
        a: "12d30"
      }, {
        s: "H",
        p: "د هغۀ د ټېلفون نمبر دا دے.",
        f: "du haghú de Telifóon nambúr daa day.",
        e: "This is his telephone number.",
        a: "12d31"
      }]}
    </Dialogue>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation drill`}</h2>
    <ol>
      <li parentName="ol">{`Illustrating `}<Word w={{
          p: "ور",
          f: "wr",
          e: "",
          a: "wr-sound"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "هغه مې ورور دے.",
        f: "haghá me wror day.",
        e: "He is my brother.",
        a: "12p1"
      }, {
        p: "هغه مې ورارۀ دے.",
        f: "haghá me wraarú day.",
        e: "He is my nephew.",
        a: "12p2"
      }, {
        p: "وروستو بيا راشه.",
        f: "wroosto byaa ráasha",
        e: "Come later.",
        a: "12p3"
      }, {
        p: "اوس ورځ ده.",
        f: "ooswrudz da.",
        e: "It is daytime now.",
        a: "12p4"
      }, {
        p: "وريژې پخې دى.",
        f: "wrijze paxé dee.",
        e: "The rice is cooked.",
        a: "12p5"
      }]}
    </Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Illustrating `}<Word w={{
          p: "ش",
          f: "sh",
          e: "",
          a: "sh-sound"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "شپږ پيشوګانې دلته شته.",
        f: "shpuG peeshogáane dălta shta.",
        e: "There are six cats here.",
        a: "12p6"
      }, {
        p: "شپاړس شپې هلته پاتې شُو.",
        f: "shpáaRus shpe hálta páate shoo.",
        e: "We stayed sixteen nights here.",
        a: "12p7"
      }, {
        p: "شپُونکى د شپې په صحرا کښې پاتې شول.",
        f: "shpoonkée du shpe pu sahrá ke páate shwul.",
        e: "The shepherds spent the night in the desert.",
        a: "12p8"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p>{`👆 How is your pronunciation going? Try recording your voice in the blanks in one of your practice sessions. Then listen to how you sound speaking Pukhto. You may find it discouraging to do this, but it should be a stimulus to go over the sounds at the start of the book all over again. Keep encouraging your helper to correct you. For words you continue to mispronounce, make your own drills.`}</p>
    <h2 {...{
      "id": "twelve-intransitive-verbs-in-the-present-tense"
    }}>{`Twelve intransitive verbs in the present tense`}</h2>
    <ol>
      <li parentName="ol">
        <Word w={{
          p: "اودرېدل",
          f: "oodredúl",
          e: "to stop, stand, wait",
          a: "oodredul-f"
        }} mdxType="Word" />
      </li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "بس دلته ودرېږى؟",
        f: "bus dălta oodréGee?",
        e: "Does the bus stop here?",
        a: "12e1"
      }, {
        p: "او، هره ګنټه پس دلته ودرېږى.",
        f: "aw, hăra ginTa pas dălta odréGee.",
        e: "Yes it stops here after every hour.",
        a: "12e2"
      }]}
    </Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <Word w={{
          p: "ګرځېدل",
          f: "gurdzedul",
          e: "to walk, go around",
          a: "gurdzedul"
        }} mdxType="Word" />
      </li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "تۀ هر سحر چرته ګرځې؟",
        f: "tu hăr sahár chărta gúrdze?",
        e: "Where do you walk every morning?",
        a: "12e3"
      }, {
        p: "زۀ هر سحر په ښار کښې ګرځم.",
        f: "zu hăr sahár pu xaar ke gúrdzum.",
        e: "I go for a walk in the city every morning.",
        a: "12e4"
      }, {
        p: "زما ګړۍ نن نۀ ګرځى.",
        f: "zmaa gaRúy nun nu gurdzee.",
        e: "My watch is not going today.",
        a: "12e5"
      }, {
        p: "نن ګاڼۍ ګرځى؟",
        f: "nun gaaNúy gurdzee?",
        e: "Is the sugar press running today?",
        a: "12e6"
      }, {
        p: "په کلى کښې ښځې کولاؤ مخ نۀ ګرځى.",
        f: "pu kúlee ke xudze kooláaw mukh nu gurdzee.",
        e: "In the village, ladies don't go around with uncovered faces.",
        a: "12e7"
      }]}
    </Examples>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">
        <Word w={{
          p: "ایسارېدل",
          f: "eesaaredul",
          e: "to wait, stay, become late",
          a: "eesaaredul-f"
        }} mdxType="Word" />
      </li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "زۀ به هلته ايساريږم.",
        f: "zu ba dălta eesaaréGum.",
        e: "I will wait over there.",
        a: "12e8"
      }, {
        p: "ولې بس به هميشه ايساريږى؟",
        f: "wăle bus ba hamayshá eesaareGee?",
        e: "Will the bus always be late?",
        a: "12e9"
      }, {
        p: "تاسو به زما په کور كښې ايسارېږئ؟",
        f: "taaso ba zmaa pu kor ke eesaaréGey?",
        e: "Will you (pl.) stay in my house?",
        a: "12e10"
      }]}
    </Examples>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">
        <Word w={{
          p: "کښېناستل",
          f: "kenaastúl",
          e: "to sit (down)",
          a: "kenaastul-f"
        }} mdxType="Word" />
      </li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "خافظه په تولائ باندې ناسته ده او کۀ په فرش؟",
        f: "haafiza pu tolaay baande naasta da aw ku pu farsh?",
        e: "Is Hafiza sitting on the mattress or on the floor?",
        a: "12e11"
      }, {
        p: "هغه په فرش باندې ناسته ده.",
        f: "haghá pu farsh baande naasta da.",
        e: "She is sitting on the floor.",
        a: "12e12"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>{`The ending `}<Word w={{
        p: "ـتل",
        f: "-tul",
        e: "",
        a: "tul-ending"
      }} mdxType="Word" /> usually signals a transitive verb, but exceptions include <Word w={{
        p: "کښېناستل",
        f: "kenaastul",
        a: "kenaastul-f"
      }} mdxType="Word" />, <Word w={{
        p: "ننوتل",
        f: "nunawatúl",
        e: "",
        a: "nunawatul-f"
      }} mdxType="Word" />, <Word w={{
        p: "وتل",
        f: "watúl",
        e: "",
        a: "watul-f"
      }} mdxType="Word" />, and <Word w={{
        p: "څملاستل",
        f: "tsumlaastúl",
        e: "",
        a: "tsumlaastul-f"
      }} mdxType="Word" />{`, which are all intransitive.`}</p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">
        <Word w={{
          p: "پرېوتل",
          f: "prewatúl",
          e: "to fall, drop, drop down",
          a: "prewatul-f"
        }} mdxType="Word" />
      </li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "هغه هلک د خپل سائيکل نه پرېوځى.",
        f: "hágha haalúk du khpul saaykúl na pre`óodzee.",
        e: "That boy is falling off his bike.",
        a: "12e13"
      }, {
        p: "کتاب مې د مېز نه پرېوځى.",
        f: "kitaab me du mez na pre`óodzee.",
        e: "My book is falling off the table.",
        a: "12e14"
      }]}
    </Examples>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">
        <Word w={{
          p: "ننوتل",
          f: "nunawatul",
          e: "to enter, go in",
          a: "nunawatul-f"
        }} mdxType="Word" />
      </li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "تاسو سکُول ته ننوځئ؟",
        f: "taaso skool ta nuna`óodzey?",
        e: "Are you going in to the school?",
        a: "12e15"
      }, {
        p: "نه، مُونږ نۀ ننوّځو.",
        f: "na, moonG nu nuna`óodzoo",
        e: "No, we are not going in.",
        a: "12e16"
      }, {
        p: "ولې په ګوټ کښې ننوځې؟",
        f: "wăle pu gooT ke nuna`oodze?",
        e: "Why are you going into the corner?",
        a: "12e17"
      }]}
    </Examples>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">
        <Word w={{
          p: "اوسېدل",
          f: "osedúl",
          e: "to live, stay, reside",
          a: "osedul-f"
        }} mdxType="Word" />
      </li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "تاسو په پېښور کښې چرته اوسېږئ؟",
        f: "taaso pu pexawăr ke chărta oséGey?",
        e: "Where do you live in Peshawar?",
        a: "12e18"
      }, {
        p: "مُونږ اوس په ټاؤن کښې اوسېږُو.",
        f: "moonG oos pu Taawn ke oséGoo.",
        e: <>
          We are now living in <em>Town</em>.
        </>,
        a: "12e19"
      }, {
        p: "ستا خاندان په پاړه چنار کښې اوسېږى؟",
        f: "staa khaanadáan pu paaRachináar ke oseGee?",
        e: "Does your family live in Parachinar?",
        a: "12e20"
      }, {
        p: "نه، خاندان مې په کراچۍ کښې اوسېږى.",
        f: "na, khaanadáan me pu karaachúy ke oseGee.",
        e: "No, my family lives in Karachi.",
        a: "12e21"
      }]}
    </Examples>
    <p><em parentName="p">{`(University) Town is a suburb of Peshawar.`}</em></p>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">
        <Word w={{
          p: "پاځېدل",
          f: "paatsedúl",
          e: "to get up, rise, awaken",
          a: "paatsedul-f"
        }} mdxType="Word" />
      </li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "تۀ څۀ وخت پاڅې؟",
        f: "tu tsu wakht paatse?",
        e: "When do you get up?",
        a: "12e22"
      }, {
        p: "سحر وختى پاڅم.",
        f: "sahár wakhtee páatsum.",
        e: "I get up early in the morning.",
        a: "12e23"
      }]}
    </Examples>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">
        <Word w={{
          p: "پوهېدل",
          f: "pohedúl",
          e: "to know, understand",
          a: "pohedul-f"
        }} mdxType="Word" />
      </li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "تۀ په ټوله پښتو پوهېږې؟",
        f: "tu pu Tola puxtó pohéGe?",
        e: "Do you understand everything in Pukhto?",
        a: "12e24"
      }, {
        p: "نه، زۀ لږه لږه پوهېږم.",
        f: "na, zu luGa luGa pohéGum.",
        e: "No, I understand a tiny bit.",
        a: "12e25"
      }, {
        p: "نه، زۀ لږ لږ پوهېږم.",
        f: "na, zu luG luG pohéGum.",
        e: "No, I understand a tiny bit.",
        a: "12e26"
      }, {
        p: "دا په سبق ښۀ پوهېږى؟",
        f: "daa pu sabúq xu pohéGee?",
        e: "Does she understand the lesson well?",
        a: "12e27"
      }, {
        p: "نه، تر اوسه پورې ښۀ نۀ پوهېږى.",
        f: "na, tur oosa pore xu nu pohéGee.",
        e: "No, she doesn't understand it well yet.",
        a: "12e28"
      }, {
        p: "تۀ په ليکلو باندې پوهېږې؟",
        f: "tu pu leekulo baande pohéGe?",
        e: "Can you write? (Lit. Do you know writing?)",
        a: "12e29"
      }, {
        p: "او، زۀ په ليکلو باندى پوهېږم.",
        f: "aw, zu pu leekulo baande pohéGum.",
        e: "Yes, I can write. (Lit. Yes, I know how to write.)",
        a: "12e30"
      }, {
        p: "تۀ په ډرائيونګ پوهېږې؟",
        f: "tu pu Draaywing pohéGe?",
        e: "Can you drive?",
        a: "12e31"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-3"
    }}>{`NOTE:`}</h4>
    <p>
  <Word w={{
        p: "پوهېدل",
        f: "pohedúl",
        e: "",
        a: "pohedul-f"
      }} mdxType="Word" /> is used for
  'knowing about' a thing, or 'knowing how to do' something.
    </p>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">
        <Word w={{
          p: "رسېدل",
          f: "rasedúl",
          e: "to arrive, reach",
          a: "rasedul-f"
        }} mdxType="Word" />
      </li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "تاسو څۀ وخت صوابۍ ته رسئ؟",
        f: "taaso tsu wakht swaabúy to raséy?",
        e: "What time are you reaching Swabi?",
        a: "12e32"
      }, {
        p: "مُونږ نن شپه هلته رسُو.",
        f: "moonG nun shpa hálta rasóo.",
        e: "We are arriving there tonight.",
        a: "12e33"
      }, {
        p: "لاس دې بلف ته رسېږې؟",
        f: "laas de búluf ta raséGe?",
        e: "Does your hand reach the bulb?",
        a: "12e34"
      }, {
        p: "او، لاس مې رسېږى.",
        f: "aw, laas me raséGee.",
        e: "Yes, my hand reaches.",
        a: "12e35"
      }, {
        p: "د هغۀ لاس تر ډېر لرې لرې پورې رسى.",
        f: "du haghú laas tur Der lúre lúre pore rasee.",
        e: "He is influential. (Lit. His hand reaches very far.)",
        a: "12e36"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-4"
    }}>{`NOTE:`}</h4>
    <p>{`Many transitive verbs ending in `}<Word w={{
        p: "ـېدل",
        f: "edul",
        e: "",
        a: "edul"
      }} mdxType="Word" /> can be said in a short or long form, for instance, you can say either <Word w={{
        p: "رسېږی",
        f: "raséGee"
      }} mdxType="Word" /> or <Word w={{
        p: "رسی",
        f: "rasee",
        e: "",
        a: "rasee"
      }} mdxType="Word" />{`.`}</p>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">
        <Word w={{
          p: "څملاستل",
          f: "tsumlaastúl",
          e: "to lie (down)",
          a: "tsumlaastul-f"
        }} mdxType="Word" />
      </li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "عبدل چرته څملی؟",
        f: "abdÚl chărta tsúmlee?",
        e: "Where is Abdul lying?",
        a: "12e37"
      }, {
        p: "هغه به هغه کټ کښې څملى.",
        f: "haghá pu hágha kaT ke tsúmlee.",
        e: "He is lying on that bed.",
        a: "12e38"
      }]}
    </Examples>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol">
        <Word w={{
          p: "وتل",
          f: "watúl",
          e: "to exit, leave",
          a: "watul-f"
        }} mdxType="Word" />
      </li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "په دې مياشت کښې د پاکِستان نه وځې؟",
        f: "pu de myaasht ke du paakistáan na óodze?",
        e: "Are you leaving Pakistan this month?",
        a: "12e39"
      }, {
        p: "نه، زۀ هېڅ کله د پاکِستان نه نۀ وځم.",
        f: "na, zu hets kala du paakistáan na nu oo-dzum.",
        e: "No, I am never leaving Pakistan.",
        a: "12e40"
      }, {
        p: "غل مې د لاس نه وځى.",
        f: "ghul me du laas na oodzee.",
        e: "The thief slips from my hand.",
        a: "12e41"
      }]}
    </Examples>
    <h2 {...{
      "id": "infinitive"
    }}>{`Infinitive`}</h2>
    <p>{`An infinitive is the base or dictionary form of the verb.`}</p>
    <Table bordered unset verticalContent mdxType="Table">
  {[[{
        p: "تلل",
        f: "tlul",
        e: "to go / going",
        a: "tlul-m"
      }, {
        p: "کول",
        f: "kawúl",
        e: "to do / doing",
        a: "kawul-m"
      }], [{
        p: "راتلل",
        f: "raatlúl",
        e: "to come / coming",
        a: "raatlul-m"
      }, {
        p: "لیدل",
        f: "leedúl",
        e: "to see / seeing",
        a: "leedul-m"
      }], [{
        p: "خوړل",
        f: "khoRúl",
        e: "to eat / eating",
        a: "khorrul-m"
      }, {
        p: "وئیل",
        f: "wayúl",
        e: "to say / saying",
        a: "wayul-m"
      }]]}
    </Table>
    <p>{`Many verbs, which are found in English in the infinitive form, such as “to eat” and 'to help’, are found in Pukhto as compound verbs. See below for two examples out of vast range of possibilities:`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "خوراک کول",
        f: "khoráak kawul",
        e: "to eat (food) / eating",
        a: "khoraak-kawul"
      }, {
        p: "مدد کول",
        f: "madúd kawúl",
        e: "to help / helping",
        a: "madud-kawul"
      }]}
    </Examples>
    <p>{`One use of the infinitive form is with the verb 'to want’. As in the following examples:`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "زۀ تلل غواړم.",
        f: "zu tlul ghwaaRum.",
        e: "I want to go.",
        a: "12e42"
      }, {
        p: "زۀ خوراک کول غواړم.",
        f: "zu khoraak kawul ghwaaRum.",
        e: "I want to eat.",
        a: "12e43"
      }, {
        p: "زۀ ستا مدد کول غواړم.",
        f: "zu staa madúd kawúl ghwáaRum.",
        e: "I want to help you.",
        a: "12e44"
      }]}
    </Examples>
    <h3 {...{
      "id": "infinitives-as-gerunds--ing"
    }}>{`Infinitives as gerunds (-ing)`}</h3>
    <p>{`In Pukhto, the infitive form of the verb can also be used as a `}<em parentName="p">{`gerund`}</em>{`. A gerund is verb that functions as a noun, like when we add “-ing” to the ends of verbs in English.`}</p>
    <Examples mdxType="Examples">
  {[{
        p: <>
          <strong>مرغۍ نيول</strong> ښه خبره نۀ ده.
        </>,
        f: <>
          <strong>marghúy neewul</strong> xa khabura nu da.
        </>,
        e: <>
          <strong>Catching birds</strong> is not a good thing.
        </>,
        a: "11e64"
      }, {
        p: "راتلل په اراده او تلل په اجازه.",
        f: "raatlul pu iraada aw tlul pu ijaaza.",
        e: "Coming requires intention, going requires permission.",
        a: "12mm3"
      }]}
    </Examples>
    <p>{`It is important to note that `}<strong parentName="p">{`infinitives/gerunds are always masculine plural`}</strong>{` in Pashto. If you use them with an equative verb you need to treat them as a masculine plural noun:`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "ډېرې خبرې کول ښه نهٔ دی.",
        f: "Dere khabure kawul xu nu dee.",
        e: "Talking lots is not good.",
        a: ""
      }]}
    </Examples>
    <p>{`This also means that when they get inflected we add an `}<Word w={{
        p: "و",
        f: "o",
        e: "",
        a: "o-sound"
      }} mdxType="Word" />{`, just as we would with any other masculine plural noun.`}</p>
    <h4 {...{
      "id": "inflected-form-of-some-infinitivesgerunds"
    }}>{`Inflected form of some infinitives/gerunds`}</h4>
    <Table bordered unset verticalContent mdxType="Table">
  {[[{
        p: "تلو",
        f: "tlo",
        e: "to go / going",
        a: "tlo"
      }, {
        p: "کولو",
        f: "kawúlo",
        e: "to do / doing",
        a: "kawulo"
      }], [{
        p: "راتلو",
        f: "raatló",
        e: "to come / coming",
        a: "raatlo"
      }, {
        p: "لیدلو",
        f: "leedúlo",
        e: "to see / seeing",
        a: "leedulo"
      }], [{
        p: "خوړلو",
        f: "khoRúlo",
        e: "to eat / eating"
      }, {
        p: "وئیلو",
        f: "wayúlo",
        e: "to say / saying",
        a: "wayulo"
      }]]}
    </Table>
    <img src={girlEating} />
    <p>{`Now you can translate this well-know `}<Word w={{
        p: "ټپه",
        f: "Tapá",
        e: "",
        a: "ttapa"
      }} mdxType="Word" />{`:`}</p>
    <Examples memorizingMoment hideEnglish mdxType="Examples">
  {[{
        p: ["مينه په تلو، راتلو زياتېږى", "کله راځه، کله به زۀ درځم مئينه."],
        f: ["meena pu tlo, raatlo zyaatéGee", "kala raadza, kala ba zu dărdzum mayína."],
        e: ["Love increases by going and coming", "Come sometimes, sometimes I'll go to you my love."],
        a: "meena-pu-tlo"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-5"
    }}>{`NOTE:`}</h4>
    <p>{`The `}<em parentName="p">{`tappa`}</em>{` is one of the oldest forms of folk poetry in the region. They often have a romantic theme, and like Japanese haiku they conform to an exact pattern of syllables, with 9 in the first line and 13 in the second.`}</p>
    <h4 {...{
      "id": "examples-using-the-infinitive-as-a-gerund--ing"
    }}>{`Examples using the infinitive as a gerund (-ing)`}</h4>
    <Examples mdxType="Examples">
  {[{
        p: "مينه په تلو، راتلو زياتيږى.",
        f: "meena pu tla, raatlo zyaatéGee.",
        e: "Love increases with coming and going.",
        a: "12e45"
      }, {
        p: "علم په لوستو زده کېږى.",
        f: "ílum pu lostó zda kéGee.",
        e: "Knowledge is learned by reading.",
        a: "12e46"
      }, {
        p: "کار په کولو ختميږى.",
        f: "kaar pu kawulo khatméGee.",
        e: "Work is finished by doing.",
        a: "12e47"
      }, {
        p: "ژبه په وئيلو زده کېږى.",
        f: "jziba pu wayúlo zda keGée.",
        e: "Language is learned by speaking.",
        a: "12e48"
      }, {
        p: "زۀ د مرغو آواز اورېدلو د پاره باغ ته ځم.",
        f: "zu du marghó aawáaw aawredúlo du paara baagh ta dzum.",
        e: "I'm off to the park, to listen to (lit. for listening to the) bird song.",
        a: "12e49"
      }, {
        p: "هغه زما مدد کولو د پاره راځى.",
        f: "haghá zmaa madúd kawúlo du paara raadzee.",
        e: "He is coming to help (lit. for helping) me.",
        a: "12e50"
      }, {
        p: "زۀ هسپتال ته د هغۀ ليدلو د پاره ځم.",
        f: "zu haspatáal ta du haghú leedúlo du paara dzum.",
        e: "I'm going to the hospital to see (lit. for seeing) him.",
        a: "12e51"
      }, {
        p: "ولې په پښتو وئيلو شرمېږې؟",
        f: "wále pu puxtó wayúlo shărméGe?",
        e: "Why are you embarrassed about speaking Pukhto?",
        a: "12e52"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-the-infinitive-is-inflected-in-each-of-these-examples-because-it-is-in-the-oblique-case"
    }}>{`NOTE: The infinitive is inflected in each of these examples because it is in the oblique case.`}</h4>
    <p>{`👆 What is the meaning of this sentence?`}</p>
    <Examples memorizingMoment hideEnglish mdxType="Examples">
  {[{
        p: "په دې هوټل کښې چائې څښلو سره ګپ لګول ضرُورى دى.",
        f: "pu de hoTúl ke, chaay skúlo sara gup lugawúl zarooree dee.",
        e: "In this hotel, it's necessary to chit-chat along with drinking tea.",
        a: "12e53"
      }]}
    </Examples>
    <h2 {...{
      "id": "the-prepositions-word-w-p-په-حق-کښې-f-pu-haq-ke-e--a-pu-haq-ke-f---word-w-p-په-باره-کښې-f-pu-baara-ke-e--a-pu-baara-ke-f---word-w-p-باندې-f-baande-e--a-baande---meaning-about"
    }}>{`The prepositions `}<Word w={{
        p: "په حق کښې",
        f: "pu haq ke",
        e: "",
        a: "pu-haq-ke-f"
      }} mdxType="Word" />, <Word w={{
        p: "په باره کښې",
        f: "pu baara ke",
        e: "",
        a: "pu-baara-ke-f"
      }} mdxType="Word" />, <Word w={{
        p: "باندې",
        f: "baande",
        e: "",
        a: "baande"
      }} mdxType="Word" />{` meaning “about”`}</h2>
    <Examples mdxType="Examples">
  {[{
        p: "ستا د الېکشن په حق کښې څۀ خيال دے؟",
        f: "staa du ilekshún pu haq ke, tsu khayaal day?",
        e: "What do you think about the election?",
        a: "12e54"
      }, {
        p: "دوی د پښتنو په باره کښې څۀ ليکى؟",
        f: "dwee du puxtano pu baara ke tsu léekee?",
        e: "What are they writing about Pukhtuns?",
        a: "12e55"
      }, {
        p: "دوى د پښتنو په دستور يعنې رسم او رِواج باندې کتاب ليکى.",
        f: "dwee du puxtano pu dastóor yáne răsam aw riwaaj báande kitáab léekee.",
        e: "They are writing a book about Pukhtun culture, that is, customs and traditions.",
        a: "12e56"
      }]}
    </Examples>
    <img src={manLookingDown} />
    <h2 {...{
      "id": "memorising-moment"
    }}>{`Memorising moment`}</h2>
    <h4 {...{
      "id": "matalóona"
    }}>{`matalóona`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[12]}</Examples>
    <h2 {...{
      "id": "exercises-for-lesson-twelve"
    }}>{`Exercises for Lesson Twelve`}</h2>
    <ol>
      <li parentName="ol">{`Fill in the blanks with the correct form of the verb listed:`}</li>
    </ol>
    <Quiz mdxType="Quiz">
  {[{
        pref: {
          p: "ګرځېدل",
          f: "gurdzedul"
        },
        beg: {
          p: "تاسو هر ماښام چرته",
          f: "taaso hăr maaxáam chărta"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "ګرځئ",
          f: "gurdzey"
        }]
      }, {
        pref: {
          p: "ګرځېدل",
          f: "gurdzedul"
        },
        beg: {
          p: "تاسو هر ماښام چرته",
          f: "taaso hăr maaxáam chărta"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "ګرځئ",
          f: "gurdzey"
        }]
      }, {
        pref: {
          p: "اودرېدل",
          f: "oodredul"
        },
        beg: {
          p: "بس دلته",
          f: "bus dălta"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "اودرېږي",
          f: "oodreGee"
        }]
      }, {
        pref: {
          p: "پرېوتل",
          f: "prewatul"
        },
        beg: {
          p: "کتاب مې له مېز نه",
          f: "kitáab me la mez na"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "پرېوځي",
          f: "pre`oodzee"
        }, {
          p: "پرېوزي",
          f: "pre`oozee"
        }]
      }, {
        pref: {
          p: "پوهېدل",
          f: "pohedul"
        },
        beg: {
          p: "تهٔ په ټوله پښتو",
          f: "tu pu Tola puxto"
        },
        end: {
          p: "؟",
          f: "?"
        },
        answer: [{
          p: "پوهېږې",
          f: "poheGe"
        }]
      }]}
    </Quiz>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`For the twelve verbs listed in this chapter, make up sentences in the present tense, in the infinitive and using the infinitive as a gerund.`}</li>
    </ol>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "اودرېدل",
        f: "oodredul",
        e: "",
        a: "oodredul"
      }, {
        p: "کښېناستل",
        f: "kenaastul",
        e: "",
        a: "kenaastul"
      }, {
        p: "اوسېدل",
        f: "osedul",
        e: "",
        a: "osedul"
      }, {
        p: "څملاستل",
        f: "tsumlaastul",
        e: "",
        a: "tsumlaastul"
      }, {
        p: "ګرځېدل",
        f: "gurdzedul",
        e: "",
        a: "gurdzedul"
      }, {
        p: "پاڅېدل",
        f: "paatsedul",
        e: "",
        a: "paatsedul"
      }, {
        p: "رسېدل",
        f: "rasedul",
        e: "",
        a: "rasedul"
      }, {
        p: "ایسارېدل",
        f: "eesaaredul",
        e: "",
        a: "eesaaredul"
      }, {
        p: "ننوتل",
        f: "nunawatul",
        e: "",
        a: "nunawatul"
      }, {
        p: "پوهېدل",
        f: "pohedul",
        e: "",
        a: "pohedul-m"
      }, {
        p: "وتل",
        f: "watul",
        e: "",
        a: "watul-m"
      }]}
    </Examples>
    <img src={praying} />
    <h2 {...{
      "id": "summary-of-verbs-in-the-present-tense"
    }}>{`Summary of verbs in the present tense`}</h2>
    <Table bordered unset wide mdxType="Table">
  {[[{
        rowSpan: 3,
        e: <strong>Infinitive</strong>
      }, {
        span: 3,
        e: <strong>Present tense</strong>
      }], [{
        e: "I"
      }, {
        e: "You"
      }, {
        e: "He, She"
      }], [{
        e: "We"
      }, {
        e: "You (pl)"
      }, {
        e: "They"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "اخستل",
        f: "akhistul",
        e: "to buy",
        a: "akhistul-m"
      }, {
        p: "اخلم",
        f: "akhlúm",
        e: "",
        a: "akhlum"
      }, {
        p: "اخلې",
        f: "akhlé",
        e: "",
        a: "akhle"
      }, {
        p: "اخلی",
        f: "akhlée",
        e: "",
        a: "akhlee"
      }], [{
        p: "اخلو",
        f: "akhlóo",
        e: "",
        a: "akhloo"
      }, {
        p: "اخلئ",
        f: "akhléy",
        e: "",
        a: "akhley"
      }, {
        p: "اخلی",
        f: "akhlée",
        e: "",
        a: "akhlee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "اغوستل",
        f: "aghostul",
        e: "to wear",
        a: "aghostul-m"
      }, {
        p: "اغوندم",
        f: "ághondum",
        e: "",
        a: "aghondum"
      }, {
        p: "اغوندې",
        f: "ághonde",
        e: "",
        a: "aghonde"
      }, {
        p: "اغوندی",
        f: "ághondee",
        e: "",
        a: "aghondee"
      }], [{
        p: "اغوندو",
        f: "ághondoo",
        e: "",
        a: "aghondoo"
      }, {
        p: "اغوندئ",
        f: "ághondey",
        e: "",
        a: "aghondey"
      }, {
        p: "اغوندی",
        f: "ághondee",
        e: "",
        a: "aghondee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "آورېدل",
        f: "aawredul",
        e: "to hear",
        a: "aawredul-m"
      }, {
        p: "آورم",
        f: "áawrum",
        a: "aawrum"
      }, {
        p: "آورې",
        f: "áawre",
        a: "aawre"
      }, {
        p: "آوری",
        f: "áawree",
        a: "aawree"
      }], [{
        p: "آورو",
        f: "áawroo",
        a: "aawroo"
      }, {
        p: "آورئ",
        f: "áawrey",
        a: "aawrey"
      }, {
        p: "آوری",
        f: "áawree",
        a: "aawree"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "اوسېدل",
        f: "osedúl",
        e: "to live (reside)",
        a: "osedul-m"
      }, {
        p: "اوسېږم",
        f: "oséGum",
        a: "oseggum"
      }, {
        p: "اوسېږې",
        f: "oséGe",
        a: "osegge"
      }, {
        p: "اوسېږی",
        f: "oséGee",
        a: "oseggee"
      }], [{
        p: "اوسېږو",
        f: "oséGoo",
        a: "oseggoo"
      }, {
        p: "اوسېږئ",
        f: "oséGey",
        a: "oseggey"
      }, {
        p: "اوسېږی",
        f: "oséGee",
        a: "oseggee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "ایسارېدل",
        f: "eesaaredul",
        e: "to wait",
        a: "eesaaredul-m"
      }, {
        p: "ایسارېږم",
        f: "eesaaréGum",
        a: "eesaareggum"
      }, {
        p: "ایسارېږې",
        f: "eesaaréGe",
        a: "eesaaregge"
      }, {
        p: "ایسارېږی",
        f: "eesaaréGee",
        a: "eesaareggee"
      }], [{
        p: "ایسارېږو",
        f: "eesaaréGoo",
        a: "eesaareggoo"
      }, {
        p: "ایسارېږئ",
        f: "eesaaréGey",
        a: "eesaareggey"
      }, {
        p: "ایسارېږی",
        f: "eesaaréGee",
        a: "eesaareggee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "بوتلل",
        f: "botlúl",
        e: "to take (animate)",
        a: "botlul-m"
      }, {
        p: "بوځم",
        f: "bodzúm",
        a: "bodzum"
      }, {
        p: "بوځې",
        f: "bodzé",
        a: "bodze"
      }, {
        p: "بوځی",
        f: "bodzée",
        a: "bodzee"
      }], [{
        p: "بوځو",
        f: "bodzóo",
        a: "bodzoo"
      }, {
        p: "بوځئ",
        f: "bodzéy",
        a: "bodzey"
      }, {
        p: "بوځی",
        f: "bodzée",
        a: "bodzee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "پاڅېدل",
        f: "paatsedul",
        e: "to get up",
        a: "paatsedul-m"
      }, {
        p: "پاځم",
        f: "paatsúm",
        a: "paatsum"
      }, {
        p: "پاځې",
        f: "paatsé",
        a: "paatse"
      }, {
        p: "پاځی",
        f: "paatsée",
        a: "paatsee"
      }], [{
        p: "پاځو",
        f: "paatsóo",
        a: "paatsoo"
      }, {
        p: "پاځئ",
        f: "paatséy",
        a: "paatsey"
      }, {
        p: "پاځی",
        f: "paatsée",
        a: "paatsee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "پرېوتل",
        f: "prewatul",
        e: "to fall",
        a: "prewatul-m"
      }, {
        p: "پرېوځم",
        f: "pre`oodzum",
        a: "preoodzum"
      }, {
        p: "پرېوځې",
        f: "pre`oodze",
        a: "preoodze"
      }, {
        p: "پرېوځی",
        f: "pre`oodzee",
        a: "preoodzee"
      }], [{
        p: "پرېوځو",
        f: "pre`oodzoo",
        a: "preoodzoo"
      }, {
        p: "پرېوځئ",
        f: "pre`oodzey",
        a: "preoodzey"
      }, {
        p: "پرېوځی",
        f: "pre`oodzee",
        a: "preoodzee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "پوهېدل",
        f: "pohedul",
        e: "to understand (know)",
        a: "pohedul-m"
      }, {
        p: "پوهېږم",
        f: "pohéGum",
        a: "poheggum"
      }, {
        p: "پوهېږې",
        f: "pohéGe",
        a: "pohegge"
      }, {
        p: "پوهېږی",
        f: "pohéGee",
        a: "poheggee"
      }], [{
        p: "پوهېږو",
        f: "pohéGoo",
        a: "poheggoo"
      }, {
        p: "پوهېږئ",
        f: "pohéGey",
        a: "poheggey"
      }, {
        p: "پوهېږی",
        f: "pohéGee",
        a: "poheggee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "پېژندل",
        f: "pejzandúl",
        e: "to know (recognize)",
        a: "pejzandul-m"
      }, {
        p: "پېژنم",
        f: "péjzanum",
        a: "pejzanum"
      }, {
        p: "پېژنې",
        f: "péjzane",
        a: "pejzane"
      }, {
        p: "پېژنی",
        f: "péjzanee",
        a: "pejzanee"
      }], [{
        p: "پېژنو",
        f: "péjzanoo",
        a: "pejzanoo"
      }, {
        p: "پېژنئ",
        f: "péjzaney",
        a: "pejzaney"
      }, {
        p: "پېژنی",
        f: "péjzanee",
        a: "pejzanee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "تلل",
        f: "tlul",
        e: "to go",
        a: "tlul-m"
      }, {
        p: "ځم",
        f: "dzum",
        a: "dzum"
      }, {
        p: "ځې",
        f: "dze",
        a: "dze"
      }, {
        p: "ځی",
        f: "dzee",
        a: "dzee"
      }], [{
        p: "ځو",
        f: "dzoo",
        a: "dzoo"
      }, {
        p: "ځئ",
        f: "dzey",
        a: "dzey"
      }, {
        p: "ځی",
        f: "dzee",
        a: "dzee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "څښل",
        f: "skul",
        e: "to drink",
        a: "skul-m"
      }, {
        p: "څښم",
        f: "skum",
        a: "skum"
      }, {
        p: "څښې",
        f: "ske",
        a: "ske"
      }, {
        p: "څښی",
        f: "skee",
        a: "skee"
      }], [{
        p: "څښو",
        f: "skoo",
        a: "skoo"
      }, {
        p: "څښئ",
        f: "skey",
        a: "skey"
      }, {
        p: "څښی",
        f: "skee",
        a: "skee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "څملاستل",
        f: "tsumlaastul",
        e: "to lie (down)",
        a: "tsumlaastul-m"
      }, {
        p: "څملم",
        f: "tsumlúm",
        a: "tsumlum"
      }, {
        p: "څملې",
        f: "tsumlé",
        a: "tsumle"
      }, {
        p: "څملی",
        f: "tsumlée",
        a: "tsumlee"
      }], [{
        p: "څملو",
        f: "tsumlóo",
        a: "tsumloo"
      }, {
        p: "څملئ",
        f: "tsumléy",
        a: "tsumley"
      }, {
        p: "څملی",
        f: "tsumlée",
        a: "tsumlee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "خوړل",
        f: "khoRul",
        e: "to eat",
        a: "khorrul-m"
      }, {
        p: "خورم",
        f: "khorúm",
        a: "khorum"
      }, {
        p: "خورې",
        f: "khoré",
        a: "khore"
      }, {
        p: "خوری",
        f: "khorée",
        a: "khoree"
      }], [{
        p: "خورو",
        f: "khoróo",
        a: "khoroo"
      }, {
        p: "خورئ",
        f: "khoréy",
        a: "khorey"
      }, {
        p: "خوری",
        f: "khorée",
        a: "khoree"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "راتلل",
        f: "raatlul",
        e: "to come",
        a: "raatlul-m"
      }, {
        p: "راځم",
        f: "raadzúm",
        a: "raadzum"
      }, {
        p: "راځې",
        f: "raadzé",
        a: "raadze"
      }, {
        p: "راځی",
        f: "raadzée",
        a: "raadzee"
      }], [{
        p: "راځو",
        f: "raadzóo",
        a: "raadzoo"
      }, {
        p: "راځئ",
        f: "raadzéy",
        a: "raadzey"
      }, {
        p: "راځی",
        f: "raadzée",
        a: "raadzee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "راوړل",
        f: "raawRul",
        e: "to bring (inanimate)",
        a: "raawrrul-m"
      }, {
        p: "راوړم",
        f: "raawRúm",
        a: "raawrrum"
      }, {
        p: "راوړې",
        f: "raawRé",
        a: "raawrre"
      }, {
        p: "راوړی",
        f: "raawRée",
        a: "raawrree"
      }], [{
        p: "راوړو",
        f: "raawRóo",
        a: "raawrroo"
      }, {
        p: "راوړئ",
        f: "raawRéy",
        a: "raawrrey"
      }, {
        p: "راوړی",
        f: "raawRée",
        a: "raawrree"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "راوستل",
        f: "raawustul",
        e: "to bring (animate)",
        a: "raawustul-m"
      }, {
        p: "راولم",
        f: "raawulúm",
        a: "raawulum"
      }, {
        p: "راولې",
        f: "raawulé",
        a: "raawule"
      }, {
        p: "راولی",
        f: "raawulée",
        a: "raawulee"
      }], [{
        p: "راولو",
        f: "raawulóo",
        a: "raawuloo"
      }, {
        p: "راولئ",
        f: "raawuléy",
        a: "raawuley"
      }, {
        p: "راولی",
        f: "raawulée",
        a: "raawulee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "رسېدل",
        f: "rasedul",
        e: "to arrive, reach",
        a: "rasedul-m"
      }, {
        p: "رسېږم",
        f: "raséGum",
        a: "raseggum"
      }, {
        p: "رسېږې",
        f: "raséGe"
      }, {
        p: "رسېږی",
        f: "raséGee"
      }], [{
        p: "رسېږو",
        f: "raséGoo"
      }, {
        p: "رسېږئ",
        f: "raséGey"
      }, {
        p: "رسېږی",
        f: "raséGee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "غوښتل",
        f: "ghoxtul",
        e: "to want",
        a: "ghoxtul-m"
      }, {
        p: "غواړم",
        f: "ghwáaRum",
        a: "ghwaarrum"
      }, {
        p: "غواړې",
        f: "ghwáaRe",
        a: "ghwaarre"
      }, {
        p: "غواړی",
        f: "ghwáaRee",
        a: "ghwaarree"
      }], [{
        p: "غواړو",
        f: "ghwáaRoo",
        a: "ghwaarroo"
      }, {
        p: "غواړئ",
        f: "ghwáaRey",
        a: "ghwaarrey"
      }, {
        p: "غواړی",
        f: "ghwáaRee",
        a: "ghwaarree"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "کول",
        f: "kawul",
        e: "to do",
        a: "kawul-m"
      }, {
        p: "کوم",
        f: "kawúm",
        a: "kawum"
      }, {
        p: "کوې",
        f: "kawé",
        a: "kawe"
      }, {
        p: "کوی",
        f: "kawée",
        a: "kawee"
      }], [{
        p: "کوو",
        f: "kawóo",
        a: "kawoo"
      }, {
        p: "کوئ",
        f: "kawéy",
        a: "kawey"
      }, {
        p: "کوی",
        f: "kawée",
        a: "kawee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "کېدل",
        f: "kedul",
        e: "to become",
        a: "kedul-m"
      }, {
        p: "کېږم",
        f: "kéGum",
        a: "keggum"
      }, {
        p: "کېږې",
        f: "kéGe",
        a: "kegge"
      }, {
        p: "کېږی",
        f: "kéGee",
        a: "keggee"
      }], [{
        p: "کېږو",
        f: "kéGoo",
        a: "keggoo"
      }, {
        p: "کېږئ",
        f: "kéGey",
        a: "keggey"
      }, {
        p: "کېږی",
        f: "kéGee",
        a: "keggee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "کښېناستل",
        f: "kenaastul",
        e: "to sit",
        a: "kenaastul-m"
      }, {
        p: "کښېنم",
        f: "kenúm",
        a: "kenum"
      }, {
        p: "کښېنې",
        f: "kené",
        a: "kene"
      }, {
        p: "کښېنی",
        f: "kenée",
        a: "kenee"
      }], [{
        p: "کښېنو",
        f: "kenóo",
        a: "kenoo"
      }, {
        p: "کښېنئ",
        f: "kenéy",
        a: "keney"
      }, {
        p: "کښېنی",
        f: "kenée",
        a: "kenee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "ګرځېدل",
        f: "gurdzedul",
        e: "to walk",
        a: "gurdzedul"
      }, {
        p: "ګرځم",
        f: "gúrdzum",
        a: "gurdzum"
      }, {
        p: "ګرځې",
        f: "gúrdze",
        a: "gurdze"
      }, {
        p: "ګرځی",
        f: "gúrdzee",
        a: "gurdzee"
      }], [{
        p: "ګرځو",
        f: "gúrdzoo",
        a: "gurdzoo"
      }, {
        p: "ګرځئ",
        f: "gúrdzey",
        a: "gurdzey"
      }, {
        p: "ګرځی",
        f: "gúrdzee",
        a: "gurdzee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "ګنډل",
        f: "ganDul",
        e: "to sew",
        a: "ganddul-m"
      }, {
        p: "ګنډم",
        f: "ganDúm",
        a: "ganddum"
      }, {
        p: "ګنډې",
        f: "ganDé",
        a: "gandde"
      }, {
        p: "ګنډی",
        f: "ganDée",
        a: "ganddee"
      }], [{
        p: "ګنډو",
        f: "ganDóo",
        a: "ganddoo"
      }, {
        p: "ګنډئ",
        f: "ganDéy",
        a: "ganddey"
      }, {
        p: "ګنډی",
        f: "ganDée",
        a: "ganddee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "لرل",
        f: "larul",
        e: "to have",
        a: "larul-m"
      }, {
        p: "لرم",
        f: "larum",
        a: "larum"
      }, {
        p: "لرې",
        f: "lare",
        a: "lare"
      }, {
        p: "لری",
        f: "laree",
        a: "laree"
      }], [{
        p: "لرو",
        f: "laroo",
        a: "laroo"
      }, {
        p: "لرئ",
        f: "larey",
        a: "larey"
      }, {
        p: "لری",
        f: "laree",
        a: "laree"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "لوستل",
        f: "lostul, lawastul",
        e: "to read",
        a: "lostul-m"
      }, {
        p: "لولم",
        f: "lólum",
        a: "lolum"
      }, {
        p: "لولې",
        f: "lóle",
        a: "lole"
      }, {
        p: "لولی",
        f: "lólee",
        a: "lolee"
      }], [{
        p: "لولو",
        f: "lóloo",
        a: "loloo"
      }, {
        p: "لولئ",
        f: "lóley",
        a: "loley"
      }, {
        p: "لولی",
        f: "lólee",
        a: "lolee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "لیدل",
        f: "leedul",
        e: "to see",
        a: "leedul-m"
      }, {
        p: "وینم",
        f: "wéenum",
        a: "weenum"
      }, {
        p: "وینې",
        f: "wéene",
        a: "weene"
      }, {
        p: "وینی",
        f: "wéenee",
        a: "weenee"
      }], [{
        p: "وینو",
        f: "wéenoo",
        a: "weenoo"
      }, {
        p: "وینئ",
        f: "wéeney",
        a: "weeney"
      }, {
        p: "وینی",
        f: "wéenee",
        a: "weenee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "لیکل",
        f: "leekul",
        e: "to write",
        a: "leekul-m"
      }, {
        p: "لیکم",
        f: "leekúm",
        a: "leekum"
      }, {
        p: "لیکې",
        f: "leeké",
        a: "leeke"
      }, {
        p: "لیکی",
        f: "leekée",
        a: "leekee"
      }], [{
        p: "لیکو",
        f: "leekóo",
        a: "leekoo"
      }, {
        p: "لیکئ",
        f: "leekéy",
        a: "leekey"
      }, {
        p: "لیکی",
        f: "leekée",
        a: "leekee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "ننوتل",
        f: "nunawatul",
        e: "to enter",
        a: "nunawatul"
      }, {
        p: "ننوځم",
        f: "nunaóodzum",
        a: "nunaoodzum"
      }, {
        p: "ننوځې",
        f: "nunaóodze",
        a: "nunaoodze"
      }, {
        p: "ننوځی",
        f: "nunaóodzee",
        a: "nunaoodzee"
      }], [{
        p: "ننوځو",
        f: "nunaóodzoo",
        a: "nunaoodzoo"
      }, {
        p: "ننوځئ",
        f: "nunaóodzey",
        a: "nunaoodzey"
      }, {
        p: "ننوځی",
        f: "nunaóodzee",
        a: "nunaoodzee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "نیول",
        f: "neewul",
        e: "to catch",
        a: "neewul-m"
      }, {
        p: "نیسم",
        f: "néesum",
        a: "neesum"
      }, {
        p: "نیسې",
        f: "néese",
        a: "neese"
      }, {
        p: "نیسی",
        f: "néesee",
        a: "neesee"
      }], [{
        p: "نیسو",
        f: "néesoo",
        a: "neesoo"
      }, {
        p: "نیسئ",
        f: "néesey",
        a: "neesey"
      }, {
        p: "نیسی",
        f: "néesee",
        a: "neesee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "وتل",
        f: "watul",
        e: "to go out",
        a: "watul-m"
      }, {
        p: "وځم",
        f: "óodzum",
        a: "oodzum"
      }, {
        p: "وځې",
        f: "óodze",
        a: "oodze"
      }, {
        p: "وځی",
        f: "óodzee",
        a: "oodzee"
      }], [{
        p: "وځو",
        f: "óodzoo",
        a: "oodzoo"
      }, {
        p: "وځئ",
        f: "óodzey",
        a: "oodzey"
      }, {
        p: "وځی",
        f: "óodzee",
        a: "oodzee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "وړل",
        f: "oRul",
        e: "to take (inanimate)",
        a: "orrul-m"
      }, {
        p: "وړم",
        f: "oRúm",
        a: "orrum"
      }, {
        p: "وړې",
        f: "oRé",
        a: "orre"
      }, {
        p: "وړی",
        f: "oRée",
        a: "orree"
      }], [{
        p: "وړو",
        f: "oRóo",
        a: "orroo"
      }, {
        p: "وړئ",
        f: "oRéy",
        a: "orrey"
      }, {
        p: "وړی",
        f: "oRée",
        a: "orree"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "اودرېدل",
        f: "oodredul",
        e: "to stop",
        a: "oodredul-m"
      }, {
        p: "اودرېږم",
        f: "oodréGum",
        a: "oodreggum"
      }, {
        p: "اودرېږې",
        f: "oodréGe",
        a: "oodregge"
      }, {
        p: "اودرېږی",
        f: "oodréGee",
        a: "oodreggee"
      }], [{
        p: "اودرېږو",
        f: "oodréGoo",
        a: "oodreggoo"
      }, {
        p: "اودرېږئ",
        f: "oodréGey",
        a: "oodreggey"
      }, {
        p: "اودرېږی",
        f: "oodréGee",
        a: "oodreggee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "وهل",
        f: "wahul",
        e: "to hit",
        a: "wahul-m"
      }, {
        p: "وهم",
        f: "wahúm",
        a: "wahum"
      }, {
        p: "وهې",
        f: "wahé",
        a: "wahe"
      }, {
        p: "وهی",
        f: "wahée",
        a: "wahee"
      }], [{
        p: "وهو",
        f: "wahóo",
        a: "wahoo"
      }, {
        p: "وهئ",
        f: "wahéy",
        a: "wahey"
      }, {
        p: "وهی",
        f: "wahée",
        a: "wahee"
      }], [{
        verticalContent: true,
        rowSpan: 2,
        p: "وینځل",
        f: "weendzul",
        e: "to wash",
        a: "weendzul-m"
      }, {
        p: "وینځم",
        f: "wéendzum",
        a: "weendzum"
      }, {
        p: "وینځې",
        f: "wéendze",
        a: "weendze"
      }, {
        p: "وینځی",
        f: "wéendzee",
        a: "weendzee"
      }], [{
        p: "وینځو",
        f: "wéendzoo",
        a: "weendzoo"
      }, {
        p: "وینځئ",
        f: "wéendzey",
        a: "weendzey"
      }, {
        p: "وینځی",
        f: "wéendzee",
        a: "weendzee"
      }]]}
    </Table>
    <p>{`👆 To practice these verbs, have your helper make a simple sentence using vocabulary you already know. Then have him or her give you a pronoun. Now make a sentence using the new pronoun. Fluency will improve if you resist the temptation to use English. As you get better, go faster.`}</p>
    <p>{`For example:`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "زه لاس وینځم.",
        f: "zu laas wéendzum.",
        e: "",
        a: "zu-laas-weendzum"
      }, {
        p: "مونږ",
        f: "moonG",
        e: "",
        a: "moongg-2"
      }, {
        p: "مونږ لاس وینځو.",
        f: "moonG laas wéendzoo.",
        e: "",
        a: "moongg-laas-weendzoo"
      }]}
    </Examples>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-twelve-ladies-visiting-a-newborn",
    level: 2,
    title: "Dialogue twelve: Ladies visiting a newborn",
    children: [
        
      ]
  },
{
    id: "renting-a-house",
    level: 2,
    title: "Renting a house",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation drill",
    children: [
        
      ]
  },
{
    id: "twelve-intransitive-verbs-in-the-present-tense",
    level: 2,
    title: "Twelve intransitive verbs in the present tense",
    children: [
        
      ]
  },
{
    id: "infinitive",
    level: 2,
    title: "Infinitive",
    children: [
        {
              id: "infinitives-as-gerunds--ing",
              level: 3,
              title: "Infinitives as gerunds (-ing)",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "the-prepositions-word-w-p-په-حق-کښې-f-pu-haq-ke-e--a-pu-haq-ke-f---word-w-p-په-باره-کښې-f-pu-baara-ke-e--a-pu-baara-ke-f---word-w-p-باندې-f-baande-e--a-baande---meaning-about",
    level: 2,
    title: <React.Fragment>{`The prepositions `}<Word w={{ p: "په حق کښې", f: "pu haq ke", e: "", a: "pu-haq-ke-f" }} />, <Word w={{ p: "په باره کښې", f: "pu baara ke", e: "", a: "pu-baara-ke-f" }} />, <Word w={{ p: "باندې", f: "baande", e: "", a: "baande" }} />{` meaning “about”`}</React.Fragment>,
    children: [
        
      ]
  },
{
    id: "memorising-moment",
    level: 2,
    title: "Memorising moment",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-twelve",
    level: 2,
    title: "Exercises for Lesson Twelve",
    children: [
        
      ]
  },
{
    id: "summary-of-verbs-in-the-present-tense",
    level: 2,
    title: "Summary of verbs in the present tense",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Twelve"
}

