
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"17 min read","minutes":16.53,"time":991800,"words":3306}
/* @jsxRuntime classic */
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import memorizingMoments from "./memorizing-moments.jsx";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import womanSadir from "../assets/images/woman-sadir.png";
import talkingByDoor from "../assets/images/talking-by-door.png";
import stirringPots from "../assets/images/stirring-pots.png";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "dialogue-twenty-three-ladies-home-life"
    }}>{`Dialogue twenty-three: Ladies’ home-life`}</h2>
    <Dialogue audio="dialogue-23a" mdxType="Dialogue">
  {[{
        s: "L",
        p: "ستا ژوند څنګه تېرېږى؟",
        f: "staa jzwund tsunga teréGee?",
        e: "How is your life going?",
        a: "23d1"
      }, {
        s: "H",
        p: "د الله فضل دے.",
        f: "du aallaa fazul day.",
        e: "By God's grace.",
        a: "23d2"
      }, {
        s: "L",
        p: "وادۀ دې په خپله خوښه کړے دے؟",
        f: "waadu de pu khpula khwaxa kuRay day?",
        e: "Was your wedding of your own choosing?",
        a: "23d3"
      }, {
        s: "H",
        p: "نه، د مور پلار په خوښه مې کړے دے.",
        f: "na, du mor plaay pu khwaxa me kúRay day.",
        e: "No, I have gone with my parents' choice.",
        a: "23d4"
      }, {
        s: "L",
        p: "خاوند درسره ښۀ دے؟",
        f: "khaawúnd dăr-sara xu day?",
        e: "Is your husband good to you?",
        a: "23d5"
      }, {
        s: "H",
        p: "نه، ډېر تريخ دے.",
        f: "na, Der treekh day.",
        e: "No, he is very harsh.",
        a: "23d6"
      }, {
        s: "L",
        p: "ولې ټکوی دې څۀ؟",
        f: "wăle Takawee de tsu?",
        e: "Why, does he hit you?",
        a: "23d7"
      }, {
        s: "H",
        p: "نه، ټکوی مې نۀ خو هر وخت غُصه کېږى.",
        f: "na, Takawée me na kho hăr wakht ghUsa kéGee.",
        e: "No, he doesn't hit me, but he is always angry.",
        a: "23d8"
      }, {
        s: "L",
        p: "هغه ولې داسې کوى؟",
        f: "haghá wăle daase kawée?",
        e: "Why is he like that? (Lit. 'Why does he do that?')",
        a: "23d9"
      }, {
        s: "H",
        p: "هغه هر وخت غواړى چې زۀ د سعد خيال وساتم.",
        f: "haghá hăr wakht ghwaaRee che zu du sa'ad khyaal óosaatum.",
        e: "He always wants me to take care of Saad.",
        a: "23d10"
      }, {
        s: "L",
        p: "خو تۀ به څۀ وکې، د کور کارُونه هم ډېر وى.",
        f: "kho tu bu tsu óoke, du kor kaaróona hum Der wee.",
        e: "But what can you do, there is also a lot of housework.",
        a: "23d11"
      }]}
    </Dialogue>
    <img src={womanSadir} style={{
      maxWidth: "10rem"
    }} />
    <h2 {...{
      "id": "mens-dialogue-enmity"
    }}>{`Men’s dialogue: Enmity`}</h2>
    <Dialogue audio="dialogue-23b" mdxType="Dialogue">
  {[{
        s: "L",
        p: "زۀ نن دا زده کوم چې دُشمنى څنګه پېدا کېږى.",
        f: "zu nun daa zda kawum che dUshmanee tsungo pedaa kéGee.",
        e: "Today I am learning how feuds start.",
        a: "23d12"
      }, {
        s: "H",
        p: "دُشمنى يا په ښځو يا په زمکو يا په دولت او يا په غلا پېدا کېږى.",
        f: "dUshmanee yaa pu xudzo yaa pu zmúka ya pu dolăt aw yaa pu ghlaa pedaa keGee.",
        e: "Feuds start over women, land, money or theft.",
        a: "23d13"
      }, {
        s: "L",
        p: "بدله اخستل ښۀ کار دے؟",
        f: "badlá akhistúl xu kaar day?",
        e: "Is taking revenge a good thing?",
        a: "23d14"
      }, {
        s: "H",
        p: "آو، په څۀ حالاتو کښې ښۀ کار دے.",
        f: "aw, pu tso haalaato ke xu kaar day.",
        e: "Yes, in some situations it's good.",
        a: "23d15"
      }, {
        s: "L",
        p: "کۀ څوک قتل کړے شى، نو اول څوک د هغۀ بدل اخلى؟",
        f: "ku tsok qátal kRay shee, no awál tsok du haghú badál ákhlee?",
        e: "If someone is killed, then who is the first one to take revenge?",
        a: "23d16"
      }, {
        s: "H",
        p: "ورُوڼه او زامن يې بدل اخلى.",
        f: "wrooNa aw zaamún ye badál akhlee.",
        e: "Brothers and sons take revenge.",
        a: "23d17"
      }, {
        s: "L",
        p: "چې دُشمنى يوه پېره شروع شى، نو بيا څنګه ختمېدے شى؟",
        f: "che dUshmanee yuwa pera shUróo shee, no byaa tsungo khatmeday shee?",
        e: "If once a feud starts, then how can it stopped?",
        a: "23d18"
      }, {
        s: "H",
        p: "هغوى له به سوره او يا پیسې ورکوى.",
        f: "haghwee la ba swara aw ya paysee wărkawée.",
        e: "They will give swara or money to them.",
        a: "23d19"
      }, {
        s: "L",
        p: "ما ته د دُشمنۍ يوه قيصه وکه.",
        f: "maa ta du dUshmanúy yuwa qeesa óoka.",
        e: "Tell me a story about a feud.",
        a: "23d20"
      }]}
    </Dialogue>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>
  <Word w={{
        p: "سوره",
        f: "swara",
        e: "",
        a: "swara-f"
      }} mdxType="Word" /> is the tradition
  in some villages in which a girl of marriageable age is given to the aggrieved
  party in the hope that her marriage to the enemy will end the enmity.
    </p>
    <img src={talkingByDoor} style={{
      maxWidth: "10rem"
    }} />
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation drill`}</h2>
    <p>{`Practice these tongue twisters:`}</p>
    <Examples memorizingMoment mdxType="Examples">
  {[{
        p: "ولى د ولے ولې ولې ولى؟",
        f: "waalée, du walay walé wăle wulee?",
        e: "",
        a: "23tt1"
      }, {
        p: "طياره په تيارۀ کښې تياره شوه.",
        f: "tayáara pu tiyáara ke tayáara shwa.",
        e: "",
        a: "23tt2"
      }]}
    </Examples>
    <h2 {...{
      "id": "used-to-would-the-habitual-past"
    }}>{`'used to, would’: The habitual past`}</h2>
    <p>{`The habitual past is used to talk about actions that would happen regularly, or habitually in the past. There are two forms:`}</p>
    <Table bordered unset wide verticalContent titleRow={["", "Imperfective Habitual Past", "Perfective Habitual Past"]} mdxType="Table">
  {[[{
        e: "used for"
      }, {
        e: "actions that would happen regularly or habitually, with each occurance viewed as ongoing action or an action that was happening over time"
      }, {
        e: "actions that would happen regularly or habitually, with each occurance viewed as a single, complete action"
      }], [{
        e: "formula"
      }, {
        e: <>
            <Word w={{
            p: "به",
            f: "ba",
            e: "",
            a: "ba-f"
          }} mdxType="Word" /> + progressive
            past
          </>
      }, {
        e: <>
            <Word w={{
            p: "به",
            f: "ba",
            e: "",
            a: "ba-f"
          }} mdxType="Word" /> + simple past
          </>
      }], [{
        e: "example"
      }, {
        p: "دوستان به راتلل",
        f: "dostáan ba raatlúl",
        e: "Friends would/used to come (emphasizing the process of coming each time)",
        a: ""
      }, {
        p: "دوستان به راغلل",
        f: "dostáan ba ráaghlul",
        e: "Friends would/used to come (with each coming as a complete event - ie. just saying they arrived each time)",
        a: ""
      }]]}
    </Table>
    <h3 {...{
      "id": "intransitive-verbs-in-the-imperfective-habitual-past"
    }}>{`Intransitive verbs in the imperfective habitual past`}</h3>
    <Examples mdxType="Examples">
  {[{
        p: "بس به دلته ودرېدو؟",
        f: "bus dălta oodredó?",
        e: "Did the bus used to stop here?",
        a: "23e1"
      }, {
        p: "آو، پروسکال به دلته ودرېدو.",
        f: "aw, paroskáal ba dălta oodredó.",
        e: "Yes, last year it used to stop here.",
        a: "23e2"
      }, {
        p: "تاسو به په پېښور کښې اوسېدئ؟",
        f: "taaso ba pu pexawúr ke osedéy?",
        e: "Did you used to live in Peshawar?",
        a: "23e3"
      }, {
        p: "آو، مُونږ به هلته اوسېدُو.",
        f: "aw, moonG ba halta osedóo.",
        e: "Yes, we used to live there.",
        a: "23e4"
      }, {
        p: "هغه به څۀ وخت کار ته راتلو؟",
        f: "haghá ba tsu wakht kaar ta raatló?",
        e: "When did he used to come to work?",
        a: "23e5"
      }, {
        p: "هغه به وختى کار ته راتلو.",
        f: "haghá ba wakhtee kaar ta raatló.",
        e: "He used to come to work early.",
        a: "23e6"
      }, {
        p: "تۀ به هميشه چرته تلې؟",
        f: "tu ba hamaysha chărta tle?",
        e: "Where did you always used to go?",
        a: "23e7"
      }, {
        p: "زۀ به باغ ته تلم.",
        f: "zu ba baagh ta tlum.",
        e: "I used to go to the park.",
        a: "23e8"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p>{`The third sentence makes a very good one for substitution drills.`}</p>
    <h3 {...{
      "id": "intransitive-stative-compounds-with-word-w-p-کېدل-f-kedul-e-to-become-a-kedul---in-the-imperfective-habitual-past"
    }}>{`Intransitive stative compounds with `}<Word w={{
        p: "کېدل",
        f: "kedul",
        e: "to become",
        a: "kedul"
      }} mdxType="Word" />{` in the imperfective habitual past`}</h3>
    <Examples mdxType="Examples">
  {[{
        p: "ډوډۍ به څۀ وخت پخېده؟",
        f: "DoDúy ba tsu wakht pakhedá?",
        e: "When did the bread used to be cooked?",
        a: "23e9"
      }, {
        p: "شپږ بجې به پخېده.",
        f: "shpuG baje ba pakhedá.",
        e: "It used to be cooked at six o'clock.",
        a: "23e10"
      }, {
        p: "تۀ به څۀ وخت وږے کېدې؟",
        f: "tu ba tsu wakht Úgay kedé?",
        e: "When did you used to become hungry?",
        a: "23e11"
      }, {
        p: "زۀ به ماښام وږے کېدم.",
        f: "zu ba maaxáam ÚGay kedúm.",
        e: "I used to become hungry in the evening.",
        a: "23e12"
      }, {
        p: "بارانونه به کله شروع کېدل؟",
        f: "baaraanóona ba kala shUroo kedúl?",
        e: "When did it used to start raining? (m.pl.)",
        a: "23e13"
      }, {
        p: "په پشکال کښې به شروع کېدل.",
        f: "pu pashakáal ke ba shUroo kedúl.",
        e: "It used to rain in Pashakal.",
        a: "23e14"
      }, {
        p: "پښه به دې خوږېده؟",
        f: "xpo ba de khooGeda?",
        e: "Did your foot used to hurt?",
        a: "23e15"
      }, {
        p: "آو، خوږېده به، خو اوس ټيک ده.",
        f: "aw, khooGeda ba, kho oos Teek da.",
        e: "Yes, it used to hurt, but now it is fine.",
        a: "23e16"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>
  <Word w={{
        p: "پشکال",
        f: "pashakáal",
        e: "",
        a: "pashakaal-f"
      }} mdxType="Word" /> is the
  hot and humid time prior to the monsoon. Explore this and other traditional
  Pukhtun names for the months.
    </p>
    <h3 {...{
      "id": "passive-verbs-in-the-imperfective-habitual-past"
    }}>{`Passive verbs in the imperfective habitual past`}</h3>
    <p>{`The verb `}<Word w={{
        p: "کېدل",
        f: "kedul",
        e: "to become",
        a: "kedul"
      }} mdxType="Word" />{` is also used in the passive forms of transitive verbs.`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "کپړې به کله وينځلې کېدې؟",
        f: "kapRé ba kala weendzule kedé?",
        e: "When did your clothes used to be washed?",
        a: "23e17"
      }, {
        p: "هره هفته به وينځلې کېدې.",
        f: "hăra haftá ba weendzule kedé.",
        e: "They used to be washed every Saturday.",
        a: "23e18"
      }]}
    </Examples>
    <h3 {...{
      "id": "transitive-verbs-in-the-imperfective-habitual-past"
    }}>{`Transitive verbs in the imperfective habitual past`}</h3>
    <Examples mdxType="Examples">
  {[{
        p: "تا به کپړې ګنډلې؟",
        f: "taa ba kapRé ganDúle?",
        e: "Did you used to sew clothes? (f.pl.)",
        a: "23e19"
      }, {
        p: "آو، ما به ګنډلې.",
        f: "aw, maa ba ganDúle.",
        e: "Yes, I used to sew them.",
        a: "23e20"
      }, {
        p: "هغۀ به چرته ډوډۍ خوړه؟",
        f: "haghu ba chărta DoDúy khoRá?",
        e: "Where did he used to eat food? (f.s.)",
        a: "23e21"
      }, {
        p: "هغۀ به هره ورځ دلته خوړه.",
        f: "haghú ba hăra wradze dălta khoRá.",
        e: "He used to eat here every day.",
        a: "23e22"
      }, {
        p: "تا به هره شپه شراب څښل؟",
        f: "taa ba hăra shpa sharáab skul?",
        e: "Did you used to drink alcohol every night? (m.pl.)",
        a: "23e23"
      }, {
        p: "آو، ما به څښل خو اوس مې پرېښودل.",
        f: "aw, maa ba skul kho oos me préxodul.",
        e: "Yes, I used to drink it, but now I've stopped it.",
        a: "23e24"
      }, {
        p: "تا به روزانه اخبار وې؟",
        f: "taa ba rozaana akhbáar we?",
        e: "Did you used to read the newspaper every day?",
        a: "23e25"
      }, {
        p: "آو، ما به وې.",
        f: "aw, maa ba we.",
        e: "Yes, I used to read it.",
        a: "23e26"
      }, {
        p: "هغۀ به تۀ وهلې؟",
        f: "haghú ba te wahúle?",
        e: "Did he used to beat you?",
        a: "23e27"
      }, {
        p: "آو، زۀ به يې روزانه وهلم.",
        f: "aw, zu ba ye rozaana wahúlum.",
        e: "Yes, he used to beat me every day.",
        a: "23e28"
      }]}
    </Examples>
    <h3 {...{
      "id": "transitive-dynamic-compounds"
    }}>{`Transitive dynamic compounds`}</h3>
    <Examples mdxType="Examples">
  {[{
        p: "تا به په قطر کښې کار کولو؟",
        f: "taa ba pu qatár ke kaar kawúlo?",
        e: "Did you used to work in Qatar?",
        a: "23e29"
      }, {
        p: "آو، هلته به مې کار کولو.",
        f: "aw, halta ba me kaar kawúlo.",
        e: "Yes, I used to work there.",
        a: "23e30"
      }, {
        p: "تا به د هغوى مدد کولو؟",
        f: "taa ba du haghwée madad kawúlo?",
        e: "Did you used to help them?",
        a: "23e31"
      }, {
        p: "نه، ما به هېڅ کله د هغوى مدد نۀ کولو.",
        f: "na, maa ba hets kala du haghwée madad nu kawúlo.",
        e: "No, I never used to help them.",
        a: "23e32"
      }, {
        p: "تا به مېښې ساتلې؟",
        f: "taa ba méxe saatúle?",
        e: "Did you used to keep buffaloes?",
        a: "23e33"
      }, {
        p: "هغې به ما سره خبرې کولې.",
        f: "haghe ba maa sara khabúre kawúle.",
        e: "She used to talk with me.",
        a: "23e34"
      }, {
        p: "تېر کال به مې د دوئ سره ډېرې ټوقې کولې.",
        f: "tek kaal ba me du dwee sara Dere Tóqe kawúle.",
        e: "I used to joke with them a lot last year.",
        a: "23e35"
      }]}
    </Examples>
    <h3 {...{
      "id": "transitive-stative-compounds"
    }}>{`Transitive stative compounds`}</h3>
    <Examples mdxType="Examples">
  {[{
        p: "چې تۀ ځوان وې تا به ګاډى سمول؟",
        f: "che tu dzwaan we taa ba gáaDay sumawúl?",
        e: "When you were young did you used to fix cars?",
        a: "23e36"
      }, {
        p: "آو، سمول به مې.",
        f: "aw, sumawúl ba me.",
        e: "Yes, I used to fix them.",
        a: "23e37"
      }, {
        p: "ستا نوکر به پیسې پټولې؟",
        f: "staa nokúr ba payse puTawúle?",
        e: "Did your servant used to steal money?",
        a: "23e38"
      }, {
        p: "نه، هغه د هميشه نه ايماندار وو.",
        f: "na, haghá du hamayshá na eemaandáar wo.",
        e: "No, he was always honest.",
        a: "23e39"
      }, {
        p: "هغې به هميشه دومره ښۀ خوراک تيارولو؟",
        f: "haghé ba hamayshá doomra xu khoráak tayaarawúlo?",
        e: "Did she always used to prepare so much good food?",
        a: "23e40"
      }, {
        p: "په ګرمۍ کښې به دې څۀ وخت خپل دُوکان کولاولو او بندولو؟",
        f: "pu garmúy ke ba de tsu wakht khpul dookáan koolaawúlo aw bandawúlo?",
        e: "In the summer when did you used to open and close your shop?",
        a: "23e41"
      }, {
        p: "ما به سحر شپږ بجې کولاولو او د شپې لس بجې به مې بندولو.",
        f: "maa ba sahár shpuG baje koolawúlo aw du shpe lus baje ba me bandawúlo.",
        e: "I used to open it at six in the morning and close it at ten at night.",
        a: "23e42"
      }]}
    </Examples>
    <h3 {...{
      "id": "telling-stories"
    }}>{`Telling stories`}</h3>
    <p>{`This is one of the verb forms that is commonly used in story telling. It needs to be practised by telling short stories that are mostly in this tense.`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "دادا مې، خُدائ دې وبخښی، هغۀ به وې ...",
        f: "daadaa me, khUdaay de óobakhxee, haghé ba we ...",
        e: "My dad, may God forgive him, used to say ...",
        a: "23e43"
      }, {
        p: "ښځه مې، دې خُدائ وبخښى، هغه به وې ...",
        f: "xudza me, de khUdáay óobakhxee, haghá ba we ...",
        e: "My wife, may God forgive her, used to say ...",
        a: "23e44"
      }]}
    </Examples>
    <p>{`Remember that in colloquial speech the verb `}<Word w={{
        p: "وئیل",
        f: "wayul",
        e: "to say",
        a: "wayul-m"
      }} mdxType="Word" /> is often shortened to something like <Word w={{
        p: "وې",
        f: "we",
        e: "",
        a: "we"
      }} mdxType="Word" />{`.`}</p>
    <img src={stirringPots} />
    <h2 {...{
      "id": "useful-time-words"
    }}>{`Useful time words`}</h2>
    <Examples mdxType="Examples">
  {[{
        p: "بصير تل ډېرې پیسې ګټى.",
        f: "baséer tul Dére paysee gaTée.",
        e: "Basir always earns a lot of money.",
        a: "23e45"
      }, {
        p: "تۀ ولې تل هغۀ سره جنګ کوې؟",
        f: "tu wăle tul haghú sara jang kawe?",
        e: "Why are you always fighting with him?",
        a: "23e46"
      }, {
        p: "مُونږ به ټول عُمر پورې هغه يادوُو.",
        f: "moonG bu Tool Umur pore haghá yaadawóo.",
        e: "We will be thinking of him our whole lives.",
        a: "23e47"
      }, {
        p: "ګُلالۍ هميشه خوشحاله وى.",
        f: "gUlaalúy hamayshá khoshaala wee.",
        e: "Gulalay is always happy.",
        a: "23e48"
      }, {
        p: "مُونږ اکثر سبق وايُو.",
        f: "moonG aksár sabúq wáayoo.",
        e: "We usually study.",
        a: "23e49"
      }, {
        p: "زۀ هغه هر ځل وينم.",
        f: "zu haghá hăr dzal weenum.",
        e: "I see him every time.",
        a: "23e50"
      }, {
        p: "هغوى ښار ته ډېر نۀ ځى.",
        f: "haghwee xaar ta Der nu dzee.",
        e: "They don't go to the city very often.",
        a: "23e51"
      }, {
        p: "کله کله پېنټ شرټ اغوندم.",
        f: "kala kala penT shirT aaghondum.",
        e: "I sometimes wear western clothes.",
        a: "23e52"
      }, {
        p: "دا کله نا کله ما له راځى.",
        f: "daa kala naa kala maa la raadzee.",
        e: "She sometimes comes to see me.",
        a: "23e53"
      }, {
        p: "هغوى ډېر کم په کور کښې وى.",
        f: "haghwee Der kum pu kor ke wee.",
        e: "They are seldom at home.",
        a: "23e54"
      }, {
        p: "ډېر کم هغه دلته وينم.",
        f: "Der kum haghá dălta weenum.",
        e: "I seldom see him here.",
        a: "23e55"
      }, {
        p: "ته هېچرې زما کور ته نه راځې!",
        f: "tu hecher zmaa kor ta nu raadze!",
        e: "You never come to my house!",
        a: "23e56"
      }, {
        p: "زۀ هېڅ کله د شپې کار نۀ کوم.",
        f: "zu hech kala du shpe kaar nu kawum.",
        e: "I never work at night.",
        a: "23e57"
      }]}
    </Examples>
    <h2 {...{
      "id": "seasons"
    }}>{`Seasons`}</h2>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "سپرلے",
        f: "sparláy",
        e: "spring",
        a: "sparlay-m"
      }, {
        p: "بهار",
        f: "baháar",
        e: "spring",
        a: "bahaar-m"
      }, {
        p: "اوړے",
        f: "óRay",
        e: "summer",
        a: "orray-m"
      }, {
        p: "منے",
        f: "múnay",
        e: "autumn",
        a: "munay-m"
      }, {
        p: "ژمے",
        f: "jzímay",
        e: "winter",
        a: "jzimay-m"
      }]}
    </Examples>
    <Examples mdxType="Examples">
  {[{
        p: "په سپرلى کښې موسم څنګه وى؟",
        f: "pu sparlée ke mosúm tsínga wee?",
        e: "How is the weather in the spring?",
        a: "23e58"
      }, {
        p: "برابر وى.",
        f: "buraabur wee.",
        e: "It is moderate.",
        a: "23e59"
      }, {
        p: "په ژمى کښې واؤره ورېږى؟",
        f: "pu jzímee ke waawra waréGee?",
        e: "Does it snow in winter?",
        a: "23e60"
      }, {
        p: "نه، نۀ ورېږى.",
        f: "na, nu waréGee.",
        e: "No, it doesn't snow.",
        a: "23e61"
      }]}
    </Examples>
    <h2 {...{
      "id": "comprehension-drill-pictures"
    }}>{`Comprehension drill: Pictures`}</h2>
    <p>{`👆 Collect some photographs of a wide range of local life, or cut out pictures from newspapers and magazines. You can also use the illustrations in `}<a parentName="p" {...{
        "href": "https://www.interlitfoundation.org/learning-dari/picture-it-in-dari-and-pashto"
      }}>{`Picture It in Dari and Pashto`}</a>{`. Choose a photo or picture and talk about it in the following way:`}</p>
    <ol>
      <li parentName="ol">{`Have your helper name the objects. For example,`}</li>
    </ol>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "دا ونه ده. دا سړے دے.",
        f: "daa wúna da. daa saRáy day.",
        e: "This is a tree. This is a man.",
        a: "23c1"
      }]}
    </Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`The helper points to the objects and you name them.`}</li>
    </ol>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "ونه چرته ده؟ سړے چرته دے؟",
        f: "wúna chărta da? saRáy chărta day?",
        e: "Where is the tree? Where is the man?",
        a: "23c2"
      }]}
    </Examples>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Use the picture to show simple relationships between objects.`}</li>
    </ol>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "د ونې لاندې روان سړى ته اِشاره وکه.",
        f: "du wúne laande rawáan saRée ta ishaara óoka.",
        e: "Point to the man walking under the tree.",
        a: "23c3"
      }]}
    </Examples>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Make up a story about the picture in different tenses.`}</li>
    </ol>
    <p>{`When conversation drags in the `}<em parentName="p">{`hujra`}</em>{` or guest room, it is helpful to have a small booklet of pictures with you. It turns any helpful person into a language teacher. It will also keep the conversation focused on a topic with which you are familiar.`}</p>
    <h2 {...{
      "id": "memorising-moment"
    }}>{`Memorising moment`}</h2>
    <h4 {...{
      "id": "matalóona"
    }}>{`matalóona`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[23]}</Examples>
    <h2 {...{
      "id": "exercises-for-lesson-twenty-three"
    }}>{`Exercises for Lesson Twenty-Three`}</h2>
    <ol>
      <li parentName="ol">{`This week record a short story about a feud, or some other event. Write out what your friend has told you using phonetics or script. Pay special attention to the verb endings. The following phrase may help you get started:`}</li>
    </ol>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "ما ته د دُشمنۍ يوه قيصه وکه!",
        f: "maa ta du dUshmanúy yuwa qeesa óoka!",
        e: "Tell me a story about a feud!",
        a: "23d20"
      }]}
    </Examples>
    <p>{`Now look for chances to retell the story.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Tell a short story that starts like this:`}</li>
    </ol>
    <p>{`a. There used to be a rich man …`}<br parentName="p"></br>{`
`}{`b. He used to graze his sheep on a nearby mountain …`}<br parentName="p"></br>{`
`}{`c. He used to eat a lot …`}<br parentName="p"></br>{`
`}{`d. She used to be very poor …`}<br parentName="p"></br>{`
`}{`e. We used to sell vegetables …`}<br parentName="p"></br>{`
`}{`f. She used to attack the villagers …`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-twenty-three-ladies-home-life",
    level: 2,
    title: "Dialogue twenty-three: Ladies’ home-life",
    children: [
        
      ]
  },
{
    id: "mens-dialogue-enmity",
    level: 2,
    title: "Men’s dialogue: Enmity",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation drill",
    children: [
        
      ]
  },
{
    id: "used-to-would-the-habitual-past",
    level: 2,
    title: "'used to, would’: The habitual past",
    children: [
        {
              id: "intransitive-verbs-in-the-imperfective-habitual-past",
              level: 3,
              title: "Intransitive verbs in the imperfective habitual past",
              children: [
                      
                    ]
            },
      {
              id: "intransitive-stative-compounds-with-word-w-p-کېدل-f-kedul-e-to-become-a-kedul---in-the-imperfective-habitual-past",
              level: 3,
              title: <React.Fragment>{`Intransitive stative compounds with `}<Word w={{ p: "کېدل", f: "kedul", e: "to become", a: "kedul" }} />{` in the imperfective habitual past`}</React.Fragment>,
              children: [
                      
                    ]
            },
      {
              id: "passive-verbs-in-the-imperfective-habitual-past",
              level: 3,
              title: "Passive verbs in the imperfective habitual past",
              children: [
                      
                    ]
            },
      {
              id: "transitive-verbs-in-the-imperfective-habitual-past",
              level: 3,
              title: "Transitive verbs in the imperfective habitual past",
              children: [
                      
                    ]
            },
      {
              id: "transitive-dynamic-compounds",
              level: 3,
              title: "Transitive dynamic compounds",
              children: [
                      
                    ]
            },
      {
              id: "transitive-stative-compounds",
              level: 3,
              title: "Transitive stative compounds",
              children: [
                      
                    ]
            },
      {
              id: "telling-stories",
              level: 3,
              title: "Telling stories",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "useful-time-words",
    level: 2,
    title: "Useful time words",
    children: [
        
      ]
  },
{
    id: "seasons",
    level: 2,
    title: "Seasons",
    children: [
        
      ]
  },
{
    id: "comprehension-drill-pictures",
    level: 2,
    title: "Comprehension drill: Pictures",
    children: [
        
      ]
  },
{
    id: "memorising-moment",
    level: 2,
    title: "Memorising moment",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-twenty-three",
    level: 2,
    title: "Exercises for Lesson Twenty-Three",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Twenty-Three"
}

