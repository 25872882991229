
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"16 min read","minutes":15.245,"time":914700,"words":3049}
/* @jsxRuntime classic */
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import memorizingMoments from "./memorizing-moments.jsx";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import PersistentBlank from "../components/persistent-blank";
import guyWithPatoo from "../assets/images/guy-with-patoo.png";
import shortHairedGirl from "../assets/images/short-haired-girl.png";
export const RhymeBlanks = ({
  children,
  storePrefix
}) => <div style={{
  marginBottom: "2rem"
}}>
    {children.map((line, i) => <div kay={i}>
        <Examples mdxType="Examples">{[line]}</Examples>
        <PersistentBlank width="100%" store={`${storePrefix}-${i}`} mdxType="PersistentBlank" />
      </div>)}
  </div>;

const layoutProps = {
  RhymeBlanks
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Dialogue audio="dialogue-27" mdxType="Dialogue">
  {[{
        s: "L",
        p: "بشيره ياره، نن ولې خفه خفه يواځې ناست يې؟",
        f: "basheera yaara, nun wăle khufa khufa yawaadze naast ye?",
        e: "Dear Bashira, why are you sitting alone, so sad today?",
        a: "27d1"
      }, {
        s: "H",
        p: "ترۀ مې مړ شوے دے.",
        f: "tru me muR shíway day.",
        e: "My uncle has died.",
        a: "27d2"
      }, {
        s: "L",
        p: "دې سره زۀ ډېر خفه شوم، دا څۀ وخت؟",
        f: "de sara zu Der khufa shwum, daa tsu wakht?",
        e: "This makes me very sad, when was it?",
        a: "27d3"
      }, {
        s: "H",
        p: "نن سحر راته خبر مِلاؤ شو.",
        f: "nun sahár raa-ta khabúr miláaw sho.",
        e: "The news came to me this morning.",
        a: "27d4"
      }, {
        s: "L",
        p: "زما ډېر افسوس دے. جنازه به کله وى؟",
        f: "zmaa Der afsós day. janaazá ba kala wee?",
        e: "I am very sorry. When will the funeral be?",
        a: "27d5"
      }, {
        s: "H",
        p: "جنازه به څلور بجې وى.",
        f: "janaasá ba tsalór baje wee.",
        e: "The funeral will be at four o'clock.",
        a: "27d6"
      }, {
        s: "L",
        p: "زۀ تا سره جنازې ته تلل غواړم.",
        f: "zu taa sara janaazé ta tlul ghwaaRum.",
        e: "I want to go with you to the ceremony.",
        a: "27d7"
      }, {
        s: "H",
        p: "زۀ خو هلته اوس روان يم، تۀ اوس ما سره هلته ځې؟",
        f: "zu kho halta oos rawáan yim, tu oos maa sara halta dze?",
        e: "I am going there now, will you come with me?",
        a: "27d8"
      }, {
        s: "L",
        p: "زۀ ډېر خفه يم چې د دې دُنيا نه، يو ښۀ سړے لاړو.",
        f: "zu Der khufa yim che de de dUnyaa na, yo xu saRáy láaRo.",
        e: "I am very sad, that a good man has left the world.",
        a: "27d9"
      }, {
        s: "H",
        p: "خو خُدائ ته به دا منظوره وه. هر يو سړے چې دې دُنيا ته راغلے دے، واپس به ځى.",
        f: "kho khUdáay ta ba daa manzóora wa. hăr yo saRáy che de dUnyáa ta raaghúlay day, waapás ba dzee.",
        e: "But that was God's will. Every man who has come into the world will return.",
        a: "27d10"
      }, {
        s: "L",
        p: "خُدائ دې وبخښى.",
        f: "khUdaay de óobakhxee.",
        e: "May God forgive him.",
        a: "27d11"
      }, {
        s: "L",
        p: "خُدائ دې تاسو له صبر درکړى.",
        f: "khUdaay de taaso lo sabur dăr-kRee.",
        e: "May God give you patience.",
        a: "27d12"
      }]}
    </Dialogue>
    <h2 {...{
      "id": "the-past-potential"
    }}>{`The past potential`}</h2>
    <p>{`There are two forms of the past potential in Pukhto.`}</p>
    <ol>
      <li parentName="ol">{`Imperfective Past Potential - “I could … / I couldn’t”`}</li>
    </ol>
    <p>{`This form is to express if something was possible over a period of time, or in general in the past.`}</p>
    <p>{`Form: `}<strong parentName="p">{`infinitive`}</strong>{` + `}<Word w={{
        p: "ـے",
        f: "-ay",
        e: "",
        a: "ay-sound"
      }} mdxType="Word" /> + <Word w={{
        p: "شول",
        f: "shwul",
        e: "",
        a: "shwul-f"
      }} mdxType="Word" />{` root conjugated.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Perfective Past Potential - “I could … / I couldn’t”`}</li>
    </ol>
    <p>{`This form is used if something was possible at one particular moment, or in one particular attempt.`}</p>
    <p>{`Form: `}<strong parentName="p">{`perfective root`}</strong>{` + `}<Word w={{
        p: "ـے",
        f: "-ay",
        e: "",
        a: "ay-sound"
      }} mdxType="Word" /> + <Word w={{
        p: "شول",
        f: "shwul",
        e: "",
        a: "shwul-f"
      }} mdxType="Word" />{` root conjugated.`}</p>
    <p>{`Note: some verbs, like `}<Word w={{
        p: "راتلل",
        f: "raatlúl",
        e: "",
        a: "raatlul"
      }} mdxType="Word" />{` apear to only use thi imperfective form.`}</p>
    <h3 {...{
      "id": "examples-with-the-imperfective-past-potential"
    }}>{`Examples with the imperfective past potential`}</h3>
    <Examples mdxType="Examples">
  {[{
        p: "تا ورسره مدد کولے شو؟",
        f: "taa wăr-sara madúd kawúlay sho?",
        e: "Could you have helped them?",
        a: "27e1"
      }, {
        p: "آو، ما د هغوى مدد کولے شو، خو ومې نۀ کو.",
        f: "aw, maa du haghwee madúd kawúlay sho, kho oo-me-ne-ko.",
        e: "Yes, I could have helped them, but I didn't.",
        a: "27e2"
      }, {
        p: "هغې په خپله پخولے شوه؟",
        f: "haghé pu khpúla pakhawúlay shwa?",
        e: "Could she have cooked it herself?",
        a: "27e3"
      }, {
        p: "زۀ پرُون تاسو کره نۀ شوم راتلے.",
        f: "zu paróon taaso kara nu shwum raatláy.",
        e: "I couldn't come to your house yesterday.",
        a: "27e4"
      }, {
        p: "مُونږ خپل کار خلاصولے شو، خو زمُونږ ډېر مېلمانۀ وُو.",
        f: "moonG khpul kaar khilaasawulay sho, kho zmoonG Der melmaanú woo.",
        e: "We could have finished the work, but we had lots of guests.",
        a: "27e5"
      }, {
        p: "هغې دوه ورځې مخکښې خپل کار نۀ شو خلاصولے.",
        f: "haghé dwa wradze makhke khpul kaar nu sho khilaasawúlay.",
        e: "She couldn't have finished her work two days ago.",
        a: "27e6"
      }, {
        p: "هغۀ زۀ بوتلے نۀ شوم.",
        f: "haghú ze botlay nu shwum.",
        e: "He couldn't take me.",
        a: "27e7"
      }, {
        p: "هغوى مې نن نۀ شو ليدے.",
        f: "haghwée me nun nu shoo leedáy",
        e: "I couldn't see them today.",
        a: "27e8"
      }, {
        p: "ولې تا لرګى نۀ شُو ماتولے؟",
        f: "wăle taa largée nu shoo maatawúlay?",
        e: "Why couldn't you have broken the wood?",
        a: "27e10"
      }, {
        p: "مُونږ پلاؤ نۀ شو خوړے، ځکه چې وريژې خرابې وې!",
        f: "moonG palaam no sho khoRay, dzuka che wreejze kharáabe we!",
        e: "We couldn't eat the pulao, because the rice was bad!",
        a: "27e11"
      }, {
        p: "تاسو عربى هم وئيلے شوه؟",
        f: "taaso arabée hum wayulay shwa?",
        e: "Could you speak Arabic too?",
        a: "27e12"
      }, {
        p: "تا خپل افسر سره خبرې کولے شوې، خو تا ونۀ کې.",
        f: "taa khpul afsúr sara khabúre kawúlay shwe, kho taa oo-nu-ke.",
        e: "You could have talked to your boss, but you didn't.",
        a: "27e13"
      }, {
        p: "زۀ د زيارت په ورځ تلے شوم.",
        f: "zu du ziyaarút wradz tlay shwum.",
        e: "I could have gone on Thursday.",
        a: "27e14"
      }, {
        p: "تۀ پرُون ولې نۀ شوې راتلے؟",
        f: "tu paróon wăle nu shwe raatláy?",
        e: "Why couldn't you have come yesterday?",
        a: "27e15"
      }, {
        p: "کۀ زۀ هلته تلے شوے، زۀ به هلته کښې وم.",
        f: "ku zu halta tlay shway, zu ba halta ke wum.",
        e: "If I could have gone there I would have been there.",
        a: "27e16"
      }]}
    </Examples>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Because this is a past tense construction, when the verb is transitive, the `}<Word w={{
            p: "شول",
            f: "swul",
            e: "",
            a: "shwul-f"
          }} mdxType="Word" />{` root on the end is conjugated according to the object. Otherwise it agrees with the subject.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Conditional (past subjunctive) sentences can also occur with the past potential. In such cases, the `}<Word w={{
            p: "شول",
            f: "shwul",
            e: "",
            a: "shwul-f"
          }} mdxType="Word" /> root at the end is conjugated as <Word w={{
            p: "شوے",
            f: "shway",
            e: "",
            a: ""
          }} mdxType="Word" />{`. Note the examples below:`}</p>
      </li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "کۀ زۀ راتلے شوے، نو تا ته به مې وئيلي وُو.",
        f: "ku zu raatláy shway, no taa ta ba me wayulee woo.",
        e: "If I could have come, I would have told you.",
        a: "27e17"
      }, {
        p: "کۀ مې کولے شوے، مرسته به مې درسره کړې وه.",
        f: "ku me kawúlay shway, mrasta ba me dăr-sara kúRe wa.",
        e: "If I could have done it, I would have helped you.",
        a: "27e18"
      }]}
    </Examples>
    <h2 {...{
      "id": "emphatic-and-exclamatory"
    }}>{`Emphatic and exclamatory`}</h2>
    <Examples mdxType="Examples">
  {[{
        p: "څومره زر زر لوئېږې!",
        f: "tsomra zur zur looyéGe!",
        e: "How quickly you are growing!",
        a: "27e19"
      }, {
        p: "وګوره! څومره ورو ورو هغه روانه ده!",
        f: "óogora! tsóomra wro wro haghá rawáana da!",
        e: "Look! How slowly she is moving!",
        a: "27e20"
      }, {
        p: "هغه څومره ډوډۍ خورى!",
        f: "haghá tsóomra DoDúy khorée!",
        e: "How much food he eats!",
        a: "27e21"
      }, {
        p: "آه! هغه څونه [څومره] ښکُلې ده!",
        f: "aah! haghá tsóna [tsóomra] xkÚle da!",
        e: "Oh! How beautiful she is!",
        a: "27e22"
      }, {
        p: "دومره لوئ کور!",
        f: "doomra looy kor!",
        e: "Such a big house!",
        a: "27e23"
      }, {
        p: "څومره زر راورسېدې!",
        f: "tsoomra zur raa-óorasede!",
        e: "How quickly you arrived!",
        a: "27e24"
      }, {
        p: "زۀ دومره ستړے شوے يم چې وئيلے نۀ شم!",
        f: "zu doomra stúRay shíway yim, che wayulay nu shum.",
        e: "I'm so tired I can't say!",
        a: "27e25"
      }, {
        p: "هغه دومره ناراسته دے، چې تپوس يې مۀ کوه!",
        f: "haghá doomra naaráasta day, che tapós ye mu kawa!",
        e: "He's so lazy, don't even ask him!",
        a: "27e26"
      }, {
        p: "هغه دومره سبق وائى، چې وئيلے نۀ شم!",
        f: "haghá dóomra sabúq waayee, che wayulay nu shum!",
        e: "He studies so much, that I can't say!",
        a: "27e27"
      }, {
        p: "څۀ عجيبه سړے دے!",
        f: "tsu ajéeba saRáy day!",
        e: "What a strange man!",
        a: "27e28"
      }]}
    </Examples>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>{`In the first two sentences the adverbs `}<Word w={{
        p: "زر زر",
        f: "zur zur",
        e: "",
        a: "zur-zur"
      }} mdxType="Word" /> and <Word w={{
        p: "ورو ورو",
        f: "wro wro",
        e: "",
        a: "wro-wro"
      }} mdxType="Word" />{` are repeated for emphasis. You will often hear this type of emphasis in spoken Pukhto.`}</p>
    <h2 {...{
      "id": "rhyming-words"
    }}>{`Rhyming words`}</h2>
    <p>{`You have probably heard words that are combined with a second one in a delightful rhyming couplet. Often the second word starts with an `}<Word w={{
        p: "مـ",
        f: "m-",
        e: "",
        a: "m-sound"
      }} mdxType="Word" />{` although this has no meaning. For example:`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "ډوډۍ موډۍ تیاره ده؟",
        f: "DoDúy moDúy tayáara da?",
        e: "Is the food-mood ready?",
        a: "27e29"
      }]}
    </Examples>
    <p>{`👆 Make sentences with the following words that are commonly combined in this way.`}</p>
    <RhymeBlanks mdxType="RhymeBlanks">
  {[{
        p: "چائې",
        f: "chaay",
        e: "tea",
        a: "chaay-m3"
      }, {
        p: "پنټ",
        f: "pinT",
        e: "trousers",
        a: "pintt"
      }, {
        p: "لامبل",
        f: "laambúl",
        e: "bathe",
        a: "laambul2"
      }, {
        p: "خوږ",
        f: "khoG",
        e: "sweet",
        a: "khogg2"
      }, {
        p: "پکے",
        f: "pakáy",
        e: "fan",
        a: "pakay"
      }, {
        p: "هلک",
        f: "halúk",
        e: "boy",
        a: "haluk"
      }, {
        p: "جرابې",
        f: "juraabé",
        e: "socks",
        a: "julaabe"
      }, {
        p: "سترګې",
        f: "stúrge",
        e: "ayes",
        a: "sturge"
      }, {
        p: "بلب",
        f: "búluf",
        e: "light bulb",
        a: "buluf"
      }, {
        p: "جامې",
        f: "jaamé",
        e: "clothes",
        a: "jaame"
      }, {
        p: "کتابونه",
        f: "kitaabóona",
        e: "books",
        a: "kitaaboona"
      }, {
        p: "سړے",
        f: "saRáy",
        e: "man",
        a: "sarray3"
      }]}
    </RhymeBlanks>
    <img src={guyWithPatoo} />
    <h2 {...{
      "id": "some-useful-idioms"
    }}>{`Some useful idioms`}</h2>
    <p>{`The normal sequence of learning is first the pronunciation, then the grammar, and finally the vocabulary is mastered. Idioms are an important part of this process. Like proverbs, the use of an idiom at the right time and place in a conversation will always be appreciated by your friends. Here are a few to get you started:`}</p>
    <ol>
      <li parentName="ol">{`Equating people with animals:`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "نن زۀ د هغه ځناورانو نُومُونه زده کوم چې خلق انسانانو ته وائى.",
        f: "nun zu du hágha dzanaawaaraano noomóona zda kawúm kum che khaluq insaanáano to waayee.",
        e: "Today I'm learning names of animals that people use to call others by.",
        a: "27e30"
      }, {
        p: "هغه د زمرى په شان بهادر دے.",
        f: "haghá du zmaree pu shaan bahaadúr day.",
        e: "He is as brave as a lion.",
        a: "27e31"
      }, {
        p: "دومره غته ده لکه مېښه.",
        f: "doomra ghúTa da lăka méxa.",
        e: "She is as fat as a buffalo.",
        a: "27e32"
      }, {
        p: "سترګې يې د اوسۍ په شان ښکُلې دى.",
        f: "stúrge ye do osúy pu shaan xkÚle dee.",
        e: "Her ayes are as beautiful as a deer's.",
        a: "27e33"
      }, {
        p: "داسې ګرځى لکه زرکه.",
        f: "daase gúrdze lăka zúrka.",
        e: "She walks like a partridge.",
        a: "27e34"
      }, {
        // check pronounciation / spelling / word / dictionary
        p: "داسې هڼکی چې لکه سپے.",
        f: "daase háNkee che lăka spay.",
        e: "She makes a noise like a dog [eating].",
        a: "27e35"
      }, {
        p: "د سپى په شان غپېږى.",
        f: "du spee pu shaan ghapéGee.",
        e: "He barks like a dog.",
        a: "27e36"
      }, {
        p: "اوس ته ما ته نور داسې نُومُونه ووايه.",
        f: "oos to maa ta noor daase noomóona óowaaya.",
        e: "Now tell me some more names like this.",
        a: "27e37"
      }]}
    </Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Equating parts of the body with special meaning:`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "په پښتو کښې خلق وائى چې ...",
        f: "pu puxto ke khaluq waayee che ...",
        e: "In Pukhto people say that …",
        a: "27e38"
      }, {
        p: "هغه د سر نه تر پښو پورې د ګُناه نه ډک دے.",
        f: "haghá du săr na tur xpo pore du gUnáa na Duk day.",
        e: "He is full of sin from head to foot.",
        a: "27e39"
      }, {
        p: "ډېر نورانى مخ لرى.",
        f: "Der nooraanee mukh laree.",
        e: "He has a very shining (holy) face.",
        a: "27e40"
      }, {
        p: "غوږُونه مې نيولى دى، چې بيا به داسې نۀ کوم.",
        f: "ghwuGoona me neewúlee dee, che byaa ba daase nu kawum.",
        e: "I pulled my ears (in repentance), so that I will not do it again.",
        a: "27e41"
      }, {
        p: "هغۀ زما غوږُونه وخوړل.",
        f: "haghú zmaa ghwuGóona óokhoRul.",
        e: "He ate my ears off (with talk).",
        a: "27e42"
      }, {
        p: "زۀ يې تر پوزې راوستم.",
        f: "zu ye tur póze ráawustum.",
        e: "I've had it with him up to my nose.",
        a: "27e43"
      }, {
        p: "دا زما د زړۀ ټوټه ده!",
        f: "daa zmaa du zRu ToTá da!",
        e: "She is a piece of my heart!",
        a: "27e44"
      }, {
        p: "د زړۀ په غوږو يې واؤره!",
        f: "du zRu pu ghwuGo ye wáawra!",
        e: "Listen with your heart's ear!",
        a: "27e45"
      },
      // check pronunciation کهٔ [کړل]
      {
        p: "غوږُونه يې راته پاخۀ کهٔ [کړل].",
        f: "ghwuGóona ye raa-ta paakhú ku [kRul].",
        e: "He cooked my ears off.",
        a: "27e46"
      }, {
        p: "ته اوس ما ته داسې نور مِثالُونه ووايه چې د بدن د اندامونو سره لږى.",
        f: "tu oos maa ta daase noor misaalóona óowaaya, che du badún d andaamóona sara lúgee.",
        e: "Now give me some more examples related to parts of the body.",
        a: "27e47"
      }]}
    </Examples>
    <h2 {...{
      "id": "comprehension-drill-body-language"
    }}>{`Comprehension drill: Body language`}</h2>
    <p>{`👆 Much communication is without words. Below is a list of some common body language. Have your helper read the command, and show the unspoken way to “say” the same. Then you show the right sign at the given command. Finally you give the meaning of signs that your helper gives.`}</p>
    <Examples twoColumns mdxType="Examples">
  {[{
        p: "دلته راشه!",
        f: "dălta ráasha!",
        e: "",
        a: "27c1"
      }, {
        p: "پیسې مۀ راوباسه!",
        f: "paysé mu raa-oobaasa!",
        e: "",
        a: "27c2"
      }, {
        p: "حیران شو!",
        f: "hayráan sho!",
        e: "",
        a: "27c3"
      }, {
        p: "ځه!",
        f: "dza!",
        e: "",
        a: "27c4"
      }, {
        p: "څهٔ کوې؟",
        f: "tsu kawe?",
        e: "",
        a: "27c5"
      }, {
        p: "خفه!",
        f: "khufa!",
        e: "",
        a: "27c6"
      }, {
        p: "خوشحاله!",
        f: "khosháala!",
        e: "",
        a: "27c7"
      }, {
        p: "چپ شه!",
        f: "chup sha!",
        e: "",
        a: "27c8"
      }, {
        p: "ودرېږه!",
        f: "óodreGa!",
        e: "",
        a: "27c9"
      }, {
        p: "کښېنه!",
        f: "kéna!",
        e: "",
        a: "27c10"
      }, {
        p: "ډېر ښۀ!",
        f: "Der xu!",
        e: "",
        a: "27c11"
      }, {
        p: "زۀ تابعدار يم!",
        f: "zu taabidáar yim!",
        e: "",
        a: "27c12"
      }, {
        p: "په ګوته يې شمار که!",
        f: "pu góota ye shmaar ka!",
        e: "",
        a: "27c13"
      }, {
        p: "پیسې شمار که!",
        f: "payse shmaar ka!",
        e: "",
        a: "27c14"
      }, {
        p: "پاګل دے!",
        f: "paagúl day!",
        e: "",
        a: "27c15"
      }, {
        p: "دننه راځئ!",
        f: "dunúna raadzéy!",
        e: "",
        a: "27c16"
      }, {
        p: "ګورُو به!",
        f: "góroo ba!",
        e: "",
        a: "27c17"
      }, {
        p: "ښۀ کار دې کړے وو!",
        f: "xu kaar de kúRay wo.",
        e: "",
        a: "27c18"
      }, {
        p: "ښځې!",
        f: "xúdze!",
        e: "",
        a: "27c19"
      }, {
        p: "هغۀ ته مۀ وايه!",
        f: "haghú ta ma waaya!",
        e: "",
        a: "27c20"
      }, {
        p: "شرمېږې!",
        f: "sherméGe!",
        e: "",
        a: "27c21"
      }, {
        p: "ځه چې ځُو!",
        f: "dza che dzóo!",
        e: "",
        a: "27c22"
      }, {
        p: "معاف که!",
        f: "mUaaf ka!",
        e: "",
        a: "27c23"
      }, {
        p: "مۀ کوه!",
        f: "mú-kawa!",
        e: "",
        a: "27c24"
      }]}
    </Examples>
    <img src={shortHairedGirl} style={{
      maxWidth: "8rem"
    }} />
    <h2 {...{
      "id": "memorising-moment"
    }}>{`Memorising moment`}</h2>
    <h4 {...{
      "id": "rahman-baba"
    }}>{`Rahman Baba`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[27]}</Examples>
    <h2 {...{
      "id": "exercises-for-lesson-twenty-seven"
    }}>{`Exercises for Lesson Twenty-Seven`}</h2>
    <p>{`Complete the following sentences in the past potential.`}</p>
    <Quiz mdxType="Quiz">
  {[{
        beg: {
          p: "زۀ تېره شپه تللے",
          f: "zu téra shpa tlúlay"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "شوم",
          f: "shwum"
        }]
      }, {
        beg: {
          p: "ډېر وبخښه، درتللے نۀ",
          f: "Der óobakha, dărtlulay nu"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "شوم",
          f: "shwum"
        }]
      }, {
        beg: {
          p: "ډېر ګران وو اخستلے مې نۀ",
          f: "Der graan wo, akhistúlay me nu"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "شو",
          f: "sho"
        }]
      }, {
        beg: {
          p: "ډېر باران ورېدو، تۀ مې ليدلے نۀ",
          f: "Der baaráan waredó, tu me leedúlay nu"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "شوې",
          f: "shwe"
        }]
      }, {
        beg: {
          p: "هوا ډېره يخه وه، اوبۀ مې څکلے نۀ",
          f: "hawaa Dera yakha wa, oobú me skulay nu"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "شوې",
          f: "shwe"
        }]
      }, {
        beg: {
          p: "ما هغه موټر چلولے",
          f: "maa hágha moTúr chalawúlay"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "شو",
          f: "sho"
        }]
      }, {
        beg: {
          p: "زاهد ښۀ رباب وهلے",
          f: "zaahíd xu rabáab wahúlay"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "شو",
          f: "sho"
        }]
      }, {
        beg: {
          p: "هغوى ډېرې ښې قيصې ليکلے",
          f: "haghwée Déry xe qeesé leekúlay"
        },
        end: {
          p: ".",
          f: "."
        },
        answer: [{
          p: "شوې",
          f: "shwe"
        }]
      }]}
    </Quiz>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "the-past-potential",
    level: 2,
    title: "The past potential",
    children: [
        {
              id: "examples-with-the-imperfective-past-potential",
              level: 3,
              title: "Examples with the imperfective past potential",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "emphatic-and-exclamatory",
    level: 2,
    title: "Emphatic and exclamatory",
    children: [
        
      ]
  },
{
    id: "rhyming-words",
    level: 2,
    title: "Rhyming words",
    children: [
        
      ]
  },
{
    id: "some-useful-idioms",
    level: 2,
    title: "Some useful idioms",
    children: [
        
      ]
  },
{
    id: "comprehension-drill-body-language",
    level: 2,
    title: "Comprehension drill: Body language",
    children: [
        
      ]
  },
{
    id: "memorising-moment",
    level: 2,
    title: "Memorising moment",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-twenty-seven",
    level: 2,
    title: "Exercises for Lesson Twenty-Seven",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Twenty-Seven"
}

