import { useState, useEffect } from "react";

export function useDKeyDown() {
  const [dKeyDown, setDKeyDown] = useState<boolean>(false);
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === "d") {
      setDKeyDown(true);
    }
  }
  function handleKeyUp(e: KeyboardEvent) {
    if (e.key === "d") {
      setDKeyDown(false);
    }
  }
  return dKeyDown;
}
