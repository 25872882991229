
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"30 min read","minutes":29.055,"time":1743300,"words":5811}
/* @jsxRuntime classic */
/* @jsx mdx */
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import memorizingMoments from "./memorizing-moments.jsx";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import PersistentBlank from "../components/persistent-blank";
import handToEar from "../assets/images/hand-to-ear.png";
import palangFamily from "../assets/images/palang-family.png";
import girlEating from "../assets/images/girl-eating.png";
import twoMenWalking from "../assets/images/two-men-walking.png";
import carryingWater from "../assets/images/carrying-water.png";
import guyWithPatoo from "../assets/images/guy-with-patoo.png";
import boySitting from "../assets/images/boy-sitting.PNG";
import thinOldMan from "../assets/images/thin-old-man.png";
import horseGroup from "../assets/images/horse-group.png";
import donkeyMan from "../assets/images/donkey-man.png";
import smallRickshaw from "../assets/images/small-rickshaw.png";
import twoBoys from "../assets/images/two-boys.png";
import parrot from "../assets/images/parrot.png";
import scorpion from "../assets/images/scorpion.png";
import sheep from "../assets/images/sheep.PNG";
import chilum from "../assets/images/chilum.png";
import coveredWoman from "../assets/images/covered-woman.PNG";
import manShooting from "../assets/images/man-shooting.png";
import manWithCane from "../assets/images/man-with-cane.png";
import saw from "../assets/images/saw.png";
import mangoSeller from "../assets/images/mango-seller.PNG";
import dishes from "../assets/images/dishes.png";
import palang from "../assets/images/palang.png";
import stirringPots from "../assets/images/stirring-pots.png";
import littleGirl from "../assets/images/little-girl.png";
import potter from "../assets/images/potter.png";
import toolbox from "../assets/images/toolbox.png";
import gateway from "../assets/images/gateway.png";
import manWithLoonguy from "../assets/images/man-with-loonguy.png";
import ox from "../assets/images/ox.png";
import womanSadir from "../assets/images/woman-sadir.png";
import girlWaving from "../assets/images/girl-waving.png";
import donkey from "../assets/images/donkey.png";
import camels from "../assets/images/camels.png";
import readingWithDaughter from "../assets/images/reading-with-daughter.png";
import womanDancing from "../assets/images/woman-dancing.png";
import flyingCoach from "../assets/images/flying-coach.png";
import ttaanga from "../assets/images/ttaanga.png";
import youngManWithLoonguy from "../assets/images/young-man-with-loonguy.png";
import guyCrouching from "../assets/images/guy-crouching.png";
export const PictureGrid = ({
  children
}) => <div style={{
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexWrap: "wrap"
}}>
    {children.map((pic, i) => <img kay={i} className="grid-img" src={pic} />)}
  </div>;
export const TensesSection = () => <>
    <h5>Tenses</h5>
    <div style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }}>
      <ul>
        <li>Present</li>
        <li>Imperative</li>
        <li>Future</li>
      </ul>
      <ul>
        <li>Past</li>
        <li>Past progressive</li>
        <li>Habitual past</li>
      </ul>
      <ul>
        <li>Present perfect</li>
        <li>Past perfect</li>
        <li>Subjuctive</li>
      </ul>
    </div>
  </>;

const layoutProps = {
  PictureGrid,
TensesSection
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "dialogue-twenty-six-questions-questions-questions"
    }}>{`Dialogue twenty-six: Questions, questions, questions`}</h2>
    <p>{`👆 With your helper prepare full answers to these common questions. You should memorise your answers. Make them longer as your fluency increases.`}</p>
    <Dialogue audio="dialogue-26" storePrefix="dialogue-26" mdxType="Dialogue">
  {[{
        p: "تۀ د کُوم ځائ يې؟",
        f: "tu du kum dzaay ye?",
        e: "Where are you from?",
        a: "26d1"
      }, {
        p: "تۀ د کُوم ملک نه راغلے يې؟",
        f: "tu du kum mUlk na raaghulay ye?",
        e: "What country have you come from?",
        a: "26d2"
      }, {
        answer: true
      }, {
        p: "تۀ دلته څۀ کوې؟",
        f: "tu dălta tsu kawe?",
        e: "What do you do here?",
        a: "26d3"
      }, {
        answer: true
      }, {
        p: "ستا په پاکِستان کښې څومره کاله کېږى؟",
        f: "staa pu paakistaan ke tsomra [tsona] kaala kéGee?",
        e: "How many years have you been in Pakistan?",
        a: "26d4"
      }, {
        answer: true
      }, {
        p: "ستا تنخا څومره ده؟",
        f: "staa tankháa tsomra da?",
        e: "What is your pay?",
        a: "26d5"
      }, {
        answer: true
      }, {
        p: "تۀ ما خپل ملک ته بوتلے شې؟",
        f: "tu maa khpul mUlk ta botláy she?",
        e: "Can you take me to your country?",
        a: "26d6"
      }, {
        answer: true
      }, {
        p: "تۀ مُسلمان شوے يې؟",
        f: "tu mUsulmáan shíway ye?",
        e: "Have you become a Muslim?",
        a: "26d7"
      }, {
        answer: true
      }, {
        p: "تۀ ولې مُسلمان نۀ جوړېږې؟",
        f: "tu wăle mUsalmaan nu joRéGe?",
        e: "Why don't you become a Muslim?",
        a: "26d8"
      }, {
        answer: true
      }, {
        p: "تۀ دلته ولې راغلے يې؟",
        f: "tu dălta wăle raaghulay ye?",
        e: "Why did you come here?",
        a: "26d9"
      }, {
        answer: true
      }, {
        p: "تۀ به تر کله پورې دلته يې؟",
        f: "tu ba tur kala pore dălta ye?",
        e: "How long will you stay here?",
        a: "26d10"
      }, {
        answer: true
      }, {
        p: "ستا دا ځائ خوښ دے؟",
        f: "shaa da dzaay khwax day?",
        e: "Do you like this place?",
        a: "26d11"
      }, {
        answer: true
      }, {
        p: "زمُونږ په ملک کښې ستا څۀ خوښ شُو؟",
        f: "zmoonG pu mUlk ke staa tsu khwax shoo?",
        e: "What did you like in our country?",
        a: "26d12"
      }, {
        answer: true
      }, {
        p: "ستا په ملک کښې هم داسې ګرمى شته؟",
        f: "staa pu mUlk ke hum daase garmée shta?",
        e: "Is it as hot as this in your country?",
        a: "26d13"
      }, {
        answer: true
      }]}
    </Dialogue>
    <p>{`This week listen very carefully to any other questions you are asked. Write them down or record them and transcribe them here. Learn to answer them too.`}</p>
    <PersistentBlank store="26-other-questions" rows={6} mdxType="PersistentBlank" />
    <img src={handToEar} style={{
      float: "right"
    }} />
    <h2 {...{
      "id": "should-have-the-subjunctive-mood-in-the-past-tense"
    }}>{`'should have’: The subjunctive mood in the past tense`}</h2>
    <p>{`There are several ways to indicate “should” or “ought”`}</p>
    <ol>
      <li parentName="ol">{`Using a past participle and the past tense hypothetical form of the equative “be” verb, `}<Word w={{
          p: "وے",
          f: "way",
          e: "",
          a: "way-f"
        }} mdxType="Word" />{` to communicate the idea of necessity, possibility and repsonsibility.`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "ضرُورى نۀ وه چې پیسې يې ورته لېږلې وې.",
        f: "zaroorée nu wa che payse ye wăr-ta leGúle way.",
        e: "It wasn't necessary to have sent them money.",
        a: "26e1"
      }, {
        p: "پکار دى چې هغوى اوس دلته را رسېدلی وے.",
        f: "pukaar dee che haghwée oos dălta raa-rasedúlee way.",
        e: "They should have arrived here by now.",
        a: "26e2"
      }, {
        p: "پکار دى چې تۀ يې ويشتے وے، خو ویې نۀ ويشتې.",
        f: "pukaar dee che tu ye weeshtay way, kho oo-ye [we`e] nu weeshte.",
        e: "They should have shot you, but they didn't shoot you.",
        a: "26e3"
      }, {
        p: "هغۀ غوښتل چې دلته پاتې شوے وے خو مجبُور وو، نو لاړو.",
        f: "haghú ghoxtúl che dălta paate shíway way, kho majbóor wo, no láaRo.",
        e: "He wanted to stay here, but was compelled, so he went.",
        a: "26e4"
      }, {
        p: "کۀ تۀ تلے وے، نو زۀ به هم تلے وم.",
        f: "ku tu túlay way, no zu ba hum túlay wum.",
        e: "If you had gone, I would have gone too.",
        a: "26e5"
      }, {
        p: "کۀ پرُون موسم ښۀ وے، نو زۀ به خپل کور ته راغلے وم.",
        f: "ku paróon mosúm xu way, no zu ba khpul kor ta raaghulay wum.",
        e: "If the weather had been good yesterday, I would have come to my house.",
        a: "26e6"
      }, {
        p: "کۀ خُدائ هغۀ ته شفا ورکړے وے، نو هغه به اوس ژوندے وے.",
        f: "ku khUdáay haghú ta shifáa wărkúRay way, no haghá ba oos jzwundáy way.",
        e: "If God had healed him, he would have been alive now.",
        a: "26e7"
      }, {
        p: "کۀ تا ما ته وئيلی وے چې تۀ تږے يې، نو ما به تاله اوبۀ درکړې وې.",
        f: "ku taa maa ta wayulee way che tu túGay ye, no maa ba taa la oobú dăr-kúRe we.",
        e: "If you had told me you were thirsty, I would have given you some water. (m.)",
        a: "26e8"
      }, {
        p: "کۀ ما هغه وهلے وے، نو هغه به خوږ شوے وو.",
        f: "ku maa haghá wahúlay way, no haghá ba khoG shúway wo.",
        e: "If I would have hit him, he would have been hurt. (m.)",
        a: "26e9"
      }, {
        p: "کۀ دا کاسه ماته شوے وے، نو زما مور به خفه شوې وه.",
        f: "ku daa kaasá maata shíway way, no zmaa mor ba khufa shíwe wa.",
        e: "If this bowl had been broken my mother would have been upset.",
        a: "26e10"
      }, {
        p: "کۀ تۀ نۀ وے راغلے، نو ما به تا ته نۀ وُو وئيلى.",
        f: "ku tu nu way raaghulay, no maa ba taa ta nu woo wayulee.",
        e: "If you had not come, I wouldn't have told you. (m.)",
        a: "26e11"
      }, {
        p: "پکار وُو چې پیسې يې ترې اخستې وے او ورته يې ورکړے وے.",
        f: "pukáar woo che payse ye tre akhíste way aw wăr-ta ye wăr-kúRe way.",
        e: "He should have taken the money and given it to him.",
        a: "26e12"
      }]}
    </Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Using the past perfect with the particle `}<Word w={{
          p: "به",
          f: "ba",
          e: "",
          a: "ba-f2"
        }} mdxType="Word" />{` to indicate blame, regret, or failure.`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "تۀ به راغلے وې!",
        f: "tu ba raaghúlay we!",
        e: "You should have come!",
        a: "26e13"
      }, {
        p: "مُونږ به هغه ليدلې وه.",
        f: "moonG ba haghá leedúle wa.",
        e: "We should have seen her.",
        a: "26e14"
      }, {
        p: "مُونږ به هغوى ليدلى وُو.",
        f: "moonG ba haghwée leedúlee woo.",
        e: "We might have seen them.",
        a: "26e15"
      }, {
        p: "زۀ به هلته تلے وم، خو تلے نۀ شوم.",
        f: "zu ba halta túlay wum, kho tlay nu shwum.",
        e: "I might have gone there, but I couldn't go.",
        a: "26e16"
      }]}
    </Examples>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Using `}<Word w={{
          p: "پکار دے",
          f: "pukáar day",
          e: "it's necessary/needed",
          a: "pu-kaar-day-f"
        }} mdxType="Word" />, <Word w={{
          p: "شاید چې",
          f: "shaayid che",
          e: "",
          a: "shaayid-che-f"
        }} mdxType="Word" />, <Word w={{
          p: "ممکن",
          f: "mUmkín",
          e: "possible",
          a: "muumkin-f"
        }} mdxType="Word" />, <Word w={{
          p: "خیال",
          f: "khyaal",
          e: "thought",
          a: "khyaal-f"
        }} mdxType="Word" />, and <Word w={{
          p: "کېدے شی چې",
          f: "kedáy shee che",
          e: "it's could be that",
          a: "keday-shee-che-f"
        }} mdxType="Word" />{` to indicate a past possibility. That is, something that might have happened in the past.`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "زما په خيال هغه تلے وو.",
        f: "zmaa pu khyaal haghá túlay wo.",
        e: "I think he may have gone.",
        a: "26e17"
      }, {
        p: "شايد چې هغوى زۀ ليدلے وم.",
        f: "shaayid che haghwée zu leedúlay wum.",
        e: "Perhaps they might have seen me.",
        a: "26e18"
      }, {
        p: "ممکنه وه چې هغې کار شروع کړے وے کۀ هغه دلته وے.",
        f: "mUmkína wa che haghé kaar shUroo kúRay way ku haghá dălta way.",
        e: "She might have started work by now if she had been here.",
        a: "26e19"
      }, {
        p: "کېدے شى چې خپل کتاب يې هېر کړے وو.",
        f: "kedáy shee che khpul kitáab ye her kúRay wo.",
        e: "It is possible that he had forgotten his book.",
        a: "26e20"
      }, {
        p: "زما خيال وو چې هغه به تلے وى.",
        f: "zmaa khyaal wo che haghá ba túlay way.",
        e: "I thought he would have been gone.",
        a: "26e21"
      }, {
        p: "مُونږ له هغه وخت د هغوى مدد کول پکار وو.",
        f: "moonG la hágha wakht du haghwée madúd kawúl pukaar woo.",
        e: "We should have assisted them at that time.",
        a: "26e22"
      }, {
        p: "هغۀ له کوشش کول پکار وُو خو هغه فيل شو.",
        f: "haghú la koshis kawul pukáar woo kho haghá fayl sho.",
        e: "He should have tried, but he failed.",
        a: "26e23"
      }, {
        p: "ماله پکار وُو چې خپل پښتو سبق کښې مې مِحنت کړے وے.",
        f: "maa la pukáar woo che khpul puxto sabúq ke me mihnút kúRay way.",
        e: "I should have been more diligent in my Pukhto studies.",
        a: "26e24"
      }, {
        p: "کېدے شى زۀ هلته مخکښې تلے وم.",
        f: "kedáy shee ze halta makhke túlay wum.",
        e: "I might have gone there earlier.",
        a: "26e25"
      }, {
        p: "مُونږ له هغه ليدل پکار وو.",
        f: "moonG la haghá leedúl pukáar woo.",
        e: "We should have seen them.",
        a: "26e26"
      }]}
    </Examples>
    <h2 {...{
      "id": "expressing-an-unfulfilled-desire"
    }}>{`Expressing an unfulfilled desire`}</h2>
    <Examples mdxType="Examples">
  {[{
        p: "زما په انګرېزۍ زده کولو کښې دلچسپى ده، خو زما سره وخت نشته.",
        f: "zmaa pu angrezúy zda kawúlo ke dilchaspee da, kho zmaa sara wakht níshta.",
        e: "I have an interest to learn English, but I don't have the time.",
        a: "26e27"
      }, {
        p: "پکار دے چې نن يو ښۀ خبر راغلے وے!",
        f: "pukáar day che nun yo xu khabúr raaghúlay way.",
        e: "I wish some good news would have come today!",
        a: "26e28"
      }, {
        p: "زما دا اُميد وو چې هلک به وشى، خو جينۍ وشوه.",
        f: "zmaa daa Uméed wo che halúk ba óoshee, kho jeenúy óoshwa.",
        e: "I had hoped a boy would have been born, but it was a girl.",
        a: "26e29"
      }, {
        p: "پرُون ما لامبل غوښتل.",
        f: "paróon maa laambúl ghoxtúl.",
        e: "I wanted to bathe yesterday.",
        a: "26e30"
      }, {
        p: "ما غوښتل چې زۀ کلى ته لاړ شم، خو زما افسر ما ته د چُټۍ اجازت رانۀ کړو.",
        f: "maa ghoxtúl che zu kúlee ta laaR shum, kho zmaa afsúr maa ta du chooTúy ijaazút raa-nu-kRo.",
        e: "I wanted to go to the village, but my boss wouldn't give me permission for leave.",
        a: "26e31"
      }]}
    </Examples>
    <h2 {...{
      "id": "expressing-something-that-is-doubtful-or-contrary-to-fact"
    }}>{`Expressing something that is doubtful or contrary to fact`}</h2>
    <p>{`The following phrases provide useful ways in which to communicate expressions of “if only” or 'would have’:`}</p>
    <ol>
      <li parentName="ol">{`Intransitive`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "کۀ ما ته پته وے ...",
        f: "ku maa ta páta way ...",
        e: "If I had known ...",
        a: "26e32"
      }, {
        p: "کۀ تا ته پته وے ...",
        f: "ku taa ta páta way ...",
        e: "If you had known ...",
        a: "26e33"
      }]}
    </Examples>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "زۀ به تلے وم.",
        f: "zu ba túlay wum.",
        e: "I would have gone.",
        a: "26e34"
      }, {
        p: "مُونږ به تلى وُو.",
        f: "moonG ba túlee woo.",
        e: "We would have gone.",
        a: "26e35"
      }, {
        p: "بصير به تلے وو.",
        f: "baséer ba túlay wo.",
        e: "Basir would have gone.",
        a: "26e36"
      }, {
        p: "زاهده به تلې وه.",
        f: "zaahída ba túle wa.",
        e: "Zahida would have gone.",
        a: "26e37"
      }]}
    </Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Transitive`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "کۀ ما تا ته وئيلی وے ...",
        f: "ku maa taa tu wayulee way ...",
        e: "If I had told you ...",
        a: "26e38"
      }, {
        p: "کۀ تا ما ته وئيلی وے ...",
        f: "ku taa maa ta wayulee way ...",
        e: "If you had told me ...",
        a: "26e39"
      }]}
    </Examples>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "ما به هغه وهلے وو.",
        f: "maa ba haghá wahúlay wo.",
        e: "I would have hit him.",
        a: "26e40"
      }, {
        p: "مُونږ به هغه وهلے وو.",
        f: "moonG ba haghá wahúlay wo.",
        e: "We would have hit him.",
        a: "26e41"
      }, {
        p: "هغۀ به زۀ وهلے وم.",
        f: "haghú ba zu wahúlay wum.",
        e: "He would have hit me.",
        a: "26e42"
      }, {
        p: "هغه به وهلے شوے وو.",
        f: "hagha ba wahúlay shíway wo.",
        e: "He would have been hit.",
        a: "26e43"
      }]}
    </Examples>
    <Examples mdxType="Examples">
  {[{
        p: "کۀ احمد يې وهلے وو ...",
        f: "ku ahmád ye wahúlay wo",
        e: "If he had hit Ahmad ...",
        a: "26e44"
      }, {
        p: "کۀ ډوډۍ پخه شوې وه ...",
        f: "ku DoDúy pakhá shíwe wa ...",
        e: "If the bread had been cooked ...",
        a: "26e45"
      }]}
    </Examples>
    <h2 {...{
      "id": "the-all-purpose-word-w-p-چې-f-che-e-who-whom-that-which-a-che-f3--"
    }}>{`The all purpose `}<Word w={{
        p: "چې",
        f: "che",
        e: "who, whom, that, which",
        a: "che-f3"
      }} mdxType="Word" /></h2>
    <Examples mdxType="Examples">
  {[{
        p: "څوک چې ډېر کار کوى ډېره ډوډۍ خورى.",
        f: "tsok che Der kaar kawee Déra DoDúy khoree.",
        e: "The one who works a lot eats a lot of food.",
        a: "26e46"
      }, {
        p: "څوک چې دلته اوسېږى، د هغۀ ښۀ ژوند وى.",
        f: "tsok ke dălta oséGee, du haghú xe jzwund wee.",
        e: "He who lives here has a good life.",
        a: "26e47"
      }, {
        p: "څوک چې ښۀ اُودۀ کېږى هغه ښۀ کار هم کولے شى.",
        f: "tsok che xu oodú kéGee, haghá xu kaar hum kawúlay shee.",
        e: "The person who sleeps well is also able to work well.",
        a: "26e48"
      }, {
        p: "هغه سړے چې چا سره پیسې زياتې وې اوس پيرپائى کښې دے.",
        f: "hágha saRáy che chaa sara payse zyaate we, oos peerpaa`ee ke day.",
        e: "The person who had a lot of money is now in Pirpai.",
        a: "26e49"
      }, {
        p: "چا چې هغۀ سره خبرې کړې دى، هغه ښۀ پېژنى.",
        f: "chaa che haghú sara khabúre kúRe dee, haghá xu pejzunee.",
        e: "Anyone who has talked with him knows him well.",
        a: "26e50"
      }, {
        p: "هغه هلک ته آواز که [کړه] چا چې سپين کوټ اچولے دے.",
        f: "hágha halúk ta aawáaz ka, chaa che speen koT achawúlay day.",
        e: "Call the boy who has put on the white coat.",
        a: "26e51"
      }, {
        p: "هغه کسان چا ته چې د کور ضرُورت دے، تا ته کمره کښې ناست دى.",
        f: "hágha kasáan chaa ta che de kor zaroorút day, taa ta kamrá ke naast dee.",
        e: "Those people who are in need of a house are waiting for you in the room.",
        a: "26e52"
      }, {
        p: "هغه ښځه چې تا پرُون ليدلې وه، هغه ړنده وه.",
        f: "hágha xúdza che taa paróon leedúle wa, haghá Randá wa.",
        e: "The woman whom you had seen yesterday was blind.",
        a: "26e53"
      }, {
        p: "زۀ هغه ډاکټر له ځم چې ډېره ښه دوائى ورکوى.",
        f: "zu hágha DakTúr la dzum, che Dera xa dawaa`ee wăr-kawee.",
        e: "I am going to that doctor who gives very good medicine.",
        a: "26e54"
      }, {
        p: "هغه شے چې تا اخستے وو، زۀ به يې هم اخلم.",
        f: "hágha shay che taa akhístay wo, zu ba ye hum ákhlum.",
        e: "That thing which you have bought, I will also buy.",
        a: "26e55"
      }, {
        p: "چې ستا څۀ شے پکار دے، هغه دلته نشته.",
        f: "che staa tsu shay pukáar day, haghá dălta níshta.",
        e: "That thing which you want isn't here.",
        a: "26e56"
      }, {
        p: "هغه کار چې تا کړے دے، هغه ډېر ضرُورى دے.",
        f: "hágha kaar che taa kúRay day, Der zarooree day.",
        e: "The work which you have done is very important.",
        a: "26e57"
      }, {
        p: "چې کُومه ټوټه تا اخستې ده، هغه ډېره ګرانه ده.",
        f: "che kúma ToTa taa akhíste da, haghá Déra gráana da.",
        e: "The piece of cloth which you have bought is very expensive.",
        a: "26e58"
      }, {
        p: "هغه پن چې ستا خوښ دے، هغه ما سره اوس نشته.",
        f: "haghá pin che staa khwax day, haghá maa sara oos níshta.",
        e: "The pen which you like, I don't have now.",
        a: "26e59"
      }, {
        p: "کُوم ځائ ته چې هغه تلې ده، زۀ ورته نۀ يم تلے.",
        f: "kum dzaar che haghá túle da, zu wăr-ta nu yum túlay.",
        e: "The place to which she has gone, I haven't gone to. (m. speaker)",
        a: "26e60"
      }, {
        p: "کُوم ځاى کښې چې هغه اوسى، هلته موسم ډېر يخ وى.",
        f: "kum dzaak ke che haghá osee, halta mosúm Der yukh wee.",
        e: "The place where she lives has very cold weather.",
        a: "26e61"
      }, {
        p: "چرته چې زۀ اوسم، هغه ځائ د ښار نه لرې دے.",
        f: "chărta che zu osum, haghá dzaay du xaar na lúre day.",
        e: "The place where I live is a long way from the city.",
        a: "26e62"
      }]}
    </Examples>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`The word `}<Word w={{
            p: "چې",
            f: "che",
            e: "",
            a: "che-4f"
          }} mdxType="Word" /> can be used by itself or in combination with other words, such as <Word w={{
            p: "څوک",
            f: "tsok",
            e: "who",
            a: "tsok"
          }} mdxType="Word" />, <Word w={{
            p: "چا",
            f: "chaa",
            e: "whom / inflected 'who'",
            a: "chaa-inf"
          }} mdxType="Word" />, <Word w={{
            p: "چرته",
            f: "chărta",
            e: "where",
            a: "charta"
          }} mdxType="Word" />{` etc.`}</p>
      </li>
      <li parentName="ol">
        <Word w={{
          p: "چې",
          f: "che",
          e: "",
          a: "che-4f"
        }} mdxType="Word" /> often links to clauses
in a sentence. For example.
      </li>
    </ol>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "چې زۀ دراغلم، تا څۀ کول؟",
        f: "che zu dăraaghlum, taa tsu kawul?",
        e: "When I came to you, what were you doing?",
        a: "26e63"
      }, {
        p: "چې تۀ راغلې ما روټۍ خوړله.",
        f: "che tu raaghle, maa roTúy khoRúla.",
        e: "When you arrived, I was eating food.",
        a: "26e64"
      }]}
    </Examples>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`The same kind of linking may also be done by `}<Word w={{
          p: "خو",
          f: "kho",
          e: "",
          a: "kho"
        }} mdxType="Word" />, <Word w={{
          p: "نو",
          f: "no",
          e: "",
          a: "no"
        }} mdxType="Word" />, <Word w={{
          p: "بیا",
          f: "byaa",
          e: "",
          a: "byaa"
        }} mdxType="Word" />, and <Word w={{
          p: "او",
          f: "aw",
          e: "",
          a: "aw-f2"
        }} mdxType="Word" />{`.`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "هغه راغے او اُودۀ شو.",
        f: "haghá ráaghay aw oodú sho.",
        e: "He came and went to sleep.",
        a: "26e65"
      }, {
        p: "هغه راغے، خو اُودۀ نۀ شو.",
        f: "haghá ráaghay, kho oodú nu sho.",
        e: "He came, but didn't go to sleep.",
        a: "26e66"
      }, {
        p: "کۀ هغه راغلے وے، نو بيا به اُودۀ شوے وو.",
        f: "ku haghá raaghúlay way, no byaa ba oodú shíway wo.",
        e: "If he had come, then he would have gone to sleep.",
        a: "26e67"
      }, {
        p: "يا خو راغلے دے، او يا اُودۀ شوے دے.",
        f: "yaa kho raaghúlay day, aw yaa oodú shíway day.",
        e: "Either he has come, or he has gone to sleep.",
        a: "26e68"
      }, {
        p: "راتلو نه پس، هغه اُودۀ شو.",
        f: "raatlo no pas, haghá oodú sho.",
        e: "After coming, he went to sleep.",
        a: "26e69"
      }, {
        p: "چې هغه کله راغے، نو اُودۀ شو.",
        f: "che haghá kála ráaghay, no oodú sho.",
        e: "When he came he went to sleep.",
        a: "26e70"
      }]}
    </Examples>
    <h2 {...{
      "id": "the-conjunction-word-w-p-چې-f-che-e--a-che-4f--"
    }}>{`The conjunction `}<Word w={{
        p: "چې",
        f: "che",
        e: "",
        a: "che-4f"
      }} mdxType="Word" /></h2>
    <p>
  <Word w={{
        p: "چې",
        f: "che",
        e: "",
        a: "che-4f"
      }} mdxType="Word" /> is an all purpose
  conjunction used to mark the transition between ideas in Pukhto. It is used in
  two main ways.
    </p>
    <ol>
      <li parentName="ol">
        <Word w={{
          p: "چې",
          f: "che",
          e: "",
          a: "che-4f"
        }} mdxType="Word" /> as a simple conjunction
      </li>
    </ol>
    <p>{`By itself `}<Word w={{
        p: "چې",
        f: "che",
        e: "",
        a: "che-4f"
      }} mdxType="Word" />{` means 'that’, 'when’, 'if’, and 'in order that’, and is used to introduce a dependent clause.`}</p>
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "چې ليلیٰ راځى نو مُونږ به حيران شُو.",
        f: "che laila raadzée no moonG ba hayráan shoo.",
        e: "We will be surprised if Laila comes.",
        a: "26e71"
      }]}
    </Examples>
    <p>{`You will note that the dependent clause `}<Word w={{
        p: "چې لیلیٰ راځی",
        f: "che laila raadzée",
        e: "",
        a: "che-laila-r"
      }} mdxType="Word" />{` comes before the main clause. This is a common occurrence in Pukhto.`}</p>
    <p>{`{" "}`}</p>
    <Word w={{
      p: "چې",
      f: "che",
      e: "",
      a: "che-4f"
    }} mdxType="Word" /> is also used to introduce
reported speech, commands or questions. For example,
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "عمرغنى وائى چې ليلیٰ به خامخا راځى.",
        f: "Umar Ghani says that Laila will definitely come.",
        e: "Umar ghanee waayee che laila ba khaamakháa raadzée.",
        a: "26e72"
      }]}
    </Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <Word w={{
          p: "چې",
          f: "che",
          e: "",
          a: "che-4f"
        }} mdxType="Word" /> in combination with other
words.
      </li>
    </ol>
    <p>{`{" "}`}</p>
    <Word w={{
      p: "چې",
      f: "che",
      e: "",
      a: "che-4f"
    }} mdxType="Word" /> is used in combination with
other words, particularly adverbs. Here are a few of the more common uses:
    <p>{`{" "}`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "هر کله چې",
        f: "hăr kala che",
        e: "whenever",
        a: "26e73"
      }, {
        p: "کُومه (ورځ) چې",
        f: "kúma (wradz) che",
        e: "whatever (day), whichever (day)",
        a: "26e74"
      }, {
        p: "تر څو پورې چې",
        f: "tu tso pore che",
        e: "as long as, until",
        a: "26e75"
      }, {
        p: "لکه چې",
        f: "lúka che",
        e: "as if, like",
        a: "26e76"
      }, {
        p: "بې د دې نه چې",
        f: "be du de na che",
        e: "unless",
        a: "26e77"
      }, {
        p: "څومره چې",
        f: "tsoomra che",
        e: "however much",
        a: "26e78"
      }, {
        p: "ولې چې",
        f: "wăle che",
        e: "because",
        a: "26e79"
      }, {
        p: "ځکه چې",
        f: "dzúka che",
        e: "since, because",
        a: "26e80"
      }]}
    </Examples>
    <p>{`👆 Make sentences using `}<Word w={{
        p: "چې",
        f: "che",
        e: "",
        a: "che-4f"
      }} mdxType="Word" />{` in these ways and practice using them with your helper.`}</p>
    <h2 {...{
      "id": "the-prefix-word-w-p-هر-f-hăr-e-ever--any--a-har-f2--"
    }}>{`The prefix `}<Word w={{
        p: "هر",
        f: "hăr",
        e: "ever-, any-",
        a: "har-f2"
      }} mdxType="Word" /></h2>
    <Examples mdxType="Examples">
  {[{
        p: "هر څوک چې دلته راځى، کتاب اخلى.",
        f: "hăr tsok che dălta raadzée, kitáab ákhlee.",
        e: "Everybody who comes here buys a book.",
        a: "26e81"
      }, {
        p: "هر څۀ چې ستا پکار دى، دلته شته.",
        f: "hăr tsu che staa pukáar dee, dălta shta.",
        e: "Everything that you need is present.",
        a: "26e82"
      }, {
        p: "چې تۀ هر چرته تلل غواړې هلته لاړ شه.",
        f: "che tu hăr chărta tlul ghwaaRe, halta laaR sha.",
        e: "Wherever you want to go, go there.",
        a: "26e83"
      }, {
        p: "هر چرته مۀ ځه! / هر خواه مۀ ځه!",
        f: "hăr chărta mu dza! / hăr khwaa mú dza!",
        e: "Don't go everywhere!",
        a: "26e84"
      }, {
        p: "دې کښې هر څۀ چې تۀ اخستل غواړې واخله.",
        f: "de ke hăr tsu che tu akhistul ghwaaRe waakhla.",
        e: "Take anything in it which you want to buy.",
        a: "26e85"
      }, {
        p: "هر شے مۀ خوره!",
        f: "hăr shay mu khora!",
        e: "Don't eat everything!",
        a: "26e86"
      }, {
        p: "هر يو کُوم چې ستا خوښ وى ځان سره راوله!",
        f: "hăr yo kum cho staa khwax wee, dzaan sara raawula!",
        e: "Bring with you everything you like!",
        a: "26e87"
      }, {
        p: "هر شے کُوم چې دې کښې نۀ دے ښۀ، خرڅ يې که!",
        f: "hăr shay kum che de ke nu day xu, kharts ye ka!",
        e: "Everything in it which is not good, sell it!",
        a: "26e88"
      }, {
        p: "هر کله چې راتلے شې، راشه!",
        f: "hăr kala che raatláy she, ráasha!",
        e: "Whenever you can come, come!",
        a: "26e89"
      }]}
    </Examples>
    <h2 {...{
      "id": "improving-fluency-in-the-tenses"
    }}>{`Improving fluency in the tenses`}</h2>
    <p>{`👆 You’ve probably realized by now that the fluent use of verbs in the past tense is one of the biggest challenges in learning Pukhto. The following five pages have lists of commonly used verbs, the basic tenses, and various pictures of people and objects. Each page can be used as a drill that involves pointing at pictures to make sentences in different tenses. This has the advantage of moving straight into Pukhto and not using translation from English to Pukhto. You may need to show your helper how to do this kind of drill.`}</p>
    <p>{`The people grouped on each page can be used to represent first, second, and third persons, singular and plural, masculine and feminine.`}</p>
    <h3 {...{
      "id": "drills"
    }}>{`Drills:`}</h3>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Sentences with confusinc word order`}</strong>{` Get your helper to make sentences using one verb, working through it tense by tense. Point to the combination of people and tense that you want to use to make a sentence..`}</li>
    </ol>
    <p>{`For example, using the verb `}<Word w={{
        p: "پوهېدل",
        f: "pohedúl",
        e: "to understand",
        a: "pohedul"
      }} mdxType="Word" />:  
Point to a picture representing <Word w={{
        p: "زه",
        f: "zu",
        e: "yourself",
        a: "zu"
      }} mdxType="Word" />{` and then to one of the illustrations that represent the masculine singular tense, and lastly to the verb, “to understand’. The sentence you would say in the present tense (in Pukhto) would be 'I understand him’. You can then work through the same process, still in the same tense with masculine plural, feminine singular and feminine plural. You can then reverse the whole thing – for example, 'He understands me” – and methodically go through all the possibilities in the same tense: That is, 'You understand him’, 'She understands them’, etc.`}</p>
    <p>{`When you think you grasp the use of a verb in one tense, get your helper to point to people and other verb tense combinations for you to try to produce sentences. With each verb progress down the tenses; have your helper say the sentences each time before you produce them.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Using animals`}</strong>{` (in feminine singular, and plural and masculine singular and plural) as objects of sentences, and people as the subjects. For example, 'He milked a buffalo’. However, some sentences only make sense if people are the objects. For example, 'The donkey bit the ladies’, etc.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Objects of different gender`}</strong>{`, for example, 'She bought two pots’. You can complicate it further by pointing back to a person, and saying, 'He bought a candle for her’, etc.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Intransitive verbs`}</strong>{`. Your helper can add any other words or phrases. Do not try and learn any new vocabulary here, but concentrate on the verbs and their uses.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Transitive verbs`}</strong>{`. Again supply words that fit each sentence.`}</p>
      </li>
    </ol>
    <h4 {...{
      "id": "drill-one"
    }}>{`Drill one:`}</h4>
    <TensesSection mdxType="TensesSection" />
    <PictureGrid mdxType="PictureGrid">
  {[palangFamily, girlEating, twoMenWalking, carryingWater, guyWithPatoo, boySitting, thinOldMan]}
    </PictureGrid>
    <h5 {...{
      "id": "verbs"
    }}>{`Verbs`}</h5>
    <Examples twoColumns mdxType="Examples">
  {[{
        p: "پوهېدل",
        f: "pohedúl",
        e: "to know (understand)",
        a: "26v1"
      }, {
        p: "پېژندل",
        f: "pejzandúl",
        e: "to know (recognise)",
        a: "26v2"
      }, {
        p: "ورکول",
        f: "wăr-kawul",
        e: "to give",
        a: "26v3"
      }, {
        p: "اخستل",
        f: "akhistúl",
        e: "to buy, to get, to take",
        a: "26v4"
      }, {
        p: "تپوس کول",
        f: "tapos kawul",
        e: "to ask",
        a: "26v5"
      }, {
        p: "وئيل",
        f: "wayul",
        e: "to say",
        a: "26v6"
      }, {
        p: "خبرې کول",
        f: "khabúre kawul",
        e: "to talk",
        a: "26v7"
      }, {
        p: "راتلل",
        f: "raatlúl",
        e: "to come",
        a: "26v8"
      }, {
        p: "تلل",
        f: "tlul",
        e: "to go",
        a: "26v9"
      }]}
    </Examples>
    <h4 {...{
      "id": "drill-two"
    }}>{`Drill Two`}</h4>
    <TensesSection mdxType="TensesSection" />
    <PictureGrid mdxType="PictureGrid">
  {[donkeyMan, smallRickshaw, twoBoys, parrot, sheep, chilum, scorpion, manShooting, coveredWoman]}
    </PictureGrid>
    <h5 {...{
      "id": "verbs-1"
    }}>{`Verbs`}</h5>
    <Examples twoColumns mdxType="Examples">
  {[{
        p: "ويشتل",
        f: "weeshtúl",
        e: "to shoot",
        a: "26v10"
      }, {
        p: "مړ کول",
        f: "muR kawul",
        e: "to kill",
        a: "26v11"
      }, {
        p: "کتل",
        f: "katúl",
        e: "to look at",
        a: "26v12"
      }, {
        p: "چيچل",
        f: "cheechúl",
        e: "to bite (sting)",
        a: "26v13"
      }, {
        p: "وهل",
        f: "wahúl",
        e: "to hit",
        a: "26v14"
      }, {
        p: "اوسېدل",
        f: "osedúl",
        e: "to live, to stay",
        a: "26v15"
      }, {
        p: "نيول",
        f: "neewúl",
        e: "to hold",
        a: "26v16"
      }, {
        p: "ديکه ورکول",
        f: "deeka wăr-kawul",
        e: "to push",
        a: "26v17"
      }]}
    </Examples>
    <h4 {...{
      "id": "drill-three"
    }}>{`Drill Three`}</h4>
    <TensesSection mdxType="TensesSection" />
    <PictureGrid mdxType="PictureGrid">
  {[twoBoys, manWithCane, saw, mangoSeller, dishes, palang, stirringPots, littleGirl, potter, toolbox]}
    </PictureGrid>
    <Examples twoColumns mdxType="Examples">
  {[{
        p: "قرض ورکول",
        f: "qarz wăr-kawul",
        e: "to lend",
        a: "26v18"
      }, {
        p: "ماتول",
        f: "maatawúl",
        e: "to break",
        a: "26v19"
      }, {
        p: "راکول",
        f: "raa-kawul",
        e: "to give me",
        a: "26v20"
      }, {
        p: "مُوندل",
        f: "moondúl",
        e: "to find out",
        a: "26v21"
      }, {
        p: "اخستل",
        f: "akhistúl",
        e: "to buy",
        a: "26v22"
      }, {
        p: "خرڅول",
        f: "khatsawúl",
        e: "to sell",
        a: "26v23"
      }, {
        p: "وينځل",
        f: "weendlúz",
        e: "to wash",
        a: "26v24"
      }, {
        p: "سمول",
        f: "sumawúl",
        e: "to mend",
        a: "26v25"
      }, {
        p: "جوړول",
        f: "joRawúl",
        e: "to make",
        a: "26v26"
      }]}
    </Examples>
    <h4 {...{
      "id": "drill-four"
    }}>{`Drill four`}</h4>
    <TensesSection mdxType="TensesSection" />
    <PictureGrid mdxType="PictureGrid">
  {[boySitting, gateway, manWithLoonguy, ox, womanSadir, palangFamily, girlWaving, donkey, camels]}
    </PictureGrid>{" "}
    <h5 {...{
      "id": "verbs-2"
    }}>{`Verbs`}</h5>
    <Examples twoColumns mdxType="Examples">
  {[{
        p: "وادۀ کېدل",
        f: "waadú kedúl",
        e: "to become married",
        a: "26v27"
      }, {
        p: "غُصه کېدل",
        f: "ghÚsa kedúl",
        e: "to become angry",
        a: "26v28"
      }, {
        p: "يرېدل",
        f: "yăredúl",
        e: "to fear",
        a: "26v29"
      }, {
        p: "راوتل",
        f: "raawatúl",
        e: "to come out",
        a: "26v30"
      }, {
        p: "کښېناستل",
        f: "kenaastúl",
        e: "to sit",
        a: "26v31"
      }, {
        p: "تلل",
        f: "tlul",
        e: "to go",
        a: "26v32"
      }, {
        p: "مړ کېدل",
        f: "muR kedúl",
        e: "to die",
        a: "26v33"
      }, {
        p: "پاتې کېدل",
        f: "paate kedúl",
        e: "to stay",
        a: "26v34"
      }, {
        p: "تاوېدل",
        f: "taawedúl",
        e: "to turn",
        a: "26v35"
      }, {
        p: "ستړے کېدل",
        f: "stúRay kedúl",
        e: "to become tired",
        a: "26v36"
      }]}
    </Examples>
    <h4 {...{
      "id": "drill-five"
    }}>{`Drill five`}</h4>
    <p>{`👆 Use the same tenses as before; the verbs are on the following page.`}</p>
    <PictureGrid mdxType="PictureGrid">
  {[readingWithDaughter, smallRickshaw, womanDancing, flyingCoach, ttaanga, youngManWithLoonguy, guyCrouching, carryingWater]}
    </PictureGrid>
    <h5 {...{
      "id": "verbs-3"
    }}>{`Verbs`}</h5>
    <Examples twoColumns mdxType="Examples">
  {[{
        p: "شلول",
        f: "shlawúl",
        e: "to tear (rip)",
        a: "26v37"
      }, {
        p: "لېږل",
        f: "leGúl",
        e: "to send",
        a: "26v38"
      }, {
        p: "پاکول",
        f: "paakawúl",
        e: "to clean",
        a: "26v39"
      }, {
        p: "لرې کول",
        f: "lúre kawúl",
        e: "to extract/remove",
        a: "26v40"
      }, {
        p: "مُوندل",
        f: "moondúl",
        e: "to find",
        a: "26v41"
      }, {
        p: "معلُومول",
        f: "maaloomawúl",
        e: "to find out",
        a: "26v42"
      }, {
        p: "خوړل",
        f: "khoRúl",
        e: "to eat",
        a: "26v43"
      }, {
        p: "لامبل",
        f: "laambúl",
        e: "to bathe",
        a: "26v44"
      }, {
        p: "وړل",
        f: "oRúl",
        e: "to carry",
        a: "26v45"
      }, {
        p: "اخستل",
        f: "akhistúl",
        e: "to take",
        a: "26v46"
      }, {
        p: "شور کول",
        f: "shor kawúl",
        e: "to make noise",
        a: "26v47"
      }, {
        p: "راوړل",
        f: "raawRúl",
        e: "to bring",
        a: "26v48"
      }, {
        p: "تاؤول",
        f: "taawawúl",
        e: "to spin",
        a: "26v49"
      }, {
        p: "پخول",
        f: "pakhawúl",
        e: "to cook",
        a: "26v50"
      }, {
        p: "پټول",
        f: "puTawúl",
        e: "to hide",
        a: "26v51"
      }, {
        p: "اِستعمالول",
        f: "istimaalawúl",
        e: "to use",
        a: "26v52"
      }, {
        p: "وادۀ کول",
        f: "waadú kawúl",
        e: "to marry",
        a: "26v53"
      }, {
        p: "بندول",
        f: "bandawúl",
        e: "to close",
        a: "26v54"
      }, {
        p: "ګټل",
        f: "gaTúl",
        e: "to gain/earn",
        a: "26v55"
      }, {
        p: "حاصلول",
        f: "haasilawúl",
        e: "to obtain",
        a: "26v56"
      }, {
        p: "تقسيمول",
        f: "taqseemawúl",
        e: "to divide",
        a: "26v57"
      }, {
        p: "جمع کول",
        f: "jáma kawúl",
        e: "to collect",
        a: "26v58"
      }, {
        p: "وړۀ کول",
        f: "ooRú kawúl",
        e: "to powder",
        a: "26v59"
      }, {
        p: "نيول",
        f: "neewúl",
        e: "to catch",
        a: "26v60"
      }, {
        p: "سمبالول",
        f: "sambaalawúl",
        e: "to dress",
        a: "26v61"
      }, {
        p: "کېښودل",
        f: "kexodúl",
        e: "to put",
        a: "26v62"
      }, {
        p: "اورېدل",
        f: "awredúl",
        e: "to hear",
        a: "26v63"
      }, {
        p: "څښل",
        f: "skul",
        e: "to drink",
        a: "26v64"
      }, {
        p: "ليکل",
        f: "leekúl",
        e: "to write",
        a: "26v65"
      }, {
        p: "بوتلل",
        f: "botlúl",
        e: "to carry",
        a: "26v66"
      }, {
        p: "پرېښودل",
        f: "prexodúl",
        e: "to quit",
        a: "26v67"
      }, {
        p: "شريکول",
        f: "shareekawúl",
        e: "to share",
        a: "26v68"
      }, {
        p: "رېبل",
        f: "rebúl",
        e: "to reap",
        a: "26v69"
      }, {
        p: "جنګ کول",
        f: "jang kawúl",
        e: "to fight",
        a: "26v70"
      }, {
        p: "وهل ټکول",
        f: "wahúl Takawúl",
        e: "to beat",
        a: "26v71"
      }, {
        p: "څټل",
        f: "tsaTúl",
        e: "to lick",
        a: "26v72"
      }, {
        p: "شمارل",
        f: "shmaarúl",
        e: "to count",
        a: "26v73"
      }, {
        p: "چکر وهل",
        f: "chakúr wahúl",
        e: "to take a trip",
        a: "26v74"
      }, {
        p: "کول",
        f: "kawúl",
        e: "to do",
        a: "26v75"
      }, {
        p: "تښتول",
        f: "tuxtawúl",
        e: "to kidnap",
        a: "26v76"
      }, {
        p: "بچ کول",
        f: "buch kawúl",
        e: "to save (rescue)",
        a: "26v77"
      }]}
    </Examples>
    <img src={horseGroup} />
    <h2 {...{
      "id": "comprehension-drill-speed-counting"
    }}>{`Comprehension drill: Speed counting`}</h2>
    <p>{`Ask and answer quick questions using mental arithmetic. For example:`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "دوه جمعه درې جمعه څلور څو شوې؟",
        f: "dwa jáma dre jama tsalór tso shwe?",
        e: "",
        a: "26c1"
      }, {
        p: "درې ځله شپږ څومره وى؟",
        f: "dre dzúla shpuG tsóomra wee?",
        e: "",
        a: "26c2"
      }, {
        p: "چې دولس په درېو کسانو تقسيم کړې، څومره يو کس ته رسى؟",
        f: "che dólus pu dre kasáano taqséem ke, tsóomra yo kas to rásee?",
        e: "",
        a: "26c3"
      }, {
        p: "چې د شپږو نه درې لرې کړې، نو څو پاتې شو؟",
        f: "che du shpuG na dre lúre kRe, no tso paate shoo?",
        e: "",
        a: "26c4"
      }]}
    </Examples>
    <h2 {...{
      "id": "memorising-moment"
    }}>{`Memorising moment`}</h2>
    <h4 {...{
      "id": "mataloona"
    }}>{`mataloona`}</h4>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoments[26]}</Examples>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-twenty-six-questions-questions-questions",
    level: 2,
    title: "Dialogue twenty-six: Questions, questions, questions",
    children: [
        
      ]
  },
{
    id: "should-have-the-subjunctive-mood-in-the-past-tense",
    level: 2,
    title: "'should have’: The subjunctive mood in the past tense",
    children: [
        
      ]
  },
{
    id: "expressing-an-unfulfilled-desire",
    level: 2,
    title: "Expressing an unfulfilled desire",
    children: [
        
      ]
  },
{
    id: "expressing-something-that-is-doubtful-or-contrary-to-fact",
    level: 2,
    title: "Expressing something that is doubtful or contrary to fact",
    children: [
        
      ]
  },
{
    id: "the-all-purpose-word-w-p-چې-f-che-e-who-whom-that-which-a-che-f3--",
    level: 2,
    title: <React.Fragment>{`The all purpose `}<Word w={{ p: "چې", f: "che", e: "who, whom, that, which", a: "che-f3" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "the-conjunction-word-w-p-چې-f-che-e--a-che-4f--",
    level: 2,
    title: <React.Fragment>{`The conjunction `}<Word w={{ p: "چې", f: "che", e: "", a: "che-4f" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "the-prefix-word-w-p-هر-f-hăr-e-ever--any--a-har-f2--",
    level: 2,
    title: <React.Fragment>{`The prefix `}<Word w={{ p: "هر", f: "hăr", e: "ever-, any-", a: "har-f2" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "improving-fluency-in-the-tenses",
    level: 2,
    title: "Improving fluency in the tenses",
    children: [
        {
              id: "drills",
              level: 3,
              title: "Drills:",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "comprehension-drill-speed-counting",
    level: 2,
    title: "Comprehension drill: Speed counting",
    children: [
        
      ]
  },
{
    id: "memorising-moment",
    level: 2,
    title: "Memorising moment",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Twenty-Six"
}

