
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.295,"time":77700,"words":259}
/* @jsxRuntime classic */
/* @jsx mdx */
import minaret from "../assets/images/minaret.png";
import Examples from "../components/Examples";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div style={{
      margin: "4rem 1rem",
      textAlign: "center"
    }}>
    <h1>SPEAKING PAKISTANI PUKHTO</h1>
    <h2 style={{
        maxWidth: "400px",
        margin: "0 auto"
      }}>A practical guide to learning the language  of Khyber Pukhtunkhwa</h2>
    </div>
    <h2 {...{
      "id": "preface"
    }}>{`Preface`}</h2>
    <p>{`This book is an attempt to help anyone wanting to learn the spoken language of modern Pakistani Pukhto. It was developed through the kindness and generosity of Pukhtun friends.`}</p>
    <p>{`Learning another language has to do with motivation. No greater motivation exists than the desire to respect and enjoy the great wisdom, wit and values of another culture. It is a goal that can only be achieved through shared language. As the great poet Rahman Baba says:`}</p>
    <Examples memorizingMoment mdxType="Examples">{[{
        p: ["واړه د خپل ځان په نظر ګوره که دا نا يې", "ای عبدالرحمانه درست جهان عبدالرحمان دی"],
        e: ["If you are wise, treat everyone like you do yourself.", "O Abdul Rahman, the whole world is Abdul Rahman."]
      }]}</Examples>
    <p>{`Robert Sampson`}<br parentName="p"></br>{`
`}{`Peshawer, 2020 `}</p>
    <h2 {...{
      "id": "on-the-current-digital-edition"
    }}>{`On the Current Digital Edition`}</h2>
    <p>{`What you’re viewing now is a `}<em parentName="p">{`DRAFT`}</em>{` revision and digitization of the original printed version. A few major changes include:`}</p>
    <ul>
      <li parentName="ul">{`Revision of some grammar explanations and terminology`}</li>
      <li parentName="ul">{`A new phonetic system which can easily typed by learners as no special symbols are required`}</li>
      <li parentName="ul">{`A much easier way of accessing the recordings; simply click on a word or example to hear the pronunciation`}</li>
    </ul>
    <p>{`The revision and development of this new digital version was done by `}<a parentName="p" {...{
        "href": "https://www.lingdocs.com/"
      }}>{`LingDocs`}</a>{`.`}</p>
    <img src={minaret} />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "preface",
    level: 2,
    title: "Preface",
    children: [
        
      ]
  },
{
    id: "on-the-current-digital-edition",
    level: 2,
    title: "On the Current Digital Edition",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Preface",
  "hideTitle": true
}

