export default [
  {
    p: "دا غټ او درُوند مېز دے.",
    f: "daa ghaT aaw droond mez day.",
    e: "This is a large and heavy table.",
    a: "daa-ghatt-aaw-d",
  },
  {
    p: "هغه موټر تور او سپین دے.",
    f: "hágha moTúr tor aaw speen day.",
    e: "That car is black and white.",
    a: "hagha-mottor-t",
  },
  {
    p: "تۀ ستړے يې؟",
    f: "tu stúRay ye?",
    e: "Are you tired?",
    a: "tu-sturray-ye-q",
  },
  {
    p: "نه، ډېر ستړے نۀ يم.",
    f: "na, Der stúRay nu yim.",
    e: "No, I'm not very tired.",
    a: "na-dder-sturry-n",
    mb: true,
  },
  {
    p: "دا غټ کتاب دے؟",
    f: "daa ghaT kitáab day?",
    e: "Is this a big book?",
    a: "daa-ghatt-kitaab-day",
  },
  {
    p: "آو، دا غټ کتاب دے.",
    f: "aaw, daa ghaT kitáab day.",
    e: "Yes, this is a big book.",
    a: "aaw-daa-ghatt-k",
    mb: true,
  },
  {
    p: "تۀ تږے يې؟",
    f: "tu túgay ye?",
    e: "Are you (m.) thirsty?",
    a: "tu-tugay-ye-q",
  },
  {
    p: "آو، زۀ ډېر تږے يم.",
    f: "aaw, zu Der túGay yim.",
    e: "Yes, I (m.) am very thirsty.",
    a: "aaw-zu-dder-tugay",
    mb: true,
  },
  {
    p: "هغه موټر کُوم رنګ دے؟",
    f: "hágha moTúr kum rang day?",
    e: "What colour is that car?",
    a: "hagha-mottur-kum-r",
  },
  {
    p: "هغه سپين او سُور دے.",
    f: "hágha speen aaw soor day.",
    e: "It's white and red.",
    a: "hagha-speen-aaw-s",
    mb: true,
  },
  {
    p: "دغه مېز درُوند دے؟",
    f: "dágha mez dróond day?",
    e: "Is this table heavy?",
    a: "dagha-mez-droond-q",
  },
  {
    p: "نه، دغه مېز درُوند نۀ دے، سپک دے.",
    f: "na, dágha mez droond nu day, spuk day.",
    e: "No this table is not heavy, it's light.",
    a: "na-dagha-mez-droond-n",
  },
  {
    p: "دغه څۀ شے دے؟",
    f: "dágha tsu shay day?",
    e: "What is that?",
    a: "dagha-tsu-shay-day",
  },
  {
    p: "دغه قلم دے.",
    f: "dágha qalúm day.",
    e: "It's a pen.",
    a: "dagha-qalum-day",
  },
  {
    p: "دا قلم څنګه دے؟",
    f: "daa qalúm tsínga day?",
    e: "How is this pen?",
    a: "daa-qalum-tsunga-day",
  },
  {
    p: "دا ښۀ قلم دے.",
    f: "daa xu qalúm day.",
    e: "It is a good pen.",
    a: "daa-xu-qalum-day",
  },
  {
    p: "هغه څۀ شے دے؟",
    f: "hágha tsu shay day?",
    e: "What thing is that?",
    a: "hagha-tsu-shay-day",
  },
  {
    p: "هغه موټر دے.",
    f: "hágha moTúr day.",
    e: "That is a car.",
    a: "hagha-mottur-day",
  },
  {
    p: "هغه موټر څنګه دے؟",
    f: "hágha moTur tsunga day?",
    e: "How is that car?",
    a: "hagha-mottur-tsunga",
  },
  {
    p: "هغه موټر ډېر خراب دے.",
    f: "hágha moTur Der kharaab day.",
    e: "That car is very bad.",
    a: "hagha-mottur-der-k",
  },
];
