
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"26 min read","minutes":25.13,"time":1507800,"words":5026}
/* @jsxRuntime classic */
/* @jsx mdx */
import Media from "react-media";
import Dialogue from "../components/Dialogue";
import Word from "../components/Word";
import Examples from "../components/Examples";
import Table from "../components/Table";
import Quiz from "../components/Quiz";
import rickshaw from "../assets/images/rickshaw.PNG";
import elderTurban from "../assets/images/elder-turban.png";
import memorizingMoment from "./memorizing-moments";
import palangFamily from "../assets/images/palang-family.png";


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <img src={rickshaw} style={{
      float: "right"
    }} />
    <h2 {...{
      "id": "dialogue-seven-rickshaw"
    }}>{`Dialogue Seven: “rickshaw”`}</h2>
    <Dialogue audio="7-dialogue" mdxType="Dialogue">
  {[{
        s: "L",
        p: "صدر ته څې؟",
        f: "súdur ta dze?",
        e: "Are you going to Saddar?",
        a: "7d1"
      }, {
        s: "H",
        p: "کښېنه، ځه چې ځو!",
        f: "kena, dza che dzoo!",
        e: "Sit down, let's go!",
        a: "7d2"
      }, {
        s: "L",
        p: "څومره پیسې به اخلې؟",
        f: "tsoomra payse ba akhle?",
        e: "How much money will you take?",
        a: "7d3"
      }, {
        s: "H",
        p: ["څه چې دې خوښه وی. (یا)", "څه چې درته مُناسب ښکاری."],
        f: ["tsu che de khwáxa wee. (yaa)", "tsu che dărta mUnaasíb xkaaree"],
        e: ["Whatever you like. (or)", "Whatever seems proper to you."],
        a: "7d4"
      }, {
        s: "L",
        p: "نه، ما ته ووایه.",
        f: "na, maa ta óowaaya.",
        e: "No, tell me.",
        a: "7d5"
      }, {
        s: "H",
        p: "اویا روپۍ.",
        f: "awyaa roopúy",
        e: "Seventy rupees.",
        a: "7d6"
      }, {
        s: "L",
        p: "نه، دا ډېرې دى. لږ کم شه.",
        f: "na, daa Dere dee. luG kum sha.",
        e: "No, that's too much. Make it less.",
        a: "7d7"
      }, {
        s: "H",
        p: "پينځۀ شپېته روپۍ راکه.",
        f: "peendzú shpeta roopúy ráaka.",
        e: "Give me sixty-five rupees.",
        a: "7d8"
      }, {
        s: "L",
        p: "ښه، شپېته روپۍ به درکم.",
        f: "xa, shpeta roopúy ba dărkum.",
        e: "OK, I'll give you sixty.",
        a: "7d9"
      }, {
        s: "L",
        p: "دلته ښى طرف ته تاؤ شه.",
        f: "dălta xee taráf ta taaw sha.",
        e: "Turn left here.",
        a: "7d10"
      }, {
        s: "L",
        p: "دلته ګڅ طرف ته تاؤ شه.",
        f: "dălta guts taráf ta taaw sha.",
        e: "Turn left here.",
        a: "7d11"
      }, {
        s: "L",
        p: "سیده ځه.",
        f: "séeda dza.",
        e: "Go straight on.",
        a: "7d12"
      }, {
        s: "L",
        p: "دلته مې کُوز که.",
        f: "dălta me kooz ka.",
        e: "Let me off here.",
        a: "7d13"
      }, {
        s: "L",
        p: "دلته ودرېږه",
        f: "dălta óodreGa! brek sha!",
        e: "Stop here! Brake!",
        a: "7d14"
      }]}
    </Dialogue>
    <h4 {...{
      "id": "note"
    }}>{`NOTE:`}</h4>
    <p>
  <Word w={{
        p: "ځه چې",
        f: "dza che",
        a: "dza-che"
      }} mdxType="Word" /> literally means 'come
  that you may', and is an idiomatic way of saying 'let's'.
    </p>
    <h2 {...{
      "id": "pronunciation-drill"
    }}>{`Pronunciation Drill`}</h2>
    <ol>
      <li parentName="ol">{`Contrasting `}<Word w={{
          p: "څ",
          f: "ts",
          a: "ts-sound"
        }} mdxType="Word" /> and <Word w={{
          p: "س, ص, ث",
          f: "s",
          a: "s-sound"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "دے ښه څېره لرى.",
        f: "day xa tserá larée.",
        e: "He has a good-looking face.",
        a: "7p1"
      }, {
        p: "دا ښۀ صُورت لرى.",
        f: "daa xu soorat larée.",
        e: "She has a good image.",
        a: "7p2"
      }, {
        p: "دا کوڅه ده.",
        f: "daa kootsá da.",
        e: "This is a street.",
        a: "7p3"
      }, {
        p: "دا غُصه ده.",
        f: "daa ghUsá da.",
        e: "She is angry.",
        a: "7p4"
      }]}
    </Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Contrasting `}<Word w={{
          p: "هٔ",
          f: "u",
          a: "u-sound"
        }} mdxType="Word" /> hamza on the final letter and <Word w={{
          p: "ه",
          f: "a",
          a: "a-sound"
        }} mdxType="Word" /></li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "زهٔ له دې سره وادهٔ کوم.",
        f: "zu la de sara waadú kawúm.",
        e: "I am getting married to her.",
        a: "7p5"
      }, {
        p: "زهٔ له هغهٔ سره وعده کوم.",
        f: "zu la haghú sara wadá kawúm.",
        e: "I am making her a promise.",
        a: "7p6"
      }, {
        p: "دا لرګی کاږهٔ دی.",
        f: "daa largée kaaGú dee.",
        e: "These bits of wood are bent.",
        a: "7p7"
      }, {
        p: "دا ونه کږه ده.",
        f: "daa wúna kaGá da.",
        e: "This tree is bent.",
        a: "7p8"
      }, {
        p: "دا خواږۀ دى.",
        f: "daa khwaaGú dee.",
        e: "These are sweet.",
        a: "7p9"
      }, {
        p: "دا خوږه ده.",
        f: "daa khwaGá da.",
        e: "This is sweet.",
        a: "7p10"
      }]}
    </Examples>
    <h2 {...{
      "id": "possessive-pronouns"
    }}>{`Possessive pronouns`}</h2>
    <p>{`The personal pronouns `}<Word w={{
        p: "زما",
        f: "zmaa",
        e: "mine",
        a: "zmaaf"
      }} mdxType="Word" /> and <Word w={{
        p: "د دهٔ",
        f: "du du",
        e: "his",
        a: "du-duf"
      }} mdxType="Word" />, etc., introduced in Lesson 3, form the possessive case, answering the question <Word w={{
        p: "د چا؟",
        f: "du chaa?",
        e: "whose?",
        a: "du-chaaf"
      }} mdxType="Word" />{`.`}</p>
    <Table bordered unset wide titleRow={["Person", "Singular", "Plural"]} mdxType="Table">
  {[[{
        e: "1st"
      }, {
        p: "زما",
        f: "zmáa",
        e: "my, mine",
        a: "zmaa"
      }, {
        p: "زمُونږ",
        f: "zmoonG",
        e: "our, ours",
        a: "zmoongg"
      }], [{
        e: "2nd"
      }, {
        p: "ستا",
        f: "staa",
        e: "your, yours",
        a: "staa"
      }, {
        p: "ستاسو",
        f: "staaso",
        e: "your, yours",
        a: "staaso"
      }], [{
        e: "3rd masc."
      }, {
        p: "د دهٔ",
        f: "du du",
        e: "his",
        a: "du-du"
      }, {
        p: "د دئ، د دوی",
        f: "du dey, du dwee",
        e: "their, theirs (near)",
        a: "du-dey-du-dwee"
      }], [{
        e: "3rd fem."
      }, {
        p: "د دې",
        f: "du de",
        e: "hers",
        a: "du-de"
      }, {
        p: "د دئ، د دوی",
        f: "du dey, du dwee",
        e: "their, theirs (near)",
        a: "du-dey-du-dwee"
      }], [{
        e: "3rd masc."
      }, {
        p: "د هغهٔ",
        f: "du haghú",
        e: "his (far)",
        a: "du-haghu"
      }, {
        p: "د هغئ، د هغوی",
        f: "du haghéy, du haghwée",
        e: "their, theirs (far)",
        a: "du-haghey-du-haghwee"
      }], [{
        e: "3rd fem."
      }, {
        p: "د هغې",
        f: "du haghé",
        e: "hers (far)",
        a: "du-haghe"
      }, {
        p: "د هغئ، د هغوی",
        f: "du haghéy, du haghwée",
        e: "their, theirs (far)",
        a: "du-haghey-du-haghwee"
      }]]}
    </Table>
    <h4 {...{
      "id": "note-1"
    }}>{`NOTE:`}</h4>
    <p>{`You will find that some speakers will habitually use either `}<Word w={{
        p: "دوی",
        f: "dwee",
        a: "dweef"
      }} mdxType="Word" /> or <Word w={{
        p: "دی",
        f: "dee",
        a: "deef"
      }} mdxType="Word" /> for objects close at hand, and <Word w={{
        p: "هغوی",
        f: "haghwee",
        a: "haghwee"
      }} mdxType="Word" /> or <Word w={{
        p: "هغۍ",
        f: "haghuy",
        a: "haghuyf"
      }} mdxType="Word" />{` for objects out of sight.`}</p>
    <h2 {...{
      "id": "statements-with-possives-pronouns-and-adjectives"
    }}>{`Statements with possives pronouns and adjectives`}</h2>
    <Table bordered wide titleRow={["Possesive pronoun", "Noun", "Adjective", "Verb"]} mdxType="Table">
  {[[{
        f: "zmaa"
      }, {
        f: "mez"
      }, {
        f: "plun"
      }, {
        f: "day."
      }, {
        span: 2,
        p: "زما مېز پلن دے.",
        e: "My table is wide.",
        a: "7s1"
      }], [{
        f: "zmoong"
      }, {
        f: "kor"
      }, {
        f: "waRóokay"
      }, {
        f: "day."
      }, {
        span: 2,
        p: "زمُونږ کور وړُوکے دے.",
        e: "Our house is small.",
        a: "7s2"
      }], [{
        f: "staa"
      }, {
        f: "wror"
      }, {
        f: "ghaT"
      }, {
        f: "day."
      }, {
        span: 2,
        p: "ستا ورور غټ دے.",
        e: "Your brother is fat.",
        a: "7s3"
      }], [{
        f: "stáaso"
      }, {
        f: "múshar"
      }, {
        f: "maaldáar"
      }, {
        f: "day?"
      }, {
        span: 2,
        p: "ستاسو مشر مالدار دے؟",
        e: "Is your elder rich?",
        a: "7s4"
      }], [{
        f: "du du"
      }, {
        f: "gaRúy"
      }, {
        f: "gráana"
      }, {
        f: "da."
      }, {
        span: 2,
        p: "د دۀ ګړۍ ګرانه ده.",
        e: "His watch is expensive.",
        a: "7s5"
      }], [{
        f: "du haghú"
      }, {
        f: "khor"
      }, {
        f: "xkÚle"
      }, {
        f: "da."
      }, {
        span: 2,
        p: "د هغۀ خور ښُکلې ده.",
        e: "His sister is pretty.",
        a: "7s6"
      }], [{
        f: "du de"
      }, {
        f: "kamées"
      }, {
        f: "loond"
      }, {
        f: "day."
      }, {
        span: 2,
        p: "د دې قميص لوند دے.",
        e: "Her shirt is wet.",
        a: "7s7"
      }], [{
        f: "du haghé"
      }, {
        f: "mor"
      }, {
        f: "stúRe"
      }, {
        f: "da."
      }, {
        span: 2,
        p: "د هغې مور ستړې ده.",
        e: "Her mother is tired.",
        a: "7s8"
      }], [{
        f: "du dwee"
      }, {
        f: "moTúr"
      }, {
        f: "zoR"
      }, {
        f: "day."
      }, {
        span: 2,
        p: "د دوی موټر زوړ دے.",
        e: "Their (nearby) car is old.",
        a: "7s9"
      }], [{
        f: "du haghwée"
      }, {
        f: "sabúq"
      }, {
        f: "graan"
      }, {
        f: "day."
      }, {
        span: 2,
        p: "د هغوى سبق ګران دے.",
        e: "Their (distant) lesson is hard.",
        a: "",
        a: "7s10"
      }], [{
        f: "du cháa"
      }, {
        f: "DoDúy"
      }, {
        f: "xa"
      }, {
        f: "da?"
      }, {
        span: 2,
        p: "د چا ډوډۍ ښه ده?",
        e: "Whose food is good?",
        a: "7s11"
      }]]}
    </Table>
    <h4 {...{
      "id": "note-2"
    }}>{`NOTE:`}</h4>
    <p>
  <Word w={{
        p: "پلن",
        f: "plun",
        e: "wide",
        a: "plun"
      }} mdxType="Word" /> finds a special use
  in the aggressive challenge:{" "}
  <Word w={{
        p: "پلن به دې کم",
        f: "plun ba de kum",
        e: "I will make you wide",
        a: "plun-ba-de-kum"
      }} mdxType="Word" />
    </p>
    <h2 {...{
      "id": "statements-and-questions-with-possessive-pronouns"
    }}>{`Statements and questions with possessive pronouns`}</h2>
    <Examples mdxType="Examples">
  {[{
        p: "دا ستا کتاب دے؟",
        f: "daa staa kitáab day?",
        e: "Is this your book?",
        a: "7e1"
      }, {
        p: "او، دا زما کتاب دے.",
        f: "aw, daa zmaa kitáab day.",
        e: "Yes, this is my book.",
        a: "7e2"
      }, {
        p: "زما پيشو چرته ده؟",
        f: "zmaa peeshó chărta da?",
        e: "Where is my cat?",
        a: "7e3"
      }, {
        p: "ستا پیشو په بام باندې ده.",
        f: "staa peesho pu baam báande da.",
        e: "Your cat is on the roof.",
        a: "7e4"
      }, {
        p: "ستاسو زوئ ناجوړه دے؟",
        f: "staaso zooy naajóRa day?",
        e: "Is your son sick?",
        a: "7e5"
      }, {
        p: "او، زمُونږ زوئ ناروغه دے.",
        f: "aw, zmoonG zooy naarógha day.",
        e: "Yes, our son is sick.",
        a: "7e6"
      }, {
        p: "ستاسو نوکر په بازار کښې دے؟",
        f: "stáaso nokúr pu baazáar ke day?",
        e: "Is your servant in the market?",
        a: "7e7"
      }, {
        p: "نه، زمُونږ نوکر په کور کښې دے.",
        f: "na, zmoonG nokúr pu kor ke day.",
        e: "No, our servant is in the house.",
        a: "7e8"
      }, {
        p: "د هغۀ څپلۍ دلته دى؟",
        f: "du haghú tsaplúy dălta dee?",
        e: "Are his sandals here?",
        a: "7e9"
      }, {
        p: "نه، د هغۀ څپلۍ دلته نشته.",
        f: "na, du haghú tsaplúy dălta nishta.",
        e: "No, his sandals are not here.",
        a: "7e10"
      }, {
        p: "د هغۀ واسکټ تور دے کۀ سپين؟",
        f: "du haghú waaskáT tor day, ku speen?",
        e: "Is his waistcoat black or white?",
        a: "7e11"
      }, {
        p: "د هغۀ واسکټ تور دے.",
        f: "du haghú waaskáT tor day.",
        e: "His waistcoat is black.",
        a: "7e12"
      }, {
        p: "د دې سترګه خوږ ده؟",
        f: "du de stúrga khoG da?",
        e: "Is her aye hurt?",
        a: "7e13"
      }, {
        p: "او، د دې سترګه خوږ ده.",
        f: "aw, du de stúrga khoG da.",
        e: "Yes, her aye is hurt.",
        a: "7e14"
      }, {
        p: "د هغې خور چرته ده؟",
        f: "du haghé khor chărta da?",
        e: "Where is her sister?",
        a: "7e15"
      }, {
        p: "د هغې خور په یونیورسټۍ کښې ده.",
        f: "du haghé khor pu yooneewărsiTuy ke da.",
        e: "Her sister is at university.",
        a: "7e16"
      }, {
        p: "هغه ستاسو لوښى دى؟",
        f: "hágha stáaso lóoxee dee?",
        e: "Are those your pots?",
        a: "7e17"
      }, {
        p: "نه، هغه د دوی لوښی دی.",
        f: "na, hágha du dwee lóoxee dee.",
        e: "No, those are their pots.",
        a: "7e18"
      }, {
        p: "د هغوی لُور چرته ده.",
        f: "du haghwee lóor chărta da?",
        e: "Where is their daughter?",
        a: "7e19"
      }, {
        p: "د هغوی لُور هلته ناسته ده.",
        f: "du haghwée loor hălta náasta da.",
        e: "Their daughter is sitting there.",
        a: "7e20"
      }, {
        p: "هلته د چا کتاب پروت دے؟",
        f: "hălta du chaa kitáab prot day?",
        e: "Whose book is lying there?",
        a: "7e21"
      }, {
        p: "هغه زما کتاب دے.",
        f: "hágha zmaa kitáab day.",
        e: "That is my book.",
        a: "7e22"
      }, {
        p: "د چا زوئ هوښیار دے؟",
        f: "du chaa zooy ooxyáar day?",
        e: "Whose son is intelligent?",
        a: "7e23"
      }, {
        p: "د هغوى زوئ هوښیار دے.",
        f: "du haghwee zooy ooxyáar day.",
        e: "Their son is intelligent.",
        a: "7e24"
      }, {
        p: "د چا ټوپۍ توره ده؟",
        f: "du chaa Topúy tóra da?",
        e: "Whose hat is black?",
        a: "7e25"
      }, {
        p: "ستا ټوپۍ توره ده.",
        f: "staa Topúy tóra da.",
        e: "Your hat is black.",
        a: "7e26"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-3"
    }}>{`NOTE:`}</h4>
    <p>{`Words like `}<Word w={{
        p: "ناست",
        f: "naast",
        e: "sitting",
        a: "naast"
      }} mdxType="Word" /> and <Word w={{
        p: "پروت",
        f: "prot",
        e: "lying",
        a: "prot"
      }} mdxType="Word" />{` function as adjectives and are inflected for both gender and number.`}</p>
    <h2 {...{
      "id": "possessive-pronouns-in-questions-and-answers"
    }}>{`Possessive pronouns in questions and answers`}</h2>
    <Examples mdxType="Examples">
  {[{
        p: "دا کتاب د چا دے؟",
        f: "daa kitáab du chaa day?",
        e: "Whose book is this?",
        a: "7e27"
      }, {
        p: "دا کتاب زما دے.",
        f: "daa kitáab zmaa day.",
        e: "This book is mine.",
        a: "7e28"
      }, {
        p: "هغه موټر د چا دے؟",
        f: "hágha moTúr du chaa day?",
        e: "Whose car is that?",
        a: "7e29"
      }, {
        p: "هغه موټر زمُونږ دے.",
        f: "hágha moTor zmóonG day.",
        e: "That car is ours.",
        a: "7e30"
      }, {
        p: "دا پِنسل د چا دے؟",
        f: "daa pinsúl du chaa day?",
        e: "Whose pencil is this?",
        a: "7e31"
      }, {
        p: "دا پِنسل ستاسو دے.",
        f: "daa pinsúl stáaso day.",
        e: "This pencil is yours.",
        a: "7e32"
      }, {
        p: "هغه تورې څپلۍ ستاسو دى؟",
        f: "hágha tore tsaplúy staaso dee?",
        e: "Are those black sandals yours?",
        a: "7e33"
      }, {
        p: "او، هغه زمُونږ دى.",
        f: "aw, hágha zmóonG dee.",
        e: "Yes, they are ours.",
        a: "7e34"
      }, {
        p: "دا سُور قلم د دۀ دے؟",
        f: "daa sóor qalúm du du day?",
        e: "Is this red pen his?",
        a: "7e35"
      }, {
        p: "نه، دا د دې دے.",
        f: "na, daa du de day.",
        e: "No it is hers.",
        a: "7e36"
      }, {
        p: "دا ښکُلې پيشو د هغۀ ده؟",
        f: "daa xkÚle peesho du haghu da?",
        e: "Is this lovely cat his?",
        a: "7e37"
      }, {
        p: "نه، دا د هغې ده.",
        f: "na, daa du haghe da.",
        e: "No, this is hers.",
        a: "7e38"
      }, {
        p: "هغه هوښيار هلک د چا دے؟",
        f: "hágha ooxyáar halúk du chaa day?",
        e: "Whose wise boy is that?",
        a: "7e39"
      }, {
        p: "هغه هلک د دوی دے.",
        f: "hágha haluk du dwee day.",
        e: "That boy is theirs.",
        a: "7e40"
      }, {
        p: "هغه تصوير د چا دے؟",
        f: "hágha taswéer du chaa day?",
        e: "Whose picture is that?",
        a: "7e41"
      }, {
        p: "هغه د مُوسىٰ دے.",
        f: "hágha du moosáa day.",
        e: "That is Moses's.",
        a: "7e42"
      }, {
        p: "دا ښۀ شِعر د چا دے؟",
        f: "daa xu shi'ir du chaa day?",
        e: "Whose good poem is this?",
        a: "7e43"
      }, {
        p: "دا د حافِظ دے.",
        f: "daa du haafíz day.",
        e: "This is Hafiz's.",
        a: "7e44"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-4"
    }}>{`NOTE:`}</h4>
    <p>
  The second person plural pronoun{" "}
  <Word w={{
        p: "ستاسو",
        f: "stáaso",
        a: "staasof"
      }} mdxType="Word" /> is used as a polite way
  to talk to an respected elder.
    </p>
    <p>{`For example the sentence:`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "دا پِنسل ستاسو دے؟",
        f: "daa pinsúl stáaso day?",
        e: "Is this pencil yours?",
        a: "pinsul-staaso"
      }]}
    </Examples>
    <img src={elderTurban} />
    <h2 {...{
      "id": "inflected-pronouns"
    }}>{`Inflected pronouns`}</h2>
    <p>{`When pronouns get attached to an adposition they take on this `}<strong parentName="p">{`inflected form`}</strong>{`.`}</p>
    <Table wide bordered unset titleRow={["Person", "Singular", "Plural"]} mdxType="Table">
  {[[{
        e: "1st"
      }, {
        p: "ما",
        f: "maa",
        e: "me",
        a: "maa-inf"
      }, {
        p: "مونږ",
        f: "moonG",
        e: "us",
        a: "moongg-inf"
      }], [{
        e: "2nd"
      }, {
        p: "تا",
        f: "taa",
        e: "you",
        a: "taa-inf"
      }, {
        p: "تاسو",
        f: "taaso",
        e: "you",
        a: "taaso-inf"
      }], [{
        e: "3rd m."
      }, {
        p: "ده",
        f: "du",
        e: "him (near)",
        a: "du-inf"
      }, {
        p: "دوی",
        f: "dwee",
        e: "them (near)",
        a: "dwee-inf"
      }], [{
        e: "3rd f."
      }, {
        p: "دې",
        f: "de",
        e: "her (near)",
        a: "de-inf"
      }, {
        p: "دوی",
        f: "dwee",
        e: "them (near)",
        a: "dwee-inf"
      }], [{
        e: "3rd m."
      }, {
        p: "هغهٔ",
        f: "haghú",
        e: "him (far)",
        a: "haghu-inf"
      }, {
        p: "haghwee",
        f: "haghwee",
        e: "them (far)",
        a: "haghwee-inf"
      }], [{
        e: "3rd f."
      }, {
        p: "هغې",
        f: "haghé",
        e: "her (far)",
        a: "haghe-inf"
      }, {
        p: "haghwee",
        f: "haghwee",
        e: "them (far)",
        a: "haghwee-inf"
      }], [{
        e: "Question"
      }, {
        p: "چا",
        f: "chaa",
        e: "whom?",
        a: "chaa-inf"
      }, {
        p: "چا",
        f: "chaa",
        e: "whom?",
        a: "chaa-inf"
      }]]}
    </Table>
    <h4 {...{
      "id": "notes"
    }}>{`NOTES:`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`These inflected pronouns are used when attached to adpositions (prepositions, postpositions, or circumpositions) like `}<Word w={{
            p: "سره",
            f: "sara",
            e: "with",
            a: "sara"
          }} mdxType="Word" /> and <Word w={{
            p: "ته",
            f: "ta",
            e: "to",
            a: "ta"
          }} mdxType="Word" />{`. This is what is called `}<em parentName="p">{`the oblique case`}</em>{`, which is introduced in Lesson 8.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Singular pronouns in the oblique case take on different forms. For example, the subject pronoun `}<Word w={{
            p: "زهٔ",
            f: "zu",
            e: "I"
          }} mdxType="Word" /> becomes the inflected pronoun <Word w={{
            p: "ما",
            f: "maa",
            e: "me"
          }} mdxType="Word" />{`. Plural pronouns do not inflect or change.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Inflected pronouns are also used in the past tense transitive sentences as “subject pronouns” (see Lesson 20).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`When using objects in present/future tense sentences, only the pronouns `}<Word w={{
            p: "ما",
            f: "maa",
            e: "me"
          }} mdxType="Word" /> and <Word w={{
            p: "تا",
            f: "taa",
            e: "you"
          }} mdxType="Word" />{` used in their inflected form. For any other pronouns, you do not use the inflected form when it is an object of a present/future sentence.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "inflected-pronouns-used-with-word-w-p-سره-f-sara-e-with--"
    }}>{`Inflected pronouns used with `}<Word w={{
        p: "سره",
        f: "sara",
        e: "with"
      }} mdxType="Word" /></h2>
    <Examples mdxType="Examples">
  {[{
        p: "زما کتاب تا سره دے؟",
        f: "zmaa kitáab taa sara day?",
        e: "Is my book with you?",
        a: "7e45"
      }, {
        p: "او، ستا کتاب ما سره دے.",
        f: "aw, staa kitaab maa sara day.",
        e: "Yes, your book is with me.",
        a: "7e46"
      }, {
        p: "زمُونږ اُستاذ دې سره دے؟",
        f: "zmoonG Ustáaz de sara day?",
        e: "Is our teacher with her?",
        a: "7e47"
      }, {
        p: "او، هغه دې سره دے.",
        f: "aw, haghá de sara day.",
        e: "Yes, he is with her.",
        a: "7e48"
      }, {
        p: "هغوى چا سره دى؟",
        f: "haghwee chaa sara dee?",
        e: "Who are they with?",
        a: "7e49"
      }, {
        p: "هغوى حَسن سن سره دى.",
        f: "haghwee hasán sara dee.",
        e: "They are with Hassan.",
        a: "7e50"
      }, {
        p: "دا تاسو سره شته؟",
        f: "daa taaso sara shta?",
        e: "Is it with you?",
        a: "7e51"
      }, {
        p: "او، مُونږ سره شته.",
        f: "aw, moonG sara shta.",
        e: "Yes, it is with us.",
        a: "7e52"
      }, {
        p: "د دۀ دارُو تا سره دى؟",
        f: "du du daaroo taa sara dee?",
        e: "Is his medicine with you?",
        a: "7e53"
      }, {
        p: "نه، د دۀ دارُو ما سره نشته.",
        f: "nu, du du daaroo maa sara nishta.",
        e: "No, his medicine is not with me.",
        a: "7e54"
      }, {
        p: "ستا موټر چا سره دے؟",
        f: "staa moTúr chaa sara day?",
        e: "Who has your car?",
        a: "7e55"
      }, {
        p: "هغه جان سره دے.",
        f: "hagha jaan sara day.",
        e: "It is with John.",
        a: "7e56"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-5"
    }}>{`NOTE:`}</h4>
    <p>{`The following expression is very useful when trying to match a piece of cloth at the tailors.`}</p>
    <Examples mdxType="Examples">
  {[{
        p: "دا تاسو سره شته؟",
        f: "daa táaso sara shta?",
        e: "Do you have this?",
        a: "7e57"
      }]}
    </Examples>
    <h2 {...{
      "id": "transitive-verbs"
    }}>{`Transitive Verbs`}</h2>
    <p>{`There are two kinds of verbs in Pukhto, and recognising them is as important as getting the masculine and feminine endings right. Verbs are either transitive or intransitive.`}</p>
    <p><strong parentName="p">{`Transitive verbs`}</strong>{` are active or “doing” verbs. They act on something, so they require a direct object. For example, to call, to send, to see, to write, to win, to take, to eat, to wash, to kill, to lick, to give, to spit, to beat, etc.`}</p>
    <h4 {...{
      "id": "the-transitive-verb-word-w-p-کول-f-kawul-e-to-do---used-as-an-auxiliary-verb"
    }}>{`The transitive verb `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "to do"
      }} mdxType="Word" />{` used as an auxiliary verb`}</h4>
    <Examples mdxType="Examples">
  {[{
        p: "تاسو چرته کار کوئ؟",
        f: "táaso chărta kaar kawéy?",
        e: "Where are you working?",
        a: "7e58"
      }, {
        p: "مُونږ په دفتر کښې کار کوو.",
        f: "moonG pu dăftúr ke kaar kawóo.",
        e: "We work in an office.",
        a: "7e59"
      }, {
        p: "دا تا سره کار کوى؟",
        f: "daa taa sara kaar kawee?",
        e: "Does she work with you?",
        a: "7e60"
      }, {
        p: "نه، ما سره کار نۀ کوى.",
        f: "na, maa sara kaar nu kawee.",
        e: "No she doesn't work with me.",
        a: "7e61"
      }, {
        p: "کريم صِحن جارُو کوى؟",
        f: "kareem sáhun jaaróo kawee?",
        e: "Is Karim sweeping the yard?",
        a: "7e62"
      }, {
        p: "نه، هغه کوټه جارُو کوى.",
        f: "na, haghá koTá jaaróo kawee.",
        e: "No, he is sweeping the room.",
        a: "7e63"
      }, {
        p: "حافِظه څۀ کوى؟",
        f: "haafiza tsu kawee?",
        e: "What is Hafiza doing?",
        a: "7e64"
      }, {
        p: "هغه کپړې اِسترى کوى.",
        f: "haghá kapRé istree kawee.",
        e: "She is ironing the cloth.",
        a: "7e65"
      }, {
        p: "زما خور ډېرې خبرې کوى.",
        f: "zmaa khor Dére khabure kawee.",
        e: "My sister talks a lot.",
        a: "7e66"
      }, {
        p: "دا چا سره خبرې کوى؟",
        f: "daa chaa sara khabúre kawee?",
        e: "Who does she talk with?",
        a: "7e67"
      }, {
        p: "دا ملګرې سره خبرې کوى.",
        f: "daa malgúre sara khabure kawee.",
        e: "She talks with her friend.",
        a: "7e68"
      }, {
        p: "ماشُومان اوس چرته لوبې کوى؟",
        f: "maashoomáan oos chărta lobe kawee?",
        e: "Where are the children playing now?",
        a: "7e69"
      }, {
        p: "هغوى په باغ کښې لوبې کوى.",
        f: "haghwee pu baagh ke lobe kawee.",
        e: "They are playing in the park.",
        a: "7e70"
      }, {
        p: "تۀ پښتو دستور زده کوې؟",
        f: "tu puxto dăstóor zda kawe?",
        e: "Are you learning Pukhto culture?",
        a: "7e71"
      }, {
        p: "او، زۀ پښتو دستور زده کوم.",
        f: "aw, zu puxto dăstóor zda kawum.",
        e: "Yes, I'm learning Pukhto culture.",
        a: "7e72"
      }, {
        p: "حبيب وادۀ کوى؟",
        f: "habeeb waadu kawee?",
        e: "Is Habib getting married?",
        a: "7e73"
      }, {
        p: "نه، هغه اوس وادۀ نۀ کوى.",
        f: "na, hagha oos waadu nu kawee.",
        e: "No, he is not getting married now.",
        a: "7e74"
      }]}
    </Examples>
    <h4 {...{
      "id": "note-6"
    }}>{`NOTE:`}</h4>
    <p>{`The `}<Word w={{
        p: "کول",
        f: "kawul",
        e: "",
        a: "kawul"
      }} mdxType="Word" /> verb endings are often shortened in speech. For example, in the sentences above the ending <Word w={{
        p: "کوی",
        f: "kawee",
        e: "",
        a: "kawee"
      }} mdxType="Word" /> is often shortened in speech to <Word w={{
        p: "کۍ",
        f: "kuy",
        a: "kuy"
      }} mdxType="Word" />{` even though the written form of the verb remains the same.`}</p>
    <h2 {...{
      "id": "comprehension-drill-learning-numbers-thirty-to-nine-thousand"
    }}>{`Comprehension drill: Learning numbers thirty to nine thousand`}</h2>
    <p>{`👆 Ask your helper to give you numbers divisible by 10 (e.g. 20, 360, 7890). You then repeat that number plus the next nine in the series. For example, 360, 361, 362 through to 369. After this, let him randomly call out numbers from 1 to 1,000. Your response should only be to write them down as you hear them. Have your helper check your answers.`}</p>
    <Examples twoColumns mdxType="Examples">
  {[{
        p: "۳۰ دېرش",
        f: "30 dersh",
        a: "dersh"
      }, {
        p: "۴۰ څلوېښت",
        f: "40 tsalwéxt",
        e: "",
        a: "tsalwext"
      }, {
        p: "۵۰ پنځوس",
        f: "50 pindzós",
        e: "",
        a: "pindzos"
      }, {
        p: "۶۰ شپېته",
        f: "60 shpetú",
        e: "",
        a: "shpetu"
      }, {
        p: "۷۰ اويا",
        f: "70 awyáa",
        e: "",
        a: "awyaa"
      }, {
        p: "۸۰ اتيا",
        f: "80 atyáa",
        e: "",
        a: "atyaa"
      }, {
        p: "۹۰ نوی، لس کم سل",
        f: "90 náwee, lus kum sil",
        e: "",
        a: "nawee-ya"
      }, {
        p: "۱۰۰ سل",
        f: "100 sil",
        e: "",
        a: "sil"
      }, {
        p: "۲۰۰ دوه سوه",
        f: "200 sawá",
        e: "",
        a: "dwa-sawa"
      }, {
        p: "۳۰۰ درې سوه",
        f: "300 dre sawá",
        e: "",
        a: "dre-sawa"
      }, {
        p: "۱۰۰۰ زر",
        f: "1,000 zur",
        e: "",
        a: "zur"
      }, {
        p: "۲۰۰۰ دوه زره",
        f: "2,000 dwa zará",
        e: "",
        a: "dwa-zara"
      }, {
        p: "۹۰۰۰ نههٔ زره",
        f: "9,000 núhu zará",
        e: "",
        a: "nuhu-zara"
      }]}
    </Examples>
    <h2 {...{
      "id": "memorising-moment"
    }}>{`Memorising moment`}</h2>
    <Examples memorizingMoment mdxType="Examples">{memorizingMoment[7]}</Examples>
    <h2 {...{
      "id": "exercises-for-lesson-seven"
    }}>{`Exercises for Lesson Seven`}</h2>
    <ol>
      <li parentName="ol">{`Replace each of the words listed below for the `}<strong parentName="li">{`bold`}</strong>{` word in the sentence:`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: <>
          زۀ غواړم چې <strong>پښتو</strong> زده کړم.
        </>,
        f: <>
          zu ghwáaRum che <strong>pukhtó</strong> zda kRum.
        </>,
        a: "7qe1"
      }]}
    </Examples>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "فارسی",
        f: "faarsée",
        a: "7qs1"
      }, {
        p: "اُردو",
        f: "Urdóo",
        a: "7qs2"
      }, {
        p: "انګرېزی",
        f: "angrezée",
        a: "7qs3"
      }]}
    </Examples>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Substitute the appropriate words in the sentence.`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "زۀ اوس کار کوم.",
        f: "zu oos kaar kawum.",
        e: "I am working now.",
        a: "7qe2"
      }]}
    </Examples>
    <Examples substitutions mdxType="Examples">
  {[{
        p: "جارو کوی",
        f: "jaroo kawee",
        e: "",
        a: ""
      }, {
        p: "په کوټه کښې",
        f: "pu koTa ke",
        e: "",
        a: ""
      }, {
        p: "کور",
        f: "kor",
        e: "",
        a: ""
      }, {
        p: "سبق",
        f: "sabúq",
        e: "",
        a: ""
      }, {
        p: "بښتو",
        f: "puxtó",
        e: "",
        a: ""
      }, {
        p: "خبرې کوی",
        f: "khabúre kawee",
        e: "",
        a: ""
      }, {
        p: "په دفتر کښې",
        f: "pu dăftúr ke",
        e: "",
        a: ""
      }, {
        p: "مونږ",
        f: "moonG",
        e: "",
        a: ""
      }, {
        p: "کار کوی",
        f: "kaar kawee",
        e: "",
        a: ""
      }, {
        p: "د دې ماشُومان",
        f: "du de maashoomáan",
        e: "",
        a: ""
      }, {
        p: "لوبې کوی",
        f: "lobe kawee",
        e: "",
        a: ""
      }, {
        p: "په باغ کښې",
        f: "pu baagh ke",
        e: "",
        a: ""
      }, {
        p: "خوب کوی",
        f: "khob kawee",
        e: "",
        a: ""
      }, {
        p: "حبیب",
        f: "habéeb",
        e: "",
        a: ""
      }, {
        p: "وادهٔ کوی",
        f: "waadú kawee",
        e: "",
        a: ""
      }]}
    </Examples>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Substitute the pairs of words for the `}<strong parentName="li">{`bold words`}</strong>{` in this sentence:`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: <>
          دا <strong>زما کور</strong> دے.
        </>,
        f: <>
          daa <strong>zmaa kor</strong> day.
        </>,
        e: "This is my house.",
        a: "7qe3"
      }]}
    </Examples>
    <Quiz mdxType="Quiz">
  {[{
        q: {
          p: "زمونږ کتاب",
          f: "zmoonG kitáab"
        },
        answer: [{
          p: "دا زمونږ کتاب دے.",
          f: "daa zmoonG kitáab day."
        }]
      }, {
        q: {
          p: "د هغهٔ پِنسل",
          f: "du haghú pinsúl"
        },
        answer: [{
          p: "دا د هغهٔ پِنسل دے.",
          f: "daa du haghú pinsúl day."
        }]
      }, {
        q: {
          p: "د دوی دفتر",
          f: "du dwee daftúr"
        },
        answer: [{
          p: "دا د دوی دفتر دے.",
          f: "daa du dwee daftúr day."
        }]
      }, {
        q: {
          p: "د هغې اُستاذ",
          f: "du haghé Ustáaz"
        },
        answer: [{
          p: "دا د هغې اُستاذ دے.",
          f: "daa du haghé Ustáaz day."
        }]
      }, {
        q: {
          p: "د هغوی څپلۍ",
          f: "du haghwee tsaplúy"
        },
        answer: [{
          p: "دا د هغوی څپلۍ دی.",
          f: "daa du haghwée tsaplúy dee."
        }, {
          p: "دا د هغوی څپلۍ ده.",
          f: "daa du haghwée tsaplúy da"
        }]
      }, {
        q: {
          p: "ستاسو سترګه",
          f: "staaso stúrga"
        },
        answer: [{
          p: "دا ستاسو سترګه ده.",
          f: "daa staaso stúrga da."
        }]
      }, {
        q: {
          p: "ستا باغ",
          f: "staa baagh"
        },
        answer: [{
          p: "دا ستا باغ دے.",
          f: "daa staa baagh day."
        }]
      }, {
        q: {
          p: "د هغوی اُوښان",
          f: "du haghwée ooxáan"
        },
        answer: [{
          p: "دا د هغوی اُوښان دی.",
          f: "daa du haghwee ooxáan dee."
        }]
      }]}
    </Quiz>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`In response to the question`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: "تا سره څوک کار کوی؟",
        f: "taa sara tsok kaar kawee?",
        e: "Who works with you?"
      }]}
    </Examples>
    <p>{`Reply using the following words in your answer:`}</p>
    <Quiz mdxType="Quiz">
  {[{
        q: {
          p: "زما مور",
          f: "zmaa mor"
        },
        answer: [{
          p: "زما مور ما سره کار کوی.",
          f: "zmaa mor maa sara kaar kawee."
        }]
      }, {
        q: {
          p: "زما زوئ",
          f: "zmaa zooy"
        },
        answer: [{
          p: "زما زوئ ما سره کار کوی.",
          f: "zmaa zooy maa sara kaar kawee."
        }]
      }, {
        q: {
          p: "د هغهٔ ورور",
          f: "du haghú wror"
        },
        answer: [{
          p: "د هغهٔ ورور ما سره کار کوی.",
          f: "du haghú wror maa sara kaar kawee."
        }]
      }, {
        q: {
          p: "زما لور",
          f: "zmaa loor"
        },
        answer: [{
          p: "زما لور ما سره کار کوی.",
          f: "zmaa loor maa sara kaar kawee."
        }]
      }, {
        q: {
          p: "هغوی",
          f: "haghwée"
        },
        answer: [{
          p: "هغوی ما سره کار کوی.",
          f: "haghwée maa sara kaar kawee."
        }]
      }, {
        q: {
          p: "یو ماشُوم",
          f: "yo maashóom"
        },
        answer: [{
          p: "یو ماشوم ما سره کار کوی",
          f: "yo maashóom maa sara kaar kawee."
        }]
      }]}
    </Quiz>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Replace the `}<strong parentName="li">{`bold parts`}</strong>{` in the sentence`}</li>
    </ol>
    <Examples mdxType="Examples">
  {[{
        p: <>
          <strong>ستا پیشو ما</strong> سره ده.
        </>,
        f: <>
          <strong>staa peesho maa</strong> sara da.
        </>,
        e: "Your cat is with me.",
        a: "staa-peesho-maa-sara-da"
      }]}
    </Examples>
    <Quiz mdxType="Quiz">
  {[{
        q: {
          p: "زږُونږ پیسې، تاسو",
          f: "zmoonG payse, taaso"
        },
        answer: [{
          p: "زمُونږ پیسې تاسو سره دی.",
          f: "zmoonG payse taaso sara dee."
        }]
      }, {
        q: {
          p: "د هغې قميص، د چا",
          f: "du haghe kamées, du chaa"
        },
        answer: [{
          p: "د هغې قمیص د چا سره دے.",
          f: "du haghe qamees du chaa sara day."
        }]
      }, {
        q: {
          p: "د دې لُور، مُونږ",
          f: "du de loor, moonG"
        },
        answer: [{
          p: "د دې لور مُونږ سره ده.",
          f: "du de loor moonG sara da."
        }]
      }, {
        q: {
          p: "ستا ورور، دۀ",
          f: "staa wror, du"
        },
        answer: [{
          p: "ستا ورور دهٔ سره دے.",
          f: "staa wror du sara day."
        }]
      }]}
    </Quiz>
    <img src={palangFamily} />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "dialogue-seven-rickshaw",
    level: 2,
    title: "Dialogue Seven: “rickshaw”",
    children: [
        
      ]
  },
{
    id: "pronunciation-drill",
    level: 2,
    title: "Pronunciation Drill",
    children: [
        
      ]
  },
{
    id: "possessive-pronouns",
    level: 2,
    title: "Possessive pronouns",
    children: [
        
      ]
  },
{
    id: "statements-with-possives-pronouns-and-adjectives",
    level: 2,
    title: "Statements with possives pronouns and adjectives",
    children: [
        
      ]
  },
{
    id: "statements-and-questions-with-possessive-pronouns",
    level: 2,
    title: "Statements and questions with possessive pronouns",
    children: [
        
      ]
  },
{
    id: "possessive-pronouns-in-questions-and-answers",
    level: 2,
    title: "Possessive pronouns in questions and answers",
    children: [
        
      ]
  },
{
    id: "inflected-pronouns",
    level: 2,
    title: "Inflected pronouns",
    children: [
        
      ]
  },
{
    id: "inflected-pronouns-used-with-word-w-p-سره-f-sara-e-with--",
    level: 2,
    title: <React.Fragment>{`Inflected pronouns used with `}<Word w={{ p: "سره", f: "sara", e: "with" }} /></React.Fragment>,
    children: [
        
      ]
  },
{
    id: "transitive-verbs",
    level: 2,
    title: "Transitive Verbs",
    children: [
        
      ]
  },
{
    id: "comprehension-drill-learning-numbers-thirty-to-nine-thousand",
    level: 2,
    title: "Comprehension drill: Learning numbers thirty to nine thousand",
    children: [
        
      ]
  },
{
    id: "memorising-moment",
    level: 2,
    title: "Memorising moment",
    children: [
        
      ]
  },
{
    id: "exercises-for-lesson-seven",
    level: 2,
    title: "Exercises for Lesson Seven",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Lesson Seven"
}

